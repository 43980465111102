import { connect } from 'react-redux';
import BlokUnit from '../../views/Settings/BlokUnit.view';
import {getUsernameOptions, getUserGroups, getSchoolUnitsOptions} from '../../states/thunks/options.thunk';
import { listResetPassword, resetPassword, listBlockUnits, setIsBlocked} from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleListBlockUnits: (payload, goback) => dispatch(listBlockUnits(payload, goback)),
    getUsernameOptions: (payload) => dispatch(getUsernameOptions(payload)),
    getUserGroups: (payload) => dispatch(getUserGroups(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    handleSaveBlockUnits: (payload, goback) => dispatch(setIsBlocked(payload, goback)),
  
};
}

export default connect(mapStateToProps, mapDispatchToProps)(BlokUnit);
