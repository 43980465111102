import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input, Select, Textarea } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";

export default function CetakSuratPindahForm(props) {
  const { onFormChange, form, data, param } = props;
  const semester = [
    { label: "Ganjil", value: "ganjil" },
    { label: "Genap", value: "genap" },
  ];
  const jenis = [
    { label: "Akademis", value: "akademis" },
    { label: "Non Akademis", value: "nonakademis" },
  ];
  const period = [
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
  ];
  var sem;
  if (form.value.semester === "ganjil"){
    sem="Ganjil";
  }else if (form.value.semester === "genap"){
    sem="Genap";
  }
  console.log(data)
  return (
    <div className="laporan-ki4__header-title3" style={{margin: 0}}>
      <div className="laporan-ki4__header-title3--left" style={{margin: 0}}>
        <table className="laporan-ki4__table-test">
          <tr>
            <td></td>
            <td>Nama</td>
            <td> : {data ? data.name : ""}</td>
          </tr>
          <tr>
            <td></td>
            <td>NIS</td>

            <td> : {data ? data.nis : ""}</td>
          </tr>
          <tr>
            <td></td>
            <td>Tema</td>
            <td> : {form.value.tema_name}</td>
          </tr>
        </table>
      </div>
      <div className="laporan-ki4__header-title3--right"  style={{width: "65%"}}>
        <table className="laporan-ki4__table-test">
          <tr>
            <td></td>
            <td>Semester</td>
            <td> : {sem}</td>
          </tr>
          <tr>
            <td></td>
            <td>Tahun Ajaran</td>
            <td> : {form.value.periods_name}</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
CetakSuratPindahForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
CetakSuratPindahForm.defaultProps = {
  onFormChange: noop,
};