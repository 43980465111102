import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, map, isEqual,
} from 'lodash';
import {
  Pagination,
  Loading,
  Button,
  Select,
  Input,
} from '../../../../components/base';
import language from '../../../../languages';
import { formatData } from '../../../../utils/transformer.util';
import { FINANCE_CASH_JOURNAL_TABLE_FIELDS } from '../../../../constants/finance/finance.constant';
import { deleteConfirmation } from '../../../../utils/alert.util';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

const monthOptions = () => {
  const options = [{ value: '', label: 'Semua' }];
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  for (let i = 0; i < 12; i += 1) {
    options.push({ value: i, label: months[i] });
  }
  return options;
};

const yearOptions = () => {
  const d = new Date();
  const year = d.getFullYear();
  const options = [{ value: '', label: 'Semua' }];
  for (let i = 2020; i <= year; i += 1) {
    options.push({ value: i, label: i });
  }
  return options;
};

const isPostedOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: 'Terposting' },
  { value: 0, label: 'Belum Terposting' },
];

const isCreditOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: 'Kas Masuk' },
  { value: 0, label: 'Kas Keluar' },
];

export default class CashJournal extends Component {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickActionButton = this._onClickActionButton.bind(this);
    this._onClickPostingButton = this._onClickPostingButton.bind(this);
    this._onClickUnpostButton = this._onClickUnpostButton.bind(this);
    this._onClickDeleteButton = this._onClickDeleteButton.bind(this);
    this.isMount = false;
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.customAction = [{
      Component: Button,
      props: {
        title: 'Preview',
        className: 'cash-journal__action-button',
        onClick: this._onClickActionButton,
      },
    }, {
      Component: Button,
      props: {
        title: 'Posting',
        className: 'cash-journal__posting-button',
        onClick: this._onClickPostingButton,
      },
    }];

    this.state = {
      content: initialContent,
      listAmount: 5,
      filters: {},
    };
  }

  componentDidMount() {
    const { content } = this.state;

    this.isMount = true;

    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  _onClickAdd() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-kas', { isEdit: false, ...state });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-kas', { data: { id: val.id }, isEdit: true });
  }

  _onClickActionButton(val) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/jurnal-kas/${val.id}`);
  }

  async _onClickPostingButton(val) {
    const { handlePostJournalPosting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalPosting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }

  _onClickUnpostButton(val) {
    const { handlePostJournalUnposting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    deleteConfirmation().then(async () => {
      const res = await handlePostJournalUnposting({
        id: val.id,
      });
      if (res) {
        this._onSearchContent({ page });
      }
    });
  }

  _onClickDeleteButton(val) {
    const { handleDeleteJournal } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    deleteConfirmation().then(async () => {
      const res = await handleDeleteJournal({
        id: val.id,
      });
      if (res) {
        this._onSearchContent({ page });
      }
    });
  }

  _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleGetCashJournal } = this.props;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleGetCashJournal({ ...params, filters });
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        }
      } catch (err) {
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    const buttons = [];
    const editButton = (
      <>
        <Button
          key="button_edit"
          onClick={() => this._onClickEdit(data)}
          title="Edit"
        />
      </>
    );
    const postButton = (
      <>
        <Button
          key="button_post"
          onClick={() => this._onClickPostingButton(data)}
          title="Post"
        />
      </>
    );
    const previewButton = (
      <>
        <Button
          key="button_preview"
          onClick={() => this._onClickActionButton(data)}
          title="Preview"
        />
      </>
    );
    const unpostButton = (
      <>
        {permissions.includes('unpost_journal') && (
          <Button
            key="button_unpost"
            onClick={() => this._onClickUnpostButton(data)}
            title="Unpost"
          />
        )}
      </>
    );
    const deleteButton = (
      <>
        {permissions.includes('unpost_journal') && (
          <Button
            key="button_delete"
            onClick={() => this._onClickDeleteButton(data)}
            title="Delete"
          />
        )}
      </>
    );

    if (data.is_posted === 0) {
      if (permissions.includes('create_journal')) {
        buttons.push(editButton, postButton);
      }

      if (permissions.includes('backdate_journal')) {
        buttons.push(previewButton);
      }
    } else {
      buttons.push(previewButton, unpostButton);
    }
    if (permissions.includes('delete_journal')) {
      buttons.push(deleteButton);
    }
    return buttons;
  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    const {
      listAmount,
      content,
      filters,
    } = this.state;
    const {
      month = '',
      year = '',
      is_credit = '',
      is_posted = '',
      journal_number = '',
    } = filters;
    const loadingProps = { show: content.loading };
    return (
      <div className="cash-journal">
        {
          permissions.includes('create_journal') && (
            <Button
              title="Tambah Jurnal Kas"
              onClick={this._onClickAdd}
            />
          )
        }
        <div className="cash-journal__content">
          <div className="content-table">
            <div className="table-filters">
              <Input
                placeholder="Nomor Jurnal"
                type="text"
                name="journal_number"
                value={journal_number}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Bulan Jurnal"
                name="month"
                value={month}
                data={monthOptions()}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Tahun Jurnal"
                name="year"
                value={year}
                data={yearOptions()}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Status Jurnal"
                name="is_posted"
                value={is_posted}
                data={isPostedOptions}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Jenis Jurnal"
                name="is_credit"
                value={is_credit}
                data={isCreditOptions}
                onChange={this._onChangeFilter}
              />
            </div>
            {
              content.loading
                ? <Loading loading={loadingProps} />
                : (
                  <table className="table">
                    <thead>
                      <tr>
                        {
                          map(FINANCE_CASH_JOURNAL_TABLE_FIELDS.label, (field, idx) => (
                            <th key={`table_th_${idx}`}>{field}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                      map(content.list, (list, idx) => (
                        <tr key={`user_management_tr_${idx}`}>
                          {
                            map(FINANCE_CASH_JOURNAL_TABLE_FIELDS.value, (field, fieldIdx) => {
                              if (fieldIdx === 0) {
                                return (
                                  <td key={`table_${fieldIdx}_${idx}`}>{(idx + 1) + ((content.currentPage - 1) * listAmount)}</td>
                                );
                              }
                              return (
                                <td
                                  className={(field.type === 'number') ? 'nominal' : ''}
                                  key={`table_${fieldIdx}_${idx}`}
                                >{formatData(list, field)}
                                </td>
                              );
                            })
                          }

                          <td>
                            <div className="table__actions">
                              {this._renderButtons(list)}
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                )
            }
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this._onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CashJournal.propTypes = {
  user: PropTypes.object,
  handleGetCashJournal: PropTypes.func,
  handlePostJournalPosting: PropTypes.func,
  handlePostJournalUnposting: PropTypes.func,
  handleDeleteJournal: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CashJournal.defaultProps = {
  user: {},
  handlePostJournalPosting: noop,
  handleGetCashJournal: noop,
  handlePostJournalUnposting: noop,
  handleDeleteJournal: noop,
};
