import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import pengisian_catatan_anekdot from '../../../assets/img/akademi/kurikulum/Ulangan/CatatanAnekdot/pengisian-catatan-anekdot.png';
import sub_tema from '../../../assets/img/akademi/kurikulum/Ulangan/CatatanAnekdot/sub-tema.png';
import tema from '../../../assets/img/akademi/kurikulum/Ulangan/CatatanAnekdot/tema.png';

export default class CatatanAnekdot extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickTema = this.onClickTema.bind(this);
    this.onClickSubTema = this.onClickSubTema.bind(this);
    this.onClickPengisianAnekdot = this.onClickPengisianAnekdot.bind(this);
    // this.onClickCatatanAnekdot = this.onClickCatatanAnekdot.bind(this);
  }

  onClickTema() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/catatan-anekdot/tema');
  }

  onClickSubTema() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema');
  }

  onClickPengisianAnekdot() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot');
  }

  

  render() {
    return (
      <div className="finance">
       
        <div className="finance__list">
          <button onClick={this.onClickTema}>
            <img src={tema} alt="psikafek" />
            <h3>Tema</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickSubTema}>
            <img src={sub_tema} alt="NilaiKognitif" />
            <h3>Sub Tema</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPengisianAnekdot}>
            <img src={tema} alt="observasiharian" />
            <h3>Pengisian Catatan Anekdot</h3>
          </button>
        </div>
      </div>
    );
  }
}
CatatanAnekdot.propTypes = {
  history: PropTypes.object.isRequired,
};
