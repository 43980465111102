import { connect } from 'react-redux';
import ImportPendaftaranSiswa from '../../views/Settings/ImportPendaftaranSiswa.student.view';
import { getYearsOfEducationsOptions, getPeriodsOptions } from '../../states/thunks/options.thunk';
import { uploadExcelStudents } from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      result: state.result,
      user: state.user || {},
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
      getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
      handleUploadExcelStudents: (payload, goBack) => dispatch(uploadExcelStudents(payload, goBack)),
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(ImportPendaftaranSiswa);