import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Select,
  Input,
  Button,
  Pagination,
  InputDate,
  SearchSelect,
} from "../../../../components/base";
import {
  EDIT_TAGIHAN_SISWA_FIELD_LIST,
  RIWAYAT_OFFLINE_TABLE_FIELD_LIST,
  RIWAYAT_H2H_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import language from "../../../../languages";

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class EditTagihanSiswa extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    // this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    // this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getUnitsVaCodeOptions = this._getUnitsVaCodeOptions.bind(this);
    this._getMsTempsSiswaOptions = this._getMsTempsSiswaOptions.bind(this);
    this._getPrmStudentGeneralOptions =
      this._getPrmStudentGeneralOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);
    this._onClickEditNon = this._onClickEditNon.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      list: [],
      filters: {},
      form: {
        value: {
          total: "",
          classes: [],
          levels_id,
          periods: "",
          listhistory: [],
          classes_id: "",
          pg_code: "",
          payload: [],
          listsiswa: [],
          listcicilan: [],
          listsiswa2: [],
          user_id: user.id,
        },
        error: {
          classes: "",
          levels: "",
          listhistory: "",
          listsiswa: "",
          listcicilan: "",
          listsiswa2: "",

        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;
    let savedFilters =
      JSON.parse(window.localStorage.getItem("invoiceStudentsFilters")) ||
      filters;
    let savedForm =
      JSON.parse(window.localStorage.getItem("invoiceStudentsForm")) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }

    console.log(savedFilters);
    console.log(savedForm);

    if (isEmpty(content.list)) {
      // this._onSearchContent({ page: 1 });
    }
    this._initializeData(savedFilters);
    if (savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          students_id: savedFilters.students_id,
        },
      }));

      if (organizations_id == 3) {
        // this._getUnitsVaCodeOptions();
        this._getMsTempsSiswaOptions({
          units_id: savedForm.value.units_id.attributes.va_code,
        });
      } else {
        // this._getSchoolUnitsOptions();
        this._getPrmStudentGeneralOptions({
          units_id: savedForm.value.units_id,
        });
      }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user;

    await this._getSchoolUnitsOptions();

     if ( organizations_id == 3){
      await this.getPrmPaymentsNon();
    }
    else {
    await this.getPrmPaymentsNonGeneral();
    }
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          students_id: savedFilters.students_id,
          // units_id : savedFilters.units_id,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _getPrmStudentGeneralOptions(filters = {}) {
    const { getPrmStudentGeneralOptions } = this.props;
    const res = await getPrmStudentGeneralOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async _getMsTempsSiswaOptions(filters = {}) {
    const { getMsTempsSiswaOptions } = this.props;
    const res = await getMsTempsSiswaOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getUnitsVaCodeOptions(filters = {}) {
    const { getUnitsVaCodeOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getUnitsVaCodeOptions(filters);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _onClickEdit(val) {
    const { filters, param } = this.state;
    const { students_id } = filters;
    const { history } = this.props;

    let student_name = "";
    let student_va = "";

    if (!isEmpty(students_id)) {
      let str = students_id.label;
      let result = str.indexOf("-");
      student_name = str.substring(result + 2, str.length);
      student_va = str.substring(0, result - 1);
    }
    // console.log("ini val" +val)
    history.push("/dashboard/tagihan-siswa/create-invoice/rutin", {
      getdata: val,
      isEdit: true,
      data: {
        user_id: 0,
        name: filters.students_id.label,
        id: filters.students_id.value,
        name: student_name,
        no_va: student_va,
        entrance_year: students_id.academics_year,
      },
    });
  }

  _onClickEditNon(val) {
    const { history, user } = this.props;
    const { organizations_id}  = user;
    // console.log("ini val" +val)
    if(organizations_id == 4 ||  organizations_id == 5){
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", {data: val, isEdit: true });
    }
    else {
      history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
    }
  }

  _onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    const { filters, param } = this.state;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem(
          "invoiceStudentsFilters",
          JSON.stringify(this.state.filters)
        );
        window.localStorage.setItem(
          "invoiceStudentsForm",
          JSON.stringify(this.state.form)
        );
        if (name === "units_id") {
          delete filters.students_id;
          const param = this.state.param.units;

          const va_code = param.find((i) => i.value == value.value)
            ? param.find((i) => i.value == value.value).attributes.va_code
            : "";

          if (organizations_id == 3) {
            this._getMsTempsSiswaOptions({ units_id: va_code });
          } else {
            this._getPrmStudentGeneralOptions({ units_id: [value.value] });
          }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                units_id: value,
              },
            },
            filters: {
              ...prevState.filters,
              students_id: [],
            },
          }));
        }
        this._onSearchContent();
      }
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    const { students_id, jenis, payments_id } = filters;

    console.log(filters.students_id);
    if (filters.date === "") {
      delete filters.date;
    }
    if (filters.date_to === "") {
      delete filters.date_to;
    }

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListStudentsSpp,
            handleListStudentsSppGeneral,
            handleListStudentsUppGeneral,
            handleListStudentsUpp,
          } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id } = user;
          let result = "";
          let result2 = "";
          if (organizations_id == 3 && jenis == "rutin") {
            result = await handleListStudentsSpp({
              ...params,
              organizations_id,
              students_id: students_id.value,
            });
          } else if (organizations_id == 3 && jenis == "nonrutin" && payments_id) {
            result2 = await handleListStudentsUpp({
              ...params,
              organizations_id,
              payments_id: payments_id,
              temps_id: students_id.value,
            });
          } else if(organizations_id !== 3 && jenis == "rutin"){
            result = await handleListStudentsSppGeneral({
              ...params,
              organizations_id,
              students_id: students_id.value,
            });
          } else if(organizations_id !== 3 && jenis == "nonrutin"){
            result2 = await handleListStudentsUppGeneral({
              ...params,
              organizations_id,
              students_id: students_id.value,
              payments_id: payments_id,
            });
          }

          // untuk set state list payment gateway dan offline
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result, 
                listsiswa2: result2,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
                listcicilan: [],
                listsiswa2: [],
              },
            },
          }));
        }
      }
    );
  }

  async _getStudentSPPOptions(filters = {}) {
    const { getStudentSPPOptions } = this.props;
    const res = await getStudentSPPOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { param, form, content, filters, listH2h } = this.state;
    const {
      date = "",
      date_to = "",
      payments_type,
      students_id,
      jenis,
      payments_id
    } = filters;
    const { listsiswa, listcicilan, listsiswa2 } = form.value;
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    const paymentstype = [
      { label: "Payment Gateway", value: "paymentgateway" },
      { label: "Offline", value: "offline" },
      { label: "H2H", value: "h2h" },
    ];
    const { prm_payments } = param;
    var count = 0;

    if (date > date_to) {
      form.error.date = "Format Tanggal Salah";
      form.error.date_to = "Format Tanggal Salah";
    } else {
      form.error.date = "";
      form.error.date_to = "";
    }

    const prm_jenis = [
      { label: organizations_id == 3 ? "SPP" : "RUTIN", value: "rutin" },
      {
        label: organizations_id == 3 ? "DPP/UPP" : "NON RUTIN",
        value: "nonrutin",
      },
    ];

    let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';
    let tr_payment_report = listsiswa2[0]?listsiswa2[0].tr_payment_report:'';

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Edit Tagihan Siswa</h1>
          <hr></hr>
        </div>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="units_id"
                list={param.units}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Unit"
                value={form.value.units_id}
                rightIcon="icon-search"
                label="Pilih Unit"
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Siswa"
                value={students_id}
                // value="061190207"
                rightIcon="icon-search"
                label="Siswa"
              />
            </div>
          </div>
          <br></br>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              {filters.students_id && (
                <Select
                  name="jenis"
                  label="Jenis Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_jenis}
                  placeholder="Pilihan"
                  value={jenis}
                />
              )}
              
            </div>
            <div className="absensi-rekap__custom-form-column__field">
            {(jenis === "nonrutin") && (
                  <Select
                    name="payments_id"
                    label="Nama Pembayaran"
                    onChange={this._onChangeFilter}
                    data={organizations_id == 3 ? prm_payments : prm_payments_main}
                    placeholder={"Pilihan"}
                    value={payments_id}
                  />
                )}
                </div>
          </div>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <div className="absensi-rekap__custom-form-column__field"></div>
            </div>
            {/* <div className="manage-registration__custom-form-row__field">
            </div> */}
          </div>
          <br></br>
        </div>

        <br></br>
        {jenis === "rutin" && filters.students_id && students_id != "" && (
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">List Tagihan Siswa</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        {map(
                          EDIT_TAGIHAN_SISWA_FIELD_LIST.label,
                          (field, idx) => (
                            <th
                              key={`table_th_${idx}`}
                              style={{
                                background: "#909090",
                                color: "#FFFFFF",
                                textAlign: "center",
                              }}
                            >
                              {field}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {map(listsiswa, (list, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {map(
                            EDIT_TAGIHAN_SISWA_FIELD_LIST.value,
                            (field, fieldIdx) => {
                              if (fieldIdx === 0) {
                                return (
                                  <td
                                    key={`table_index_${fieldIdx}_${idx}`}
                                    style={{ textAlign: "center" }}
                                  >
                                    {idx + 1}
                                  </td>
                                );
                              }
                              if (field.type === "nominal") {
                                return <td>{commaFormatted(list.nominal)}</td>;
                              }

                              return (
                                <td
                                  className={
                                    field.type === "number" ? "nominal" : ""
                                  }
                                  key={`table_${fieldIdx}_${idx}`}
                                >
                                  {formatData(list, field)}
                                </td>
                              );
                            }
                          )}

                          <td>
                            <Button
                              onClick={() => this._onClickEdit(list)}
                              title="Edit"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <div className="user-management__table-footer">
                    <p className="user-management__page-info">
                      {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                    </p>
                    <Pagination
                      totalPage={content.totalPage}
                      currentPage={content.currentPage}
                      onClick={this.onChangePage}
                    />
                  </div> */}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {jenis === "nonrutin" && filters.students_id && students_id != "" && payments_id && (
          <>
          {console.log(tr_payment_report)}
          {tr_payment_report.length !== 0?
          (
          <>
             <table className="laporan-ki4__table-test" style={{width:"100%"}}>
             <tr>
               <td>&emsp;</td>
                 <td width={"10%"} style={{padding: "10px"}}>
                   <Button
                   onClick={()=> this._onClickEditNon(listsiswa2[0])}
                   title = "Edit"
                 />
                  
                 </td>
             </tr>
           </table>
           <br></br>
            <table className="manage-rapbs-plafon__form-table table-invoice">
              <thead>
                <tr>
                  <th colSpan="2">
                    {organizations_id == 3
                      ? "List Tagihan Siswa DPP/UPP"
                      : "List Tagihan Siswa Non Rutin"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="2" style={{ border: "none" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="grey">No</th>
                          <th className="grey">Cicilan</th>
                          <th className="grey">Nominal</th>
                          <th className="grey">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(listsiswa2) &&
                          map(listsiswa2, (data, idx) => (
                            map(data.tr_payment_report[0]?data.tr_payment_report[0].paymentdetails:'', (item, idx) => (
                              <tr key={`budget_row_${idx}`}>
                                 {(() => {
                                    if (item.cicilan == "Cicilan 0") {
                                      item.cicilan = "DP"
                                    }
                                  })()}
                              <td
                                key={`table_index_${idx}`}
                                style={{ textAlign: "center" }}
                              >
                                {idx + 1}
                              </td>
                              <td>{item.cicilan}</td>
                              <td
                                className="nominal"
                                style={{
                                  width: "90px",
                                  minWidth: "90px",
                                  maxWidth: "90px",
                                }}
                              >
                                {commaFormatted(item.nominal)}
                              </td>
                              {(() => {
                                if (item.payments_date !== null) {
                                  return (
                                    <td
                                      className="reportspp"
                                      style={{
                                        color: "green",
                                        textAlign: "center",
                                      }}
                                    >
                                      LUNAS
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td
                                      className="reportspp"
                                      style={{
                                        color: "red",
                                        textAlign: "center",
                                        boxSizing: "unset",
                                      }}
                                    >
                                      BELUM LUNAS
                                    </td>
                                  );
                                }
                              })()}
                              {/* {isPrint?
                                        <td>
                                          <Button
                                            // onClick={()=> this._onClickEdit()}
                                            title = "Edit"
                                          />
                                        </td>
                                      :null} */}
                            </tr>
                            ))
                            
                          ))}
                      </tbody>
                    </table>
                    {/* <div className="user-management__table-footer">
                      <p className="user-management__page-info">
                        {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                      </p>
                      <Pagination
                        totalPage={content.totalPage}
                        currentPage={content.currentPage}
                        onClick={this.onChangePage}
                      />
                    </div> */}
                  </td>
                </tr>
              </tbody>
            </table>
            </>
        )
            :
            (
            <>
            <br></br>
            <br></br>
            <h2>Invoice belum dibuat.</h2>
            </>
            )
          }
        
          </>
        )}

        <br></br>
      </div>
    );
  }
}
EditTagihanSiswa.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
EditTagihanSiswa.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
