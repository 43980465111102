import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import { SearchSelect, Button, Select } from '../../../components/base';
import { commaFormatted } from '../../../utils/transformer.util';

const generateYear = () => {
  const year = new Date().getFullYear().toString().substr(-2);
  const data = [];
  for (let i = 6; i <= parseInt(year, 10); i += 1) {
    const val = i.toString().padStart(2, '0');
    const nextVal = (i + 1).toString().padStart(2, '0');
    data.push({
      value: val,
      label: `2${val.padStart(3, 0)}/2${nextVal.padStart(3, 0)}`,
    });
  }
  return data;
};

export default class Payment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        year: '',
        va_code: {},
      },
      report: [],
      va_code_options: [],
    };
    this._getReport = this._getReport.bind(this);
    this._getOptions = this._getOptions.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (prevWorkingUnit !== workingUnit) {
      this._getOptions({});
    }
  }

  async _getReport() {
    const { handleGetReport } = this.props;
    const { filters } = this.state;
    const { va_code = {}, year = '' } = filters;
    const res = await handleGetReport({
      va_code: va_code.value || '',
      year,
    });
    this.setState({ report: res });
  }

  async _getOptions({ keyword = '' }) {
    const { handleGetOptions } = this.props;
    const res = await handleGetOptions({ value: keyword, type: 'va-code' });
//    this.setState('va_code_options', res);
    return res;
  }

  _onChange(event) {
    const { value, name } = event.target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => {
      if (name === 'year') {
        this._getOptions({});
      }
    });
  }

  render() {
    const {
      filters, report = {}, va_code_options = []
    } = this.state;
    const {
      invoices = [],
      liabilities = [],
      student = {},
    } = report;
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus',
      'September', 'Oktober', 'November', 'Desember',
    ];
    const { year = 20, va_code } = filters;
    const { user } = this.props;
    const { school_unit } = user;
    const currentYear = new Date().getFullYear();
    const nextYear = parseInt(currentYear, 10) + 1;

    return (
      <div className="balance-report">
        <div className="balance-report__form">
          <Select
            type="date"
            label="Tahun Akademis"
            name="year"
            data={generateYear()}
            value={year}
            onChange={this._onChange}
          />
          <SearchSelect
            async
            type="text"
            label="Kode VA"
            name="va_code"
            value={va_code}
            onSearch={this._getOptions}
            onChange={this._onChange}
            labelName="label"
            valueName="value"
	    list={va_code_options}
          />
          <Button
            title="Cari"
            onClick={this._getReport}
          />
        </div>
        {
          report && (
            <div>
              <div className="report__title">
                <h1>Kartu Pembayaran SPP</h1>
                <h1>{school_unit ? school_unit.name : ''}</h1>
                <h3>
                  Tahun Ajaran {year ? `20${year}` : currentYear}
                  /{year ? `20${parseInt(year, 10) + 1}` : nextYear}
                </h3>
              </div>
              <div className="report__details">
                <br />
                <h4 className="payment_table__header">No. VA: {student.id}<br/></h4>
                <h4 className="payment_table__header">Nama Siswa: {student.name}</h4>
                <h4 className="payment_table__header">Kelas: {student.class} {student.paralel} {student.jurusan}</h4>

                { liabilities && !isEmpty(liabilities) && (
                  <>
                    <table className="payment_table__liabilities">
                      <thead>
                        <tr>
                          <th colSpan={2}><b>Data Kewajiban</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          liabilities.map(value => (
                            <tr key={`liabilities-${value.name}`}>
                              <td>{value.name}</td>
                              <td className="nominal"><span className="currency">Rp.</span>{commaFormatted(value.nominal)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="total"><b>Jumlah SPP</b></td>
                          <td className="total"><b><span className="currency">Rp.</span>
                            {
                              commaFormatted(liabilities.reduce((a, b) => a
                              + parseInt(b.nominal, 10), 0))
                            }
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </>
                )}
              </div>
              <div className="report__details">
                { invoices && !isEmpty(invoices) && (
                  <>
                    <table className="payment_table__report">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Bulan</th>
                          <th>Nominal</th>
                          <th>Tanggal Bayar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          invoices.map((value, index) => (
                            <tr key={`invoices-${value.periode_month}`}>
                              <td className="index">{index + 1}</td>
                              <td className="periode">{months[parseInt(value.periode_month, 10) - 1]}</td>
                              <td className="nominal"><span className="currency">Rp.</span>{commaFormatted(value.nominal)}</td>
                              <td className="date">{value.payments_date}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
Payment.propTypes = {
  user: PropTypes.object,
  handleGetReport: PropTypes.func,
  handleGetOptions: PropTypes.func,
};
Payment.defaultProps = {
  user: {},
  handleGetReport: noop,
  handleGetOptions: noop,
};
