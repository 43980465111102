import { connect } from 'react-redux';
import TambahKelas from '../../../views/Akademi/DataKelas/TambahKelas.view';
import { addBudget } from '../../../states/thunks/finance.thunk';
import { getClasses, getLevels, getMajorOptions, getPrmClassesOptions, getClassesOptions,getParamOptions, getTeacherOptions, 
  getParamOptionsAcademics, getPrmLevels, getMsStaffOptions } from '../../../states/thunks/options.thunk';
import { saveClassesData, getClassData, editClassData } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    classes:state.options.classes,
    levels: state.options.levels,
    prm_classes: state.options.prm_classes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPrmClassesOptions: (payload) => dispatch(getPrmClassesOptions(payload)),
    getTeacherOptions: (payload) => dispatch(getTeacherOptions(payload)),
    getMsStaffOptions: (payload) => dispatch(getMsStaffOptions(payload)),
    handleGetClassData: payload => dispatch(getClassData(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getMajorOptions: (payload) => dispatch(getMajorOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleSaveClassesData: (payload, goback) => dispatch(saveClassesData(payload, goback)),
    handleEditClassData: (payload, goback) => dispatch(editClassData(payload, goback)), 
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
   
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahKelas);