import { connect } from 'react-redux';
import InformationLetter from '../../../views/Personnel/Letter/AssingmentLetter.letter.view';
import { getInformationLetter, deleteInformationLetter,unpostingAssignmentLetter } from '../../../states/thunks/personnel.thunk';
import { getDataListST,getDataPegawai } from '../../../states/thunks/sk.thunks';
function mapStateToProps(state) {
  console.log(state)
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetInformationLetter: payload => dispatch(getDataListST(payload)),
    handleGetDataPegawai : payload => dispatch(getDataPegawai(payload)),
    // handleDeleteInformationLetter: payload => dispatch(deleteInformationLetter(payload)),
    handleUnpostingAssignmentLetter: (payload,goBack) => dispatch(unpostingAssignmentLetter(payload,goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationLetter);
