import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { noop, isEqual, map } from 'lodash';
import { Button } from '../base';
import { CanContextProvider, AuthContext } from '../../contexts/AuthContext';
import { Field }from '../base/BaseForm.component';

export default class BaseTable extends Component {
  constructor(props) {
    super(props);
    this.onClickAddList = this.onClickAddList.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this.onClicEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickPost = this.onClickPost.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
    this.onClickReject = this.onClickReject.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this.onChangeFilters = this.onChangeFilters.bind(this);

    this.renderButtons = this.renderButtons.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderStatus = this.renderStatus.bind(this);

    this.renderTableHead = this.renderTableHead.bind(this);

    this.setTableHead = this.setTableHead.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.handleGetData = this.handleGetData.bind(this);

    this.user = props.user;
    this.counter = 0;
    this.state = {
      filters: {},
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.handleGetData();
    this.setColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters: prevFilters } = prevState;
    const { filters } = this.state;

    if (!isEqual(filters, prevFilters)) {
      this.handleGetData();
    }
  }

  onClickAddList() {
    const { history } = this.props;
    history.push(`/dashboard/${this.addListPath}`, { isEdit: true });
  }

  onClickAdd() {
    const { history } = this.props;
    const { add } = this.actions;
    history.push(`/dashboard/${add.path}`, { isEdit: false });
  }

  onClickView(data) {
    const { history } = this.props;
    const { view } = this.actions;
    history.push(`/dashboard/${view.path}/${data.id}`, { data, isEdit: false });
  }

  onClickEdit(data) {
    const { history } = this.props;
    const { edit } = this.actions;
    history.push(`/dashboard/${edit.path}/${data.id}`, { data, isEdit: false });
  }

  onClickPreview(id) {
    const { history } = this.props;
    const { preview } = this.actions;
    history.push(`/dashboard/${preview.path}/${id}`, { data: id, isEdit: true });
  }

  onClickPrint(id) {
    const { history, urlPath } = this.props;
    const { print } = urlPath;
    history.push(print, { data: id, isEdit: true });
  }

  async onClickDelete(id) {
    const { handleDelete } = this.props;

    await handleDelete({
      id,
    });

    this.handleGetData();
  }

  onClickPost(id) {
    const { handlePost } = this.props;

    handlePost({
      id,
    });

    this.handleGetData();
  }

  onClickApprove(id) {
    const { handleApprove } = this.props;

    handleApprove({
      id,
    });

    this.handleGetData();
  }

  onClickReject(id) {
    const { handleReject } = this.props;

    handleReject({
      id,
    });

    this.handleGetData();
  }

  onChangeFilters(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => {
      this.handleGetData();
    });
  }

  setColumns() {
    const { columns = [] } = this.props;
    this.setState({ columns });
  }

  setTableHead() {
    this.setState({ tableHead: [] });
  }

  static contextType = AuthContext;

  async handleGetData() {
    const { handleGet } = this.props;
    const { filters = {} } = this.state;

    const res = await handleGet({
      ...filters,
    });

    this.setState(prevState => ({
      ...prevState,
      list: res,
      loading: false,
    }));
  }

  /**
   * [renderFilters description]
   * subject={
   * label:
   * name:
   * @return filter component [description]
   */
  renderFilters() {
    const { filters = {} } = this.props;
    const { filters: filtersState = '' } = this.state;
    const filterComponents = [];

    Object.keys(filters).forEach((filterKey) => {
      const { type, data = [], value = '' } = filters[filterKey];
      filterComponents.push((
        <Field
          type={type}
          name={filterKey}
          value={filtersState[filterKey]}
          data={data}
          onChange={this.onChangeFilters}
        />
      ));
    });

    return (
      <div className="table__filters">
        {filterComponents}
      </div>
    );
  }

  renderStatus(workflow = null) {
    if (!workflow || workflow.length === 0) {
      return (
        <td><i>Belum diajukan</i></td>
      );
    }
    const { user_group } = this.user;
    const userGroupName = user_group.name;
    const workflowLength = workflow.length;
    const lastWorkflow = workflow[workflowLength - 1] || {};
    const {
      next_role = '',
      prev_role = '',
      is_done = false,
      action = '',
    } = lastWorkflow;
    const roleMap = {
      'Keuangan Sekolah': 'Bendahara Unit',
      'Kepala Sekolah': 'Kepala Sekolah',
      'Bendahara Perwakilan': 'Bendahara Perwakilan',
      'Korektor Perwakilan': 'Perwakilan',
      'Ketua Perwakilan': 'Ketua Perwakilan',
      'Bendahara Pusat': 'Bendahara Pusat',
      'Korektor Pusat': 'Korektor Pusat',
      'Manager Keuangan': 'Manager Keuangan',
      'Accounting Pusat': 'Akunting Pusat',
      Bendahara: 'Pengurus',
    };

    let status = 'Telah diajukan ke';

    if (is_done) {
      status = 'Telah disetujui oleh';
      return (
        <td><i>{`${status} ${prev_role}`}</i></td>
      );
    }

    if (next_role !== userGroupName) {
      if (action === 'reject') {
        status = 'Telah dikembalikan ke';
      }

      return (
        <td><i>{`${status} ${roleMap[next_role]}`}</i></td>
      );
    }
    status = 'Telah diajukan oleh';
    if (action === 'reject') {
      status = 'Telah dikembalikan oleh';
    }
    return (
      <td><i>{`${status} ${roleMap[prev_role]}`}</i></td>
    );
  }

  renderButtons(data = null) {
    if (data) {
      return (
        <div className="table_actions">
          { this.editPath
            && (
            <CanContextProvider action="edit" subject={this.subject} workflow={data.workflow}>
              <Button
                onClick={() => this.onClickEdit(data)}
                icon="icon-edit"
                fullsize={false}
              />
            </CanContextProvider>
            )
          }
          { this.deletePath
            && (
            <CanContextProvider action="delete" subject={this.subject} workflow={data.workflow}>
              <Button
                onClick={() => this.onClickDelete(data.id)}
                icon="icon-trash-empty"
                fullsize={false}
              />
            </CanContextProvider>
            )
          }
          { this.viewPath
            && (
            <CanContextProvider action="view" subject={this.subject} workflow={data.workflow}>
              <Button
                onClick={() => this.onClickView(data)}
                icon="icon-doc-text"
                fullsize={false}
              />
            </CanContextProvider>
            )
          }
        </div>
      );
    }

    return false;
  }

  renderTableHead() {
    const tableHead = [
      this.renderFilters(),
      this.addListPath && (
        <CanContextProvider action="create" subject={this.subject} key="button-add-list" value={this.context}>
          <Button
            onClick={this.onClickAddList}
            icon="icon-list-add"
            fullsize={false}
          />
        </CanContextProvider>
      ),
    ];

    return (
      <div className="base-table-head">
        {tableHead}
      </div>
    );
  }

  render() {
    const { columns, list = {}, loading = true } = this.state;
    const { title } = this.props;
    return (
      <div className="data-table-wrapper">
        <DataTable
          columns={columns}
          data={list}
          noHeader={typeof title === 'undefined'}
          title={title}
          subHeader
          subHeaderComponent={this.renderTableHead()}
          pagination
          paginationServer
          paginationDefaultPage={1}
          paginationTotalRows={3}
          persistTableHead
          responsive
          fixedHeader
          fixedHeaderScrollHeight="50vh"
          progressPending={loading}
        />
      </div>
    );
  }
}

BaseTable.propTypes = {
  handleGet: PropTypes.func,
  handleDelete: PropTypes.func,
  handleApprove: PropTypes.func,
  handlePost: PropTypes.func,
  handleReject: PropTypes.func,
  history: PropTypes.object.isRequired,
  urlPath: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  filters: PropTypes.object,
  buttons: PropTypes.object,
  user: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

BaseTable.defaultProps = {
  handleGet: noop,
  handleDelete: noop,
  handleApprove: noop,
  handlePost: noop,
  handleReject: noop,
  filters: {},
  buttons: {},
  columns: [],
};
