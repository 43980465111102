import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import { commaFormatted, dateFormat } from "../../../../utils/transformer.util";
import Button from "../../../../components/base/Button/Button.component";
import { printBukuInduk } from "../../../../utils/print.util";
import ListForm from "../../../../components/ListForm/ListForm.component";
import { LAPORAN_8355_FORM } from "../../../../constants/student.constant";
import { Input, Select } from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";
import ReactToPrint from "react-to-print";
import noimg from '../../../../assets/img/no-img-profile.png';


function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    // this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);

  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  // async onGetDetails() {
  //   const { handleGetCashJournal, match } = this.props;
  //   const { id } = match.params;
  //   try {
  //     const payload = await handleGetCashJournal({ id });
  //     this.setState({
  //       journal: payload,
  //     });
  //   } catch (err) {
  //     // err action
  //   }
  // }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const { data, listscore, filters, param, isPrint } = this.props;
    const semester = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];
    
  
    return (
      <div id="divPrint" style={{ border: "0px solid black", padding: "2rem", pageBreakInside: 'avoid' }}>
        <div>
          {/* <div className="report__content2">
          <Select
          name="kelas"
          onChange={this._onFormChange}
          data={ '7A', 'kelas7a'}
          placeholder={'Kelas 7A'}
          />  
          </div> */}
            {/* <Button
            title="Cetak Ke Printer"
            onClick={() => {
              onClickPrint('detail-cash-journal__header',
                'detail-cash-journal__table',
                'detail-cash-journal__footer');
            }}
          /> */}
          <div id="cetak-buku-induk2" className="cetak-buku-induk2">
            {
              <div className="cetak-buku-induk2__content">
                <div className="cetak-buku-induk2__header">
                  <div className="cetak-buku-induk2__header-title">
                    <div className="cetak-buku-induk2__header-title--column">
                      <div className="cetak-buku-induk2__header-title--bold">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        <p>II. LEMBAR BUKU INDUK SISWA</p>
                      </div>
                      <table className="cetak-buku-induk2__table-test_nisn">
                        <tr>
                          <td>NOMOR INDUK SISWA/NISN: </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data.nisn ? data.nisn : data.nis}{" "}
                          </td>
                        </tr>
                      </table>
                      {/* <p> </p><p class="font-weight-bold"></p> */}
                    </div>
                  </div>
                  <div className="cetak-buku-induk2__header-title3">
                    <div className="cetak-buku-induk2__header-title3--left">
                      <table className="cetak-buku-induk2__table-test">
                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>A. KETERANGAN TENTANG DIRI SISWA</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>&emsp;</td>
                          <td style={{width: "25px"}}>1. </td>
                          <td colSpan={2}>Nama Siswa </td>
                          <td></td>
                          <td></td>
                          <td rowSpan={13}>
                            <div className="cetak-buku-induk2__title4">
                              <div className="cetak-buku-induk2__box--square2" style={{ width: "200px", height: "260px", padding: 0 }}>
                                <img
                                  alt="Foto" style={{ width: "195px", height: "255px", padding: "3px 0 0 3px",}}
                                  src={data.url_image==null?noimg:data.url_image}
                                />
                              </div>
                              <div className="cetak-buku-induk2__box--square">
                                Cap Tiga Jari Kiri
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td colSpan={2}>Nama Lengkap </td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.first_name} &nbsp; {data.last_name}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td colSpan={2}>Nama Panggilan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.first_name}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>2. </td>
                          <td colSpan={2}>Jenis Kelamin</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.genders_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>3. </td>
                          <td colSpan={2}>Tempat dan Tanggal Lahir</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>
                            {" "}
                            {data.pob}, &nbsp; {data.dob}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>4. </td>
                          <td colSpan={2}>Agama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.religions_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>5. </td>
                          <td colSpan={2}>Kewarganegaraan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.citizenships_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>6. </td>
                          <td colSpan={2}>Anak Keberapa</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.nth_child} </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>7. </td>
                          <td colSpan={2}>Jumlah Saudara Kandung</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.siblings} </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>8. </td>
                          <td colSpan={2}d>Jumlah Saudara Tiri</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>9. </td>
                          <td colSpan={2}>Jumlah Saudara Angkat</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>10. </td>
                          <td colSpan={2}>Anak Yatim Piatu</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>- </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>11. </td>
                          <td colSpan={2}>Bahasa Sehari-hari Dirumah</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td>{data.language} </td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>B. KETERANGAN TEMPAT TINGGAL</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>12. </td>
                          <td colSpan={2}>Alamat</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.address}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>13. </td>
                          <td colSpan={2}>Nomor Telepon</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.phone}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>14. </td>
                          <td colSpan={2}>Tinggal dengan orang tua/<br/>saudara/di Asrama/kost</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.house_ownership}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>15. </td>
                          <td colSpan={2}>Jarak tempat tinggal - Rumah</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.school_house_distance} km</td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>C. KETERANGAN KESEHATAN</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>16. </td>
                          <td colSpan={2}>Golongan Darah</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.blood_types_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>17. </td>
                          <td colSpan={2}>Penyakit yang pernah diderita</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.disabilities}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>18. </td>
                          <td colSpan={2}>Kelainan Jasmani</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>-</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>19. </td>
                          <td colSpan={2}>Tinggi dan Berat Badan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.height} cm &emsp; &emsp; {data.weight}kg</td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>D. KETERANGAN PENDIDIKAN</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>20. </td>
                          <td colSpan={2}>Pendidikan Sebelumnya</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>a. </td>
                          <td>Lulusan dari</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.previous_school_name}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>b. </td>
                          <td>Tanggal dan Nomor STTB</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.date_of_transfer}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>c. </td>
                          <td>Lama Belajar</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.long_study}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>21. </td>
                          <td colSpan={2}>Pindahan</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>a. </td>
                          <td>Dari Sekolah</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.previous_school_name}</td>
                        </tr>
                        <tr>
                        <td></td>
                          <td></td>
                          <td style={{width: "20px"}}></td>
                          <td></td>
                          <td style={{padding:"0 5px 0 5px"}}> </td>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                        <td></td>
                          <td></td>
                          <td style={{width: "20px"}}></td>
                          <td></td>
                          <td style={{padding:"0 5px 0 5px"}}> </td>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>b. </td>
                          <td>Alasan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.reason_move}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>22. </td>
                          <td colSpan={2}>Diterima di Sekolah ini</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>a. </td>
                          <td>Dikelas</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.classes_id} {data.levels_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>b. </td>
                          <td>Program</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{width: "20px"}}>c. </td>
                          <td>Tanggal</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}></td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>E. KETERANGAN TENTANG AYAH KANDUNG</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>23. </td>
                          <td colSpan={2}>Nama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_name}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>24. </td>
                          <td colSpan={2}>Tempat dan Tanggal Lahir</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_pob}, {data.father_dob}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>25. </td>
                          <td colSpan={2}>Agama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_religions_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>26. </td>
                          <td colSpan={2}>Kewarganegaraan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_citizenships_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>27. </td>
                          <td colSpan={2}>Pendidikan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_educations_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>28. </td>
                          <td colSpan={2}>Pekerjaan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_occupations_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>29. </td>
                          <td colSpan={2}>Penghasilan / bulan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>Rp. {commaFormatted(data.father_salary)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>30. </td>
                          <td colSpan={2}>Alamat rumah dan telepon</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_address}, {data.father_phone}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>31. </td>
                          <td colSpan={2}>Masih hidup / meninggal</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.father_status}</td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>F. KETERANGAN TENTANG IBU KANDUNG</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>32. </td>
                          <td colSpan={2}>Nama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_name}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>33. </td>
                          <td colSpan={2}>Tempat dan Tanggal Lahir</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_pob}, {data.mother_dob}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>34. </td>
                          <td colSpan={2}>Agama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_religions_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>35. </td>
                          <td colSpan={2}>Kewarganegaraan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_citizenships_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>36. </td>
                          <td colSpan={2}>Pendidikan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_educations_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>37. </td>
                          <td colSpan={2}>Pekerjaan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_occupations_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>38. </td>
                          <td colSpan={2}>Penghasilan / bulan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>Rp. {commaFormatted(data.mother_salary)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>38. </td>
                          <td colSpan={2}>Alamat rumah dan telepon</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_address}, {data.mother_phone}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>40. </td>
                          <td colSpan={2}>Masih hidup / meninggal</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.mother_status}</td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>G. KETERANGAN TENTANG WALI</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>41. </td>
                          <td colSpan={2}>Nama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_name ? data.guardian_name : "-"}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>42. </td>
                          <td colSpan={2}>Tempat dan Tanggal Lahir</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_pob}, {data.guardian_dob}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>43. </td>
                          <td colSpan={2}>Agama</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_religions_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>44. </td>
                          <td colSpan={2}>Kewarganegaraan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_citizenships_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>45. </td>
                          <td colSpan={2}>Pendidikan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_educations_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>46. </td>
                          <td colSpan={2}>Pekerjaan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_occupations_id}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>47. </td>
                          <td colSpan={2}>Penghasilan / bulan</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>Rp. {commaFormatted(data.guardian_salary)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>48. </td>
                          <td colSpan={2}>Alamat rumah dan telepon</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.guardian_address}, {data.guardian_phone}</td>
                        </tr>
                        <br/>

                        <tr>
                          <td colSpan={7}>
                            <div className="cetak-buku-induk2__title">
                              <p>H. KEGEMARAN SISWA</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>49. </td>
                          <td colSpan={2}>Hobi</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}>{data.hobby}</td>
                        </tr>
                        {/* <tr>
                          <td></td>
                          <td style={{width: "25px"}}>50. </td>
                          <td colSpan={2}>Olahraga</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>51. </td>
                          <td colSpan={2}>Kemasyarakatan / Organisasi</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{width: "25px"}}>52. </td>
                          <td colSpan={2}>Lain-lain</td>
                          <td style={{padding:"0 5px 0 5px"}}> : </td>
                          <td colSpan={2}></td>
                        </tr> */}
                        <br/>

                      </table>
                    </div>
                  </div>

                  {/* <div className="cetak-buku-induk2__title">  
                        <p >I. KETERANGAN PERKEMBANGAN SISWA</p>
                      </div>
                      <div className="cetak-buku-induk2__header-title4">
                            <table className="cetak-buku-induk2__table-test7">
                                <tr> 
                                    <td></td>    
                                    <td>53. Menerima Beasiswa</td>
                                    <td>  : &emsp; &emsp; Tahun </td> 
                                    <td> &emsp; /Kelas</td>
                                    <td>&emsp;dari</td>
                                </tr> 
                                <tr> 
                                    <td></td>    
                                    <td>&emsp; &emsp; &emsp;</td>
                                    <td>  &nbsp; &emsp; &emsp; Tahun </td> 
                                    <td> &emsp; /Kelas</td>
                                    <td>&emsp;dari</td>
                                </tr> 
                                <tr> 
                                    <td></td>    
                                    <td>&emsp; &emsp; &emsp;</td>
                                    <td>  &nbsp; &emsp; &emsp; Tahun </td> 
                                    <td> &emsp; /Kelas</td>
                                    <td>&emsp;dari</td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>54. Meninggalkan sekolah</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; a. Tgl Meninggalkan Sekolah</td>
                                  <td> : {data.date_of_exit}</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; b. Alasan</td>
                                  <td> : {data.transfer_to}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>55. Akhir Pendidikan</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; a. Tamat Belajar</td>
                                  <td> : &emsp; &nbsp;Tahun </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; b. STTB Nomor</td>
                                  <td> : </td>
                                </tr>
                               
                            </table> 
                      </div>

                      <div className="cetak-buku-induk2__title">  
                        <p >J. KETERANGAN SETELAH SELESAI PENDIDIKAN</p>
                      </div>
                      <div className="cetak-buku-induk2__header-title4">
                            <table className="cetak-buku-induk2__table-test8">
                                
                                <tr>
                                    <td></td>
                                    <td>56. Melanjutkan di</td>
                                    <td> : </td>
                                </tr>
                                
                                <tr>
                                    <td></td>
                                    <td>57. Bekerja</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; a. Tanggal mulai kerja</td>
                                  <td> :  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; b. nama perusahaan / lembaga</td>
                                  <td> : </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; c. Penghasilan</td>
                                  <td> : </td>
                                </tr>
                               
                            </table> 
                      </div> */}
                </div>
                
                <div className="cetak-buku-induk2__body">
                  {isPrint && (
                    <>
                      <div className="manage-registration__custom-form-row">
                        <div className="manage-registration__custom-form-row__field">
                          <Select
                            name="period"
                            label="Tahun Ajaran"
                            placeholder={"Pilihan"}
                            onChange={this._onFormChange}
                            data={param.periods}
                            value={filters.period || ""}
                          />
                        </div>
                        <div className="manage-registration__custom-form-column__field">
                          <Select
                            name="semester"
                            label="Semester"
                            placeholder={"Pilihan"}
                            onChange={this._onFormChange}
                            data={semester}
                            value={filters.semester || ""}
                          />
                        </div>
                      </div>
                      <div className="manage-registration__custom-form-row">
                        <div className="manage-registration__custom-form-row__field">
                          <Select
                            name="subject_id"
                            label="Mata Pelajaran"
                            placeholder={"Pilihan"}
                            onChange={this._onFormChange}
                            data={param.subject}
                            value={filters.subject_id || ""}
                          />
                        </div>
                        <div className="manage-registration__custom-form-column__field">
                        </div>
                      </div>
                      <br></br>
                    </>
                  )}

                  <table className="manage-rapbs-plafon__form-table table-invoice">
                    <thead>
                      <tr>
                        <th colSpan="2">Daftar Nilai</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td style={{padding: "5px"}}>
                        <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                          {(filters.period && filters.period != "") && (
                            <tr>
                              <td style={{border:"none"}}>Tahun Ajaran </td>
                              <td style={{border:"none"}}>&emsp; : {!isEmpty(param.periods) && param.periods.find((i) => i.value == filters.period).label}</td>
                            </tr>
                          )}
                          {(filters.semester && filters.semester != "") && (
                            <tr>
                              <td style={{border:"none"}}>Semester </td>
                              <td style={{border:"none"}}>&emsp; : {semester.find((i) => i.value == filters.semester).label}</td>
                            </tr>
                          )}
                        </table>
                        <br></br>
                        <table className="cetak-buku-induk2__table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Mata Pelajaran</th>
                              <th>Skor</th>
                              {!(filters.period && filters.period != "") && (
                                <th>Tahun Ajaran</th>
                              )}
                              {!(filters.semester && filters.semester != "") && (
                                <th>Semester</th>
                              )}
                              <th>Nama Guru</th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(listscore, (list, idx) => (
                              <tr key={`budget_row_${idx}`}>
                                {(() => {
                                  list.semester_label = semester.find((i) => i.value == list.semester) ? semester.find((i) => i.value == list.semester).label : ""
                                  list.period_label = param.periods.find((i) => i.value == list.period) ? param.periods.find((i) => i.value == list.period).label : ""
                                })()}
                                <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                                  {idx + 1}
                                </td>
                                <td>{list.subject_name}</td>
                                <td style={{textAlign:"center"}}>{list.score_students}</td>
                                {!(filters.period && filters.period != "") && (
                                  <td style={{textAlign:"center"}}>{list.period_label}</td>
                                )}
                                {!(filters.semester && filters.semester != "") && (
                                  <td style={{textAlign:"center"}}>{list.semester_label}</td>
                                )}
                                <td>{list.teacher}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tbody>
                  </table>
                </div>


                {/* <div className="cetak-buku-induk2__body">
                <table className="cetak-buku-induk2__table">
                  <thead>
                    <tr>
                      <th rowSpan={5}>No. </th>
                      <th rowSpan={5}>Mata Pelajaran</th>
                      <th colSpan={8}>Tahun Pelajaran</th>
                      <th rowSpan={4} colSpan={2}>Nilai Ujian Akhir</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>.../...</th>
                      <th colSpan={2}>.../...</th>
                      <th colSpan={2}>.../...</th>
                      <th colSpan={2}>.../...</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Kelas...</th>
                      <th colSpan={2}>Kelas...</th>
                      <th colSpan={2}>Kelas...</th>
                      <th colSpan={2}>Kelas...</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Nilai Prestasi</th>
                      <th colSpan={2}>Nilai Prestasi</th>
                      <th colSpan={2}>Nilai Prestasi</th>
                      <th colSpan={2}>Nilai Prestasi</th>
                    </tr>
                    <tr>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Tulis</th>
                      <th>Praktik</th>
                    </tr>
                    

                  </thead>
                  <tbody>
                   
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2" className="nominal">Jumlah</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="nominal">Rata-rata</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                      <td colSpan="1" rowSpan="3" className="nominal">Kegiatan Ekstrakulikuler</td>
                      <td className="nominal">1............</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                    <td className="nominal">2............</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">3............</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                      <td colSpan="1" rowSpan="4" className="nominal">Perilaku</td>
                      <td className="nominal">1. Kelakuan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                    <td className="nominal">2. Kerajinan/Kedisiplinan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">3. Kerjainan/Kebersihan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">4. Ketaatan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>

                    <tr>
                      <td colSpan="1" rowSpan="3" className="nominal">Ketidakhadiran</td>
                      <td className="nominal">1. Sakit</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                    <td className="nominal">2. Izin</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">3. Alpha</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                      <td colSpan="2" rowSpan="2" className="nominal">Keputusan Hasil Belajar</td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      
                     
                      
                    </tr>
                    
                  </tfoot>
                </table>
              </div> */}
                {/* <div className="cetak-8355__footer">
                  <div className="cetak-8355__footer-bottom">
                    <div>
                      <p>Diserahkan oleh:</p>
                      <p>{journal.submitted_by}</p>
                    </div>
                    <div>
                      <p>Diterima oleh:</p>
                      <p>{journal.accepted_by}</p>
                    </div>
                    <div>
                      <p>Dibukukan oleh:</p>
                      <p>{journal.booked_by}</p>
                    </div>
                    <div>
                      <p>Diketahui oleh:</p>
                      <p>Name 4</p>
                    </div>
                  </div>
                </div> */}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default class CetakBukuSiswa extends Component {
  constructor(props) {
    super(props);
    this._setForm = this._setForm.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);

    this.state = {
      data: {},
      listscore: [],
      filters: {},
      param: {},
      isPrint: true,
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, id } = state;
    const { units_id, school_unit } = user;

    const paramTypes = ["classrooms", "levels"];
    this._getSubjectOptions({ filters: { units_id: [units_id] } });
    this._getPeriodsOptions({units_id: school_unit.id});
    if (id) {
      this._setForm(id);
    }
  }

  async _setForm(id, data = {}) {
    const { handleGetStudentNumber } = this.props;
    try {
      const payload = await handleGetStudentNumber({ id });
      this.setState((prevState) => ({
        ...prevState,
        data: payload || {},
      }));
      this._onSearchContent();
    } catch (err) {
      // err action
    }
  }

  async _onSearchContent() {
    const { filters } = this.state;
    const { location } = this.props
    const { state = {} } = location;
    const { id } = state;

    try {
      const { handleListScoreStudent, user } = this.props;
      const { organizations_id } = user;

      if (filters.period == "") {
        delete filters.period
      }
      if (filters.semester == "") {
        delete filters.semester
      }
      if (filters.subject_id == "") {
        delete filters.subject_id
      }
      const result = await handleListScoreStudent({
        organizations_id: organizations_id,
        students_id: id,
        filters,
      });
      this.setState((prevState) => ({
        ...prevState,
        listscore: result || [],
      }));

      // }
    } catch (err) {
      
    }
    // }
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions(filters);

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          },
        }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  render() {
    const { data, listscore, param, filters, isPrint } = this.state;
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid
      }
    }
    `;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <h1>Laporan Kesiswaan</h1>
                  <hr></hr>
                  <br></br>
                  <div>
                    <ReactToPrint
                      pageStyle={printTest}
                      onBeforeGetContent={()=> {
                        return new Promise((resolve) => {
                          setTimeout(() => {
                            this.setState(
                              { isPrint: false },
                              resolve
                            );
                          }, 500);
                        });
                      }}
                      onAfterPrint={()=> this.setState({isPrint: true})}
                      trigger={() => (
                        <Button style={{ marginBottom: 10 }} block>
                          Cetak
                        </Button>
                      )}
                      content={() => this.componentRef}
                    />
                    <ComponentToPrint
                      data={data}
                      listscore={listscore}
                      param={param}
                      filters={filters}
                      onChange={this._onChangeFilter}
                      isPrint={isPrint}
                      ref={(el) => (this.componentRef = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
CetakBukuSiswa.propTypes = {
  handleGetCashJournal: PropTypes.func,
  match: PropTypes.object.isRequired,
};
CetakBukuSiswa.defaultProps = {
  handleGetCashJournal: noop,
};
