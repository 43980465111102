import { connect } from 'react-redux';
import H2HJournal from '../../../../views/Finance/Bookkeeping/H2HJournal/H2HJournal.finance.view';
import { getH2HJournal } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetH2HJournal: payload => dispatch(getH2HJournal(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(H2HJournal);
