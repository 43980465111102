import { connect } from 'react-redux';
import CashJournalClosingList from '../../../../views/Finance/Bookkeeping/CashJournal/CashJournalClosingList.finance.view';
import {
  getCashClosingJournalList,
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user || {},
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCashClosingJournalList: payload => dispatch(getCashClosingJournalList(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CashJournalClosingList);
