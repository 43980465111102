import { connect } from 'react-redux';
import LaporanGuruS3 from '../../../views/Akademi/LaporanIndividual/LaporanGuruS3.view';
import { getParamOptionsAcademics, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listReportGuruS3, getHeadmaster } from '../../../states/thunks/academics.thunk';
function mapStateToProps(state){
    return{
        user: state.user,
        result: state.result,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleListReportGuruS3: (payload) => dispatch(listReportGuruS3(payload)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
        getHeadmaster: (payload) => dispatch(getHeadmaster(payload)),
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanGuruS3);