import { connect } from 'react-redux';
import ManageInformationLetter from '../../../views/Personnel/Letter/ManageAssingmentLetter.letter.view';
import { manageInformationLetterST } from '../../../states/thunks/personnel.thunk';
import {getDataDeskripsiST,getDataGolongan,getStatusKepegawaianST,getRegionOptions,getUnitKerjaOptions,
  getDataJabatan,getDataST, honorarium,getDataPegawai } from '../../../states/thunks/sk.thunks';
import {
  getAktifNonAktifOptions, getStaffJabatanOptions,
} from '../../../states/thunks/options.thunk';

function mapStateToProps(state){
  return{
    user: state.user,
    unitkerja: state.options.unitkerja,
    region: state.options.region,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    handleManageInformationLetter: (payload, goBack, isEdit = false) => (
      dispatch(manageInformationLetterST(payload, goBack, isEdit)) ),
    handelgetDataDeskripsi: payload => dispatch(getDataDeskripsiST(payload)),
    handelgetDataGolongan: payload => dispatch(getDataGolongan(payload)),
    handelgetDataJabatan: payload => dispatch(getDataJabatan(payload)), 
    handelgetStatusKepegawaianST: payload => dispatch(getStatusKepegawaianST(payload)), 
    handleGetAktifNonAktifOptions: payload => dispatch(getAktifNonAktifOptions(payload)),
    handleGetDataSK : payload => dispatch(getDataST(payload)),
    handleGetHonorarium : payload => dispatch(honorarium(payload)),
    handleGetUnitKerjaOptions: payload => dispatch(getUnitKerjaOptions(payload)),
    handleGetStaffJabatanOptions: payload => dispatch(getStaffJabatanOptions(payload)),
    handleGetDataPegawai: payload => dispatch(getDataPegawai(payload)),
    handleGetRegionOptions: payload => dispatch(getRegionOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInformationLetter);
