import { connect } from 'react-redux';
import CashJournalClosing from '../../../../views/Finance/Bookkeeping/CashJournal/CashJournalClosing.finance.view';
import {
  getCashClosingJournal, saveCashClosingJournal, getCashClosingJournalLastBalance
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCashClosingJournal: payload => dispatch(getCashClosingJournal(payload)),
    saveCashClosingJournal: payload => dispatch(saveCashClosingJournal(payload)),
    getCashClosingJournalLastBalance:
      payload => dispatch(getCashClosingJournalLastBalance(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CashJournalClosing);
