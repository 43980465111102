import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Pagination } from '../../../components/base';
import { FileInput, Input, Select, Textarea, Button } from '../../../components/base/index';
import BaseTable from '../../../components/BaseTable/BaseTable.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { formatData } from '../../../utils/transformer.util';
import language from '../../../languages';
import swal from 'sweetalert';

export default class ElearningView extends BaseTable{
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
        <div className="fund-request">
          <div className="fund-request__content">
            <Input
                type="text"
                name="id_murid"
                label="Murid"
                // onChange={onFormChange}
                // value={form.value.tgl_selesai}
                // error={form.error.tgl_selesai || ''}
            />
            <Input
                type="text"
                name="id_organisasi"
                label="Organisasi"
                // onChange={onFormChange}
                // value={form.value.tgl_selesai}
                // error={form.error.tgl_selesai || ''}
            />
            <Input
                type="text"
                name="id_kelas"
                label="Kelas"
                // onChange={onFormChange}
                // value={form.value.tgl_selesai}
                // error={form.error.tgl_selesai || ''}
            />
            <div className="finance">
                <div className="finance__list">
                <button>
                    <img src={""} alt="No Image" />
                    <h3>Soal 1</h3>
                </button>
                <Button
                    type="button"
                    title="Unpublish"
                />
                <Button
                    type="button"
                    title="Edit"
                />
                </div>            
                <div className="finance__list">
                <button>
                    <img src={""} alt="No Image" />
                    <h3>Soal 2</h3>
                </button>
                <Button
                    type="button"
                    title="Unpublish"
                />
                <Button
                    type="button"
                    title="Edit"
                />
                </div>    
            </div>
          </div>
          <div className="user-management__table-footer">
            <Button
                type="button"
                title="Daftar Nilai"
            />
            <Button
                type="button"
                title="Buat Soal"
            />
            {/* <p className="user-management__page-info">
              {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
            </p> */}
            <Pagination
              totalPage={3}
              currentPage={1}
              // onClick={"#"}
            />
          </div>
        </div>
      );
    }
  }