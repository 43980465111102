import { connect } from 'react-redux';
import CreateInvoice from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoice.view';
import { saveNotifProcess, listNotifProcess, listStudentInvoice, listNotifProcessGeneral, saveNotifProcessGeneral, listStudentInvoiceGeneral } from '../../../../states/thunks/academics.thunk';
import { getPeriodsOptions, getPrmLevels, getClassesOptions, getParamOptionsAcademics, getSchoolUnitsOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},

  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleSaveNotifProcess: (payload, goback) => dispatch(saveNotifProcess(payload, goback)),
    handleSaveNotifProcessGeneral: (payload, goback) => dispatch(saveNotifProcessGeneral(payload, goback)),
    handleListNotifProcess: (payload, goback) => dispatch(listNotifProcess(payload, goback)),
    handleListNotifProcessGeneral: (payload, goback) => dispatch(listNotifProcessGeneral(payload, goback)),
    handleListStudentInvoice: (payload, goback) => dispatch(listStudentInvoice(payload, goback)),
    handleListStudentInvoiceGeneral: (payload, goback) => dispatch(listStudentInvoiceGeneral(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
