import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from '../../../../components/base';
import { commaFormatted } from '../../../../utils/transformer.util';

export default class CashJournalClosingList extends Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._addClosingJournal = this._addClosingJournal.bind(this);
    this.state = {
      filters: {
        from: new Date(),
        to: new Date(),
      },
      data: [],
    };
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { getCashClosingJournalList } = this.props;
    const { filters } = this.state;

    const res = await getCashClosingJournalList({ filters });
    this.setState({ data: res });
  }

  _onChange(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  }

  _addClosingJournal() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/keuangan/pembukuan/jurnal-kas-penutup/tambah', { isEdit: false, ...state });
  }

  render() {
    const { filters, data = [] } = this.state;

    return (
      <div className="cash-journal-closing">
        <form onSubmit={this._onSubmit}>
          <div className="form-group-member">
            <Button
              type="button"
              title="Tambah"
              onClick={this._addClosingJournal}
            />
          </div>
          <div className="form-group inline">
            <div className="form-group-member">
              <Input
                type="date"
                label="Dari"
                name="from"
                value={filters.from}
                onChange={this._onChange}
              />
            </div>
            <div className="form-group-member">
              <Input
                type="date"
                label="Sampai"
                name="to"
                value={filters.to}
                onChange={this._onChange}
              />
            </div>
            <div className="form-group-member">
              <Button
                type="submit"
                title="Tampilkan"
              />
            </div>
          </div>
          <div className="cash-journal-closing-table">
            <table className="content">
              <thead>
                <tr>
                  <th>Tanggal</th>
                  <th>Saldo</th>
                  <th>Selisih</th>
                </tr>
              </thead>
              <tbody>
                { data.map(row => (
                  <tr>
                    <td>{row.date}</td>
                    <td>{commaFormatted(row.balance)}</td>
                    <td>{commaFormatted(row.diff)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    );
  }
}


CashJournalClosingList.propTypes = {};
CashJournalClosingList.defaultProps = {};
