import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Pagination } from '../../../components/base';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import FormListSoal from './components/formlistsoal.component';
import TabelListSoal from './components/tabellistsoal.component';

export default class listsoal extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.state = {
      form: {
        value: {
          organization_id: '',
          date: '',
          judul: '',
          status: '',
          tabellistsoal: [
            {
              date: '',
              judul: '',
              mata_pelajaran: '',
              organization_id: '',
            }
          ],
        },
        error: {
            tabellistsoal: '',
        },
      },
      param: {},
    };
  }
  componentDidMount() {
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { tabellistsoal } = form.value;
    tabellistsoal.push({
        date: '',
        judul: '',
        mata_pelajaran: '',
        organization_id: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabellistsoal,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  async onSubmit() {
    
  }
  async onEdit() {
  }
  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { tabellistsoal } = prevState.form.value;
      const list = prevState.form.value.tabellistsoal;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabellistsoal: list
      };
    });
  }
  _onClickSubmit() {
    const { history } = this.props;
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName, 
    } = dataset;
    this.setState((prevState) => {
      let newList = []
      let newListError = [];
      let formattedValue = value;
      console.log("pos : " + arrayPosition + " name : " + name + " val : " +formattedValue + "dataset : " + JSON.stringify(dataset))
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if(type==="file"){
        formattedValue = files[0];
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        console.log("nl " + fieldName)
        // newList[arrayPosition] = {};
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value, // value: { oldvalues, overrideoldvalue }
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
}
render() {
    const { form } = this.state;
    const { tabellistsoal } = form.value;
    console.log("aa" + JSON.stringify(tabellistsoal))
    return (
      <div className="student-list">
        <form>
          <h1>List Soal</h1>   
            <Button
                type="button"
                title="Tambah Soal"
                onClick={this._onClickAdd}
            />
          <FormListSoal
            form={form}
            onChange={this._onFormChange}
          />
          <TabelListSoal
            list={tabellistsoal}
            onAddList={this._onClickAdd}
            onChange={this._onFormChange}
            onDeleteList={this._onDeleteList}
          />
          <div className="manage-registration__footer">
            <Pagination
              totalPage={3}
              currentPage={1}
            />
          </div>
        </form>
      </div>
    );
  }
}
listsoal.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
listsoal.defaultProps = {
  getParamOptions: noop,
  handleGetStudent: noop,
};