import { connect } from 'react-redux';
import DataJadwalMatpel from '../../views/Settings/DataJadwalMatpel.view';
import { getClasses, getParamOptionsAcademics, getPrmLevels} from '../../states/thunks/options.thunk';
import { listScheduleSubject, deleteScheduleSubject} from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListScheduleSubject: (payload, goback) => dispatch(listScheduleSubject(payload, goback)),
    handleDeleteScheduleSubject: (payload, goback) => dispatch(deleteScheduleSubject(payload, goback)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  
};
}

export default connect(mapStateToProps, mapDispatchToProps)(DataJadwalMatpel);
