import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, map, isEqual,
} from 'lodash';
import {
  Pagination,
  Loading,
  Button,
  Select,
  Input,
} from '../../../../components/base';
import language from '../../../../languages';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

const monthOptions = () => {
  const options = [{ value: '', label: 'Semua' }];
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  for (let i = 0; i < 12; i += 1) {
    options.push({ value: i, label: months[i] });
  }
  return options;
};

const yearOptions = () => {
  const d = new Date();
  const year = d.getFullYear();
  const options = [{ value: '', label: 'Semua' }];
  for (let i = 2020; i <= year; i += 1) {
    options.push({ value: i, label: i });
  }
  return options;
};

const isPostedOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: 'Terposting' },
  { value: 0, label: 'Belum Terposting' },
];

const isCreditOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: 'Kas Masuk' },
  { value: 0, label: 'Kas Keluar' },
];

export default class H2HJournal extends Component {
  constructor(props) {
    super(props);
    this._onClickActionButton = this._onClickActionButton.bind(this);
    this.isMount = false;
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.customAction = [{
      Component: Button,
      props: {
        title: 'Preview',
        className: 'H2H-journal__action-button',
        onClick: this._onClickActionButton,
      },
    }, {
      Component: Button,
      props: {
        title: 'Posting',
        className: 'H2H-journal__posting-button',
        onClick: this._onClickPostingButton,
      },
    }];

    this.state = {
      content: initialContent,
      listAmount: 5,
      filters: {},
    };
  }

  componentDidMount() {
    const { content } = this.state;

    this.isMount = true;

    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-h2h', { data: { id: val.id }, isEdit: true });
  }

  _onClickActionButton(val) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/jurnal-h2h/${val.id}`, { data: val });
  }

  _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleGetH2HJournal } = this.props;
        const {
          data = [], current_page = 1, total = 1, last_page = 1,
        } = await handleGetH2HJournal({ ...params, filters });
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: data,
              currentPage: current_page,
              total,
              totalPage: last_page,
            },
          });
        }
      } catch (err) {
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  render() {
    const {
      content,
      filters,
    } = this.state;
    const {
      month = '',
      year = '',
      is_credit = '',
      is_posted = '',
      journal_number = '',
    } = filters;
    const loadingProps = { show: content.loading };
    return (
      <div className="H2H-journal">
        <div className="H2H-journal__content">
          <div className="content-table">
            <div className="table-filters">
              <Input
                placeholder="Nomor Jurnal"
                type="text"
                name="journal_number"
                value={journal_number}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Bulan Jurnal"
                name="month"
                value={month}
                data={monthOptions()}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Tahun Jurnal"
                name="year"
                value={year}
                data={yearOptions()}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Status Jurnal"
                name="is_posted"
                value={is_posted}
                data={isPostedOptions}
                onChange={this._onChangeFilter}
              />
              <Select
                placeholder="Jenis Jurnal"
                name="is_credit"
                value={is_credit}
                data={isCreditOptions}
                onChange={this._onChangeFilter}
              />
            </div>
            {
              content.loading
                ? <Loading loading={loadingProps} />
                : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Nomor Jurnal</th>
                        <th>Tanggal</th>
                        <th>Nilai</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {
                      map(content.list, (data, idx) => (
                        <tr key={`user_management_tr_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{data.journal_number}</td>
                          <th className="date">{dateFormat(data.journal_date)}</th>
                          <td className="nominal">{commaFormatted(data.nominal)}</td>
                          <td>
                            <Button
                              key="button_preview"
                              onClick={() => this._onClickActionButton(data)}
                              title="Preview"
                            />
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                )
            }
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this._onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

H2HJournal.propTypes = {
  user: PropTypes.object,
  handleGetH2HJournal: PropTypes.func,
  history: PropTypes.object.isRequired,
};
H2HJournal.defaultProps = {
  user: {},
  handleGetH2HJournal: noop,
};
