import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, map } from 'lodash';
import { Button } from '../../../components/base';
import { formatData } from '../../../utils/transformer.util';
import { LAPORAN_SEKOLAH_DATAKELAS_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/identitas.constant';


export default class LaporanSekolah extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickJenis = this.onClickJenis.bind(this);
    this.onClickStatus = this.onClickStatus.bind(this);
    this.onClickEdit = this._onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);

    this.state = {
      isPrint: true,
    }
  }

  componentDidMount() {
    this.handleGetData();
  }

  async handleGetData() {
    const { handleListPrmEducators } = this.props;
    const res = await handleListPrmEducators();
    this.setState({
      list: res,
    });
  }

  onClickJenis() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah/edit/jenis');
  }

  onClickStatus() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah/edit/status');
  }
    
  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah/edit/jenis', { data: val, isEdit: true } );
  }

  async onClickDelete(data) {
    const { handleDeletePrmEducators } = this.props;
    const res = await handleDeletePrmEducators(data);
    if (res) {
      this.handleGetData();
    }
  }

  _renderButtons(data) {    
    return (
      <td>
        <div className="table__actions">
            <Button
             onClick={() => this._onClickEdit(data)}
             title='Edit'
           />
           {/* <Button
            onClick={() => this._onClickDelete(data)}
            title='Edit'
          /> */}
        </div>
      </td>
    );
  }

  render() {
    const { list } = this.state;

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Tenaga Pendidik dan Kependidikan</h1>
          <hr></hr>
          <br></br>
        </div>

        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Jenis Tenaga Pendidik"
              onClick={this.onClickJenis}
            />
          </div>
          <div className="student-list__button">
            <Button
              title="Status Tenaga Pendidik"
              onClick={this.onClickStatus}
            />
          </div>
        </div>
        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(LAPORAN_SEKOLAH_DATAKELAS_TABLE_FIELDS.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(list, (data, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(LAPORAN_SEKOLAH_DATAKELAS_TABLE_FIELDS.value, (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td style={{textAlign:'center'}}key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                        );
                      }
                      return (
                        <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                          {formatData(data, field)}
                        </td>
                      );
                    })}
                    {this._renderButtons(data)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

LaporanSekolah.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
LaporanSekolah.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
