import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEmpty } from 'lodash';
import { Button, Input } from '../../../components/base';
import { formatData, normalizeAmount } from '../../../utils/transformer.util';
import { validateRequiredFields } from "../../../utils/validation.util";

export default class LaporanSekolahJenis extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);

    this.state = {
      page: 1,
      list: [],
      form: {
        value: {
          details: [{
            prm_type_educators_id: "",
            name: "",
            nominal: 0,
          }]
        },
        error: {},
      },
      param: {},
    }
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    if (isEdit && id) {
      this.setState({ id: id, isEdit: true });
      this._setForm(data);
    } else {
      this.handleGetData();
    }
  }

  async _setForm(data) {
    try {
      this.setState({
        form: {
          value: {
            id: data.id,
            name: data.name,
            total: data.total,
            details: map(data.details, data => ({
              prm_type_educators_id: data.prm_type_educators_id,
              name: data.name,
              nominal: data.nominal,
            }))
          },
          error: {},
        },
      });
    } catch (err) {
      // err action
    }
  }

  async handleGetData() {
    const { handleListPrmTypeEducators } = this.props;
    const res = await handleListPrmTypeEducators();
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          details: map(res, data => ({
            prm_type_educators_id: data.id,
            name: data.name,
            nominal: 0,
          }))
        },
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { form } = this.state;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (type === "number") {
        formattedValue=Number(value)
      }

      if (inputType == "number") {
        formattedValue = normalizeAmount(value);
      }
      if (name === "nominal" || name === "dp" || name === "total") {
        var toNum = value
        do{
          toNum=toNum.replace(".", "")
        }
        while(toNum.indexOf(".") != -1)
        formattedValue=Number(toNum)
      }

      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  async onClickSubmit() {
    const { handleSavePrmEducators, history } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, ["name"])

    if (!isEmpty(error)) {
      this.setState((prevState) => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
    } else {
      const res = await handleSavePrmEducators(value);
      if (res) {
        history.push("/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah/edit");
      }
    }
  }

  async onClickUpdate() {
    const { handleEditPrmEducators, history } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, ["name"])
    
    if (!isEmpty(error)) {
      this.setState((prevState) => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
    } else {
      const res = await handleEditPrmEducators(value);
      if (res) {
        history.push("/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah/edit");
      }
    }
  }

  render() {
    const { form, isEdit } = this.state;
    const { details } = form.value

    form.value.total = 0;

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Jenis Tenaga Pendidik</h1>
          <hr></hr>
          <br></br>
        </div>
        <div className="kode-matpel__custom-form">
          <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
              <Input
                type="text"
                name="name"
                label="Nama Jenis Tenaga Pendidikan"
                onChange={this._onFormChange}
                value={form.value.name}
                error={form.error.name || ""}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Status Tenaga Pendidikan</th>
                <th width={"20%"}>Nominal</th>
              </tr>
            </thead>
            <tbody>
              {map(details, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {(() => {
                    form.value.total += Number(data.nominal);
                  })()}
                  <td style={{ textAlign: "center" }}>
                    {idx + 1}
                  </td>
                  <td>{data.name}</td>
                  <td className="nominal" width={"20%"}>
                    <Input
                      name="nominal"
                      onChange={this._onFormChange}
                      value={data.nominal}
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name="details"
                      isNumber
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} style={{textAlign:"center"}}>Jumlah</td>
                <td className="nominal" width={"20%"} style={{padding:"0 20px"}}>{form.value.total}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        
        <div className="student-list__header">
          <div className="student-list__button">
            {isEdit ? (
              <Button
                type="button"
                title="Update"
                onClick={this.onClickUpdate}
              />
            ) : (
              <Button
                type="button"
                title="Simpan"
                onClick={this.onClickSubmit}
              />
            )}
          </div>
        </div>

      </div>
    );
  }
}

LaporanSekolahJenis.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
LaporanSekolahJenis.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
