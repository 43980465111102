import { connect } from "react-redux";
import {getPayrollData,storePayrollData} from "../../../states/thunks/personnel.thunk";
import PayrollSlipInput from "../../../views/Personnel/Payroll/PayrollSlipInput.view"

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayrollData: (payload) => dispatch(getPayrollData(payload)),
    handleStorePayrollData : (payload,goBack,isEdit=false) => dispatch(storePayrollData(payload,goBack,isEdit)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSlipInput);
