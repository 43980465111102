import { connect } from 'react-redux';
import CreateInvoiceKelas from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoiceKelas.view';
import { saveRutinClassrooms, saveRutinClassroomsGeneral } from '../../../../states/thunks/academics.thunk'
import { getYearsOfEducationsOptions, getPrmLevels, getParamOptionsAcademics, getStudentSPPOptions, getPrmPayments, getStudentPeriodSPPOptions, getPrmPaymentsGeneral } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch){
  return{
    handleSaveRutinClassrooms: (payload, goback) => dispatch(saveRutinClassrooms(payload, goback)),
    handleSaveRutinClassroomsGeneral: (payload, goback) => dispatch(saveRutinClassroomsGeneral(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    getStudentPeriodSPPOptions: (payload) => dispatch(getStudentPeriodSPPOptions(payload)),
    getPrmPayments: (payload) => dispatch(getPrmPayments(payload)),
    getPrmPaymentsGeneral: (payload) => dispatch(getPrmPaymentsGeneral(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps) (CreateInvoiceKelas);