import JadwalMatpel from '../../../views/Kurikulum/MataPelajaran/JadwalMatpel.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptionsAcademics, getPrmLevels, getPeriodsOptions, getDayOptions } from '../../../states/thunks/options.thunk';
import { listScheduleSubject, deleteScheduleSubject } from '../../../states/thunks/academics.thunk';
function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      result: state.result,
      user: state.user || {},
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleListScheduleSubject: (payload, goback) => dispatch(listScheduleSubject(payload, goback)),
        handleDeleteScheduleSubject: (payload, goback) => dispatch(deleteScheduleSubject(payload, goback)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getDayOptions: (payload) => dispatch(getDayOptions(payload)),

      };
}
export default connect(mapStateToProps, mapDispatchToProps) (JadwalMatpel);