import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { noop } from 'lodash';
import { commaFormatted } from '../../../../utils/transformer.util';
import { printJournal } from '../../../../utils/print.util';
import { SearchSelect, Button, Select } from '../../../../components/base';


export default class BudgetRecaps extends Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderByCoa = this.renderByCoa.bind(this);
    this.renderByPerwakilan = this.renderByPerwakilan.bind(this);
    this.getUnitFilter = this.getUnitFilter.bind(this);
    this.getPerwakilanFilter = this.getPerwakilanFilter.bind(this);
    this.getBudgetPeriodeOptions = this.getBudgetPeriodeOptions.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      list: [],
      filters: {
        perwakilan: {},
        unit: {},
        periode: {
          id: new Date().getFullYear(),
          title: new Date().getFullYear(),
        },
      },
    }
  }

  componentDidMount() {
    //this.getData();
    this.getUnitFilter();
    this.getBudgetPeriodeOptions();
  }

  onFilterChange(event) {
    const { target } = event;
    const { value, name } = target;
    let newFilters = {};
    if (name === 'perwakilan') {
      newFilters = {
        perwakilan: value,
        unit: {},
      };
    } else {
      newFilters = {
        [name]: value,
      };
    }
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...newFilters,
      },
    }), () => {
      this.getData();
      if (name === 'perwakilan') {
        this.getUnitFilter();
        this.getBudgetPeriodeOptions();
      }
      if (name === 'unit') {
        this.getBudgetPeriodeOptions();
      }
    });
  }

  async getData() {
    const { filters } = this.state;
    const { handleGetBudgetRecaps } = this.props;
    const res = await handleGetBudgetRecaps({
      perwakilan_id: filters.perwakilan.id,
      unit_id: filters.unit.id,
      periode: filters.periode.id,
      layout: filters.layout
    });

    this.setState(prevState => ({
      ...prevState,
      list: res,
    }));
  }

  async getUnitFilter(payload = null) {
    const { user, handleGetUnitOptions } = this.props;
    const { filters } = this.state;
    const { perwakilan } = filters;
    const { id = user.prm_perwakilan_id } = perwakilan;
    const newFilters = {};
    if (id !== null) {
      newFilters.prm_perwakilan_id = id;
    }
    const res = handleGetUnitOptions({
      ...payload,
      filters: newFilters,
    });
    return res.filter(o => o.id !== 96 && o.id !== 97 && o.id !== 98);
  }

  async getPerwakilanFilter(payload = null) {
    const { handleGetPerwakilanOptions } = this.props;
    const res = handleGetPerwakilanOptions(payload);
    return res;
  }

  async getBudgetPeriodeOptions() {
    const { handleGetBudgetPeriodeOptions } = this.props;
    const { filters } = this.state;
    const { unit = {}, perwakilan = {} } = filters;
    const res = await handleGetBudgetPeriodeOptions({
      unit_id: unit.id,
      perwakilan_id: perwakilan.id,
    });

    this.setState({ periodeOptions: res });
  }

  renderFilter() {
    const { user, unit, periode = {} } = this.props;
    const { permissions } = user.user_group;
    const { filters } = this.state;

    if (permissions.includes('select_perwakilan')) {
      return (
        <div className="filter inline">
          <SearchSelect
            async
            name="perwakilan"
            label="Perwakilan"
            placeholder="Pilih Perwakilan"
            onSearch={this.getPerwakilanFilter}
            onClick={this.onFilterChange}
            onChange={this.onFilterChange}
            value={filters.perwakilan}
            valueName="id"
            labelName="title"
          />
          <SearchSelect
            name="unit"
            label="Unit"
            placeholder="Pilih Unit"
            onSearch={this.getUnitFilter}
            onClick={this.onFilterChange}
            onChange={this.onFilterChange}
            searchParams={{ perwakilan_id: filters.perwakilan.id }}
            value={filters.unit}
            list={unit.list}
            valueName="id"
            labelName="title"
          />
          <SearchSelect
            name="periode"
            label="Periode"
            placeholder="Pilih periode"
            onSearch={this.getUnitFilter}
            onClick={this.onFilterChange}
            onChange={this.onFilterChange}
            searchParams={{ periode: filters.periode.id }}
            value={filters.periode}
            list={periode.list}
            valueName="id"
            labelName="title"
          />
          <Select
            name="layout"
            label="Tampilan"
            placeholder="Pilih Tampilan"
            data={[
              { value: 'PER_COA', label: 'Per Kode Akun' },
              { value: 'PER_PERWAKILAN', label: 'Per Perwakilan' },
            ]}
            onChange={this.onFilterChange}
            value={filters.layout}
          />
        </div>
      );
    }
    return (
      <div className="filter">
        <SearchSelect
          async
          name="unit"
          label="Unit"
          placeholder="Pilih Unit"
          onSearch={this.getUnitFilter}
          onClick={this.onFilterChange}
          onChange={this.onFilterChange}
          value={filters.unit}
          valueName="id"
          labelName="title"
        />
        <SearchSelect
          name="periode"
          label="Periode"
          placeholder="Pilih periode"
          onSearch={this.getUnitFilter}
          onClick={this.onFilterChange}
          onChange={this.onFilterChange}
          searchParams={{ periode: filters.periode.id }}
          value={filters.periode}
          list={periode.list}
          valueName="id"
          labelName="title"
        />
      </div>
    );
  }

  renderHeader() {
    const { filters, list = {} } = this.state;
    const { layout } = filters;
    const { perwakilan = [] } = list;

    if (layout === 'PER_COA') {
      return (
        <>
          <tr>
            <th rowSpan={2} style={{ 'text-align': "right", width: "111.91pt", border: "1px solid #000000" }}>KODE AKUN</th>
            {
              perwakilan.map(row => (
                <th colSpan={3} key={`${row.name}`}>{row.name}</th>
              ))
            }
            <th colSpan={3}>Total</th>
          </tr>
          <tr>
            {
              perwakilan.map(row => (
                <>
                  <th className="nominal">RAPBS</th>
                  <th className="nominal">REKOMENDASI</th>
                  <th className="nominal">APBS</th>
                </>
              ))
            }
            <th className="nominal">RAPBS</th>
            <th className="nominal">REKOMENDASI</th>
            <th className="nominal">APBS</th>
          </tr>
        </>
      );
    }

    return (
      <tr>
        <th colSpan={3}>URAIAN</th>
        <th>PENDAPATAN</th>
        <th>BEBAN</th>
        <th>BELANJA INVENTARIS</th>
        <th>LABA/RUGI</th>
        <th>SALDO</th>
      </tr>
    );
  }

  renderRows() {
    const { filters } = this.state;
    if (filters.layout === 'PER_COA') {
      return this.renderByCoa();
    }
    return this.renderByPerwakilan();
  }

  renderByCoaItems(coaList, totalTitle) {
    const { list = {} } = this.state;
    const {
      byCoa = {},
      totalPerCoa = {},
      totalPerPerwakilan = {},
    } = list;
    const rows = [];
    const total = {};
    const grandTotal = {
      apbs: 0,
      rekomendasi: 0,
      rapbs: 0,
    };
    const perwakilan = [];
    coaList.forEach((coa) => {
      rows.push(<tr key={`row_${coa}`}>
        <td>
          {coa}
        </td>
        { byCoa[coa].perwakilan.map((item) => {
          if (!total[item.id]) {
            total[item.id] = {
              apbs: 0,
              rekomendasi: 0,
              rapbs: 0,
            };
          }
          if (!perwakilan.includes(item.id)) {
            perwakilan.push(item.id);
          }

          total[item.id].apbs += item.apbs || 0;
          total[item.id].rekomendasi += item.rekomendasi || 0;
          total[item.id].rapbs += item.rapbs || 0;
          grandTotal.apbs += item.apbs;
          grandTotal.rekomendasi += item.rekomendasi;
          grandTotal.rapbs += item.rapbs;
          return (
            <>
              <td className="nominal" style={{ 'mso-number-format': 'General' }}>
                {commaFormatted(item.rapbs)}
              </td>
              <td className="nominal" style={{ 'mso-number-format': 'General' }}>
                {commaFormatted(item.rekomendasi)}
              </td>
              <td className="nominal" style={{ 'mso-number-format': 'General' }}>
                {commaFormatted(item.apbs)}
              </td>
            </>
          );
        })
      }
        <td className="nominal" style={{ 'mso-number-format': 'General' }}>
          {commaFormatted(totalPerCoa[coa].rapbs)}
        </td>
        <td className="nominal" style={{ 'mso-number-format': 'General' }}>
          {commaFormatted(totalPerCoa[coa].rekomendasi)}
        </td>
        <td className="nominal" style={{ 'mso-number-format': 'General' }}>
          {commaFormatted(totalPerCoa[coa].apbs)}
        </td>
      </tr>);
    });

    rows.push(
      <tr className="total">
        <td>Total {totalTitle} </td>
        { perwakilan.map(item => (
          <>
            <td className="nominal" style={{ 'mso-number-format': 'General' }}>
              {commaFormatted(total[item].rapbs)}
            </td>
            <td className="nominal" style={{ 'mso-number-format': 'General' }}>
              {commaFormatted(total[item].rekomendasi)}
            </td>
            <td className="nominal" style={{ 'mso-number-format': 'General' }}>
              {commaFormatted(total[item].apbs)}
            </td>
          </>
        ))}
        <td className="nominal" style={{ 'mso-number-format': 'General' }}>
          {commaFormatted(grandTotal.rapbs)}
        </td>
        <td className="nominal" style={{ 'mso-number-format': 'General' }}>
          {commaFormatted(grandTotal.rekomendasi)}
        </td>
        <td className="nominal" style={{ 'mso-number-format': 'General' }}>
          {commaFormatted(grandTotal.apbs)}
        </td>
      </tr>
    );
    return rows;
  }


  renderByCoa() {
    const { list = {} } = this.state;
    const { byCoa = {} } = list;
    const pendapatan = Object.keys(byCoa)
      .filter(key => key.toString().startsWith('4'));
    const pengeluaran = Object.keys(byCoa)
      .filter(key => key.toString().startsWith('5'));
    const inventaris = Object.keys(byCoa)
      .filter(key => key.toString().startsWith('13'));

    return [
      ...this.renderByCoaItems(pendapatan, 'Pendapatan'),
      ...this.renderByCoaItems(pengeluaran, 'Pengeluaran'),
      ...this.renderByCoaItems(inventaris, 'Inventaris'),

    ];
  }

  renderByPerwakilan() {
    const { list = {} } = this.state;
    const rows = [];
    if (list.perwakilan) {
      list.perwakilan.forEach((perwakilan) => {
        const { units, name, total } = perwakilan;
        rows.push(
          <tr>
            <td className="sub_header" colSpan="8">
              <h3>{name}</h3>
            </td>
          </tr>
        );

        if (units) {
          units.forEach((unit) => {
            const {
              rapbs,
              apbs,
              tambahan,
              realisasi,
              rekomendasi,
            } = unit;
            rows.push(
              <tr>
                <td rowSpan="5"><div className="page-break-avoid">{unit.code}</div></td>
                <td rowSpan="5"><div className="page-break-avoid">{unit.name}</div></td>
                <td>RAPBU</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rapbs.pendapatan)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rapbs.beban)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rapbs.inventaris)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rapbs.labaRugi)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rapbs.saldo)}</td>
              </tr>,
              <tr>
                <td>REKOMENDASI</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rekomendasi.pendapatan)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rekomendasi.beban)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rekomendasi.inventaris)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rekomendasi.labaRugi)}</td>
                <td className="nominal" style={{ 'mso-number-format': 'General' }}>{commaFormatted(rekomendasi.saldo)}</td>
              </tr>,
              <tr>
                <td>APBU</td>
                <td className="nominal">{commaFormatted(apbs.pendapatan)}</td>
                <td className="nominal">{commaFormatted(apbs.beban)}</td>
                <td className="nominal">{commaFormatted(apbs.inventaris)}</td>
                <td className="nominal">{commaFormatted(apbs.labaRugi)}</td>
                <td className="nominal">{commaFormatted(apbs.saldo)}</td>
              </tr>,
              <tr>
                <td>TAMBAHAN</td>
                <td className="nominal">{commaFormatted(tambahan.pendapatan)}</td>
                <td className="nominal">{commaFormatted(tambahan.beban)}</td>
                <td className="nominal">{commaFormatted(tambahan.inventaris)}</td>
                <td className="nominal">{commaFormatted(tambahan.labaRugi)}</td>
                <td className="nominal">{commaFormatted(tambahan.saldo)}</td>
              </tr>,
              <tr>
                <td>REALISASI</td>
                <td className="nominal">{commaFormatted(realisasi.pendapatan)}</td>
                <td className="nominal">{commaFormatted(realisasi.beban)}</td>
                <td className="nominal">{commaFormatted(realisasi.inventaris)}</td>
                <td className="nominal">{commaFormatted(realisasi.labaRugi)}</td>
                <td className="nominal">{commaFormatted(realisasi.saldo)}</td>
              </tr>);
          });
        }

        if (total) {
          const {
            rapbs,
            apbs,
            tambahan,
            realisasi,
            rekomendasi,
          } = total;
          rows.push(
            <tr className="total">
              <td colSpan="2" rowSpan="5"><div className="page-break-avoid">{`TOTAL ${name}`}</div></td>
              <td>RAPBU</td>
              <td className="nominal">{commaFormatted(rapbs.pendapatan)}</td>
              <td className="nominal">{commaFormatted(rapbs.beban)}</td>
              <td className="nominal">{commaFormatted(rapbs.inventaris)}</td>
              <td className="nominal">{commaFormatted(rapbs.labaRugi)}</td>
              <td className="nominal">{commaFormatted(rapbs.saldo)}</td>
            </tr>,
            <tr className="total">
              <td>Rekomendasi</td>
              <td className="nominal">{commaFormatted(rekomendasi.pendapatan)}</td>
              <td className="nominal">{commaFormatted(rekomendasi.beban)}</td>
              <td className="nominal">{commaFormatted(rekomendasi.inventaris)}</td>
              <td className="nominal">{commaFormatted(rekomendasi.labaRugi)}</td>
              <td className="nominal">{commaFormatted(rekomendasi.saldo)}</td>
            </tr>,
            <tr className="total">
              <td>APBU</td>
              <td className="nominal">{commaFormatted(apbs.pendapatan)}</td>
              <td className="nominal">{commaFormatted(apbs.beban)}</td>
              <td className="nominal">{commaFormatted(apbs.inventaris)}</td>
              <td className="nominal">{commaFormatted(apbs.labaRugi)}</td>
              <td className="nominal">{commaFormatted(apbs.saldo)}</td>
            </tr>,
            <tr className="total">
              <td>TAMBAHAN</td>
              <td className="nominal">{commaFormatted(tambahan.pendapatan)}</td>
              <td className="nominal">{commaFormatted(tambahan.beban)}</td>
              <td className="nominal">{commaFormatted(tambahan.inventaris)}</td>
              <td className="nominal">{commaFormatted(tambahan.labaRugi)}</td>
              <td className="nominal">{commaFormatted(tambahan.saldo)}</td>
            </tr>,
            <tr className="total">
              <td>REALISASI</td>
              <td className="nominal">{commaFormatted(realisasi.pendapatan)}</td>
              <td className="nominal">{commaFormatted(realisasi.beban)}</td>
              <td className="nominal">{commaFormatted(realisasi.inventaris)}</td>
              <td className="nominal">{commaFormatted(realisasi.labaRugi)}</td>
              <td className="nominal">{commaFormatted(realisasi.saldo)}</td>
            </tr>,
          );
        }
      });
    }
    if (list.total) {
      rows.push(
        <tr className="total">
          <td colSpan="2" rowSpan="5"><div className="page-break-avoid">{`TOTAL YPL`}</div></td>
          <td>RAPBU</td>
          <td className="nominal">{commaFormatted(list.total.rapbs.pendapatan)}</td>
          <td className="nominal">{commaFormatted(list.total.rapbs.beban)}</td>
          <td className="nominal">{commaFormatted(list.total.rapbs.inventaris)}</td>
          <td className="nominal">{commaFormatted(list.total.rapbs.labaRugi)}</td>
          <td className="nominal">{commaFormatted(list.total.rapbs.saldo)}</td>
        </tr>,
        <tr className="total">
          <td>Rekomendasi</td>
          <td className="nominal">{commaFormatted(list.total.rekomendasi.pendapatan)}</td>
          <td className="nominal">{commaFormatted(list.total.rekomendasi.beban)}</td>
          <td className="nominal">{commaFormatted(list.total.rekomendasi.inventaris)}</td>
          <td className="nominal">{commaFormatted(list.total.rekomendasi.labaRugi)}</td>
          <td className="nominal">{commaFormatted(list.total.rekomendasi.saldo)}</td>
        </tr>,
        <tr className="total">
          <td>APBU</td>
          <td className="nominal">{commaFormatted(list.total.apbs.pendapatan)}</td>
          <td className="nominal">{commaFormatted(list.total.apbs.beban)}</td>
          <td className="nominal">{commaFormatted(list.total.apbs.inventaris)}</td>
          <td className="nominal">{commaFormatted(list.total.apbs.labaRugi)}</td>
          <td className="nominal">{commaFormatted(list.total.apbs.saldo)}</td>
        </tr>,
        <tr className="total">
          <td>TAMBAHAN</td>
          <td className="nominal">{commaFormatted(list.total.tambahan.pendapatan)}</td>
          <td className="nominal">{commaFormatted(list.total.tambahan.beban)}</td>
          <td className="nominal">{commaFormatted(list.total.tambahan.inventaris)}</td>
          <td className="nominal">{commaFormatted(list.total.tambahan.labaRugi)}</td>
          <td className="nominal">{commaFormatted(list.total.tambahan.saldo)}</td>
        </tr>,
        <tr className="total">
          <td>REALISASI</td>
          <td className="nominal">{commaFormatted(list.total.realisasi.pendapatan)}</td>
          <td className="nominal">{commaFormatted(list.total.realisasi.beban)}</td>
          <td className="nominal">{commaFormatted(list.total.realisasi.inventaris)}</td>
          <td className="nominal">{commaFormatted(list.total.realisasi.labaRugi)}</td>
          <td className="nominal">{commaFormatted(list.total.realisasi.saldo)}</td>
        </tr>
      );
    }

    return rows;
  }

  render() {
    const { filters = {} } = this.state;
    const { periode = {} } = filters;
    return (
      <div className="content_wrapper">
        <div className="table_title" id="printable-header">
          <h1>REKAP RAPBU, PERSETUJUAN DAN REALISASI</h1>
          <h1>TAHUN { periode.id }</h1>
          <h2>YAYASAN PANGUDI LUHUR</h2>
        </div>
        <div className="print">
          <ReactHTMLTableToExcel
            id="export-to-xls-button"
            className="button "
            table="printable"
            filename={`Rekap RAPBU Yayasan Pangudi Luhur Tahun ${periode.id}`}
            sheet={periode.id}
            buttonText="Unduh ke Excel"
          />
        </div>
        {this.renderFilter()}
        <table className="table" id="printable">
          <thead>{this.renderHeader()}</thead>
          <tbody>{this.renderRows()}</tbody>
        </table>

      </div>
    );
  }
}

BudgetRecaps.propTypes = {
  handleGetBudgetRecaps: PropTypes.func,
  handleGetPerwakilanOptions: PropTypes.func,
  handleGetUnitOptions: PropTypes.func,
  handleGetBudgetPeriodeOptions: PropTypes.func,
  user: PropTypes.object,
  unit: PropTypes.object,
  periode: PropTypes.object,
};

BudgetRecaps.defaultProps = {
  handleGetBudgetRecaps: noop,
  handleGetPerwakilanOptions: noop,
  handleGetUnitOptions: noop,
  handleGetBudgetPeriodeOptions: noop,
  user: noop,
  unit: {},
  periode: {
    list: [
      {
        id: new Date().getFullYear(),
        title: new Date().getFullYear(),
      },
    ],
  },
};
