import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, findIndex, isEqual,
} from 'lodash';
import {
  Pagination,
  Table,
  Loading,
  Button
} from '../base';
import language from '../../languages';
import { DataTable } from 'react-data-components';
import PaginationTable from 'react-data-components/lib/Pagination';
import { deleteConfirmation } from '../../utils/alert.util';
import {
  FileInput, Input, Select, SearchSelect, Checkbox, Textarea,
  Option,
} from '../base';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ContentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: initialContent,
      listAmount: 10,
      keywords: '',
    };
    this.isMount = false;
    this.onSearchContent = this.onSearchContent.bind(this);
    this.onChangeListAmount = this.onChangeListAmount.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onDeleteContent = this.onDeleteContent.bind(this);

    this.onClickView = this.onClickView.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickSK = this.onClickSK.bind(this);
    this.onClickST = this.onClickST.bind(this);
  }

  componentDidMount() {
    this.isMount = true;
    const { content } = this.state;
    const { initialParams } = this.props;
    if (isEmpty(content.list)) {
        this.onSearchContent({ unit_kerja : this.props.unit_kerja , page: 1, ...initialParams });
    }
  }

  componentDidUpdate(prevProps) {
    const { initialParams} = this.props;
    const prev_unit_kerja = prevProps.unit_kerja;
    const unit_kerja = this.props.unit_kerja;
    console.log(unit_kerja)
    if (!isEqual(unit_kerja, prev_unit_kerja)) {
      this.onSearchContent({unit_kerja : unit_kerja , page: 1, ...initialParams });
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  onSearchContent(params) {
    console.log(params)
    this.setState({
      content: initialContent,
    }, async () => {
        try {
        const { onSearchContent, user } = this.props;
        const { prm_school_units_id, workingUnit } = user;

        let unit_id = prm_school_units_id;

        if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
        }
        
        const {
            result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await onSearchContent(params, unit_id);
        if (this.isMount) {
            this.setState({
            content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
            },
            });
        }
        } catch (err) {
        if (this.isMount) {
            this.setState({
            content: {
                loading: false,
                list: [],
                currentPage: 1,
                total: 1,
                totalPage: 1,
                error: true,
            },
            });
        }
        }
    });
  }

  onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this.onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page: page+1,
      unit_kerja : this.props.unit_kerja,
    });
  }

  async onDeleteContent(val,data) {
    const {handleGetDataPegawai,onClickDelete} = this.props;
    const datapegawai = await handleGetDataPegawai({nip:val});
    let datap = datapegawai.result;
    let id = datap.id_staff;
    const willDelete = await deleteConfirmation();
    if (willDelete) {
        try {
          const res = await onClickDelete(id, datap);
          this.setState((prevState) => {
            const newList = prevState.content.list;
            const index = findIndex(newList, list => list.id === res);
            newList.splice(index, 1); 
            return {
              content: {
                ...prevState.content,
                loading: false,
                list: [...newList],
              },
            };
          });
        } catch (err) {
          // err action
        }
    }
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  async onClickSearch(){
    this.isMount = true;
    const { content } = this.state;
    const { initialParams } = this.props;
    this.onSearchContent({ unit_kerja : this.props.unit_kerja , keywords: this.state.keywords, page: 1, ...initialParams });
    
  }

  async onClickView(nip){
    const {handleGetDataPegawai,onClickView} = this.props;
    const datapegawai = await handleGetDataPegawai({nip:nip});
    onClickView(datapegawai.result);
  }

  async onClickEdit(nip){
    const {handleGetDataPegawai,onClickEdit } = this.props;
    const datapegawai = await handleGetDataPegawai({nip:nip});
    onClickEdit(datapegawai.result);
  }

  async onClickSK(nip){
    const {onClickSK} = this.props;
    onClickSK(nip);
  }

  async onClickST(nip){
    const {onClickST} = this.props;
    onClickST(nip);
  }
  
  render() {
    const {
      content,
    } = this.state;
    const loadingProps = { show: content.loading };
    console.log(content);

    const renderViewButton = (val,row) =>
    <div className="table__actions">
      <Button
          onClick= {()=>this.onClickView({nip: row['nip']})}    
          title="View"
      />
      <Button
          onClick= {()=>this.onClickEdit({nip: row['nip']})}    
          title="Edit"
      />
      {/* <Button
          onClick= {()=>this.onDeleteContent({nip: row['nip']})}    
          title="Delete"
      />
      <Button
          onClick= {()=>this.onClickSK({nip: row['nip']})}    
          title="SK"
      />
      <Button
          onClick= {()=>this.onClickST({nip: row['nip']})}    
          title="ST"
      /> */}
    </div>;

    const getJabatan = (val,row) =><>{row['jabatan'].name}</>;
    const getGolongan = (val,row) =><>{row['golongan'].pangkat}</>;
    const getStatus = (val,row) =><>{row['status_kepegawaian'].name}</>;
    const getIdx = (val,row) =><>{console.log(row)}</>;
    
    const tableColumns =[
      {title : 'No', prop : 'idx'},
      {title : 'No G',prop : 'no_g'},
      {title : 'Nama Pegawai',prop : 'nama_lengkap'},
      {title : 'Tanggal Lahir',prop : 'tanggal_lahir'},
      {title : 'Status Pegawai', render : getStatus},
      {title : 'Golongan',render : getGolongan},
      {title : 'Masa Kerja',prop : 'masa_kerja_saat_ini'},
      {title : 'Jabatan',render : getJabatan},
      {title : 'Action', render: renderViewButton }
    ];
    
    

    return (
      <>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/carlosrocha/react-data-components@master/css/table-twbs.css" />
        <div>
          <div className="container" style={{display: 'flex', alignItems: 'center',}}>
            <div style={{width:90, fontSize:20}}>Search :</div>
            
            <div>
            <Input
                type="text"
                name="keywords"
                placeholder="cari"
                onChange={this.onChange}
                disabled={false}
                value={this.state.keywords}
                error=""
                style={{width:250}}
              />
            </div>
            <div style={{width:200,paddingLeft:10, alignItems: 'center', justifyContent: 'center', marginTop:-10}}>
              <Button
                onClick= {()=>this.onClickSearch()}    
                title="Cari"
              />
            </div>
          </div>

          {
            content.loading
              ? <Loading loading={loadingProps} />
              : (
                <DataTable
                    className="container"
                    keys = 'id_staff' 
                    columns={tableColumns}
                    initialData={content.list}
                    initialPageLength={10}
                    pageLengthOptions={[ 5, 10, 20, 50 ]}
                    Pagination={false}
                />
              )
          }

          <div className="container">
              <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
            <PaginationTable
              className="pagination"
              currentPage={content.currentPage-1}
              totalPages={content.totalPage}
              onChangePage={this.onChangePage}
            />
          </div>
          {/* {<div className="user-management__table-footer">
              <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this.onChangePage}
              />
          </div> } */}
        </div>
      </>
    );
  }
}
ContentTable.propTypes = {
  customThead: PropTypes.node,
  customAction: PropTypes.array,
  showActions: PropTypes.bool,
  showView: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onSearchContent: PropTypes.func,
  onClickView:PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  tableFields: PropTypes.object.isRequired,
  initialParams: PropTypes.object,
  user: PropTypes.object,
  handleGetDataPegawai : PropTypes.func,
  onClickSK : PropTypes.func,
  onClickST : PropTypes.func,
};
ContentTable.defaultProps = {
  customThead: null,
  customAction: [],
  showActions: true,
  showView: true,
  showEdit: true,
  showDelete: true,
  onSearchContent: noop,
  onClickView:noop,
  onClickEdit: noop,
  onClickDelete: noop,
  initialParams: {},
  user: {},
  handleGetDataPegawai : noop,
  onClickSK:noop,
  onClickST:noop,
};
