import { connect } from 'react-redux';
import PaymentRecap from '../../../views/Finance/Report/PaymentRecap.report.view';
import { getPaymentOptions } from '../../../states/thunks/options.thunk';
import { getPaymentRecap } from '../../../states/thunks/report.thunk';


function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetReport: payload => dispatch(getPaymentRecap(payload)),
    handleGetOptions: payload => dispatch(getPaymentOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRecap);
