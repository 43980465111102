import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop, isEqual, capitalize } from "lodash";
import ListForm from "../../../components/ListForm/ListForm.component";
import { validateRequiredFields } from "../../../utils/validation.util";

import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { BUATDATA_DATAKELASKHUSUS_FORM_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import {
  Button,
  Pagination,
  SearchSelect,
  Select,
  Input,
  InputDate
} from "../../../components/base";
import language from "../../../languages";
import PengisianEkskulForm from "../components/PengisianEkskulForm.component";
import BuatEkskulForm from "../components/BuatEkskulForm.component";
import AbsensiEkskulForm from "../components/AbsensiEkskulForm.component";
import { ISI_ABSEN_EKSKUL_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianEkskul extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    //this.deletepelajaran = this.deletepelajaran.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;

    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: organizations_id,
          absensiekskul: [
            {
              students_id: "",
              classrooms_id: "",
              ekskul_id: "",
              description: "",
              date: "",
              status: "",
              academic_year: "",
              period: "",
            },
          ],
        },
        error: {
          absensiekskul: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, handleGetclassrooms, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const paramTypes = [ "classrooms", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    } else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getStudentOptions();
    this._getPeriodsOptions({units_id: school_unit.id});
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });

  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }


  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptions("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        this._onSearchContent();
      }
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
              academic_year: data.value,
            }
          }
          }))
      }
    });


    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { form } = this.state;
    const { value } = form;
    try {
      const { handleListStudentEkskulAbsen, user } = this.props;
      const { school_unit, workingUnit, id, user_group, organizations_id } =
        user;
      const { levels_id, units_id } = school_unit;

      const result = await handleListStudentEkskulAbsen({
        ...params,
        organizations_id: organizations_id,
        // units_id,
        classrooms_id: value.classrooms_id,
      });
      // if (this.isMount) {
      if (result) {
        result.forEach((item) => {
          if (!item.description) {
            item.description = "";
          }
        });
      }
      console.log(result);
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiekskul: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiekskul: [],
          },
        },
      }));
    }
    // }
  }

  // on formchange untuk form
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === 'levels_id') {
          this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: organizations_id });
        }
        if (name === "classrooms_id") {
          this._onSearchContent();
        }
      }
    );
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { pelajaran } = form.value;

    pelajaran.push({
      Kelas: "",
      Matpel: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          pelajaran,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    console.log(pelajaran);
  }

  async _setForm(id) {
    const { handleGetEkskulAbsen } = this.props;
    try {
      const payload = await handleGetEkskulAbsen({ id });

      // console.log(payload)
      this.setState({
        // filters: {
        //   classrooms_id: payload.classrooms_id,
        //   period: payload.period,
        //   date: payload.date,
        // },
        form: {
          value: {
            id: payload.id,
            academic_year: payload.academic_year,
            period: payload.period,
            date: payload.date,
            absensiekskul: map(payload.absensiekskul, (detail) => ({
              classrooms_name: detail.classrooms_name,
              classrooms_id: detail.classrooms_id,
              student_name: detail.student_name,
              nisn: detail.nisn,
              no_va: detail.no_va,
              grades: detail.grades,
              ekskul_id: detail.ekskul_id,
              ekskul_name: detail.ekskul_name,
              status: detail.status,
              students_id: detail.students_id,
              description: detail.description,
            })),
          },
          error: {
            absensiekskul: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { pelajaran } = prevState.form.value;
      const list = prevState.form.value.pelajaran;

      list.splice(idx, 1);
      return {
        ...prevState,
        pelajaran: list,
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    console.log(name);
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newpelajaran = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newpelajaran = prevState.form.value.pelajaran;
        newpelajaran[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { pelajaran: newpelajaran }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  async onEdit() {
    const { history, handleEditEkskulAbsen } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditEkskulAbsen(value);
    history.push("/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul");
  }

  async onSubmit() {
    const { history, handleSaveEkskulAbsen } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    const error = validateRequiredFields(form.value, [
      "classrooms_id",
      "period",
      "date",
    ]);

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
    await handleSaveEkskulAbsen(value);
    history.push("/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul");
    }
  }

  deletepelajaran(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ people: array });
    }
  }

  render() {
    const { form, page, content, param, filters } = this.state;
    const { value } = form;
    const { plafon } = form.value;
    const { academic = "", order = "", classrooms_id = "" } = filters;
    const { absensiekskul } = form.value;
    const {
      handleGetKelas,
      handleDeleteKelas,
      onFormChange,
      classrooms = {},
      location,
    } = this.props;
    const { state = {} } = location;
    const dataset = {
      toggle: "modal",
    };
    const academic_year = [
      { label: "2019", value: "1" },
      { label: "2020", value: "2" },
      { label: "2021", value: "3" },
    ];
    const periods = [
      { label: "Genap", value: "genap" },
      { label: "Ganjil", value: "ganjil" },
    ];
    return (
      <div className="manage-registration">
        <div className="data-pelanggaran__custom-form">
          <div className="data-pelanggaran__custom-form-column">
            <div className="data-pelanggaran__custom-form-row__field">
              <Select
                label="Tahun Ajaran"
                name="academic_year"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                data={param.periods}
                value={form.value.academic_year}
                error={form.error.academic_year || ""}
                disabled
              />

              <div className="data-pelanggaran__custom-form-row__field"></div>
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                placeholder="Pilih Unit"
                data={param.levels}
                disabled
                value={form.value.levels_id}
                onChange={this._onFormChange}
                error={form.error.levels_id || ""}
              />
            </div>

            <div className="data-pelanggaran__custom-form-row">
              <Select
                placeholder="Pilih Kelas"
                label="Kelas"
                name="classrooms_id"
                value={form.value.classrooms_id}
                error={form.error.classrooms_id || ""}
                data={param.classrooms}
                onChange={this._onFormChange}
              />

              <div className="data-pelanggaran__custom-form-row__field"></div>
              <Select
                name="period"
                label="Periode"
                placeholder="Pilih Periode"
                onChange={this._onFormChange}
                data={periods}
                value={form.value.period}
                error={form.error.period || ""}
              />
            </div>
            
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
              <InputDate
                type="date"
                name="date"
                label="Tanggal"
                onChange={this._onFormChange}
                value={form.value.date}
                error={form.error.date || ""}
              />
              </div>

              <div className="kode-matpel__custom-form-column__field"></div>
            </div>
          </div>
        </div>

        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(ISI_ABSEN_EKSKUL_TABLE_FIELDS.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(absensiekskul, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(
                      ISI_ABSEN_EKSKUL_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td style={{textAlign: "center"}} key={`table_index_${fieldIdx}_${idx}`}>
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.attribute === "nisn"){
                          return (
                            <td style={{textAlign:'center'}}>{list.no_va}</td>
                          );
                        }
                        if (field.attribute === "no_va"){
                          return (
                            <td style={{textAlign:'center'}}>{list.no_va}</td>
                          );
                        }
                        if (field.attribute === "student_name") {
                          return (
                            <td style={{textTransform: "capitalize"}}>{capitalize(list.student_name)}</td>
                          )
                        }
                        if (field.attribute === "status") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <Input
                                type="checkbox"
                                name="status"
                                data-input-array={true}
                                data-array-position={idx}
                                data-field-name="absensiekskul"
                                onChange={this._onFormChange}
                                checked={list.status === field.value}
                                value={field.value}
                                // ref={this._setRef}
                              />{" "}
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        if (field.attribute === "description") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <Input
                                type="text"
                                name="description"
                                data-input-array={true}
                                data-array-position={idx}
                                data-field-name="absensiekskul"
                                onChange={this._onFormChange}
                                value={list.description}
                              />{" "}
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }

                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(list, field)}
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="student-list__button">
          {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
          )}
        </div>
      </div>
    );
  }
}
PengisianEkskul.propTypes = {
  getParamOptions: PropTypes.func,
  handleAddBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
PengisianEkskul.defaultProps = {
  getParamOptions: noop,
  handleAddBudget: noop,
  user: null,
};
