import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";

import {
  Input,
  Select,
  Textarea,
  Checkbox,
  SelectMultiple,
} from "../../../components/base/index";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { GENDER } from "../../../variables/common.variable";

const padStr = (num) => {
  if (num !== "" && num !== null && typeof num !== "undefined") {
    return num.toString().padStart(2, "0");
  }

  return null;
};
export default class BuatEkskulForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*",
      },
    };
    this.multiselectRef = React.createRef();
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name,
        value,
        dataset: {},
      },
    });
  }
  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  render() {
    const { onFormChange, form, param } = this.props;
    console.log(param);
    const hour = [
      { label: '05', value: '05'}, { label: '06', value: '06'}, { label: '07', value: '07'}, { label: '08', value: '08'}, { label: '09', value: '09'},
      { label: '10', value: '10'}, { label: '11', value: '11'}, { label: '12', value: '12'}, { label: '13', value: '13'}, { label: '14', value: '14'}, { label: '15', value: '15'}, { label: '16', value: '16'}, { label: '17', value: '17'}, { label: '18', value: '18'}, { label: '19', value: '19'},
      { label: '20', value: '20'}, { label: '21', value: '21'}, { label: '22', value: '22'}, { label: '23', value: '23'},
    ];
    const minute = [
      { label: '00', value: '00'}, { label: '01', value: '01'}, { label: '02', value: '02'}, { label: '03', value: '03'}, { label: '04', value: '04'}, { label: '05', value: '05'}, { label: '06', value: '06'}, { label: '07', value: '07'}, { label: '08', value: '08'}, { label: '09', value: '09'},
      { label: '10', value: '10'}, { label: '11', value: '11'}, { label: '12', value: '12'}, { label: '13', value: '13'}, { label: '14', value: '14'}, { label: '15', value: '15'}, { label: '16', value: '16'}, { label: '17', value: '17'}, { label: '18', value: '18'}, { label: '19', value: '19'},
      { label: '20', value: '20'}, { label: '21', value: '21'}, { label: '22', value: '22'}, { label: '23', value: '23'}, { label: '24', value: '24'}, { label: '25', value: '25'}, { label: '26', value: '26'}, { label: '27', value: '27'}, { label: '28', value: '28'}, { label: '29', value: '29'},
      { label: '30', value: '30'}, { label: '31', value: '31'}, { label: '32', value: '32'}, { label: '33', value: '33'}, { label: '34', value: '34'}, { label: '35', value: '35'}, { label: '36', value: '36'}, { label: '37', value: '37'}, { label: '38', value: '38'}, { label: '39', value: '39'},
      { label: '40', value: '40'}, { label: '41', value: '41'}, { label: '42', value: '42'}, { label: '43', value: '43'}, { label: '44', value: '44'}, { label: '45', value: '45'}, { label: '46', value: '46'}, { label: '47', value: '47'}, { label: '48', value: '48'}, { label: '49', value: '49'},
      { label: '50', value: '50'}, { label: '51', value: '51'}, { label: '52', value: '52'}, { label: '53', value: '53'}, { label: '54', value: '54'}, { label: '55', value: '55'}, { label: '56', value: '56'}, { label: '57', value: '57'}, { label: '58', value: '58'}, { label: '59', value: '59'},
    ]
    return (
      <div className="buat-ekskul__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="buat-ekskul__custom-form-row__field">
            <Input
              type="text"
              name="ekskul_code"
              label="Kode Ekskul *"
              onChange={onFormChange}
              value={form.value.ekskul_code}
              error={form.error.ekskul_code || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="name"
              label="Nama Ekskul *"
              onChange={onFormChange}
              value={form.value.name}
              error={form.error.name || ""}
            />
          </div>
        </div>

        <div className="buat-ekskul__custom-form-row">
          <div className="buat-ekskul__custom-form-row__field">
            <Select
              name="teacher_id"
              label="Nama Guru *"
              onChange={onFormChange}
              data={param ? param.teacher : ""}
              value={form.value.teacher_id}
              error={form.error.teacher_id || ""}
              placeholder={"Pilihan"}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
          <p>Hari</p>
            <SelectMultiple
              name="ekskul_day"
              style={{ width: "50%" }}
              options={param ? param.day : ""}
              label="Hari *"
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              values={form.value.ekskul_day}
              onRemove={(value) => this._onRemoveMulti(value, "ekskul_day")}
              onSelect={(value) => this._onChangeMulti(value, "ekskul_day")}
            />
          </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            
              <Select
                name="hour"
                label="Jam *"
                onChange={onFormChange}
                data={hour}
                placeholder={"Pilih Jam"}
                value={padStr(form.value.hour)}
                error={form.error.hour || ""}
              />
            <div className="manage-registration__custom-form-row-datadiri">
            <Select
              name="minute"
              label="Menit *"
              onChange={onFormChange}
              placeholder="Pilih Menit"
              data={minute}
              value={padStr(form.value.minute)}
              error={form.error.minute || ""}
            />

            </div>
          </div>
          <div className="data-pelanggaran__custom-form-row__field">
          </div>
        </div>
      </div>
    );
  }
}
BuatEkskulForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatEkskulForm.defaultProps = {
  onFormChange: noop,
};