import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import daftar_nilai_raport from '../../../assets/img/akademi/kurikulum/Raport/daftar-nilai-raport.png';

export default class Raport extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickNilaiRaport = this.onClickNilaiRaport.bind(this);
    this.onClickRaportKBK = this.onClickRaportKBK.bind(this);
  }

  onClickNilaiRaport() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/raport/daftar-nilai-raport');
  }

  onClickRaportKBK() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/raport/raport-kbk');
  }

 
  render() {
    return (
      <div className="finance">
        {/* <div className="finance__list">
          <button onClick={this.onClickNilaiRaport}>
            <img src={daftar_nilai_raport} alt="kode" />
            <h3>Daftar Nilai Raport</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickRaportKBK}>
            <img src={budgeting} alt="raportkbk" />
            <h3>Raport KBK</h3>
          </button>
        </div> */}
        
        
      
      </div>
    );
  }
}
Raport.propTypes = {
  history: PropTypes.object.isRequired,
};
