import React, { Component } from "react";
import { Input, Select, Textarea} from "../../../../components/base";
import PropTypes from "prop-types";
export default class IdentitasSekolah1 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this._filterOptions = this._filterOptions.bind(this);
    this.state = {
      original: {
        sub_districts: props.sub_districts || [],
        districts: props.districts || [],
        cities: props.cities || [],
        provinces: props.provinces || [],
      },
      options: {
        sub_districts: [],
        districts: [],
        cities: [],
        provinces: [],
      },
      form: {
        value: {},
        error: {},
      },
    };

    this.updated = false;
  }

  componentDidUpdate({
    provinces: prevP,
    cities: prevC,
    sub_districts: prevS,
    districts: prevD,
  }) {
    const {
      provinces = [],
      cities = [],
      sub_districts = [],
      districts = [],
    } = this.props;
    if (
      JSON.stringify(provinces) !== JSON.stringify(prevP) ||
      JSON.stringify(cities) !== JSON.stringify(prevC) ||
      JSON.stringify(districts) !== JSON.stringify(prevD) ||
      JSON.stringify(sub_districts) !== JSON.stringify(prevS)
    ) {
      this.setState({
        original: {
          provinces,
          cities,
          districts,
          sub_districts,
        },
        options: {
          provinces,
          cities,
          districts,
          sub_districts,
        },
      });
    }
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    // const change = {};
    // change[e.target.name] = e.target.value;
    // this.setState(change);
    onChange(e);
  }

  _filterOptions(name) {
    const { original } = this.state;
    const { form = {} } = this.props;
    const { value = {} } = form;

    if (name === "cities") {
      return original.cities.filter(
        (o) => o.data.provinces_id == value.provinces_id
      );
    } else if (name === "districts") {
      return original.districts.filter(
        (o) => o.data.cities_id == value.cities_id
      );
    } else if (name === "sub_districts") {
      return original.sub_districts.filter(
        (o) => o.data.districts_id == value.districts_id
      );
    } else {
      return original.provinces;
    }
  }

  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  async onUpload(event) {
    const {
      handleUploadImageFileStudents,
      onChange,
      idx,
      onFormChange,
      showEdit,
    } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const upload_image = files[0];

    if (upload_image) {
      const filename = upload_image.name;
      const res = await handleUploadImageFileStudents({
        file: upload_image,
      });
      onChange({
        target: {
          name: "file",
          value: JSON.stringify({
            path: res.path,
            url: res.url,
            filename,
          }),
          dataset: {
            ...dataset,
            inputArray: false,
          },
        },
      });
    }
  }

  render() {
    const {
      form,
      provinces,
      idx,
      cities,
      districts,
      // sub_districts,
      onChange,
      classes,
      param,
    } = this.props;
    const { value } = form;
    const unit_types = [
      { label: "SD", value: "1" },
      { label: "MI", value: "2" },
    ];
    const cities_type = [
      { label: "Kabupaten", value: "1" },
      { label: "Kota", value: "2" },
    ];
    const sub_districts = [
      { label: "Desa", value: "1" },
      { label: "Kelurahan", value: "2" },
    ];
    const rayon = [
      { label: "Daerah Terpencil", value: "1" },
      { label: "Daerah Perbatasan (dengan negara lain)", value: "2" },
      { label: "Daerah Transmigrasi", value: "3" },
      { label: "Tidak termasuk kategori 1, 2 atau 3", value: "4" },
    ];
    const school_status = [
      { label: "Negeri", value: "1" },
      { label: "Swasta", value: "2" },
    ];
    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="name"
              label="Nama Sekolah *"
              onChange={this._onFormChange}
              value={form.value.name}
              error={form.error.name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="levels_id"
              label="Jenis Sekolah *"
              placeholder="Pilihan"
              data={param.levels}
              onChange={this._onFormChange}
              value={form.value.levels_id}
              error={form.error.levels_id || ""}
              isRequired
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="phone"
              label="Telepon *"
              onChange={this._onFormChange}
              value={form.value.phone}
              error={form.error.phone || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="email"
              label="Email *"
              onChange={this._onFormChange}
              value={form.value.email}
              error={form.error.email || ""}
              isRequired
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="address"
            label="Alamat *"
            onChange={this._onFormChange}
            value={form.value.address}
            error={form.error.address || ""}
            isRequired
          />
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="provinces"
              label="Provinsi *"
              placeholder="Pilihan"
              onChange={this._onFormChange}
              // data={provinces.list}
              data={provinces}
              value={form.value.provinces}
              error={form.error.provinces || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="cities"
              label="Kotamadya *"
              placeholder="Pilihan"
              onChange={this._onFormChange}
              // data={cities.list}
              data={this._filterOptions("cities")}
              value={form.value.cities || ""}
              error={form.error.cities || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Select
                name="type_of_cities"
                label="Kota/Kabupaten"
                onChange={this._onFormChange}
                data={cities_type}
                placeholder="Pilihan"
                value={form.value.type_of_cities || ""}
                error={form.error.type_of_cities || ""}
              />
            </div>
          </div>
        </div>
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              <Input
                name="districts"
                label="Kecamatan"
                onChange={this._onFormChange}
                data={this._filterOptions("districts")}
                placeholder="Pilihan"
                value={form.value.districts || ""}
                error={form.error.districts || ""}
              />
            </div>
            <div className="manage-registration__custom-form-row__field">
              <Input
                name="sub_districts"
                label="Kelurahan"
                onChange={this._onFormChange}
                data={this._filterOptions("sub_districts")}
                placeholder="Pilihan"
                value={form.value.sub_districts || ""}
                error={form.error.sub_districts || ""}
              />
              <div className="manage-registration__custom-form-row-datadiri">
                <Select
                  name="village"
                  label="Kelurahan/Desa"
                  onChange={this._onFormChange}
                  data={sub_districts}
                  placeholder="Pilihan"
                  value={form.value.village || ""}
                  error={form.error.village || ""}
                />
              </div>
            </div>
          </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="postal_code"
              label="Kode Pos "
              onChange={this._onFormChange}
              value={form.value.postal_code}
              error={form.error.postal_code || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="unit_code"
              label="Kode Sekolah"
              onChange={this._onFormChange}
              value={form.value.unit_code}
              error={form.error.unit_code || ""}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              type="text"
              name="rayon"
              label="Kategori Wilayah"
              placeholder="Pilihan"
              data={rayon}
              onChange={this._onFormChange}
              value={form.value.rayon}
              error={form.error.rayon || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="website"
              label="Website"
              onChange={this._onFormChange}
              value={form.value.website}
              error={form.error.website || ""}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nds"
              label="Nomor NDS"
              onChange={this._onFormChange}
              value={form.value.nds || ""}
              error={form.error.nds || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nss"
              label="Nomor NSS"
              onChange={this._onFormChange}
              value={form.value.nss || ""}
              error={form.error.nss || ""}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="npsn"
              label="Nomor NPSN"
              onChange={this._onFormChange}
              value={form.value.npsn || ""}
              error={form.error.npsn || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
             <Select
              name="headmaster_id"
              label="Kepala Sekolah"
              placeholder="Pilihan"
              data={param.teacher}
              onChange={this._onFormChange}
              value={form.value.headmaster_id}
              error={form.error.headmaster_id || ""}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Input
              type="number"
              name="nominal_upd"
              label="Nominal Pendaftaran"
              onChange={this._onFormChange}
              value={form.value.nominal_upd}
              error={form.error.nominal_upd || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
              {/* <FileInput
                data-input-type="file"
                noMargin
                type="file"
                name="upload_logo"
                label="Upload Logo"
                onChange={this.onUpload}
                onRemoveFile={this.removeFile}
                fileName={value.upload_logo ? value.upload_logo : ""}
                displayName={ value.upload_logo ? value.upload_logo : "default"}
              /> */}
          </div>
        </div>
      </div>
    );
  }
}

IdentitasSekolah1.propTypes = {
  provinces: PropTypes.array,
  districts: PropTypes.array,
  sub_districts: PropTypes.array,
  cities: PropTypes.array,
};

IdentitasSekolah1.defaultProps = {
  provinces: [],
  cities_id: [],
  districts_id: [],
  sub_districts: [],
};
