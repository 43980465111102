import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop, isEqual, capitalize } from "lodash";
import {
  normalizeAmount,
} from "../../../utils/transformer.util";
import { Button, Select, Input, SearchSelect } from "../../../components/base";
import { PEMBATALAN_SISWA_TABEL_FIELD } from "../../../constants/kurikulum/kurikulum.constant";
import { KENAIKAN_KELAS } from "../../../constants/kurikulum/kurikulum.constant";
import { formatData } from "../../../utils/transformer.util";
import { validateRequiredFields } from '../../../utils/validation.util';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class PembatalanSiswa extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPPDBOffline = this.onSubmitPPDBOffline.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getNewStudentOptions = this._getNewStudentOptions.bind(this);
    this._getClassroomsOptions = this._getClassroomsOptions.bind(this);
    this._getStudentPPDBOptions = this._getStudentPPDBOptions.bind(this);
    this._getStudentNextClassOptions = this._getStudentNextClassOptions.bind(this);
    this._getNewStudentPPDBOptions = this._getNewStudentPPDBOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      content: initialContent,
      listAmount: 5,
      list: [],
      filters: {},
      form: {
        value: {
          organizations_id: organizations_id,
          units_id: school_unit.id,
          levels_id,
          pembatalan_siswa: [
            {
              students_id: "",
              period: "",
              classrooms_id: "",
              date: "",
              no_va: "",
            },
          ],
          class_div: "ppdb"
        },
        error: {
          pembatalan_siswa: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { content } = this.state;
    const { location, user } = this.props;
    const { school_unit, organizations_id } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;

    // const { isEdit = false, data } = location.state;
    const { id } = state.data || "";
    this._initializeData();
  }

  async _initializeData(savedFilters = {}) {
    const { content } = this.state;
    const { location, user } = this.props;
    const { school_unit, organizations_id } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;

    // const { isEdit = false, data } = location.state;
    const { id } = state.data || "";
    
    const paramTypes = ["classes","levels","classrooms"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    
    await this._getPeriodsOptions({units_id: [school_unit.id]});
    await this.getYearsOfEducationsOptions({units_id: school_unit.id});  
    this._getClassroomsOptions();
    this._getClassesOptions({
      filters: { levels_id: [levels_id], units_id: [school_unit.id] },
    });
    this._getNewStudentOptions();
    this._getPrmLevels({
      filters: { units_id: [school_unit.id], organizations_id: organizations_id },
    });
    this._getStudentPPDBOptions();
    this._getStudentNextClassOptions();
    this._getNewStudentPPDBOptions();

    // if (isEmpty(content.list)) {
    //   this._onSearchContent({ page: 1 });
    // }
  }

  // componentDidUpdate(prevProps) {
  //   const { location } = this.props;
  //   const { state = {} } = location;
  //   const { id } = state.data || "";

  //   if (!id && !isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }
  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            entrance_year: data.value,
          },
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              entrance_year: data.value,
            },
          },
        }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
      filters.units_id = school_unit.id;

    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }


  // on formchange untuk form
  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { filters, form } = this.state
    if (name == "classes_id") {
      delete form.value.classrooms_id
      const formValue = form.value
      delete formValue.classrooms_id
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: formValue,
        },
      }));
    }
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id" || name === "periods_id") {
          this._getClassroomsOptions("classrooms", {
            classes_id: value,
            organizations_id: organizations_id,
          });
        }
        if (name === "classrooms_id") {
          const param = this.state.param.classrooms;
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                capacity: param.find((i) => i.value == value)?param.find((i) => i.value == value).attributes.capacity:'',
              },
            },
          }));
        }
        if (name == "class_div" || name == "classes_id") {
          delete filters.students_id
          this.setState((prevState) => ({
            ...prevState,
            filters,
          }));
        }
        if (name == "tipe"){
          this._onSearchContent();
        }
        // if (name == "classes_id"){
        //   this._onSearchContent();
        // }
        // this._onSearchContent();
      }
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { period, entrance_year } = filters;
    const { class_div, classes_id, periods_id, nextyear, tipe } = value;
    var year = ""
  
    console.log(period)
    if (entrance_year) {
      year = entrance_year.toString().substring(2);
    }

    try {
      const { handleListStudentsPPDB, handleListPPDBOffline, user, 
        handleListStudentsPPDBF07, handleListStudentsClassDivGeneral, 
        handleListStudentClassroomsNull, handleListStudentGraduate, 
        handleListStudentPPDBReject, handleListStudentPPDBAll, handlelistStudentPPDBGeneralReject } = this.props;
      const { organizations_id, school_unit } = user;
      let result = {};
     
       if (tipe == 'noclass'){
        result = await handleListStudentClassroomsNull({
          ...params,
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
        });
      } 
      else if(tipe == 'ppdboffline'){
        result = await handleListStudentPPDBReject({
          ...params,
          units_id: school_unit.id,
          filters: {year: year},
        });
      } else if(tipe == 'ppdb') {
        if(school_unit.subdomain == null){
          result = await handleListStudentPPDBAll({
            ...params,
            organizations_id: organizations_id,
            units_id: school_unit.id,
            // year: year,
            filters: {year:year},
          });
        }
        else {
          result = await handlelistStudentPPDBGeneralReject({
            ...params,
            organizations_id: organizations_id,
            subdomain: school_unit.subdomain,
            year: year,
            filters: {year:year},
          });
        }
        
      }
      else {
        result = await handleListStudentGraduate({
          ...params,
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
        });
      }
      const { classes = [], data = [] } = result;
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pembatalan_siswa: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pembatalan_siswa: [],
          },
        },
      }));
    }
    // }
  }

  async onSubmit() {
    const { history, handleSaveClassDivPPDB } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['periods_id', 'classes_id', 'classrooms_id']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSaveClassDivPPDB(value);
      history.push(
        "/dashboard/tata-usaha/manajemen-kelas/pengaturan-kelas-ajaran-baru"
      );
    }
  }
  

  async onSubmitPPDBOffline() {
    const { history, handleSaveClassPPDBOffline } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['periods_id', 'classes_id', 'classrooms_id']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSaveClassPPDBOffline(value);
      history.push(
        "/dashboard/tata-usaha/manajemen-kelas/pengaturan-kelas-ajaran-baru"
      );
    }
  }

  async onDelete() {
    const { history, handleDeleteApiAuth } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleDeleteApiAuth(value);
      window.location.reload()
      // history.push(
      //   "/dashboard/tata-usaha/manajemen-kelas/pembatalan-siswa"
      // );
    }
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)

      if (date > start_date) {
        if (res[idx+1]) {
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              year: (res[idx+1].label.substring(0, 4)).substring(2),
            },
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                periods_id : res[idx+1].value,
                nextyear : res[idx+1].label.substring(0, 4)
              }
            }
          }))
        }
      }
    })

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods2: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;

    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  async _getNewStudentOptions(filters = {}) {
    const { getNewStudentOptions } = this.props;
    const res = await getNewStudentOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getStudentPPDBOptions(filters = {}) {
    const { getStudentPPDBOptions } = this.props;
    const res = await getStudentPPDBOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student_ppdb: res,
      },
    }));
  }

  async _getStudentNextClassOptions() {
    const { filters, form } = this.state;
    const { getStudentNextClassOptions, user } = this.props;
    const { organizations_id, school_unit } = user;

    // filters.period=form.value.periods_id
    const res = await getStudentNextClassOptions({
      filters,
      units_id:school_unit.id,
      organizations_id,
    });
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student_next: res,
      },
    }));
  }
  async _getNewStudentPPDBOptions() {
    const { filters, form } = this.state;
    const { getNewStudentPPDBOptions, user, getStudentPPDBOptions, getStudentPPDBF07Options } = this.props;
    const { organizations_id, school_unit } = user;
    const { value } = form;
    const { nextyear } = value;

    // const year = nextyear.substring(2);

    // filters.period=form.value.periods_id
    let res = {};
    if(school_unit.ppdb_online == 0){
      res = await getNewStudentPPDBOptions({
        filters,
        units_id:school_unit.id,
        organizations_id,
        // year:year
      });
    } else if (school_unit.ppdb_online == 1 && school_unit.unit_code !== 'F07'){
      res = await getStudentPPDBOptions({
        filters,
      });
    } else if (school_unit.ppdb_online == 1 && school_unit.unit_code == 'F07'){
      res = await getStudentPPDBF07Options({
        // filters: {year:year},
      });
    }
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student_new_ppdb: res,
      },
    }));
  }

  async _getClassroomsOptions() {
    const { getClassroomsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { form } = this.state;
    const { classes_id, levels_id, periods_id, classrooms_id } = form.value;

    const res = await getClassroomsOptions({
      classes_id: classes_id,
      levels_id: levels_id,
      periods_id: periods_id,
      units_id: school_unit.id
    });

    const selectedClassroom = res.find((item) => item.value == classrooms_id);

    // console.log(selectedClassroom, res, classrooms_id);
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          capacity: selectedClassroom
            ? selectedClassroom.attributes.capacity
            : "",
        },
      },
      param: {
        ...prevState.param,
        classrooms: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      list = {},
      filters,
    } = this.state;
    const { pembatalan_siswa } = form.value;
    const {students_id = "", period = ""} = filters;
    const { user } = this.props;
    const { school_unit } = user;
    const tipe = [{label: 'Siswa Tanpa Kelas', value: 'noclass'},
                  {label: 'Siswa PPDB Offline', value: 'ppdboffline'},
                  {label: 'Siswa PPDB Online', value: 'ppdb'},
                  {label: 'Siswa Lulus', value: 'graduate'}];
  

    return (
      <div className="absensi-rekap__custom-form">
         <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="tipe"
              label="Pilih Tipe"
              onChange={this._onFormChange}
              data={tipe}
              placeholder={"Pilihan"}
              value={form.value.tipe}
              error={form.error.tipe || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-row__field">
          {form.value.tipe !== 'noclass' && form.value.tipe !== 'graduate'?
            
            <Select
              name="entrance_year"
              label="Tahun Ajaran"
              placeholder={"Pilihan"}
              onChange={this._onChangeFilter}
              data={param.periods}
              value={filters.entrance_year}
              />
          :''}
          {form.value.tipe !== 'noclass' && form.value.tipe == 'graduate'?
            <Select
              name="period"
              label="Tahun Ajaran"
              placeholder={"Pilihan"}
              onChange={this._onChangeFilter}
              data={param.periods2}
              value={filters.period}
              />
          :''}

          </div>
        </div>
        {form.value.tipe?
        <div>
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    {map(PEMBATALAN_SISWA_TABEL_FIELD.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* {pembatalan_siswa.no_va? */}
                  {map(pembatalan_siswa, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        PEMBATALAN_SISWA_TABEL_FIELD.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.attribute === "checkbox") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                <Input
                                  type="checkbox"
                                  name="checkbox"
                                  data-input-array={true}
                                  data-array-position={idx}
                                  data-field-name="pembatalan_siswa"
                                  onChange={this._onFormChange}
                                  // checked={list.checkbox}
                                  checked={data.checkbox == true ? true : false}
                                  value={data.checkbox ? data.checkbox : false}
                                />{" "}
                              </td>
                            );
                          }
                          if(field.attribute === "nisn") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                {capitalize(data.nisn?data.nisn:'')}
                              </td>
                            )
                          }
                          if(field.attribute === "no_va") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                {capitalize(data.no_va?data.no_va:'')}
                              </td>
                            )
                          }
                          if(field.attribute === "fullname") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                                {capitalize(data.fullname?data.fullname:'')}
                              </td>
                            )
                          }

                          return (
                            <td
                              className={field.type === "number" ? "nominal" : ""}
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(data, field)}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  ))}
                  {/* // :
                  // <td colSpan={5}>Data PPDB Offline Belum Ada</td>} */}
                </tbody>
              </table>
            </div>
             <div className="student-list__button">
                <Button type="button" title="Delete" onClick={this.onDelete} />
             </div>
            
          </div>

          :''}
           
       
      </div>
    );
  }
}
PembatalanSiswa.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
PembatalanSiswa.defaultProps = {
  user: null,
};