export const getMonths = () => ({
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember',
});

export const getYears = (from = new Date().getFullYear() - 1, to = new Date().getFullYear()) => {
  const years = {};
  for (let i = from; i <= to; i += 1) {
    years[i] = i;
  }
  return years;
};

export const getMonthName = index => getMonths()[index];

export default () => ({
  getMonths,
  getMonthName,
  getYears,
});
