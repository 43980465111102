import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';
import mutasi_siswa from '../../assets/img/akademi/mutasi-siswa.png';
import data_siswa from '../../assets/img/akademi/data-siswa.png';
import displiner from '../../assets/img/akademi/displiner.png';
import absensi_siswa from '../../assets/img/akademi/absensi-siswa.png';
import ekskul from '../../assets/img/akademi/ekskul.png';
import bimbingan_konseling from '../../assets/img/akademi/bimbingan-konseling.png';
import laporan_kesiswaan from '../../assets/img/akademi/laporan-kesiswaan.png';

export default class Student extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPenerimaanSiswa = this.onClickPenerimaanSiswa.bind(this);
    this.onClickDataSiswa = this.onClickDataSiswa.bind(this);
    this.onClickLaporanKesiswaan = this.onClickLaporanKesiswaan.bind(this);
    this.onClickAbsensiSiswa = this.onClickAbsensiSiswa.bind(this);
    this.onClickDispliner = this.onClickDispliner.bind(this);
    this.onClickEkstrakulikuler = this.onClickEkstrakulikuler.bind(this);
    this.onClickBimbinganKonseling = this.onClickBimbinganKonseling.bind(this);
    
  }

  onClickPenerimaanSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/penerimaan-siswa');
  }

  onClickDataSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa');
  }

  onClickLaporanKesiswaan() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan')
  }

  onClickAbsensiSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa')
  }

  onClickDispliner() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner')
  }

  onClickEkstrakulikuler() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler')
  }

  onClickBimbinganKonseling() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa')
  }


  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickPenerimaanSiswa}>
            <img src={mutasi_siswa} alt="DataKelas" />
            <h3>Penerimaan Siswa Baru</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickDataSiswa}>
            <img src={data_siswa} alt="DataKelas" />
            <h3>Data Siswa</h3>
          </button>
        </div> */}
        
        {/* start untuk demo */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickAbsensiSiswa}>
            <img src={absensi_siswa} alt="AbsensiSiswa" />
            <h3>Absensi Siswa</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickDispliner}>
            <img src={displiner} alt="displiner" />
            <h3>Displiner</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickEkstrakulikuler}>
            <img src={ekskul} alt="ekstrakulikuler" />
            <h3>Ekstrakulikuler</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickBimbinganKonseling}>
            <img src={bimbingan_konseling} alt="bimbingankonseling" />
            <h3>Bimbingan Konseling</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporanKesiswaan}>
            <img src={laporan_kesiswaan} alt="LaporanKesiswaan" />
            <h3>Laporan Kesiswaan</h3>
          </button>
        </div> */}
        {/* end untuk demo */}
      
      </div>
    );
  }
}
Student.propTypes = {
  history: PropTypes.object.isRequired,
};
