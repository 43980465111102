import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import {
  Input, Button, Select,
} from '../../../components/base';
import { successAlert, errorsMessage, errorAlert} from '../../../utils/alert.util';
import * as DateUtil from '../../../utils/date.util';


export default class ManagePotonganIntern extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const { user } = props;
    const { school_unit } = user;

    this.unit_code = school_unit.unit_code;

    this.state = {
      form: {
        value: {
          unit: this.unit_code,
          month: (`0${date.getMonth() + 1}`).slice(-2),
          year: date.getFullYear(),
          data: [],
        },
        error: {},
      },
      tableHeaders: {},
      total_gaji_kotor: 0,
      total_pot_cu:0,
      total_pot_perwakilan:0,
      total_pot_intern:0,
      total_gaji_bersih:0,
    };

    this.reportTypes = [
      {
        value: '2', label: 'Konsolidasi',
      },
      {
        value: '1', label: 'Per Unit',
      },
    ];

    this.months = DateUtil.getMonths();
    this.years = DateUtil.getYears(date.getFullYear() - 1, date.getFullYear() + 1);

    this._getData = this._getData.bind(this);
    this._renderMonthOptions = this._renderMonthOptions.bind(this);
    this._renderYearOptions = this._renderYearOptions.bind(this);
    this._getTableHeaders = this._getTableHeaders.bind(this);
    this._renderReport = this._renderReport.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onPost = this._onPost.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  componentDidMount() {
    this._getTableHeaders();
  }

  async _getTableHeaders() {
    const { handleGetTableHeaders } = this.props;
    const res = await handleGetTableHeaders();
    this.setState(prevState => ({
      ...prevState,
      tableHeaders: res,
    }), () => {
      this._getData();
    });
  }

  async _getData() {
    const { handleGetData } = this.props;

    const { form } = this.state;
    const { value } = form;
    const { month, year } = value;

    const res = await handleGetData({
      month,
      year,
      unit: this.unit_code,
    });

    let isPosted = 0;
    let total_gaji_kotor = 0;
    let total_pot_cu = 0;
    let total_pot_perwakilan = 0;
    let total_pot_intern = 0;
    let total_gaji_bersih = 0;

    if (res) {
      res.forEach((item, index) => {
        total_gaji_kotor = total_gaji_kotor + parseInt(item.gaji_bersih, 0);
        total_pot_cu = total_pot_cu + parseInt(item.pot_cu, 0);
        total_pot_perwakilan = total_pot_perwakilan + parseInt(item.pot_perwakilan, 0);
        total_pot_intern = total_pot_intern + parseInt(item.pot_intern, 0);
        isPosted = parseInt(item.flag_cu, 0) === 2
          && parseInt(item.flag_perwakilan, 0) === 2
          && parseInt(item.flag_intern, 0) === 2;
        const total_potongan = parseInt(item.pot_cu, 0)
          + parseInt(item.pot_perwakilan, 0) + parseInt(item.pot_intern, 0);
        res[index].gaji_kotor = res[index].gaji_bersih || 0;
        res[index].gaji_bersih = parseInt(res[index].gaji_bersih, 0) - total_potongan;
        total_gaji_bersih = total_gaji_bersih + parseInt(res[index].gaji_bersih, 0);
      });
      this.setState({
        list: res,
        isPosted,
        form: {
          value: {
            unit: this.unit_code,
            month,
            year,
            data: res,
          },
        },
        total_gaji_kotor: total_gaji_kotor,
        total_pot_cu: total_pot_cu,
        total_pot_perwakilan: total_pot_perwakilan,
        total_pot_intern: total_pot_intern,
        total_gaji_bersih: total_gaji_bersih,
      });
    }
  }

  _onFormChange({ target }) {
    const { name, value, dataset } = target;
    const { arrayPosition, fieldName } = dataset;
    const { form } = this.state;
    const newValue = JSON.parse(JSON.stringify(form.value));

    if (arrayPosition && fieldName) {
      const dataArray = newValue[fieldName][arrayPosition];
      dataArray[name] = normalizeAmount(value);
      dataArray.gaji_bersih = dataArray.gaji_kotor - dataArray.pot_cu
        - dataArray.pot_perwakilan
        - dataArray.pot_intern;
      if(dataArray.gaji_bersih<0){
        errorAlert({ title: 'Gaji bersih tidak boleh minus' });
        dataArray[name] = 0;
        dataArray.gaji_bersih = dataArray.gaji_kotor - dataArray.pot_cu
        - dataArray.pot_perwakilan
        - dataArray.pot_intern;
      }
    } else {
      newValue[name] = value;
    }

    this.setState({
      form: {
        value: newValue,
      },
    }, () => {
      if (name === 'month' || name === 'year') {
        this._getData();
      }
      let total_gaji_kotor = 0;
      let total_pot_cu = 0;
      let total_pot_perwakilan = 0;
      let total_pot_intern = 0;
      let total_gaji_bersih = 0;
      let data_form = this.state.form.value.data;
      // console.log(data_form)
      data_form.forEach((item, index) => {
        total_gaji_kotor = total_gaji_kotor + parseInt(item.gaji_kotor, 0);
        total_pot_cu = total_pot_cu + parseInt(item.pot_cu, 0);
        total_pot_perwakilan = total_pot_perwakilan + parseInt(item.pot_perwakilan, 0);
        total_pot_intern = total_pot_intern + parseInt(item.pot_intern, 0);
        total_gaji_bersih = total_gaji_bersih + parseInt(item.gaji_bersih, 0);
      });
      this.setState({
        total_gaji_kotor: total_gaji_kotor,
        total_pot_cu: total_pot_cu,
        total_pot_perwakilan: total_pot_perwakilan,
        total_pot_intern: total_pot_intern,
        total_gaji_bersih: total_gaji_bersih,
      });
    });
  }

  async _onSubmit(e) {
    const { handleSave } = this.props;
    const { form } = this.state;
    const { value } = form;

    e.preventDefault();
    const res = await handleSave(value);

    if (res === 'Success') {
      successAlert({ title: 'Berhasil menyimpan data' });
    }
  }

  async _onPost() {
    const { handlePost } = this.props;
    const { form } = this.state;
    const { value } = form;

    const res = await handlePost(value);
    if (res === 'Success') {
      successAlert({ title: 'Berhasil memposting data' });
      this._getData();
    }
  }

  _renderMonthOptions() {
    const { form } = this.state;
    const options = Object.keys(this.months).map(item => ({
      value: (`0${item}`).slice(-2),
      label: this.months[item],
    }));

    return (
      <Select
        label="Bulan"
        data={options}
        name="month"
        value={form.value.month}
        onChange={this._onFormChange}
      />
    );
  }

  _renderYearOptions() {
    const { form } = this.state;
    const options = Object.keys(this.years).map(item => ({
      value: item,
      label: this.years[item],
    }));
    return (
      <Select
        label="Tahun"
        data={options}
        name="year"
        value={form.value.year}
        onChange={this._onFormChange}
      />
    );
  }

  _renderReport() {
    const { form } = this.state;
    const { value } = form;
    const { data } = value;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>No.</th>
            <th>No. Pegawai</th>
            <th>Nama</th>
            <th>Gaji Kotor</th>
            <th>Pot. CU</th>
            <th>Pot. Perwakilan</th>
            <th>Pot. Intern</th>
            <th>Gaji Bersih</th>
          </tr>
        </thead>
        <tbody>
          { data.map((item, index) => (
            <tr>
              <td>{ index + 1 }</td>
              <td>{item.no_g}</td>
              <td>{item.name}</td>
              <td className="nominal">{commaFormatted(item.gaji_kotor)}</td>
              <td className="nominal">
                { parseInt(item.flag_cu, 0) === 2 && (
                  <>
                    {commaFormatted(item.pot_cu)}
                  </>
                )}
                { parseInt(item.flag_cu, 0) === 1 && (
                  <Input
                    type="text"
                    data-input-array
                    data-array-position={index}
                    data-field-name="data"
                    name="pot_cu"
                    onChange={this._onFormChange}
                    value={commaFormatted(item.pot_cu)}
                  />
                )}
              </td>
              <td className="nominal">
                { parseInt(item.flag_perwakilan, 0) === 2 && (
                  <>
                    {commaFormatted(item.pot_perwakilan)}
                  </>
                )}
                { parseInt(item.flag_perwakilan, 0) === 1 && (
                  <Input
                    type="text"
                    data-input-array
                    data-array-position={index}
                    data-field-name="data"
                    name="pot_perwakilan"
                    onChange={this._onFormChange}
                    value={commaFormatted(item.pot_perwakilan)}
                  />
                )}
              </td>
              <td className="nominal">
                { parseInt(item.flag_intern, 0) === 2 && (
                  <>
                    {commaFormatted(item.pot_intern)}
                  </>
                )}
                { parseInt(item.flag_intern, 0) === 1 && (
                  <Input
                    type="text"
                    data-input-array
                    data-array-position={index}
                    data-field-name="data"
                    name="pot_intern"
                    onChange={this._onFormChange}
                    value={commaFormatted(item.pot_intern)}
                  />
                )}
              </td>
              <td className="nominal">{commaFormatted(item.gaji_bersih)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>Jumlah</th>
            <td className="nominal"><b>{commaFormatted(this.state.total_gaji_kotor)}</b></td>
            <td className="nominal"><b>{commaFormatted(this.state.total_pot_cu)}</b></td>
            <td className="nominal"><b>{commaFormatted(this.state.total_pot_perwakilan)}</b></td>
            <td className="nominal"><b>{commaFormatted(this.state.total_pot_intern)}</b></td>
            <td className="nominal"><b>{commaFormatted(this.state.total_gaji_bersih)}</b></td>
          </tr>
        </tfoot>
      </table>
    );
  }

  render() {
    const { isPosted } = this.state;
    return (
      <form onSubmit={this._onSubmit}>
        <div className="big-book-report">
          <div className="report-card">
            <div className="report-card__header">
              <div className="report-card__entries">
                <div className="form-group inline">
                  { this._renderMonthOptions() }
                  { this._renderYearOptions() }
                </div>
              </div>
            </div>
            <div className="report-card__body">
              { this._renderReport() }
            </div>
            { !isPosted && (
              <div className="buttons">
                <Button
                  type="submit"
                  title="Simpan"
                />
                &nbsp;
                <Button
                  type="button"
                  title="Posting"
                  onClick={this._onPost}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    );
  }
}

ManagePotonganIntern.propTypes = {
  user: PropTypes.object.isRequired,
  handleGetData: PropTypes.func.isRequired,
  handleGetTableHeaders: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handlePost: PropTypes.func.isRequired,
};
