import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { param } from 'jquery';


export default function BuatPelanggaranForm(props) {
  const { onFormChange, form, param } = props;
  const prestasi = [{ label : 'Ya', value : 'y'},
                    { label : 'Tidak', value : 'n'},
];
const workflow = [{ label : 'S - Per Satu Semester', value : 'satu_semester'},
                  { label : 'T - Per Satu Tahun Ajaran', value : 'tahun_ajaran'},
                  { label : 'L - Hingga Lulus', value : 'hingga_lulus'},
]
  return (
    <div className="buat-pelanggaran__custom-form">
      <div className="buat-pelanggaran__custom-form-column">
        <div className="buat-pelanggaran__custom-form-column__field">
          <Input
            type="text"
            name="name"
            label="Nama Pelanggaran"
            onChange={onFormChange}
            value={form.value.name}
            error={form.error.name || ''}
          />
          
          
          <Select
            name="violations_group_id"
            label="Grup Pelanggaran"
            onChange={onFormChange}
            data={param.prm_violations_group}
            placeholder={'Pilihan'}
            value={form.value.violations_group_id}
            error={form.error.violations_group_id || ''}
          />
         
          <Select
            name="violations_in_class"
            label="Merupakan Pelanggaran Dikelas"
            onChange={onFormChange}
            data={prestasi}
            placeholder={'Pilihan'}
            value={form.value.violations_in_class}
            error={form.error.violations_in_class || ''}
          />
        <div className="buat-pelanggaran__content">
         <Input
            type="number"
            name="score"
            label="Skor"
            onChange={onFormChange}
            value={form.value.score}
            error={form.error.score || ''}
          />
         
        
        <Input
            type="text"
            name="sort"
            label="Urutan"
            onChange={onFormChange}
            value={form.value.sort}
            error={form.error.sort || ''}
          />
           </div>
           <Select
            name="consequence_workflow"
            label="Berlaku Hingga"
            onChange={onFormChange}
            data={workflow}
            placeholder={'Pilihan'}
            value={form.value.consequence_workflow}
            error={form.error.consequence_workflow || ''}
          />

         </div>
      </div>
      {/* <div className="buat-pelanggaran__body">
        <table className="buat-pelanggaran__table">
            <thead>
            <tr>
                <th>No.</th>
                <th>Jumlah Pelanggaran</th>
                <th>Workflow yang akan dijalankan</th>
               
            </tr>
            </thead>
            <tbody>
           
                <tr>
                    <td>1.</td>
                    <td> <Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
                 /></td>
                    <td><Input
                        type="text"
                        name="score"
                        onChange={onFormChange}
                        // value={form.value.namaSaudara1 || ''}
                        // error={form.error.namaSaudara1 || ''}
                    /></td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td> <Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
                 /></td>
                    <td><Input
                        type="text"
                        name="score"
                        onChange={onFormChange}
                        // value={form.value.namaSaudara1 || ''}
                        // error={form.error.namaSaudara1 || ''}
                    /></td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td> <Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
                 /></td>
                    <td><Input
                        type="text"
                        name="score"
                        onChange={onFormChange}
                        // value={form.value.namaSaudara1 || ''}
                        // error={form.error.namaSaudara1 || ''}
                    /></td>
                </tr>
                <tr>
                    <td>4.</td>
                    <td> <Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
                 /></td>
                    <td><Input
                        type="text"
                        name="score"
                        onChange={onFormChange}
                        // value={form.value.namaSaudara1 || ''}
                        // error={form.error.namaSaudara1 || ''}
                    /></td>
                </tr>
                <tr>
                    <td>5.</td>
                    <td> <Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
                 /></td>
                    <td><Input
                        type="text"
                        name="score"
                        onChange={onFormChange}
                        // value={form.value.namaSaudara1 || ''}
                        // error={form.error.namaSaudara1 || ''}
                    /></td>
                </tr>
               
            </tbody>
        </table>
        </div> */}

    </div>
  );
}
BuatPelanggaranForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatPelanggaranForm.defaultProps = {
  onFormChange: noop,
};
