import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ganti_kata_sandi from '../../assets/img/akademi/setting/ganti_kata_sandi.png';
import identitas_unit_sekolah from '../../assets/img/akademi/administrasi/DataKelas/identitas-unit-sekolah.png';
import kelas from '../../assets/img/akademi/administrasi/DataKelas/kelas.png';
import tahun_ajaran_baru from '../../assets/img/akademi/administrasi/DataKelas/tahun-ajaran-baru.png';
import mutasi_siswa from '../../assets/img/akademi/mutasi-siswa.png';

export default class Setting extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPassword = this.onClickPassword.bind(this);
    this.onClickResetPassword = this.onClickResetPassword.bind(this);
    this.onClickIdentitas = this.onClickIdentitas.bind(this);
    this.onClickTahunAjaran = this.onClickTahunAjaran.bind(this);
    this.onClickKelas = this.onClickKelas.bind(this);
    this.onClickImportSiswa = this.onClickImportSiswa.bind(this);
  }

  onClickPassword() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/ganti-kata-sandi');
  }

  onClickResetPassword() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/atur-ulang-kata-sandi');
  }

  onClickIdentitas() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/identitas-unit-sekolah');
  }

  onClickTahunAjaran() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/tahun-ajaran');
  }

  onClickKelas(){
    const { history } = this.props;
    history.push('/dashboard/pengaturan/kelas');
  }

  onClickImportSiswa(){
    const { history } = this.props;
    history.push('/dashboard/pengaturan/import-daftar-siswa');
  }

  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickPassword}>
            <img src={ganti_kata_sandi} alt="ganti_kata_sandi" />
            <h3>Ganti Kata Sandi</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickResetPassword}>
            <img src={ganti_kata_sandi} alt="reset_kata_sandi" />
            <h3>Atur Ulang Kata Sandi</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickIdentitas}>
            <img src={identitas_unit_sekolah} alt="identitas" />
            <h3>Identitas Unit Sekolah</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickTahunAjaran}>
            <img src={tahun_ajaran_baru} alt="jurusan" />
            <h3>Tahun Ajaran Baru</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickKelas}>
            <img src={kelas} alt="kelas" />
            <h3>Pembentukan Kelas</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickImportSiswa}>
            <img src={mutasi_siswa} alt="import daftar siswa" />
            <h3>Import Daftar Siswa</h3>
          </button>
        </div> */}
      </div>
    );
  }
}
Setting.propTypes = {
  history: PropTypes.object.isRequired,
};
