import PengisianObsHarian from '../../../views/Kurikulum/Ulangan/PengisianObsHarian.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptionsAcademics,  getStudentOptions, getSubjectOptions, getPeriodsOptions, getPrmLevels} from '../../../states/thunks/options.thunk';
import {  listPengisianObsHarian, listPengisianMingguan, listDaftarNilaiReport, listDaftarNilaiReportEng, listDaftarNilaiReportMan } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state){
    return{
        classes: state.options.classes,
        result: state.result,
        user: state.user || {},
        levels: state.options.levels,
    }
}

function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: (payload) => dispatch(getClasses(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
        handleListPengisianObsHarian: (payload, goback) => dispatch(listPengisianObsHarian(payload, goback)),
        handleListPengisianObsMingguan: (payload, goback) => dispatch(listPengisianMingguan(payload, goback)),
        handleListDaftarNilaiReport: (payload, goback) => dispatch(listDaftarNilaiReport(payload, goback)),
        handleListDaftarNilaiReportEng: (payload, goback) => dispatch(listDaftarNilaiReportEng(payload, goback)),
        handleListDaftarNilaiReportMan: (payload, goback) => dispatch(listDaftarNilaiReportMan(payload, goback)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
        
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (PengisianObsHarian);