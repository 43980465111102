import { connect } from 'react-redux';
import TunggakanGeneral from '../../../../views/Finance/Spp/Tunggakan/TunggakanGeneral.view';
import { listArrearsStudents, listArrearsClasses, listArrearsAccumulations, listArrearsStudentsGeneral, listArrearsClassesGeneral, listArrearsAccumulationsGeneral, listArrearsClassesRICCI } from '../../../../states/thunks/academics.thunk';
import { getParamOptionsAcademics, getYearsOfEducationsOptions, getStudentOptions, getPeriodsOptions, getMsTempsSiswaOptions, getStudentSPPOptions, getSchoolUnitsOptions, getPrmPaymentsGeneral, getPrmPaymentsNonGeneral } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleListArrearsStudents: (payload, goback) => dispatch(listArrearsStudents(payload, goback)),
    handleListArrearsClasses: (payload, goback) => dispatch(listArrearsClasses(payload, goback)),
    handleListArrearsClassesRICCI: (payload, goback) => dispatch(listArrearsClassesRICCI(payload, goback)),
    handleListArrearsAccumulations: (payload, goback) => dispatch(listArrearsAccumulations(payload, goback)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    handleListArrearsStudentsGeneral: (payload, goback) => dispatch(listArrearsStudentsGeneral(payload, goback)),
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    handleListArrearsClassesGeneral: (payload, goback) => dispatch(listArrearsClassesGeneral(payload, goback)),
    handleListArrearsAccumulationsGeneral: (payload, goback) => dispatch(listArrearsAccumulationsGeneral(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmPaymentsGeneralOptions: (payload) => dispatch(getPrmPaymentsGeneral(payload)),
    getPrmPaymentsNonGeneralOptions: (payload) => dispatch(getPrmPaymentsNonGeneral(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TunggakanGeneral);
