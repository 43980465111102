import { connect } from 'react-redux';
import CetakBukuSiswa from '../../../../views/Student/report/cetak/CetakBukuSiswa.student.view';
import { getStudent } from '../../../../states/thunks/student.thunk';
import { editLembarObsHarian, saveLembarObsHarian, getStudentNumber, listScoreStudents } from '../../../../states/thunks/academics.thunk'
import { getSubjectOptions, getPeriodsOptions } from '../../../../states/thunks/options.thunk';
function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudentNumber: payload => dispatch(getStudentNumber(payload)),
    handleListScoreStudent: (payload, goback) => dispatch(listScoreStudents(payload, goback)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CetakBukuSiswa);


