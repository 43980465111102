import { connect } from 'react-redux';
import Elearning from '../../../views/Kurikulum/Elearning/elearning.view';

function mapStateToProps(state) {
    return {};
}
  
  function mapDispatchToProps(dispatch) {
    return {
      
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Elearning);