import { connect } from 'react-redux';
import IsiPerbidangStudy from '../../../views/Student/absensi/IsiPerbidangStudy.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getPeriodsOptions, getParamOptions, getStudentOptions , getSubjectOptions, getParamOptionsAcademics, getClassesOptions, getPrmLevels} from '../../../states/thunks/options.thunk';
import {  listStudyField, deleteStudyField} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleListStudyField: (payload, goBack) => dispatch(listStudyField(payload, goBack)),
    handleDeleteStudyField: (payload, goback) => dispatch(deleteStudyField(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(IsiPerbidangStudy);


