import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop, isEqual, capitalize } from "lodash";
import ListForm from "../../../components/ListForm/ListForm.component";
import language from "../../../languages";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { PEMBAGIAN_KELAS_TABLE_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import {
  Button,
  Select,
  Pagination,
  Input,
  Loading,
} from "../../../components/base";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PembagianKelas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.addPembagianKelas = this.addPembagianKelas.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
   
   
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;

    this.state = {
      content: initialContent,
      listAmount: 5,
      filters: {},
      param: {},
      form: {
        value: {
          levels_id,
          plafon: [
            {
              tipePlafon: "",
              //divisi: '',
              nominal: 0,
            },
          ],
        },
        error: {
          plafon: "",
        },
      },
    };
  }

  componentDidMount() {
    const { location, handleGetclassrooms, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const paramTypes = [ "classrooms", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/tata-usaha/manajemen-kelas/kelola-pembagian-kelas", {
      data: val,
      isEdit: true,
    });
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  // on formchange untuk form
  _onFormChange(event) {
    const { user } = this.props;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        if (name === "classrooms_id") {
          this._onSearchContent();
        }
      }
    );
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    return;
  }

  async onEdit() {
    const { history, handleEditClassDiv } = this.props;
    const { form } = this.state;
    const { value } = form;

    const res = await handleEditClassDiv(value);
    history.push("/dashboard/tata-usaha/manajemen-kelas/kelola-pembagian-kelas");
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListClassDiv, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id, units_id } = school_unit;
          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = await handleListClassDiv({
            ...params,
            organizations_id: organizations_id,
            units_id: school_unit.id,
            filters,
          });
          //  console.log(result)
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;

      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    // console.log(plafon)
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list,
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    // console.log(name)
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push("/dashboard/tata-usaha/manajemen-kelas/kelola-pembagian-kelas");
  }

  addPembagianKelas(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push("/dashboard/tata-usaha/manajemen-kelas/kelola-pembagian-kelas", {
      isEdit: true,
    });
  }

  deletePlafon(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ people: array });
    }
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }

  render() {
    const { form, content, filters, listAmount, list = {}, param } = this.state;
    const { value } = form;
    const { classrooms = {}, levels = {} } = this.props;
    const { classrooms_id = "" } = filters;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
   
    const loadingProps = { show: content.loading };
    // const {  levels={}}= this.props;
    return (
      <div className="add-budget">
        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Buat Pembagian Kelas"
              onClick={this.addPembagianKelas}
            />
          </div>
        </div>
        <div className="cash-journal__content">
          <div className="content-table">
            <div className="table-filters">
              {/* <Select
                name="tahun_ajaran"
                label="Tahun Ajaran"
                onChange={this._onFormChange}
                value={form.value.tahun_ajaran}
                error={form.error.tahun_ajaran || ""}
                placeholder={"Pilihan"}
                data={param.periods}
              /> */}
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                disabled
                placeholder="Pilih Unit"
                data={param.levels}
                value={form.value.levels_id}
                onChange={this._onFormChange}
                error={form.error.levels_id || ""}
              />
              <Select
                placeholder="Pilih Kelas"
                label="Kelas"
                name="classrooms_id"
                value={classrooms_id}
                data={param.classrooms}
                onChange={this._onChangeFilter}
              />
            </div>
            <div className="manage-registration">
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(PEMBAGIAN_KELAS_TABLE_FIELDS.label, (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {map(content.list, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          PEMBAGIAN_KELAS_TABLE_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td style={{textAlign: "center"}} key={`table_index_${fieldIdx}_${idx}`}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "name") {
                              return(
                                <td style={{textTransform: "capitalize"}}>{capitalize(list.name)}</td>
                              )
                            }
                            if (field.attribute === "homeroom_teacher") {
                              return (
                                <td>
                                  {list.homeroom_teacher
                                    ? list.homeroom_teacher
                                    : list.homeroom_teacher_name2}
                                </td>
                              );
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <Link
                                    to={
                                      "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                                    }
                                  >
                                    {list.name}
                                  </Link>
                                </td>
                              );
                            }
                            return (
                              <td
                                className={
                                  field.type === "number" ? "nominal" : ""
                                }
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(list, field)}
                              </td>
                            );
                          }
                        )}
                        {this._renderButtons(list)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* ) */}
            {/* } */}
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this._onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
PembagianKelas.propTypes = {
  handleGetclassrooms: PropTypes.func,
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
PembagianKelas.defaultProps = {
  handleGetclassrooms: noop,
  handleGetLevels: noop,
  user: null,
};
