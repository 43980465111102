import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, InputStaff, Button, Select } from '../../../components/base';

export default class DataSuratPeringatan extends Component {
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteListSuratPeringatan = this._onDeleteListSuratPeringatan.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }
    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteListSuratPeringatan(idx) {
        const { onDeleteListSuratPeringatan, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteListSuratPeringatan(idx, fieldName);
      }  

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }
     
    render() {
        const {
          list, error, onChange,
          fieldName, memorandum_name, memorandum,
        } = this.props;

        return (
            <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Level</th>
                  <th>Tanggal</th>
                  <th>No Surat</th>
                  <th>Jenis PeLanggaran</th>
                  <th>Keterangan</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_memorandum_form__${idx}`}>
                <td>{idx + 1}</td>
                <td className="level">
                <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='memorandum'
                    data-input-type="text"
                    name="level"
                    onChange={onChange}
                    value={data.level}
                  />
                </td>
                <td className="tanggal">
                <InputStaff
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='memorandum'
                    type="date"
                    name="tanggal"
                    onChange={onChange}
                    value={data.tanggal}
                  />
                </td>
                <td className="no_surat">
                <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='memorandum'
                    data-input-type="text"
                    name="no_surat"
                    onChange={onChange}
                    value={data.no_surat}
                  />
                </td>
                <td className="jenis_pelanggaran">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='memorandum'
                    data-input-type="text"
                    name="jenis_pelanggaran"
                    onChange={onChange}
                    value={data.jenis_pelanggaran}
                  />
                </td>
                <td className="keterangan">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='memorandum'
                    data-input-type="text"
                    name="keterangan"
                    onChange={onChange}
                    value={data.keterangan}
                  />
                </td>
                <td>
                  <Button onClick={() => this._onDeleteListSuratPeringatan(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
        }
        <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
        </table>
        );
    }
}

DataSuratPeringatan.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteListSuratPeringatan: PropTypes.func,
    onChange: PropTypes.func,
    memorandum_name: PropTypes.array,
};

DataSuratPeringatan.defaultProps = {
    onAddList: noop,
    onDeleteListSuratPeringatan: noop,
    onChange: noop,
    memorandum_name:[],
};