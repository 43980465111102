import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop, isEmpty } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { Link } from 'react-router-dom';
import { Pagination } from '../../../components/base';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class IsiPerBidangTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: initialContent,
      listAmount: 10,
    };
    this.onChangePage = this.onChangePage.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  
  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }
  // _onFormChange(event) {
  //   const { onChange } = this.props;
  //   onChange(event);
  // }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  render() {
    
    const {
      listAmount,
      content,
    } = this.state;
    const {
      paging,
      currentPage,
      totalPage,
      tableFields,
      onClickEdit,
      onClickView,
      showActions,
      customThead,
      customAction,
      showView,
      showEdit,
      showDelete,
      onChange,
      form,
    } = this.props;
    const loadingProps = { show: content.loading };
  return (
    <div className="daftar-ekskul__content">
               
                <div className="daftar-ekskul__body">
                  <table className="daftar-ekskul__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Siswa</th>
                        <th>NIS</th>
                        <th>Status</th>
                        <th>Pengawasan</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                    <tr>
                        <td>1</td>
                        <td>Ababababbababaabbabababb</td>
                        <td>122</td>
                        <td><Select
                            name="status"
                            onChange={this._onFormChange}
                            style={{backgroundColor:'yellow'}}
                            value={form.value.status}
                            error={form.error.status || ''}
                            /></td>
                      <td><Select
                            name="pengawasan"
                            onChange={this._onFormChange}
                            placeholder={"Pilih"}
                            value={form.value.pengawasan}
                            error={form.error.pengawasan || ''}
                            /></td>
                       
                        <td><Input
                             type="text"
                             name="keterangan"
                             style={{backgroundColor:'#007bff'}}
                             onChange={this._onFormChange}
                             value={form.value.keterangan}
                             error={form.error.keterangan || ''}
                            /></td>
                       

                    </tr>
                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
                {
                  paging && (
                    <div className="report-card__footer">
                      <p className="user-management__page-info">
                        {`Halaman ${currentPage} dari ${totalPage}`}
                      </p>
                      <Pagination
                          totalPage={content.totalPage}
                          currentPage={content.currentPage}
                          onClick={this.onChangePage}
                        />
                    </div>
                  )
                 }
               
              </div>
  );
}
}
IsiPerBidangTable.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  paging: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
};
IsiPerBidangTable.defaultProps = {
  onChange: noop,
  paging: true,
  currentPage: 1,
  totalPage: 1,
};
