import env from './env.config';

export const {
  URL: SERVER_URL,
  URL_2: SERVER_URL_2,
  URL_3: SERVER_URL_3,
  PAYMENT: PAYMENT_API,
  API_LOCAL,
  API_BRIDGE,
  API_AKADEMIS,
  subdomain,
} = env;
export const endpoints = {
  TEST: '/test',
  AUTH: '/dev_auth',
  LOGIN: '/dev_auth/login',
  PROFILE: '/dev_auth/profile',
  PASSWORD: 'dev_auth/password',
  CHANGE_PASSWORD: 'dev_auth/',
  PARAM_AKADEMI: '/options/param',
  ACADEMICS: '',
  ACADEMICS_PARAM: '/options',
  AKADEMIS:'',
  AKADEMIS_PROFILE: '/profile',
  GETACADEMICS: '',
  BUDGET: '/budget',
  BUDGET_REQUEST: '/budget/request',
  NON_BUDGET: '/non-budget',
  REALIZATION: '/budget/realization',
  PARAM: '/param',
  OPTIONS: '/options',
  FINANCE_OUTCOME: '/finance/outcome',
  FINANCE_RAPBU: '/finance/rapbu',
  FINANCE_JOURNAL: '/journal',
  FINANCE_CASH_JOURNAL: '/finance/cash-journal',
  FINANCE_CASH_JOURNAL_BY_ID: '/finance/cash-journal',
  FINANCE_STARTING_BALANCE: '/journal/starting-balance',
  NOTIFICATION_STREAM: '/notifications/stream',
  NOTIFICATION: '/notifications',
  PAYMENT: '/',
  PAYMENT_REPORT: '/report',
  PAYMENT_RECAP: '/recap',
  PAYMENT_OPTIONS: '/options',
  STAFF: '/staff',
  SK: '/sk',
  ST: '/st',
  REPORT: '/report',
  TAX: '',
  FILE: '/file',
  PAYROLL: '/payroll',
  INTERN: '/intern',
};

export const mockResponses = env.fixtures || {};
