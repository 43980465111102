import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import BaseTable from '../../../../components/BaseTable/BaseTable.component';
import { formatData } from '../../../../utils/transformer.util';
import language from '../../../../languages';
import { FINANCE_FUND_REQUEST_TABLE_FIELDS } from '../../../../constants/finance/budgeting/fundRequest.constant';

export default class FundRequest extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }

  async handleGetData(payload = {}) {
    const { handleGetFundRequest, user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleGetFundRequest(payload);

    this.setState({
      list: res,
    });
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-permohonan-dana', { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-permohonan-dana', { data: val, isEdit: true });
  }

  onClickView(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-permohonan-dana', { data: val, isEdit: false });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    const { workflow } = data;
    const lastWorkflow = workflow[workflow.length -1];

    if (workflow === null || workflow.length === 0 || (
      !lastWorkflow.is_done && user_group
      && user_group.name === lastWorkflow.next_role
      && permissions.includes('edit_fund_request')
    )) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
            { permissions.includes('delete_fund_request')
              && (
              <Button
                onClick={async () => { await this.onClickDelete(data.id); }}
                title="Hapus"
              />
              )
            }
          </div>
        </td>
      );
    }

    if (!lastWorkflow.is_done && user_group && user_group.name === lastWorkflow.next_role) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title={permissions.includes('edit_fund_request_recommendation') ? 'Edit' : 'View'}
            />
          </div>
        </td>
      );
    }
    let status = 'Telah diajukan ke ';
    if(lastWorkflow.is_done) {
      status = 'Telah disetujui oleh ';
    } else if(lastWorkflow.action === 'reject') {
      status = 'Telah dikembalikan ke ';
    }

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickView(data)}
            title='View'
          />
        </div>
      </td>
    );
  }

  render() {
    const { list } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const dataset = {
      toggle: "modal"
    };
    return (
      <div className="fund-request">
        { permissions.includes('create_fund_request') && (
          <Button
            title="Tambah Permohonan Dana"
            onClick={this.onClickAdd}
            data={dataset}
          />
        )}
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(FINANCE_FUND_REQUEST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(FINANCE_FUND_REQUEST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this.renderStatus(data.workflow)}
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
FundRequest.propTypes = {
  handleGetFundRequest: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
FundRequest.defaultProps = {
  handleGetFundRequest: noop,
  handleDelete: noop,
  user: null,
};
