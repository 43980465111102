import { connect } from 'react-redux';
import DaftarRiwayatSiswa from '../../../views/Student/DataSiswa/DaftarRiwayatSiswa.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';
import { getParamOptions, getStudentExitOptions, getStudentOptions, getParamOptionsAcademics, getPeriodsOptions, getClassesOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import {listStudentHistory} from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentHistory: (payload, goback) => dispatch(listStudentHistory(payload, goback)),
    // getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getStudentExitOptions: (payload) => dispatch(getStudentExitOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaftarRiwayatSiswa);


