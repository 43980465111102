import { connect } from 'react-redux';
import CreateInvoiceRutinPPDB from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoiceRutinPPDB.view';
import { getStudent } from '../../../../states/thunks/student.thunk';
import { getInvoiceRutin, editInvoiceRutin, saveInvoiceRutin, saveInvoiceRutinGeneral, getInvoiceRutinGeneral, editInvoiceRutinGeneral  } from '../../../../states/thunks/academics.thunk'
import { getYearsOfEducationsOptions, getPrmPayments, getPrmPaymentsGeneral } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch){
  return{
    handleGetStudent: () => dispatch(getStudent()),
    handleGetInvoiceRutin: payload => dispatch(getInvoiceRutin(payload)),
    handleEditInvoiceRutin: (payload, goback) => dispatch(editInvoiceRutin(payload, goback)),
    handleSaveInvoiceRutin: (payload, goback) => dispatch(saveInvoiceRutin(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPayments: (payload) => dispatch(getPrmPayments(payload)),
    getPrmPaymentsGeneral: (payload) => dispatch(getPrmPaymentsGeneral(payload)),
    handleSaveInvoiceRutinGeneral: (payload, goback) => dispatch(saveInvoiceRutinGeneral(payload, goback)),
    handleEditInvoiceRutinGeneral: (payload, goback) => dispatch(editInvoiceRutinGeneral(payload, goback)),
    handleGetInvoiceRutinGeneral: (payload, goback) => dispatch(getInvoiceRutinGeneral(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps) (CreateInvoiceRutinPPDB);