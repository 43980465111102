import React, { PureComponent } from "react";
import ListForm2 from "../../../components/ListForm/ListForm2.componet";
import { PERSONNEL_PAYROLL_FORM_FIELDS } from "../../../constants/personnel.constant";

export default class ManagePayroll extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
  }

  componentDidMount() {
    this.setPayrollData();
  }

  currencyFomatter = (data) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(data);
  };

  setPayrollData = async () => {
    const { handleGetPayrollData } = this.props;
    const { nip, payroll_id } = this.props.location.state.data;
    const data = await handleGetPayrollData({
      nip: nip,
      id_payroll: payroll_id,
    });

    let temp_form = { ...this.state.form };
    temp_form.value.no_g = data.nip;
    temp_form.value.nama_pegawai = data.nama_lengkap;
    temp_form.value.gaji_pokok = this.currencyFomatter(data.gaji_pokok);
    temp_form.value.pangkat_golongan = data.golongan.pangkat + '-' + data.golongan.masa_kerja;
    temp_form.value.unit_kerja = data.unit_kerja.name;
    temp_form.value.status = data.status_kepegawaian.name;
    temp_form.value.jabatan = data.jabatan.name;
    temp_form.value.status_keluarga = data.status_perkawinan.id_ptkp;
    temp_form.value.jumlah_jiwa = data.jumlah_jiwa;
    temp_form.value.tunjangan_istri = this.currencyFomatter(data.tunj_istri);
    temp_form.value.tujangan_anak = this.currencyFomatter(data.tunj_anak);
    temp_form.value.tunjangan_beras = this.currencyFomatter(data.tunj_beras);
    temp_form.value.tunjangan_struktural = this.currencyFomatter(
      data.tunj_struktural
    );
    temp_form.value.tunjangan_fungsional = this.currencyFomatter(
      data.tunj_fungsional
    );
    temp_form.value.tunjangan_khusus = this.currencyFomatter(data.tunj_khusus);
    temp_form.value.jumlah_pengasilan = this.currencyFomatter(
      data.jml_penghasilan
    );
    temp_form.value.subsidi_yadapen = this.currencyFomatter(data.sub_yadapen);
    temp_form.value.subsidi_bpjs_kesehatan = this.currencyFomatter(
      data.sub_bpjs_kesehatan
    );
    temp_form.value.subsidi_bpjs_ketenaga_kerjaan = this.currencyFomatter(
      data.sub_bpjs_ketenagakerjaan
    );
    temp_form.value.potongan_yadapen = this.currencyFomatter(data.pot_yadapen);
    temp_form.value.potongan_restitusi = this.currencyFomatter(
      data.pot_restitusi
    );
    temp_form.value.potongan_bpjs_kesehatan = this.currencyFomatter(
      data.pot_bpjs_kesehatan
    );
    temp_form.value.potongan_bpjs_ketenaga_kerjaan = this.currencyFomatter(
      data.pot_bpjs_ketenagakerjaan
    );
    temp_form.value.ids = this.currencyFomatter(data.ids);
    temp_form.value.idp = this.currencyFomatter(data.idp);
    temp_form.value.khalawat = this.currencyFomatter(data.khalawat);
    temp_form.value.bianglala = this.currencyFomatter(data.bianglala);
    temp_form.value.jumlah_bersih = this.currencyFomatter(data.gaji_bersih);
    temp_form.value.jumlah_dibayarkan = this.currencyFomatter(data.pembulatan);
    this.setState({ form: temp_form });
  };

  render() {
    const { form } = this.state;
    return (
      <div className="manage-payroll">
        <h1 className="manage-payroll__title">Detail Payroll</h1>
        <form>
          <ListForm2
            form={form}
            formFields={PERSONNEL_PAYROLL_FORM_FIELDS}
            onFormChange={this._onFormChange}
          />
        </form>
      </div>
    );
  }
}
