import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, noop } from 'lodash';
import { commaFormatted, dateFormat } from '../../../utils/transformer.util';
import ReportCard from '../../../components/ReportCard/ReportCard.component';

export default class ProfitAndLostReport extends PureComponent {
  constructor(props) {
    super(props);

    const date = new Date();
    this.state = {
      from: dateFormat(date, 'DD-MM-YYYY'),
      to: dateFormat(date, 'DD-MM-YYYY'),
      report: [],
      units: [],
      reportType: 'konsolidasi',
      selectedUnit: '',
      selectedPerwakilan: '',
      selectedPos: '',
    };

    this.reportTypes = [
      {
        value: 'konsolidasi', label: 'Konsolidasi',
      },
      {
        value: 'unit', label: 'Per Unit',
      },
    ];
    this.pos = [
      {
        id: 'ypl', title: 'YPL',
      }, {
        id: 'bos', title: 'BOS',
      }, {
        id: 'intern', title: 'Internal',
      },
    ];
    this._getReport = this._getReport.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getUnitOptions = this._getUnitOptions.bind(this);
    this._renderReport = this._renderReport.bind(this);
    this._countSummary = this._countSummary.bind(this);
  }

  componentDidMount() {
    const { handleGetPerwakilan, handleGetUnit } = this.props;
    handleGetPerwakilan();
    handleGetUnit();
    this._getReport();
  }

  async _getReport() {
    const { handleGetProfitLossReport, user } = this.props;
    const {
      from,
      to,
      perwakilan_id = null,
      unit_id = null,
    } = this.state;
    let unitId = unit_id;
    if (perwakilan_id === null && unit_id === null) {
      unitId = user.prm_school_units_id;
    }
    const res = await handleGetProfitLossReport({
      unitId,
      perwakilan_id,
      from,
      to,
    });

    this.setState(prevState => ({
      ...prevState,
      report: res,
    }));
  }

  _onSearch(val) {
    const {
      from = '',
      to = '',
      perwakilanId = null,
      unitId = null,
    } = val;
    this.setState(prevState => ({
      ...prevState,
      from,
      to,
      perwakilan_id: perwakilanId ? perwakilanId.id : null,
      unit_id: unitId ? unitId.id : null,
    }), () => {
      this._getReport();
    });
  }

  async _getUnitOptions(e) {
    const { handleGetUnit } = this.props;
    const { value, name } = e.target;
    const filters = {
      perwakilan_id: null,
    };
    if (name === 'perwakilan') {
      filters.perwakilan_id = value.id;
    } else if (name === 'reportType' && value === 'unit') {
      filters.perwakilan_id = null;
    }

    const res = await handleGetUnit(filters);
    this.setState({
      units: res,
    });
  }

  _countSummary() {
    const { report = [] } = this.state;
    const income = report.filter(o => o.type === 'income');
    const expenses = report.filter(o => o.type === 'expenses');

    let totalIncome = 0;
    totalIncome = income.reduce((a, b) => {
      const current = b.nominal || 0;
      return a + current;
    }, 0);

    let totalExpenses = 0;
    totalExpenses = expenses.reduce((a, b) => {
      const current = b.nominal || 0;
      return a + current;
    }, 0);

    return commaFormatted(totalIncome - totalExpenses);
  }

  _renderReport() {
    const { report = [] } = this.state;
    const data = report;
    const rows = [];
    data.forEach((item, i) => {
      rows.push(<tr className={`profit_loss_report_row_${item.level}`} key={`profit_loss_report_row_${i}`}>
        { ('total' in item) && (
          <>
            <td colSpan="3" className="total">
              JUMLAH {item.description}
            </td>
            <td className="nominal total">
              {item.total ? commaFormatted(item.total) : ''}
            </td>
          </>
        )}
        { !('total' in item) && (
          <>
            <td>
              {item.category}
            </td>
            <td>
              {item.code}
            </td>
            <td>
              {item.description}
            </td>
            <td className="nominal separator">
              {item.nominal ? commaFormatted(item.nominal) : ''}
            </td>
          </>
        )}
      </tr>);
    });
    return rows;
  }

  render() {
    const {
      perwakilan, user, unit,
    } = this.props;
    const { user_group } = user;
    const isPusat = [8, 9, 10, 13, 14, 15].includes(user_group.id);
    const isPerwakilan = [2, 6, 11, 12].includes(user_group.id);
    let role = 'unit';
    if (isPusat) {
      role = 'pusat';
    } else if (isPerwakilan) {
      role = 'perwakilan';
    }

    return (
      <div className="profit-loss-report">
        <ReportCard
          paging={false}
          header={(
            <div className="big-book-report__title">
              <h1>Laporan Posisi Keuangan</h1>
            </div>
          )}
          reportTypes={this.reportTypes}
          pos={this.pos}
          perwakilan={isPusat ? perwakilan.list : null}
          units={unit.list}
          role={role}
          onSearch={this._onSearch}
          onChangeReportType={this._getUnitOptions}
          onChangePerwakilan={this._getUnitOptions}
        >
          <table className="profit-loss-report__table">
            <thead>
              <tr>
                <th colSpan="2" className="code-group">Kode</th>
                <th rowSpan="2" className="description">Keterangan</th>
                <th rowSpan="2" className="nominal">Nominal</th>
              </tr>
              <tr>
                <th className="code">
                  Kel.
                </th>
                <th className="code">
                  Gol.
                </th>
              </tr>
            </thead>
            <tbody>
              {this._renderReport()}
            </tbody>
            <tfoot>
              <tr className="summary">
                <td colSpan="3">
                  Surplus/Defisit
                </td>
                <td className="nominal">
                  {this._countSummary()}
                </td>
              </tr>
            </tfoot>
          </table>
        </ReportCard>
      </div>
    );
  }
}
ProfitAndLostReport.propTypes = {
  handleGetPerwakilan: PropTypes.func,
  handleGetUnit: PropTypes.func,
  perwakilan: PropTypes.object,
  user: PropTypes.object,
  unit: PropTypes.object,
  handleGetProfitLossReport: PropTypes.func,
};
ProfitAndLostReport.defaultProps = {
  handleGetPerwakilan: noop,
  handleGetUnit: noop,
  perwakilan: {},
  user: {},
  unit: {},
  handleGetProfitLossReport: noop,
};
