import React,{Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class AbsensiEkskulForm extends Component {
  constructor(props){
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
   
      this.state ={
          form: {
              values: {
                  classes: '',
                  classes_year:'',
                  date:'',
                  periode: '',
              },
              error: {
                  classes: '',
                  classes_year:'',
                  date:'',
                  periode: '',
              }
          }
      }

  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }


  render(){
    const { onChange, form, classes } = this.props;
    return (
      <div className="data-pelanggaran__custom-form">
        <div className="data-pelanggaran__custom-form-column">
          <div className="data-pelanggaran__custom-form-row__field">
          <Input
              type="text"
              name="classes_year"
              label="Tahun Ajaran"
              value={form.value.classes_year}
              error={form.error.classes_year || ''}
              onChange={this._onFormChange}
              
            />
            
            <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="classes"
              label="Kelas"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              data={classes.list}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
           </div>
           
          
           <div className="data-pelanggaran__custom-form-row">
           <Select
              name="periode"
              label="Periode"
              onChange={this._onFormChange}
              data={ 'Semester 1', 'sms1'}
              value={form.value.periode}
              error={form.error.periode || ''}
            />
            
            
            <div className="data-pelanggaran__custom-form-row__field"></div>
            <Input
              type="date"
              name="date"
              label="Tanggal Pelanggaran"
              onChange={this._onFormChange}
              value={form.value.date || ''}
              error={form.error.date || ''}
            />
           </div>
          
             
        </div>
      </div>
    );

  }
  
}
AbsensiEkskulForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,

};
AbsensiEkskulForm.defaultProps = {
  onFormChange: noop,
  classes: [],
};
