import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import { Input, Button, Select } from "../../../../components/base";

export default class PengisianSikapForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*",
      },
    };
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  _onChangeMulti(value, name, inputArray, fieldName, arrayPosition) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name,
        value,
        dataset: {
          inputArray,
          fieldName,
          arrayPosition,
        },
      },
    });
  }

  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  render() {
    const { list, error, onChange, param, fieldName, form, onFormChange } =
      this.props;
    // const { value } = form;
    const Matpel = [
      { label: "Bahasa Indonesia", value: "bindo" },
      { label: "Matematika", value: "mtk" },
      { label: "Pendidikan Kewarganegaraan", value: "pkn" },
      { label: "Pendidikan Jasmani Olahraga Kesehatan", value: "penjaskes" },
    ];

    const Kelas = [
      { label: "Kelas 1", value: "kelas1" },
      { label: "kelas 2", value: "kelas2" },
      { label: "kelas 3", value: "kelas3" },
    ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th>KD</th>
            <th>Bobot Nilai</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(list, (data, idx) => (
            <tr key={`plafon_journal_data__${idx}`}>
              <td style={{textAlign:"center"}}>{idx + 1}</td>
              <td>
                {
                  <Input
                    type="text"
                    value={data.name}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name="indikator_sikap"
                    name="name"
                    onChange={onFormChange}
                  />
                }
              </td>
              {
                <td>
                  {
                    <Input
                      type="number"
                      value={data.bobot}
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name="indikator_sikap"
                      name="bobot"
                      onChange={onFormChange}
                    />
                  }
                </td>
              }
              <td>
                <Button onClick={() => this._onDeleteList(idx)}>Hapus</Button>
              </td>
            </tr>
          ))}
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={7}>
              <div>
                <Button icon="icon-plus" onClick={this._onAddList} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

PengisianSikapForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
};
PengisianSikapForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onFormChange: noop,
  onChange: noop,
};
