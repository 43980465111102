import { connect } from 'react-redux';
import PengisianEkskul from '../../../views/Student/ekskul/PengisianEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getStudentOptions, getParamOptionsAcademics, getPeriodsOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import { saveEkskulAbsen, editEkskulAbsen, getEkskulAbsen, listStudentEkskulAbsen } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetStudent: () => dispatch(getStudent()),
    handleSaveEkskulAbsen: (payload, goback) => dispatch(saveEkskulAbsen(payload, goback)),
    handleEditEkskulAbsen: (payload, goback) => dispatch(editEkskulAbsen(payload, goback)), 
    handleGetEkskulAbsen: payload => dispatch(getEkskulAbsen(payload)),
    handleListStudentEkskulAbsen: (payload, goback) => dispatch(listStudentEkskulAbsen(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PengisianEkskul);


