import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from '../../../../../components/base';
import { normalizeAmount } from '../../../../../utils/transformer.util';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';

export default class AdjustmentJournalCorrectionForm extends Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this.state = {
      code_of_account: {},
      description: '',
      debit: 0,
      credit: 0,
      reference_id: null,
    };
  }

  componentDidMount() {
    const { values } = this.props;
    if (values) {
      this.setState(values);
    }
  }

  _onChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: name === 'debit' || name === 'credit' ? normalizeAmount(value) : value,
    }));
  }

  render() {
    const {
      coa = [], onConfirm, onCancel, type,
    } = this.props;
    const {
      code_of_account, description, debit, credit,
    } = this.state;
    return (
      <div style={{ textAlign: 'left' }}>
        <div>
          <SelectCoa
            noMargin
            name="code_of_account"
            coa={coa}
            onClick={this._onChange}
            value={code_of_account}
            rightIcon="icon-search"
            label="Kode"
          />
        </div>
        <br />
        <div>
          <Input
            noMargin
            name="description"
            onChange={this._onChange}
            value={description || ''}
            label="Deskripsi"
          />
        </div>
        <br />
        { type === 'AMOUNT' && (
          <>
            <div>
              <Input
                noMargin
                data-input-type="number"
                name="debit"
                isNumber
                onChange={this._onChange}
                value={debit || ''}
                label="Debit"
                disabled={credit > 0}
              />
            </div>
            <br />
            <div>
              <Input
                noMargin
                data-input-type="number"
                name="credit"
                isNumber
                onChange={this._onChange}
                value={credit || ''}
                label="Kredit"
                disabled={debit > 0}
              />
            </div>
            <br />
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '50%' }}>
            <Button title="Tambah" type="button" onClick={onConfirm} />
          </div>
          <div style={{ width: '50%' }}>
            <Button title="Batal" type="button" onClick={onCancel} />
          </div>
        </div>
      </div>
    );
  }
}

AdjustmentJournalCorrectionForm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  coa: PropTypes.object.isRequired,
  values: PropTypes.object,
  type: PropTypes.object.isRequired,
};

AdjustmentJournalCorrectionForm.defaultProps = {
  values: {},
};
