import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Select,
  Input,
  Button,
  Pagination,
  InputDate,
  SearchSelect,
} from "../../../../components/base";
import {
  RIWAYAT_TABLE_FIELD_LIST,
  RIWAYAT_OFFLINE_TABLE_FIELD_LIST,
  RIWAYAT_H2H_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import language from "../../../../languages";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { validateRequiredFields } from '../../../../utils/validation.util';

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanPembayaranTotalGeneral extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onClickSet = this._onClickSet.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this._getPaymentsTypeOptions = this._getPaymentsTypeOptions.bind(this);
    this._onClickexport = this._onClickexport.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      isTable:true,
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      list: [],
      filters: {},
      form: {
        value: {
          classes: [],
          levels_id,
          periods: "",
          listhistory: [],
          classes_id: "",
          pg_code: "",
          listreport: [],
          payload: [],
          is_table: false,
        },
        error: {
          classes: "",
          levels: "",
          listhistory: "",
          listreport: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    const { user, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, students_id, payments_type } = state;
    const { school_unit } = user;
    // const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    // if (isEmpty(content.list)) {
    //   this._onSearchContent({ page: 1 });
    // }
    this._getSchoolUnitsOptions();
    this._getPaymentsTypeOptions();
    // if( user_group.name === 'pusat' || user_group.name === 'perwakilan'){
    //   this.getYearsOfEducationsOptions({units_id: savedForm.value.units_id.value})
    // }
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async _getPaymentsTypeOptions(filters = {}) {
    const { getPaymentsTypeOptions, user } = this.props;

    const res = await getPaymentsTypeOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        payments_type: res,
      },
    }));
  }

  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran selanjutnya
        if (res[idx+1]) {
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              year: res[idx+1].value.toString().substring(2),
            },
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                currents_year: res[idx+1].value
              }
            }
          }))
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "units_id") {
          const param = this.state.param.units;
          const levels_units = param.find((i) => i.value == value.value)
            ? param.find((i) => i.value == value.value).attributes.levels_id
            : "";

          this.setState((prevState) => ({
            ...prevState,
            total: "",
            totalAdmin: "",
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                units_id: value,
              },
            },
            filters: {
              ...prevState.filters,
              payments_type: "",
              date: "",
              date_to: "",
            },
          }));
          this.getYearsOfEducationsOptions({units_id: value.value})

        }
        if (name == 'payments_type'){
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
          }));
        }
        // this._onSearchContent()
      }
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  // fungsi show detail setelah simpan detail pembayaran
  async onShowDetail(data) {
    const { filters } = this.state;
    const { payments_type } = filters;

    if (payments_type === "va_bca") {
      this.openModalShowDetail(data); // buka modal detail
    }
  }

  openModalShowDetail(data) {
    MySwal.fire({
      width: "900px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  renderModalContentShowDetail(data) {
    const { user } = this.props;
    const { filters } = this.state;

    const componentRef = React.createRef();

    var subtotal = 0;
    let ket = "";
    if (!isEmpty(data.tr_temporary_details)) {
      if (data.tr_temporary_details[0].invoices_id.substring(0, 3) === "INV") {
        ket = "Rutin";
      } else {
        ket = "Non Rutin";
      }
    }
    return (
      <div>
        <br></br>
        <form class="form" style={{ padding: "20px" }} ref={componentRef}>
          <div style={{ textAlign: "center" }}>
            <h1>Bukti Transaksi Pembayaran {ket}</h1>
          </div>
          <br></br>
          <table
            className="cetak-buku-induk2__table-test"
            style={{ width: "unset" }}
          >
            <tr>
              <td style={{ textAlign: "left" }}>No Invoice</td>
              <td style={{ textAlign: "left" }}>&emsp;: {data.invoices_id}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Nama Siswa</td>
              <td style={{ textAlign: "left" }}>&emsp;: {data.name}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Status</td>
              <td style={{ textAlign: "left" }}>&emsp;: LUNAS</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Tanggal Pembayaran</td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.month_payments}
              </td>
            </tr>
            {filters.payments_type !== "va_bca" ? (
              <>
                <tr>
                  <td style={{ textAlign: "left" }}>Metode Pembayaran</td>
                  <td style={{ textAlign: "left" }}>
                    &emsp;: {data.bank_name}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Kode Pembayaran</td>
                  <td style={{ textAlign: "left" }}>&emsp;: {data.trx_id}</td>
                </tr>
              </>
            ) : (
              ""
            )}
          </table>
          <br></br>
          <div>
            <table className="modal-riwayat__table-test">
              {map(data.tr_temporary_details, (item, idx) => (
                <>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      {item.status_lable === "Cicilan 0"
                        ? "DP"
                        : item.status_lable}
                    </td>
                    <td style={{ textAlign: "left" }}></td>
                  </tr>

                  {map(item.tr_payment_details, (list, idx) => (
                    <>
                      {(() => {
                        subtotal = subtotal + Number(list.nominal);
                      })()}

                      <tr key={`budget_row_${idx}`} className="invoice">
                        <td style={{ textAlign: "left" }}>{list.name}</td>
                        <td style={{ textAlign: "right" }}>Rp. </td>
                        <td style={{ textAlign: "right" }}>
                          {commaFormatted(list.nominal)}
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
              <tr>
                <th style={{ textAlign: "right" }}>Sub Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(subtotal)}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "right" }}>Biaya Adm.</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.admin_fee)}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "right" }}>Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.total)}
                </th>
              </tr>
            </table>
          </div>
          <br></br>
        </form>
        <br></br>
        <div>
          <ReactToPrint
            trigger={() => (
              <div className="manage-registration__footer">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          />
          <br></br>
          <Button onClick={() => MySwal.close()}>Tutup</Button>
        </div>
        <br></br>
      </div>
    );
  }

  async _onSearchContent(params = {}, payload = {}) {
    const { filters, param, form } = this.state;
    const { students_id } = filters;
    const { payments_type } = param;
    const { currents_year } = form.value
    const units_id = filters.units_id ? filters.units_id.value : "";
    const payments_type_prm = payments_type
      ? payments_type.find((i) => i.value == filters.payments_type)
      : "";


    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleReportTotalPayments,
            user,
            handleReportTotalPaymentsGeneral,
            handleReportTotalPaymentsGeneralPPDB,
            handleReportPaymentsGeneral,
            handleReportPaymentsGeneralPPDB,
            handleReportTotalAdminGeneral,
            handleReportTotalAdminGeneralPPDB
          } = this.props;
         
          const { organizations_id, school_unit, user_group } = user;
          const { levels_id } = school_unit;
          const ppdb_name_pst = filters.units_id ? filters.units_id.attributes.ppdb_name : '';
          const ppdb_name = user_group.name == 'pusat' || user_group.name == 'perwakilan' ? ppdb_name_pst : school_unit.ppdb_name;

          console.log(units_id)
          console.log(filters)
          console.log(currents_year)
         

          let res = "";
          let resAdmin = "";
          let result = "";
          if (filters.list_siswa == 'all') {
            res = await handleReportTotalPaymentsGeneral({
              ...payload,
              ...filters,
              payments_type: payments_type_prm ? payments_type_prm.label : "",
              units_id: user_group.name == "pusat" ? units_id : school_unit.id,
            });
            resAdmin = await handleReportTotalAdminGeneral({
              ...payload,
              ...filters,
              payments_type: payments_type_prm ? payments_type_prm.label : "",
              units_id: user_group.name == "pusat" ? units_id : school_unit.id,
            });

            result = await handleReportPaymentsGeneral({
              ...payload,
              ...filters,
              units_id: user_group.name == "pusat" ? units_id : school_unit.id,
            });
          } else {
            res = await handleReportTotalPaymentsGeneralPPDB({
              ...payload,
              ...filters,
              year: filters.year,
              payments_type: payments_type_prm ? payments_type_prm.label : "",
              units_id: user_group.name == "pusat" ? units_id : school_unit.id,
              type: ppdb_name,
            });

            resAdmin = await handleReportTotalAdminGeneralPPDB({
              ...payload,
              ...filters,
              payments_type: payments_type_prm ? payments_type_prm.label : "",
              units_id: user_group.name == "pusat" ? units_id : school_unit.id,
              type: ppdb_name,
            });

            result = await handleReportPaymentsGeneralPPDB({
              ...payload,
              ...filters,
              year: filters.year,
              type: ppdb_name,
              units_id: user_group.name == "pusat" ? units_id : school_unit.id,
            });
          }
          this.setState((prevState) => ({
            ...prevState,
            total: res,
            totalAdmin: resAdmin,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: result,
              },
            },
          }));
          //   }
        } catch (err) {
          console.log(err)
          this.setState((prevState) => ({
            ...prevState,
            total: "",
            totalAdmin: "",
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: [],
              },
            },
          }));
        }
      }
    );
  }

  _onClickSet() {
    const { form, filters } = this.state;
    const { value } = form;
    let error = {}

    error = validateRequiredFields(filters, ['date', 'date_to'])

    console.log(error)
   

    if(error){
      this.setState((prevState) => ({
        // form: {
        //   value: prevState.form.value,
        //   error,
        // },
        filters: prevState.filters,
        error,
      }));
      form.error.date = "Harus Diisi"
      form.error.date_to = "Harus Diisi"
    }
    if(isEmpty(error)) {
      this.setState(
        (prevState) => ({
          ...prevState,
          isTable: false,
          filters: {
            ...prevState.filters,
          },
        }),
        () => {
          this._onSearchContent();
        }
      );
    }
  
  }

  _onClickexport(listreport, idx) {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
      data_idx: idx,
      data1: listreport,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  render() {
    const { param, form, content, filters, listH2h, total, is_table, page, totalAdmin, isTable } =
      this.state;
    const { date = "", date_to = "", payments_type, students_id, list_siswa } = filters;
    const { listreport } = form.value;
    const { user } = this.props;
    const { organization, school_unit, user_group, organizations_id } = user;

    const paymentstype = [
      { label: "Payment Gateway", value: "Faspay" },
      { label: "Offline", value: "Offline" },
      { label: "H2H", value: "H2H" },
    ];

    const listSiswa = [
      { label: "Semua Siswa", value: "all" },
      { label: "Siswa PPDB", value: "ppdb" },
    ];
    const prm_payments = param.payments_type;
    const filters_payments = prm_payments
      ? prm_payments.find((i) => i.value == filters.payments_type)
      : "";
    const payments_type_prm = filters_payments ? filters_payments.label : "";
    var count = 0;
    let totalNominal = 0;
    if (date > date_to) {
      form.error.date = "Format Tanggal Salah";
      form.error.date_to = "Format Tanggal Salah";
    } 
    if (date < date_to || date === date_to && (date!=='' && date_to!=='')) {
      form.error.date = "";
      form.error.date_to = "";
    }

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Laporan Pembayaran</h1>
          <hr></hr>
        </div>

        {page === 1 && (
          <div className="absensi-rekap__custom-form">
              <div className="absensi-rekap__custom-form-row">
                {user_group.name === "pusat" ? (
                <div className="absensi-rekap__custom-form-row__field">
                  <SearchSelect
                    noMargin
                    async={false}
                    name="units_id"
                    list={param.units}
                    inputArray
                    onClick={this._onChangeFilter}
                    placeholder="Pilih Unit"
                    value={form.value.units_id}
                    rightIcon="icon-search"
                    label="Pilih Unit"
                  />
                </div>
                 ) : (
                  ""
                )}
                 {(organizations_id !== 3) && (
                <div className="absensi-rekap__custom-form-column__field">
                    <Select
                      name="list_siswa"
                      label="Pilih List Siswa"
                      onChange={this._onChangeFilter}
                      data={listSiswa}
                      placeholder={"Pilihan"}
                      value={list_siswa}
                    />
                </div>
                )}
              </div>
             
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Select
                  type="text"
                  name="payments_type"
                  label="Tipe Pembayaran"
                  placeholder="Pilihan"
                  data={
                    organizations_id == 3 ? paymentstype : param.payments_type
                  }
                  onChange={this._onChangeFilter}
                  value={payments_type}
                />
              </div>
              <div className="manage-registration__custom-form-row__field">
                <InputDate
                  type="date"
                  name="date"
                  label="Dari Tanggal"
                  onChange={this._onChangeFilter}
                  value={date}
                  error={form.error.date || ""}
                />
                <div className="manage-registration__custom-form-row-datadiri">
                  <InputDate
                    type="date"
                    name="date_to"
                    label="Sampai Tanggal"
                    onChange={this._onChangeFilter}
                    value={date_to}
                    error={form.error.date_to || ""}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {page === 1 && (
          <div>
            <Button title="Proses" onClick={this._onClickSet} />

            <br></br>
            <Button title="Export Excel" onClick={this._onClickexport} />
          </div>
        )}
        {page === 2 && (
          <div className="beginning-balance__button-wrapper">
            <Button onClick={this._onClickBack} title="Kembali" />
            &nbsp;
            <div
              style={{ marginLeft: "0rem" }}
              className="student-list__button"
            >
              <ReactHTMLTableToExcel
                id="export-to-xls-button"
                className="button"
                table="printable"
                filename={`Laporan Pembayaran`}
                // sheet={currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}
                buttonText="Unduh ke Excel"
              />
            </div>
          </div>
        )}
         {(!isTable) && (
          <>
        <h1>
          Total Pembayaran{" "}
          {payments_type == "Faspay" ? "Payment Gateway" : payments_type_prm} :{" "}
          {payments_type ? "Rp. " + commaFormatted(total) : 0}
        </h1>
        <h1>Total Biaya Admin : {"Rp. " +commaFormatted(totalAdmin)}</h1>
          </>
         )}
        <br></br>
        {(!isTable) && (
        <table className="manage-rapbs-plafon__form-table table-invoice">
          <thead>
            <tr>
              <th>
                {payments_type == "Faspay"
                  ? "Payment Gateway"
                  : payments_type_prm}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2" style={{ border: "none" }}>
                {page === 1 && (
                    <table className="table">
                      <thead>
                        <tr className="grey">
                          <th>No</th>
                          <th>Nama</th>
                          <th>No VA</th>
                          <th>Invoice ID</th>
                          <th>Nominal</th>
                          <th>Tanggal Bayar</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(listreport, (data, idx) => (
                          <tr key={`budget_row_${idx}`}>
                            <td
                              key={`table_index_${idx}`}
                              style={{ textAlign: "center" }}
                            >
                              {idx + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {capitalize(data.name)}
                            </td>
                            <td>{data.temps_id}</td>
                            <td>{data.invoices_id}</td>
                            <td>Rp. {commaFormatted(data.total)}</td>
                            <td>{data.month_payments}</td>
                            <td>
                              <Button
                                onClick={() => this.onShowDetail(data)}
                                title="Lihat"
                              />
                            </td>
                            {(() => {
                              totalNominal += Number(data.total);
                            })()}
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>Total</td>
                          <td colSpan={3}>Rp. {commaFormatted(totalNominal)}</td>
                        </tr>
                      </tbody>
                    </table>
                )}
                {page === 2 && (
                    <table className="table" id="printable">
                      <thead>
                        <tr>
                          <th className="grey">No.</th>
                          <th className="grey">Nama</th>
                          <th className="grey">No VA</th>
                          <th className="grey">Invoice ID</th>
                          <th className="grey">Nominal</th>
                          <th className="grey">Tanggal Bayar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(listreport, (data, idx) => (
                          <tr key={`budget_row_${idx}`}>
                            <td
                              key={`table_index_${idx}`}
                              style={{ textAlign: "center" }}
                            >
                              {idx + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {capitalize(data.name)}
                            </td>
                            <td>{data.temps_id}</td>
                            <td>{data.invoices_id}</td>
                            <td>Rp. {commaFormatted(data.total)}</td>
                            <td>{data.month_payments}</td>
                            {(() => {
                              totalNominal += Number(data.total);
                            })()}
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>Total</td>
                          <td>Rp. {commaFormatted(totalNominal)}</td>
                        </tr>
                      </tbody>
                    </table>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        )}
      </div>
    );
  }
}
LaporanPembayaranTotalGeneral.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanPembayaranTotalGeneral.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
