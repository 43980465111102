import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Pagination } from '../../../components/base';
import { FileInput, Input, Select, Textarea, Button } from '../../../components/base/index';
import BaseTable from '../../../components/BaseTable/BaseTable.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { formatData } from '../../../utils/transformer.util';
import language from '../../../languages';
import swal from 'sweetalert';

export default class Elearningmurid extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
        <div className="fund-request">
          <div className="fund-request__content">
          <Select
            // value={data.teacher_id}
            placeholder="Mata Pelajaran"
            // data={Pengajar}
            // data-input-array
            // arrayPosition={idx}
            // data-array-position={idx}
            data-field-name='pelajaran'
            name='id_matpel'
          />
          <div className="finance">
            <div className="finance__list">
              <button>
                <img src={""} alt="No Image" />
                <h3>Soal 1</h3>
              </button>
              <Input
                  type="text"
                  name="status"
                  label="Status"
                  placeholder="Done"
                  // onChange={onFormChange}
                  // value={form.value.tgl_selesai}
                  // error={form.error.tgl_selesai || ''}
              />
            </div>            
            <div className="finance__list">
              <button>
                <img src={""} alt="No Image" />
                <h3>Soal 2</h3>
              </button>
              <Input
                  type="text"
                  name="status"
                  label="Status"
                  placeholder="Deadline: HH:MM:SS"
                  // onChange={onFormChange}
                  // value={form.value.tgl_selesai}
                  // error={form.error.tgl_selesai || ''}
              />
            </div>    
        </div>
          </div>
          <div className="user-management__table-footer">
            {/* <p className="user-management__page-info">
              {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
            </p> */}
            <Pagination
              totalPage={3}
              currentPage={1}
              // onClick={"#"}
            />
          </div>
        </div>
      );
    }
  }