import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class CatatPelanggaranForm extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                no_absen: '',
                tanggal: '',
                nis: '',
            },
            error: {
                classes: '',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes } = this.props;
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Select
              name="classes_year"
              label="TahunAjaran"
              onChange={this._onFormChange}
              data={ '7A', 'kelas7a'}
              placeholder={'Pilihan'}
              value={form.value.classes_year}
              error={form.error.classes_year || ''}
            />
           
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="classes"
              label="Kelas"
              onChange={this._onFormChange}
              data={ classes.list}
              placeholder={'Pilihan'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
            <Input
              type="text"
              name="nomorabsensi"
              label="Nomor Absen"
              onChange={this._onFormChange}
              value={form.value.nomorabsensi || ''}
              error={form.error.nomorabsensi || ''}
            />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
            
           <Input
              type='date'
              name="tanggal"
              label="Tanggal"
              onChange={this._onFormChange}
              value={form.value.tanggal || ''}
              error={form.error.tanggal || ''}
            />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Input
          type="text"
          name="nis"
          label="NIS"
          onChange={this._onFormChange}
          data={GENDER}
          placeholder="2019"
          value={form.value.nis || ''}
          error={form.error.nis || ''}
          />
          </div>
      </div>
  </div>
          
          
          
   
     
    );
}
  
}
CatatPelanggaranForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
CatatPelanggaranForm.defaultProps = {
  onChange: noop,
  classes: [],
};
