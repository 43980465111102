import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class LaporanIndividual extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickLulusTidakLanjut = this.onClickLulusTidakLanjut.bind(this);
    this.onClickPutusSekolah = this.onClickPutusSekolah.bind(this);
    this.onClickPendidikanGuru = this.onClickPendidikanGuru.bind(this);
  }

  onClickLulusTidakLanjut() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/laporan-individual-sekolah/lulusan-tidak-melanjutkan');
  }

  onClickPutusSekolah() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/laporan-individual-sekolah/anak-putus-sekolah');
  }
  onClickPendidikanGuru() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/laporan-individual-sekolah/pendidikan-guru');
  }

 

  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickIdentitas}>
            <img src={budgeting} alt="identitas" />
            <h3>Identitas Unit Sekolah</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPutusSekolah}>
            <img src={budgeting} alt="jurusan" />
            <h3>Anak Putus Sekolah</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLulusTidakLanjut}>
            <img src={budgeting} alt="jurusan" />
            <h3>Lulusan Tidak Melanjutkan</h3>
          </button>
        </div> */}
        <div className="akademi__list">
          <button onClick={this.onClickPendidikanGuru}>
            <img src={budgeting} alt="jurusan" />
            <h3>Pendidikan Guru</h3>
          </button>
        </div>
       
      </div>
    );
  }
}
LaporanIndividual.propTypes = {
  history: PropTypes.object.isRequired,
};
