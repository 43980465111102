import { connect } from 'react-redux';
import StudentList from '../../views/Student/StudentList.student.view';
import { getStudent } from '../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(StudentList);
