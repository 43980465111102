import { connect } from 'react-redux';
import Tunggakan from '../../../../views/Finance/Spp/Tunggakan/Tunggakan.view';
import { listArrearsStudents, listArrearsClasses, listArrearsAccumulations, listArrearsStudentsGeneral, listArrearsClassesGeneral, 
  listArrearsAccumulationsGeneral, listArrearsClassesNonRutin, listArrearsAccumulationsNon, listArrearsAccumulationsUnitRutin, listArrearsAccumulationsUnitNonRutin, listArrearsAccumulationsPerwakilanRutin, listArrearsAccumulationsPerwakilanNonRutin } from '../../../../states/thunks/academics.thunk';
import { getParamOptionsAcademics, getYearsOfEducationsOptions, getStudentOptions, getPeriodsOptions, getMsTempsSiswaOptions, getStudentSPPOptions, getSchoolUnitsOptions, getPrmPaymentsNon, getParamPerwakilan } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleListArrearsStudents: (payload, goback) => dispatch(listArrearsStudents(payload, goback)),
    handleListArrearsClasses: (payload, goback) => dispatch(listArrearsClasses(payload, goback)),
    handleListArrearsClassesNon: (payload, goback) => dispatch(listArrearsClassesNonRutin(payload, goback)),
    handleListArrearsAccumulations: (payload, goback) => dispatch(listArrearsAccumulations(payload, goback)),
    handleListArrearsAccumulationsNon: (payload, goback) => dispatch(listArrearsAccumulationsNon(payload, goback)),
    handleListArrearsAccumulationsUnitRutin: (payload, goback) => dispatch(listArrearsAccumulationsUnitRutin(payload, goback)),
    handleListArrearsAccumulationsUnitNonRutin: (payload, goback) => dispatch(listArrearsAccumulationsUnitNonRutin(payload, goback)),
    handleListArrearsAccumulationsPerwakilanRutin: (payload, goback) => dispatch(listArrearsAccumulationsPerwakilanRutin(payload, goback)),
    handleListArrearsAccumulationsPerwakilanNonRutin: (payload, goback) => dispatch(listArrearsAccumulationsPerwakilanNonRutin(payload, goback)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    handleListArrearsStudentsGeneral: (payload, goback) => dispatch(listArrearsStudentsGeneral(payload, goback)),
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    handleListArrearsClassesGeneral: (payload, goback) => dispatch(listArrearsClassesGeneral(payload, goback)),
    handleListArrearsAccumulationsGeneral: (payload, goback) => dispatch(listArrearsAccumulationsGeneral(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    getParamPerwakilan: (payload) => dispatch(getParamPerwakilan(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tunggakan);
