import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../components/base';
import BaseTable from '../../../components/BaseTable/BaseTable.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { formatData } from '../../../utils/transformer.util';
import language from '../../../languages';
import swal from 'sweetalert';
import { JADWAL_KELAS_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JadwalKelas extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    const { content } = this.state;
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }

  async handleGetData(payload = {}) {
    const { handleListSchedule, handleListScheduleStudents, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
      const res = await handleListSchedule({...payload, user_id: id, teacher_id: id, organizations_id: organizations_id});
      this.setState({
        list: res,
      });
    // }
    
    if(user_group.name === 'student'){
      const res = await handleListScheduleStudents({...payload, user_id: id, students_id: profile.id, organizations_id: organizations_id});
      this.setState({
        list: res,
      });
    }

    
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/jadwal/tambah-jadwal-per-kelas', { isEdit: true });
  }
  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/jadwal/tambah-jadwal-per-kelas', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteSchedule } = this.props;
    const res = await handleDeleteSchedule(id);
    if (res) {
      this.handleGetData();
    }
  }
  async onClickDownload(id) {
    const { handleDownloadSchedule } = this.props;
    const res = await handleDownloadSchedule(id.id, id.upload)
  }
  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    return (
      <td>
        <div className="table__actions">
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}

          {/* {user_group.id === 2 ? */}
           <Button
           onClick={() => this.onClickEdit(data)}
           title='Edit'
         />
         {/* :''
          } */}
         {/* {
           user_group.id === 2 ? */}
           <Button
           onClick={() => this.onClickDelete(data)}
           title='Hapus'
         />
         {/* :''
         } */}
         
        
        </div>
      </td>
    );
  }

  render() {
    const { list = {}} = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const dataset = {
      toggle: "modal"
    };
    return (
      <div className="fund-request">
        {/* {
          user_group.id === 2? */}
          <Button
          title="Tambah Jadwal Kelas & Karyawan"
          onClick={this.onClickAdd}
          data={dataset}
        />
        {/* :''
        } */}
         
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(JADWAL_KELAS_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                        
                      map(JADWAL_KELAS_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                       

                        // if (field.attribute === 'classes'){
                        //   for( let i = 0 ; i < data.schedule_dtl_class.length; i++){
                        //     console.log(data.schedule_dtl_class[idx])
                        //     return(
                        //       <td key={`table_${fieldIdx}_${idx}`}>{data.schedule_dtl_class[i].name}</td>
                        //     );
                        //   }
                          
                        // }
                        // {console.log(list.result[0].schedule_dtl_class[0].name)}
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
JadwalKelas.propTypes = {
  handleGetFunhandleListScheduledRequest: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
JadwalKelas.defaultProps = {
  handleListSchedule: noop,
  handleListScheduleStudents: noop,
  handleDelete: noop,
  user: null,
};
