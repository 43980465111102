import { connect } from 'react-redux';
import BuatKelasEkskul from '../../../views/Student/ekskul/BuatKelasEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {getEkskulOptions, getTeacherEkskulOptions, getClasses, getParamOptions, getParamOptionsAcademics, getPeriodsOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listClassStudentEkskul, saveClassEkskul, editClassEkskul, getClassEkskul} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
  return{
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleListClassStudentEkskul: (payload, goback) => dispatch(listClassStudentEkskul(payload, goback)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveClassEkskul: (payload, goback) => dispatch(saveClassEkskul(payload, goback)),
    handleEditClassEkskul: (payload, goback) => dispatch(editClassEkskul(payload, goback)), 
    handleGetClassEkskul: payload => dispatch(getClassEkskul(payload)),
    getTeacherEkskulOptions: (payload) => dispatch(getTeacherEkskulOptions(payload)),
    getEkskulOptions: (payload) => dispatch(getEkskulOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatKelasEkskul);


