import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Button } from "../../../components/base";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { PERSONAL_PAYROLLLIST_TABLE_FIELDS } from "../../../constants/personnel.constant";
import ContentTableStaff from "../../../components/ContentTable/ContentTablePayroll.component";
import { STAFF_TABLE_FIELDS } from "../../../constants/personnel.constant";

export default class Assignment extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickView = this.onClickView.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/personalia/payroll/tambah-payroll");
  }

  onClickView(val) {
    const { history } = this.props;
    history.push(`/dashboard/personalia/data-payroll/list-payroll/${val.nip}`, {
      data: val,
    });
  }

  onClickPrint = (val) => {
    const { history } = this.props;
    history.push(
      `/dashboard/personalia/laporan/slip-gaji/${val.nip}`,
      { data: { nip: val, user: null } }
    );
  };

  render() {
    const { handleGetDataPegawai, handleGetStaff, user } = this.props;
    let unit_kerja = 0;
    if (user.workingUnit) {
      if (user.workingUnit.id != 0) {
        unit_kerja = user.workingUnit.attributes.unit_code;
      }
    }
    return (
      <div className="payrolllist">
        <div className="result__content">
          <ContentTableStaff
            unit_kerja={unit_kerja}
            onClickView={this.onClickView}
            onClickPrint={this.onClickPrint}
            onSearchContent={handleGetStaff}
            tableFields={STAFF_TABLE_FIELDS}
            // handleGetDataPegawai = {handleGetDataPegawai}
          />
        </div>
      </div>
    );
  }
}
Assignment.propTypes = {
  handleGetStaff: PropTypes.func,
  handleGetAssignment: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Assignment.defaultProps = {
  handleGetStaff: noop,
  handleGetAssignment: noop,
};
