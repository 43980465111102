import { connect } from 'react-redux';
import LaporanKonseling from '../../../views/Student/report/LaporanKonseling.student.view';
import { manageRegistration } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptionsAcademics, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listCounselingReport } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListCounselingReport: (payload, goback) => dispatch(listCounselingReport(payload, goback)),
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LaporanKonseling);
