import { connect } from 'react-redux';
import LaporanPiutang from '../../../../views/Finance/Spp/LaporanSPP/LaporanPiutang.view';
import { listOutstandingPayments, listOutstandingPaymentsUKG, listOutstandingPaymentsDPP, listOutstandingPaymentsTGK } from '../../../../states/thunks/academics.thunk';
import { getPrmLevels, getParamOptionsAcademics, getYearsOfEducationsOptions, getPrmPaymentsNon, getPeriodsOptions, getSchoolUnitsOptions, getPrmPaymentsNickGeneral, getParamPerwakilan } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleListOutstandingPayments: (payload, goback) => dispatch(listOutstandingPayments(payload, goback)),
    handleListOutstandingPaymentssUKG: (payload, goback) => dispatch(listOutstandingPaymentsUKG(payload, goback)),
    handleListOutstandingPaymentssDPP: (payload, goback) => dispatch(listOutstandingPaymentsDPP(payload, goback)),
    handleListOutstandingPaymentssTGK: (payload, goback) => dispatch(listOutstandingPaymentsTGK(payload, goback)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmPaymentsNickGeneral: (payload) => dispatch(getPrmPaymentsNickGeneral(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getParamPerwakilan: (payload) => dispatch(getParamPerwakilan(payload)),
 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPiutang);
