import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import AdjustmentJournalForm from './components/AdjustmentJournalForm.component';
import { FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS } from '../../../../constants/finance/finance.constant';
import Button from '../../../../components/base/Button/Button.component';
import { dateFormat, normalizeAmount } from '../../../../utils/transformer.util';


export default class ManageAdjustmentJournal extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);

    this._getSourceJournalOptions = this._getSourceJournalOptions.bind(this);
    this._getSourceJournal = this._getSourceJournal.bind(this);
    this._getReversal = this._getReversal.bind(this);
    this._getCorrection = this._getCorrection.bind(this);
    this._setCodeCorrection = this._setCodeCorrection.bind(this);
    this._searchSourceJournal = this._searchSourceJournal.bind(this);
    this._onSaveCorrection = this._onSaveCorrection.bind(this);
    this._calculateCorrectionAmount = this._calculateCorrectionAmount.bind(this);
    this._setCoa = this._setCoa.bind(this);
    this._calculateTotal = this._calculateTotal.bind(this);
    this._validateSubmit = this._validateSubmit.bind(this);

    this.state = {
      totals: {
        correction: {
          debit: 0,
          credit: 0,
        },
        selected: {
          debit: 0,
          credit: 0,
        },
      },
      form: {
        value: {
          date: dateFormat(),
          source: [],
          reversals: [],
          journal_number: null,
          corrections: [],
          correction_amount: 0,
          total: 0,
          amount_difference: 0,
          type: '',
          selected_ids: [],
        },
        error: {
          corrections: [],
        },
      },
      original: {},
      coa: {},
    };
    this.confirmCorrection = false;
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, journal_number } = state;
    if (isEdit) {
      this._setForm(journal_number);
    }
    this._getSourceJournalOptions();
    this._setCoa();
  }

  componentDidUpdate({ user: oldUser }) {
    const { user } = this.props;
    if (JSON.stringify(user.workingUnit) !== JSON.stringify(oldUser.workingUnit)) {
      this._getSourceJournalOptions();
    }
  }

  async _setCoa() {
    const { handleGetCoa } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { source = {}, type } = value;
    const journalType = source.journal_type ? source.journal_type : null;
    let res = {};

    if (type === null || type === 'CODE') {
      res = await handleGetCoa();
      this.setState({ coa: res });
    } else if (journalType === 'BANK') {
      res = await handleGetCoa({
        account: source.cash_bank_journal.bankAccount,
      });
      this.setState({ coa: res });
    } else {
      res = await handleGetCoa({
        account: '11101',
      });
      this.setState({ coa: res });
    }
  }

  async _setForm(journal_number) {
    const { handleGetAdjustmentJournal } = this.props;
    try {
      const payload = await handleGetAdjustmentJournal({ journal_number });
      const { source, corrections: payloadCorrections } = payload;
      const selected_ids = payload.selected_ids || [];
      let corrections = [];

      if (payloadCorrections.length > 0) {
        corrections = payloadCorrections;
      } else {
        corrections = source.filter(item => selected_ids.includes(item.id));
      }

      selected_ids.forEach((o, i) => {
        selected_ids[i] = o.toString();
      });

      corrections.forEach((o, i) => {
        corrections[i].code_of_account = o.parameter_code;
      });

      this.setState({
        form: {
          value: {
            id: payload.id,
            journal_number: payload.journal_number,
            date: payload.date,
            corrections,
            source: payload.source || [],
            transaction_id: payload.transaction_id,
            selected_ids,
            type: payload.type,
          },
          error: {
            corrections: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getSourceJournalOptions(param = {}) {
    const { keyword = '' } = param;
    const { handleGetSourceJournalOptions } = this.props;
    const res = await handleGetSourceJournalOptions({
      keyword,
    });
    this.setState({ sourceJournals: res }, () => res);
    return res;
  }

  _searchSourceJournal({ keyword }) {
    const { sourceJournals } = this.state;
    return sourceJournals.filter(item => item.title.includes(keyword));
  }

  async _getReversal() {
    const { handleGetReversal } = this.props;
    const { form } = this.state;
    const { value } = form;
    const {
      selected_ids = [],
      transaction_id = {},
    } = value;
    const { id } = transaction_id;
    let reversals = [];

    reversals = await handleGetReversal({
      transaction_id: id,
      selected_ids,
    });

    value.reversals = reversals;
    this.setState({ form }, () => { this._calculateTotal(); });
  }

  async _getCorrection(data) {
    const { handleGetCorrection } = this.props;
    const { form } = this.state;
    const {
      code_of_account,
      debit,
      credit,
      description,
      timestamp,
      reference_id,
    } = data;
    const { value } = form;
    const {
      type,
      transaction_id = {},
    } = value;
    let { corrections = [] } = value;
    corrections = corrections.filter(o => o.timestamp !== timestamp) || [];

    const res = await handleGetCorrection({
      transaction_id: transaction_id.id,
      data: {
        code_of_account: code_of_account.code,
        debit,
        credit,
        description,
        reference_id,
      },
    });

    if (type === 'CODE') {
      value.selected_ids.push(reference_id);
    }

    value.corrections = corrections.concat(res);
    this.setState({ form }, () => {
      if (type === 'AMOUNT') {
        this._calculateTotal();
      } else {
        this._getReversal();
      }
    });
  }

  _setCodeCorrection(value, checked) {
    const { form } = this.state;
    const { type, source } = form.value;
    const { corrections } = form.value;
    let referenceId = null;

    if (type === 'CODE') {
      referenceId = parseInt(value, 0);
      if (!checked) {
        form.value.corrections = corrections.filter(o => o.reference_id !== referenceId);
      } else if (!corrections.find(o => o.reference_id === referenceId)) {
        const { credit, debit } = source.find(o => o.id === referenceId);
        form.value.corrections.push({
          credit,
          debit,
          description: '',
          reference_id: referenceId,
          code_of_account: '',
        });
      }
      this.setState({ form }, () => { this._calculateTotal(); });
    }
  }

  async _getSourceJournal(transaction_id) {
    const { handleGetSourceJournal } = this.props;
    const res = await handleGetSourceJournal({ transaction_id });
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          source: res || [],
          corrections: [],
          selected_ids: [],
          reversals: [],
          total: 0,
          amount_difference: 0,
        },
      },
    }), () => {
      this._setCoa();
    });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName, source: sourceId = null,
    } = dataset;
    this.setState((prevState) => {
      const { form } = prevState;
      const { value: formValue } = form;
      const newList = formValue[fieldName] || [];
      const newListError = [];

      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (name === 'selected_ids') {
        formattedValue = formValue[name] || [];
        if (checked) {
          formattedValue.push(value);
        } else {
          formattedValue = formattedValue.filter(o => o !== value);
          if (formValue.type === 'CODE') {
            formValue.corrections = formValue.corrections.filter(o => o.reference_id !== value);
          }
        }
      } else if (inputArray) {
        if (!newList[arrayPosition]) {
          newList[arrayPosition] = {};
          if (fieldName === 'corrections') {
            if (name === 'code_of_account') {
              if (value) {
                newList[arrayPosition].code_of_account = value.code;
              } else if (newList[arrayPosition].code_of_account) {
                newList[arrayPosition].code_of_account = value;
              }
            } else {
              newList[arrayPosition].journal_id = sourceId;
            }
          }
        } else if (fieldName === 'corrections' && name === 'code_of_account') {
          if (value) {
            newList[arrayPosition].code_of_account = value.code;
          } else if (newList[arrayPosition].code_of_account) {
            newList[arrayPosition].code_of_account = value;
          }
        } else {
          newList[arrayPosition][name] = formattedValue;
        }
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'transaction_id') {
        this._getSourceJournal(value.id);
      }
      if (name === 'selected_ids') {
        this._getReversal();
        this._setCodeCorrection(value, checked);
      }
      if (name === 'type') {
        this._setCoa();
      }
    });
  }

  _calculateTotal() {
    const { form, totals } = this.state;
    const { corrections = [], reversals = [] } = form.value;

    totals.correction = {
      debit: corrections.reduce((a, b) => b.debit + a, 0),
      credit: corrections.reduce((a, b) => b.credit + a, 0),
    };
    totals.selected = {
      debit: reversals.reduce((a, b) => b.debit + a, 0),
      credit: reversals.reduce((a, b) => b.credit + a, 0),
    };

    this.setState({
      form,
      totals,
    });
  }

  _resetCorrections() {
    const { form } = this.state;
    form.value.corrections = [];
    this.setState({ form });
  }

  _calculateCorrectionAmount() {
    const { form } = this.state;
    const { value } = form;
    const { selected_ids, source } = value;
    let total = 0;

    source.forEach((detail) => {
      if (selected_ids.includes(detail.id.toString())) {
        total += detail.amount;
      }
    });

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          correction_amount: total,
        },
      },
    }));
  }

  _onAddList(fieldName) {
    this.setState(prevState => (
      {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [
              ...prevState.form.value[fieldName],
              {},
            ],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [
              ...prevState.form.error[fieldName],
              {},
            ],
          },
        },
      }));
  }

  _onDeleteList(timestamp) {
    const { form } = this.state;
    const { value } = form;

    value.corrections = value.corrections.filter(o => o.timestamp !== timestamp);
    this.setState({ form }, () => { this._calculateTotal(); });
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  _validateSubmit() {
    const { form, totals } = this.state;
    const { corrections, id, type } = form.value;
    const { selected, correction } = totals;
    const isEqual = selected.credit === correction.credit && selected.debit === correction.debit;

    if (type === 'AMOUNT') {
      if (!id && corrections.length > 0) {
        return true;
      }
    } else if (type === 'CODE') {
      if (corrections.length > 0) {
        return true;
      }
    }

    return false;
  }

  async _onSaveCorrection() {
    const { form } = this.state;
    const { handleSaveCorrection, history } = this.props;
    const res = await handleSaveCorrection({ ...form.value });
    if (res) {
      history.push('/dashboard/keuangan/pembukuan/view-jurnal-penyesuaian', { journal_number: res, isEdit: true });
    }
  }

  render() {
    const {
      form,
      sourceJournals,
      coa = [],
      totals = {},
    } = this.state;
    const {
      type,
      id,
    } = form.value;
    const { error } = form;
    const fields = FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS.map(field => Object.assign({}, field));

    fields.forEach((field, index) => {
      if (id && id !== null) {
        if (field.name === 'transaction_id'
          || field.name === 'date' || field.name === 'type') {
          fields[index].disabled = true;
        }
      } else if (field.name === 'transaction_id') {
        fields[index].handleSearchContent = this._getSourceJournalOptions;
      }
    });
    return (
      <div className="manage-adjustment-journal">
        <form>
          <ListForm
            form={form}
            coa={coa}
            sourceJournals={sourceJournals}
            formFields={fields}
            onFormChange={this._onFormChange}
            error={error}
          >
            <AdjustmentJournalForm
              coa={coa}
              onDeleteList={this._onDeleteList}
              onChange={this._onFormChange}
              getCorrection={this._getCorrection}
              form={form}
              totals={totals}
              type={type}
            />
          </ListForm>
          <div style={{ fontSize: '1.8em' }}>
            <h3>
              <i className="fontello-icon-attention-alt">
                <b>Perhatian</b>
              </i>
            </h3>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Nilai Koreksi harus sama dengan nilai jurnal Pembalik</li>
              <li>Jurnal koreksi yang disimpan akan langsung tampil pada Buku Besar</li>
              <li>Jurnal koreksi yang telah tersimpan tidak dapat di edit kembali</li>
            </ul>
          </div>
          { this._validateSubmit() && (
            <div className="manage-adjustment-journal">
              <Button
                type="button"
                title="Koreksi"
                onClick={() => this._onSaveCorrection()}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
}
ManageAdjustmentJournal.propTypes = {
  handleGetAdjustmentJournal: PropTypes.func,
  handleGetCoa: PropTypes.func.isRequired,
  handleGetSourceJournalOptions: PropTypes.func.isRequired,
  handleGetSourceJournal: PropTypes.func.isRequired,
  handleSaveCorrection: PropTypes.func.isRequired,
  handleGetReversal: PropTypes.func.isRequired,
  handleGetCorrection: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
ManageAdjustmentJournal.defaultProps = {
  handleGetAdjustmentJournal: noop,
};
