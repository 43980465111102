import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../components/base';

export default class DataPendidikanNonFormal extends Component {
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteListDataPendidikanNonFormal = this._onDeleteListDataPendidikanNonFormal.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }
    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteListDataPendidikanNonFormal(idx) {
        const { onDeleteListDataPendidikanNonFormal, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteListDataPendidikanNonFormal(idx, fieldName);
      }  

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }
     
    render() {
        const {
          list, error, onChange,
          fieldName, pendidikan_non_formal_name,
        } = this.props;

        return (
            <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Training</th>
                  <th>Lembaga</th>
                  <th>Kota</th>
                  <th>Tahun</th>
                  <th>Lama</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_pendidikan_non_formal_form__${idx}`}>
                <td>{idx + 1}</td>
                <td>{data.nama_training}</td>
                <td>{data.lembaga}</td>
                <td>{data.kota}</td>
                <td>{data.tahun}</td>
                <td>{data.lama}</td>
                {/* <td>
                  <Button onClick={() => this._onDeleteListDataPendidikanNonFormal(idx)} icon="icon-trash-o" />
                </td> */}
              </tr>
            ))
        }
        {/* <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr> */}
        </tbody>
        </table>
        );
    }
}

DataPendidikanNonFormal.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteListDataPendidikanNonFormal: PropTypes.func,
    onChange: PropTypes.func,
    pendidikan_non_formal_name: PropTypes.array,
};

DataPendidikanNonFormal.defaultProps = {
    onAddList: noop,
    onDeleteListDataPendidikanNonFormal: noop,
    onChange: noop,
    pendidikan_non_formal_name:[],
};