import { connect } from 'react-redux';
import LaporanSekolahJenis from '../../../views/Akademi/DataKelas/LaporanSekolahJenis.view';
import { savePrmEducators, editPrmEducators, listPrmTypeEducators } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleSavePrmEducators: (payload, goback) => dispatch(savePrmEducators(payload, goback)),
    handleEditPrmEducators: (payload, goback) => dispatch(editPrmEducators(payload, goback)), 
    handleListPrmTypeEducators: (payload, goback) => dispatch(listPrmTypeEducators(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanSekolahJenis);
