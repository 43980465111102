import { connect } from 'react-redux';
import Riwayat from '../../../../views/Finance/Spp/Riwayat/Riwayat.view';
import { getStudentSPPOptions, getParamOptionsAcademics, getMsTempsSiswaOptions, getPrmStudentGeneral, getPaymentsTypeOptions, getSchoolUnitsOptions } from '../../../../states/thunks/options.thunk';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, historyDetailPaymentsH2H, 
  historyDetailPaymentsOfflineGeneral, historyDetailPaymentsGeneralRutinVA } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    // getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleHistoryDetailPayments: (payload, goback) => dispatch(historyDetailPaymentsRutin(payload, goback)),
    handleHistoryDetailPaymentsOffline: (payload, goback) => dispatch(historyDetailPaymentsOffline(payload, goback)),
    handleHistoryDetailPaymentsOfflineGeneral: (payload, goback) => dispatch(historyDetailPaymentsOfflineGeneral(payload, goback)),
    handleHistoryDetailPaymentsH2H: (payload, goback) => dispatch(historyDetailPaymentsH2H(payload, goback)),
    getPrmStudentGeneralOptions: (payload) => dispatch(getPrmStudentGeneral(payload)),
    getPaymentsTypeOptions: (payload, goback) => dispatch(getPaymentsTypeOptions(payload, goback)),
    handleHistoryPaymentsGeneralRutinVA: (payload, goback) => dispatch(historyDetailPaymentsGeneralRutinVA(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Riwayat);



