import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, has, find, capitalize } from "lodash";
import { validateRequiredFields } from "../../../utils/validation.util";
import { normalizeAmount, formatData } from "../../../utils/transformer.util";
import { Button, Input, Select, InputDate } from "../../../components/base/index";
import { PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianCatatanAnekdot extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._setForm = this._setForm.bind(this);
    this._renderButtonsObsHarian = this._renderButtonsObsHarian.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          organizations_id: organizations_id,
          nilaikognitif: [
            {
              id: "",
              score_students: "",
              students_id: "",
            },
          ],
        },
        error: {
          classes: "",
          levels: "",
          nilaikognitif: "",
        },
      },
      param: {},
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "classrooms"];

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    } else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getSubjectOptions();
    this._getPeriodsOptions();
    this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });

  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetNilaiKognitif } = this.props;
    try {
      const payload = await handleGetNilaiKognitif({ id });
      this.setState({
        filters: {
          classrooms_id: payload.classrooms_id,
          period: payload.period,
          date: payload.date,
        },
        form: {
          value: {
            nilaikognitif: map(payload.nilaikognitif, (detail) => ({
              id: detail.id,
              score_students: detail.score_students,
              students_id: detail.students_id,
            })),
          },
          error: {
            nilaikognitif: [],
          },
        },
      });
    } catch (err) {}
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { menu } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListPengisianCatatanAnekdot,
            user,
          } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;
          let res = {};
        
          res = await handleListPengisianCatatanAnekdot({
            ...params,
            organizations_id: organizations_id,
            units_id: school_unit.id,
            filters,
          });

          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = res;

          if (result) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          }
        } catch (err) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    );
  }

  onClickEditObsHarian(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot/lembar",
      { data: val, isEdit: true }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        this._onSearchContent();
      }
    );
  }

  _renderButtonsObsHarian(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEditObsHarian(data)}
            title="Edit"
          />
        </div>
      </td>
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async onSubmit() {
    const { history, handleSaveAttendance } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveAttendance(value);
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap");
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;

      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      content,
      filters,
    } = this.state;
    const componentRef = React.createRef();
    const {
      classrooms_id = "",
      semester = "",
      assessment_date = "",
    } = filters;
    const semester_data = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];

    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              label="Tahun Ajaran"
              name="period"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              data={param.periods}
              value={form.value.period}
              error={form.error.period || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="semester"
              label="Semester"
              placeholder={"Pilihan"}
              onChange={this._onChangeFilter}
              data={semester_data}
              value={semester}
              error={form.error.semester || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="levels_id"
              label="Unit"
              placeholder="Pilih Unit"
              data={param.levels}
              // disabled
              value={form.value.levels_id}
              onChange={this._onFormChange}
              error={form.error.levels_id || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="text"
              name="classrooms_id"
              label="Kelas"
              placeholder="Pilih Kelas"
              data={param.classrooms}
              onChange={this._onChangeFilter}
              value={classrooms_id}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <InputDate
              type="date"
              name="assessment_date"
              label="Tanggal Penilaian"
              onChange={this._onChangeFilter}
              value={assessment_date}
              error={form.error.date || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
          </div>
        </div>

        <div className="fund-request__content">
          <table className="table" ref={componentRef}>
            <thead>
              <tr>
                {map(
                  PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.type === "link") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Link
                              to={
                                "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                              }
                            >
                              {list.name}
                            </Link>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      if (field.attribute === "name") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                            {capitalize(list.name)}
                          </td>
                        )
                      }
                      return (
                        <td
                          className={
                            field.type === "number" ? "nominal" : ""
                          }
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtonsObsHarian(list)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="user-management__table-footer"></div>
      </div>
    );
  }
}
PengisianCatatanAnekdot.propTypes = {
  getParamOptions: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PengisianCatatanAnekdot.defaultProps = {
  getParamOptions: noop,
};
