import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { STUDENT_UNIT_2_FORM_FIELDS } from "../../../constants/student.constant";
import { DAFTAR_NILAI_FORM_FIELDS } from "../../../constants/kurikulum/ulangan.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { Button } from "../../../components/base/index";
import { Input, Select, Textarea } from "../../../components/base/index";
import { MATA_PELAJARAN_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class NilaiRaportCetak extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    const { user } = this.props;
    const { organizations_id } = user;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          organizations_id: organizations_id,
          listraport: [
            {
              score: "",
              subject_id: "",
              descriptions: "",
            },
          ],
        },
        error: {
          listraport: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { content } = this.state;
    const { id } = state.data || "";
    const paramTypes = ["classrooms", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    // console.log(data)
    if (isEdit) {
      if (isEmpty(content.list)) {
        this._onSearchContent({ page: 1 }, id, data);
      }
    }

    // this._getStudentOptions();
    // this._getFolderOptions();

    // this._getclassrooms();
    // getclassrooms();
    // getLevels();
    // if (id) {
    //   this.setState({id:id})
    //   this._handleLoadData(id)
    // }

    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _onSearchContent(params = {}, id, data) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleGetRaportSiswa, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;
          console.log(data);

          if (id) {
            // const payload_classes = await handleGetRaportSiswa({ id, organizations_id: organizations_id, units_id, classrooms_id: data.classrooms_id });
            const payload = await handleGetRaportSiswa({
              id,
              organizations_id: organizations_id,
              classes_id: data.classes_id,
              period: data.periods_id
            });
            // console.log(payload_classes)
            this.setState({
              form: {
                value: {
                  id: payload.id,
                  students_id: payload.students_id,
                  classrooms_id: data.classrooms_id,
                  semester: payload.semester,
                  classes_id: payload.classes_id,
                  organizations_id: organizations_id,
                  period: payload.period,
                  name_period: payload.name_period,
                  average_value: payload.average_value,
                  listraport: map(payload.listraport, (detail) => ({
                    subject_id: detail.subject_id,
                    name: detail.name,
                    kkm: detail.kkm,
                    score: detail.score,
                    descriptions: detail.descriptions,
                  })),
                },
                error: {
                  listraport: [],
                },
              },
            });
          }
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listraport: [],
              },
            },
          }));
        }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditLembarObsHarian(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-harian"
    );
    // }
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetRaportSiswa, user } = this.props;
    const { organizations_id } = user;

    try {
      const payload = await handleGetRaportSiswa({ id,
        organizations_id: organizations_id,
        classes_id: data.classes_id,
        period: data.periods_id
       });
      // console.log(data)
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: payload.students_id,
            classrooms_id: data.classrooms_id,
            semester: payload.semester,
            organizations_id: organizations_id,
            period: payload.period,
            name_period: payload.name_period,
            average_value: payload.average_value,
            listraport: map(payload.listraport, (detail) => ({
              subject_id: detail.subject_id,
              name: detail.name,
              kkm: detail.kkm,
              score: detail.score,
              descriptions: detail.descriptions,
            })),
          },
          error: {
            listraport: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async onSubmit() {
    const { history, handleSaveRaportSiswa } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveRaportSiswa(value);
    history.push("/dashboard/kurikulum/raport/daftar-nilai-raport");
    history.go(0);
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptions( 'classes', { filters: { levels_id: value, organizations_id: 4 }} );
        // }
        // if (name === 'classes') {
        //   this._getStudentOptions({ filters: { classes_id: value.map(item => item.value)}} );
        // }
      }
    );
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { school_unit } = user;
    const { form, param } = this.state;
    const { state = {} } = location;
    const { listraport } = form.value;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    console.log(form.value);
    const semester_data = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];

    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    const componentRef = React.createRef();
    const semesterPrm = semester_data.find(i => i.value == form.value.semester)
    console.log(form.value.name_period)
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Nilai Raport </h1>
          <hr></hr>
        </div>
        <ReactToPrint
          pageStyle={printTest}
          trigger={() => (
            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form onSubmit={this._onSubmit} ref={componentRef}>
          <br></br>
          <br></br>
          {/* <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left"> */}
              <table style={{fontSize:"15px"}}>
                <tr>
                  {/* <td style={{verticalAlign:"top", whiteSpace: "nowrap"}}></td> */}
                  <td style={{verticalAlign:"top"}}>Nama Sekolah </td>
                  <td style={{verticalAlign:"top", padding:"0 10px"}}> : {school_unit.name}</td>
                  <td style={{verticalAlign:"top"}}>Kelas </td>
                  <td style={{verticalAlign:"top", padding:"0 15px"}}> : {data ? data.classrooms_name : ""}</td>
                </tr>
                <tr>
                  {/* <td style={{verticalAlign:"top"}}></td> */}
                  <td style={{verticalAlign:"top"}}>Alamat </td>
                  <td  style={{verticalAlign:"top", padding:"0 10px"}}> : {school_unit.address}</td>
                  <td style={{verticalAlign:"top"}}>Semester</td>
                  <td style={{verticalAlign:"top", padding:"0 15px"}}> : {semesterPrm?semesterPrm.label:''}</td>
                </tr>
                <tr>
                  {/* <td style={{verticalAlign:"top"}}></td> */}
                  <td style={{verticalAlign:"top"}}>Nama</td>
                  <td style={{verticalAlign:"top", padding:"0 10px"}}> : {data ? data.name : ""}</td>
                  <td style={{verticalAlign:"top"}}>Tahun Pelajaran</td>
                  <td style={{verticalAlign:"top", padding:"0 15px"}}> : {form.value.name_period}</td>
                </tr>
                <tr>
                  {/* <td style={{verticalAlign:"top"}}></td> */}
                  <td style={{verticalAlign:"top"}}>No VA</td>
                  <td style={{verticalAlign:"top", padding:"0 15px"}}> : {data ? data.no_va : ""}</td>
                </tr>
              </table>
            {/* </div> */}

            {/* <div className="laporan-ki4__header-title3--right">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td></td>
                  <td>Kelas </td>
                  <td> : {data ? data.classrooms_name : ""}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Semester</td>
                  <td> : {semesterPrm?semesterPrm.label:''}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Tahun Pelajaran</td>
                  <td> : {form.value.name_period}</td>
                </tr>
              </table>
            </div> */}
          {/* </div> */}
          <hr></hr>
          <br></br>
          <br></br>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    {map(
                      MATA_PELAJARAN_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(listraport, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        MATA_PELAJARAN_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td
                                key={`table_index_${fieldIdx}_${idx}`}
                                style={{ textAlign: "center" }}
                              >
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.attribute === "score") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                {list.score}
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }

                          if (field.attribute === "descriptions") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                {list.descriptions}
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }

                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {/* {this._renderButtons(list)} */}
                    </tr>
                  ))}
                  {
                    <tr>
                      <td colSpan="3">Nilai Rata-Rata</td>
                      <td colSpan="2">{form.value.average_value}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="manage-registration__footer">
            {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
            }
          </div> */}
        </form>
      </div>
    );
  }
}
NilaiRaportCetak.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
NilaiRaportCetak.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
