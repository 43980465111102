import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination, SearchSelect } from '../../../components/base/index';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import { TABLE_PRESTASI_TABLE_FIELDS } from '../../../constants/Student/displiner.constant';
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class TablePrestasi extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickEdit = this._onClickEdit.bind(this);
        this._onClickAddGroup = this._onClickAddGroup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onSearchContent = this._onSearchContent.bind(this);
        this._onChangeFilter = this._onChangeFilter.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this._onChangeListAmount = this._onChangeListAmount.bind(this);
        this._getAchievementOptions= this._getAchievementOptions.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        
        this.state= {
          content: initialContent,
          loading: true,
          list: [],
          workflow: {},
          listAmount: 5,
          filters:{},
        form: {
          value: {},
          error: {},
          },
          param:{},
        };
      
      }
    
      componentDidMount() {
        const { content } = this.state;
        const paramTypes = ['levels', 'classes', 'classrooms'];
        if (isEmpty(content.list)) {
          this._onSearchContent({ page: 1 });
        }
        paramTypes.forEach((type) => {
          this._getParamOptionsAcademics(type);
        });
        this._getAchievementOptions();
      }


      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics, user } = this.props;
        const { organizations_id } = user; 
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }


      async _getAchievementOptions() {
        const { getAchievementOptions } = this.props;
        const res = await getAchievementOptions();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            prm_achievement: res,
          },
        }));
       
      }
      
      _onChangeFilter(e) {
        const { target } = e;
        const { value, name } = target;
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }), () => this._onSearchContent());
      }

      _onChangeListAmount(event) {
        const { keywords } = this.state;
        this.setState({
          listAmount: Number(event.target.value),
        });
        this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
      }

      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-prestasi', { isEdit: true });
      }
    
      _onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-prestasi', { data: val, isEdit: true });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group-prestasi', { isEdit: true });
      }

      async onClickDelete(id) {
        const { handleDeleteAchievementTable } = this.props;
        const res = await handleDeleteAchievementTable(id);
        if(res){
          this._onSearchContent();
        }
      }

      _renderButtons(data) {
        const { user } = this.props;
        const {user_group} = user;
        const button = {};
        
        return (
          <td>
            <div className="table__actions">
            
              
              {/* {user_group.id === 2? */}
                 <Button
                 onClick={() => this._onClickEdit(data)}
                 title='Edit'
               />
               {/* :'' */}
              {/* } */}
                {/* {user_group.id === 2? */}
              
               <Button
                 onClick={() => this.onClickDelete(data)}
                 title='Hapus'
               />
               {/* :'' */}
              {/* } */}
             
            </div>
          </td>
        );
      }

      async _onSearchContent(params = {}) {
        const { filters } = this.state;
        this.setState({
          content: initialContent,
        }, async () => {
          try {
            const { handleListAchievementTable, user } = this.props;
            const { units_id, workingUnit, id, user_group, organizations_id } = user;
            const {
              result = [], currentPage = 1, total = 0, totalPage = 1,
            } = await handleListAchievementTable({ ...params, filters, organizations_id: organizations_id });
            // if (this.isMount) {
              this.setState({
                content: {
                  loading: false,
                  list: result,
                  currentPage,
                  total,
                  totalPage,
                },
              });
            // }
          } catch (err) {
            // if (this.isMount) {
              this.setState({
                content: {
                  loading: false,
                  list: [],
                  currentPage: 1,
                  total: 1,
                  totalPage: 1,
                  error: true,
                },
              });
            }
          // }
        });
      }

      
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
      onChangePage(page) {
        const { listAmount, keywords } = this.state;
        const offset = listAmount * (page - 1);
        this._onSearchContent({
          limit: listAmount,
          keywords,
          offset,
          page,
        });
      }
    
      render() {
        const {form, content = {}, filters, param} = this.state;
        const { handleGetKelas, handleDeleteKelas,coa } = this.props;
        const {
          name_achievement = '',
        } = filters;
        return (
            
          <div className="student-list">
            <div className="budget__title">
              <h1>Buat Prestasi</h1>
              <hr></hr>
            </div>
              
            <div className="student-list__header">
            
              <div className="student-list__button">
                <Button
                  title="Buat Prestasi"
                  onClick={this._onClickAdd}
                />
              </div>
              <div className="student-list__button">
                <Button
                  title="Buat Grup Prestasi"
                  onClick={this._onClickAddGroup}
                />
              </div>
    
    
            </div>
            <div className="manage-registration">
              <div className="absensi-rekap__custom-form">
                <div className="absensi-rekap__custom-form-row">
                  <div className="absensi-rekap__custom-form-row__field">
                  {/* <Select
                    name="tahun_ajaran"
                    label="Tahun Ajaran"
                    onChange={this._onChangeFilter}
                    // data={ levels.list}
                    value={form.value.tahun_ajaran}
                    error={form.error.tahun_ajaran || ''}
                    placeholder={'Pilihan'}
                    data={academic_year}
                    // error={form.error.namaSaudara1 || ''}
                  /> */}
                  </div>
                  <div className="absensi-rekap__custom-form-column__field">
                  <SearchSelect
                      noMargin
                      async={false}
                      name="name_achievement"
                      list={param.prm_achievement}
                      inputArray
                      onClick={this._onChangeFilter}
                      placeholder="Pilih Nama Prestasi"
                      value={filters.name_achievement}
                     // labelName="label"
                     // valueName="value"
                      rightIcon="icon-search"
                      label= "Nama Prestasi"
                    />
                  </div>
                </div>
               
            </div>
            </div>
            <div className="budget__content">
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {
                          map(TABLE_PRESTASI_TABLE_FIELDS.label, (field, idx) => (
                            <th key={`table_th_${idx}`}>{field}</th>
                        ))
                        }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        map(content.list, (data, idx) => (
                          <tr key={`budget_row_${idx}`}>
                            {
                            map(TABLE_PRESTASI_TABLE_FIELDS.value, (field, fieldIdx) => {
                              if (fieldIdx === 0) {
                                return (
                                  <td style={{textAlign:'center'}}key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                                );
                              }
                              if (field.type === 'link') {
                                return (
                                  <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                                    // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                                );
                              }
                              return (
                                <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                  {formatData(data, field)}
                                </td>
                              );
                            })
                            }
                            {this._renderButtons(data)}
                          </tr>
                        ))
                      }
                  </tbody>
                </table>
              </div>
              <div className="user-management__table-footer">
                <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                </p>
                <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this.onChangePage}
                />
              </div>
            </div>
          </div>
        );
      }
}
TablePrestasi.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TablePrestasi.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
