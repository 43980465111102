import { connect } from 'react-redux';
import EditTagihanSiswa from '../../../../views/Finance/Spp/LaporanSPP/EditTagihanSiswa.view';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, historyDetailPaymentsH2H, reportTotalPayments, listStudentsSpp, listStudentsSppGeneral, reportPaymentsNonRutinNew, listStudentsUpp, listStudentsUppGeneral } from '../../../../states/thunks/academics.thunk';
import { getStudentSPPOptions, getParamOptionsAcademics, getUnitsVaCodeOptions, getMsTempsSiswaOptions, getSchoolUnitsOptions, getPrmStudentGeneral, getPrmPaymentsNonGeneral, getPrmPaymentsNon } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    // getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleHistoryDetailPayments: (payload, goback) => dispatch(historyDetailPaymentsRutin(payload, goback)),
    handleHistoryDetailPaymentsOffline: (payload, goback) => dispatch(historyDetailPaymentsOffline(payload, goback)),
    handleHistoryDetailPaymentsH2H: (payload, goback) => dispatch(historyDetailPaymentsH2H(payload, goback)),
    handleReportTotalPayments: (payload, goback) => dispatch(reportTotalPayments(payload, goback)),
    getUnitsVaCodeOptions: (payload) => dispatch(getUnitsVaCodeOptions(payload)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    handleListStudentsSpp: (payload, goback) => dispatch(listStudentsSpp(payload, goback)),
    handleListStudentsUpp: (payload, goback) => dispatch(listStudentsUpp(payload, goback)),
    handleListStudentsSppGeneral: (payload, goback) => dispatch(listStudentsSppGeneral(payload, goback)),
    handleListStudentsUppGeneral: (payload, goback) => dispatch(listStudentsUppGeneral(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmStudentGeneralOptions: (payload) => dispatch(getPrmStudentGeneral(payload)),
    handleReportPaymentsNonRutinNew: (payload, goback) => dispatch(reportPaymentsNonRutinNew(payload, goback)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    getPrmPaymentsNonGeneral: (payload) => dispatch(getPrmPaymentsNonGeneral(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTagihanSiswa);
