import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { IDENTITAS_DATAKELAS_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/identitas.constant';

export default class Identitas extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickView = this.onClickView.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/buat-identitas-sekolah');
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/buat-identitas-sekolah', { data: val, isEdit: true });
  }

  onClickView(val) {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/lihat-identitas-sekolah', { data: val, isEdit: true });
  }

  render() {
    const { handleListUnits, handleDeleteBudget, user } = this.props;
    return (
      <div className="budget">
        {/* <Button
          title="Buat Identitas Sekolah"
          onClick={this.onClickAdd}
        /> */}
        <div className="budget__content">
         <ContentTable
            onSearchContent={handleListUnits}
            tableFields={IDENTITAS_DATAKELAS_TABLE_FIELDS}
            onClickAdd={this.onClickAdd}
            onClickEdit={this.onClickEdit}
            onClickView={this.onClickView}
            user={user}
          />
        </div>
      </div>
    );
  }
}


// Budget.propTypes = {
//   handleGetBudget: PropTypes.func,
//   handleDeleteBudget: PropTypes.func,
//   user: PropTypes.object,
//   history: PropTypes.object.isRequired,
// };
// Budget.defaultProps = {
//   handleGetBudget: noop,
//   handleDeleteBudget: noop,
//   user: null,
// };

Identitas.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
Identitas.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
