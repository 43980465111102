import { connect } from 'react-redux';
import ProfitAndLost from '../../../views/Finance/Report/ProfitAndLost.report.view';
import { getProfitLossReport } from '../../../states/thunks/report.thunk';
import { getCoaOptions, getPerwakilanOptions, getUnitOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    perwakilan: state.options.perwakilan,
    user: state.user,
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPerwakilan: payload => dispatch(getPerwakilanOptions(payload)),
    handleGetUnit: payload => dispatch(getUnitOptions(payload)),
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetProfitLossReport: payload => dispatch(getProfitLossReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitAndLost);
