import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import { validateRequiredFields } from "../../utils/validation.util";
import { normalizeAmount, formatData } from "../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../components/base/index";
import language from '../../languages';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);
const initialContent = {
    loading: true,
    list: [],
    currentPage: 1,
    total: 1,
    totalPage: 1,
    error: false,
  };
export default class DataGuru extends Component {
  constructor(props) {
    super(props);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);

    // this.handleGetData = this.handleGetData.bind(this);
  
    const { user } = this.props;
    const { school_unit } = user;
    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          listunits: [],
        },
        error: {},
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    this._initializeData();
  }

//   componentDidUpdate(prevProps) {
//     const { user } = this.props;
//     const { workingUnit } = user;
//     const { user: prevUser } = prevProps;
//     const { workingUnit: prevWorkingUnit } = prevUser;

//     if (!isEqual(workingUnit, prevWorkingUnit)) {
//       this._onSearchContent({ page: 1 });
//     }
//   }

  async _initializeData() {
    const { user } = this.props;
    const { content} = this.state;
    const { school_unit } = user;
    // const paramTypes = ['classrooms','levels', 'classrooms'];
    // paramTypes.forEach((type) => {
    //   this._getParamOptionsAcademics(type);
    // });
    if (isEmpty(content.list)) {
      await this._onSearchContent({ page: 1 });
    }
    
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }
  // componentDidUpdate(prevProps) {
  //   // const { user } = this.props;
  //   // const { workingUnit } = user;
  //   // const { user: prevUser } = prevProps;
  //   // const { workingUnit: prevWorkingUnit } = prevUser;

  //   // if (!isEqual(workingUnit, prevWorkingUnit)) {
  //   //   this.handleGetData();
  //   // }
  //   if (!isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    if (name === "user_groups_id") {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          username: "",
        },
      }));
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    const {
      students_id = '',
      entrance_year = '',
    } = filters;
    // filters.entrance_year=new Date().getFullYear();

    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListTeacher, getYearsOfEducationsOptions, user, handleListAnnouncement } = this.props;
        const { organizations_id, school_unit, id } = user;
        const { levels_id, units_id } = school_unit;

        // const period = await getYearsOfEducationsOptions(filters);
        // var years_now = new Date().getFullYear();
        // var month = new Date().getMonth() + 1;
        // if (month < 7) {
        //   years_now -= 1;
        // }
    
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = 
        // await handleListAnnouncement({ ...params, 
        //     organizations_id: organizations_id, 
        //     units_id: school_unit.id,
        //     user_id: id, teacher_id: id });
        await handleListTeacher({ ...params, 
            organizations_id: organizations_id, 
            units_id: school_unit.id });
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        // }
      } catch (err) {
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      // }
    });
  }

//   async handleGetData(payload = {}) {
//     const { filters, form, param } = this.state;
//     const { handleListTeacher, handleListAnnouncementStudent, user } = this.props;
//     const { workingUnit, id, user_group, profile, organizations_id, school_unit } = user;

//     const {units_id} = filters;

//     console.log(filters)
//       const res = await handleListTeacher({...payload, user_id: id, teacher_id: id, organizations_id: organizations_id, units_id: school_unit.id});

//     this.setState({
//       list: res,
//     });
    
//   }


  async onSubmit() {
    const { history, handleSaveBlockUnits } = this.props;
    const { form } = this.state;
    const { value } = form;
    await handleSaveBlockUnits(value);
    history.push(
        "/dashboard/pengaturan/blok-unit"
    );
  }

  _setIsBlocked(isBlocked) {
    const { form, filters, param } = this.state;
    const { value } = form;
    const { listunits, classes_id } = value;
    const newList = JSON.parse(JSON.stringify(listunits));
    newList.forEach((item) => {
      item.is_blocked = isBlocked;
    });

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          listunits: newList,
        },
      },
    }));
  }


  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id });
        // }
        if (name === "is_blocked" && !inputArray) {
          this._setIsBlocked(value);
        }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === 'classes_id') {
          const param = this.state.param.classes_prm;
          this.setState(prevState => ({
                ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                // graduate: param.find(i => i.value == value).attributes.graduate, 
              }
            },
          }));
        
        }
      }
    );
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/tambah-data-guru', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteListTeacher } = this.props;
    const res = await handleDeleteListTeacher(id);
    if(res){
      this._onSearchContent();
    }
  }

 _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Hapus'
           />
        </div>
      </td>
    );
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/tambah-data-guru', { isEdit: true} );
  }

  render() {
    const { list = {}, content, param, filters, form, page } = this.state;
    const prm_usergroups = param.userGroups;
    const prm_block = [
      { value: 0, label: "Open All" },
      { value: 1, label: "Block All" },
    ];

    const prm_is_block = [
      { value: 0, label: "Open" },
      { value: 1, label: "Block" },
    ];

    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";
  
      console.log(content)
    return (
      <div className="manage-registration">
        <h1>Data Guru</h1>
        <hr></hr>
        <br></br>
        <div className="beginning-balance__button-wrapper">
        <Button
          title="Tambah Guru"
          onClick={() => this.onClickAdd()}
        />
        </div>
        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>NIP</th>
                  <th>NUPTK</th>
                  <th>Nama Guru</th>
                  <th>P/L</th>
                  <th>Tempat Tanggal Lahir</th>
                  <th>Opsi</th>
                </tr>
              </thead>
              <tbody>
              {map(content.list, (data, idx) => (

                  <tr key={`budget_row_${idx}`}>
                    <td style={{ textAlign: "center" }} key={`table_$${idx}`}>
                      {idx + 1}
                    </td>
                    <td key={`table_${idx}`} style={{ textAlign: "center" }}>
                      {data.nip}
                    </td>
                    <td key={`table_${idx}`} style={{ textAlign: "center" }}>
                      {data.nuptk}
                    </td>
                    <td
                      key={`table_${idx}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {(data.name)}
                    </td>
                    <td key={`table_${idx}`} style={{ textAlign: "center" }}>
                      {data.genders}
                    </td>
                    <td key={`table_${idx}`} style={{ textAlign: "center" }}>
                      {data.pob_dob}
                    </td>
                      {this._renderButtons(data)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this.onChangePage}
              />
            </div>
      </div>
    );
  }
}
DataGuru.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DataGuru.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
