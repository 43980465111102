import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Input, Select, Textarea } from '../../../../components/base/index';
import { Multiselect } from 'multiselect-react-dropdown';

export default class formlistsoal extends Component {
  constructor(props) {
    super(props);    
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  render(){
    const { onFormChange, form,param } = this.props;
    return (
        <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row"> 
                <div className="absensi-rekap__custom-form-row__field">
                    <Select
                        name="organization_id"
                        label="Organisasi"
                        onChange={this._onFormChange} 
                        value={form.value.organization_id}
                    />
                    <Input
                        type="date"
                        name="date"
                        label="Tanggal"
                        onChange={this._onFormChange}
                        value={form.value.date}
                    />
                    <Input
                        type="text"
                        name="judul"
                        label="Judul Soal"
                        onChange={this._onFormChange}
                        value={form.value.judul}
                    />
                    <Select
                        name="status"
                        label="Status"
                        onChange={this._onFormChange} 
                        value={form.value.status}
                    />
                </div>
            </div>
      </div>
    );
  }
}
formlistsoal.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
formlistsoal.defaultProps = {
  onFormChange: noop,
};