import { connect } from 'react-redux';
import TambahJadwalMatpel from '../../views/Settings/TambahJadwalMatpel.view';
import { getClasses, getParamOptionsAcademics, getTeacherOptions, 
  getSubjectOptions, getParamOptions, getDayOptions, getClassesOptions,getPeriodsOptions, getPrmLevels } from '../../states/thunks/options.thunk';
import { getScheduleSubject, editScheduleSubject, saveScheduleSubject } from '../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveScheduleSubject: (payload, goback) => dispatch(saveScheduleSubject(payload, goback)),
    handleEditScheduleSubject: (payload, goback) => dispatch(editScheduleSubject(payload, goback)), 
    handleGetScheduleSubject: payload => dispatch(getScheduleSubject(payload)), 
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getTeacherOptions: (payload) => dispatch(getTeacherOptions(payload)),
    getDayOptions: (payload) => dispatch(getDayOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),

};
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahJadwalMatpel);
