import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import { validateRequiredFields } from "../../utils/validation.util";
import { normalizeAmount, formatData } from "../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
  InputDate
} from "../../components/base/index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export default class TambahDataMatpel extends Component {
  constructor(props) {
    super(props);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);

  
    const { user } = this.props;
    const { school_unit, organizations_id } = user;
    this.state = {
      page: 1,
      filters: {},
      form: {
        value: {
          organizations_id: organizations_id,
          units_id: school_unit.id
        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    const {
      handleGetCitizenships,
      handleGetReligions,
      handleGetEducations,
      handleGetOccupations,
      handleGetClasses,
      handleGetGenders,
      handleGetBloodTypes,
      handleGetLevels,
      location,
      user,
    } = this.props;
    const { state = {} } = location;
    const { school_unit, organizations_id } = user;
    const { levels_id, units_id } = school_unit;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data);
    }
    const paramTypes = [
      "levels"
    ];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getPeriodsOptions({units_id: school_unit.id});
    this._getClassesOptions({
      filters: { levels_id: [levels_id], units_id: [school_unit.id] },
    });
  }

  async _setForm(data) {
    const { handleGetListTeacher, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { form } = this.state;
    const { value } = form;

    console.log(data)
    try {
      this.setState({
        form: {
          value: {
            id: data.id,
            periods_id: data.periods_id,
            semester: data.semester,
            kkm: data.kkm,
            subject_code: data.subject_code,
            subject_name: data.subject_name,
            classes_id: data.classes_id,
          },
          error: {
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    if (user_group.name !== 'pusat'){

    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    }
    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              periods_id: data.value,
            }
          },
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async onEdit() {
    const { history, handleEditSubjectDetail } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    await handleEditSubjectDetail(value);
    history.push("/dashboard/pengaturan/data-mata-pelajaran");
    // }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }
  
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    if (name === "user_groups_id") {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          username: "",
        },
      }));
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, param } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem("param")) || "";
    const prm_usergroups = savedFilters.userGroups;
    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";

    if (filters.user_groups_id === "") {
      delete filters.user_groups_id;
    }
    if (
      filters.username === "" ||
      (!isEmpty(filters.username) && filters.username.value === "")
    ) {
      delete filters.username;
    }

    this.setState(async () => {
      try {
        const { handleGetListTeacher, user } = this.props;
        const { school_unit, organizations_id } = user;
        const result = await handleGetListTeacher({
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
        });
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listunits: result,
            },
          },
        }));
      } catch (err) {
        // if (this.isMount) {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listunits: [],
            },
          },
        }));
      }
      // }
    });
  }


  _setIsBlocked(isBlocked) {
    const { form, filters, param } = this.state;
    const { value } = form;
    const { listunits, classes_id } = value;
    const newList = JSON.parse(JSON.stringify(listunits));
    newList.forEach((item) => {
      item.is_blocked = isBlocked;
    });

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          listunits: newList,
        },
      },
    }));
  }


  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id });
        // }
        if (name === "is_blocked" && !inputArray) {
          this._setIsBlocked(value);
        }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === 'classes_id') {
          const param = this.state.param.classes_prm;
          this.setState(prevState => ({
                ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                // graduate: param.find(i => i.value == value).attributes.graduate, 
              }
            },
          }));
        
        }
      }
    );
  }
  _renderButtons(data) {
    const { user } = this.props;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onReset(data)} title="Reset" />
        </div>
      </td>
    );
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/tambah-data-guru', { isEdit: true} );
  }

  async _onSubmit() {
    const { history, handleSaveSubjectDetail } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, [
      // "nip",
      "periods_id",
      "semester",
      "kkm",
      "subject_code",
      "subject_name",
      "classes_id",
    ]);


    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSaveSubjectDetail(value);
      history.push("/dashboard/pengaturan/data-mata-pelajaran");
    }
  }

  render() {
    const { form, filters, param } = this.state;
    const { listunits } = form.value;
    const prm_usergroups = param.userGroups;
    const prm_block = [
      { value: 0, label: "Open All" },
      { value: 1, label: "Block All" },
    ];

    const semester = [
      { value: "ganjil", label: "Ganjil" },
      { value: "genap", label: "Genap" },
    ];

    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";
  
    return (
      <div className="manage-registration">
        <form>
        <h1>Data Mata Pelajaran</h1>
       <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="periods_id"
              label="Tahun Ajaran *"
              onChange={this._onFormChange}
              data={param.periods}
              placeholder={"Pilihan"}
              value={form.value.periods_id}
              error={form.error.periods_id}
              isRequired
              disabled
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
          <Select
              name="semester"
              label="Semester *"
              onChange={this._onFormChange}
              data={semester}
              placeholder={"Pilihan"}
              value={form.value.semester}
              error={form.error.semester}
              isRequired
            />
           
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Input
              type="text"
              name="subject_code"
              label="Kode Mata Pelajaran *"
              onChange={this._onFormChange}
              value={form.value.subject_code}
              error={form.error.subject_code || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
          <Input
                type="text"
                name="subject_name"
                label="Nama Mata Pelajaran *"
                onChange={this._onFormChange}
                value={form.value.subject_name}
                error={form.error.subject_name || ""}
                isRequired
              />
           
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Select
              name="classes_id"
              label="Tingkat Kelas *"
              onChange={this._onFormChange}
              data={param.classes_prm}
              value={form.value.classes_id}
              placeholder={"Pilihan"}
              error={form.error.classes_id || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
                type="number"
                name="kkm"
                label="Nilai KKM *"
                onChange={this._onFormChange}
                value={form.value.kkm}
                error={form.error.kkm || ""}
                isRequired
              />
           
          </div>
        </div>
        <div className="student-list__button">
        {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this._onSubmit} />
          )}
        </div>
        </form>
      </div>
    );
  }
}
TambahDataMatpel.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahDataMatpel.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
