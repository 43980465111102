import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { LAPORAN_ABSEN } from '../../../constants/student.constant';
import { ABSENSI_REKAP_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Select, Pagination,SearchSelect , Input } from '../../../components/base/index';
import LaporanAbsensiTable from '../components/LaporanAbsensiTable.component';
import { STUDENT_TABLE_EXCEL_UNIT_FIELDS} from '../../../constants/student.constant';
import language from '../../../languages';
import ReactToPrint from "react-to-print";
import { Link } from 'react-router-dom';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class DaftarRiwayatSiswaExcel  extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onSearchContent = this._onSearchContent.bind(this);
          //this._onAddList = this._onAddList.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._onChangePage = this._onChangePage.bind(this);
          this.onClickCetak = this.onClickCetak.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          this.onClickEdit = this.onClickEdit.bind(this);
        //   this._studentCount = this._studentCount.bind(this);
          this._onClickExcel = this._onClickExcel.bind(this);


          const { user } = this.props;
          const { school_unit } = user;
          const { id, levels_id } = school_unit;
          this.state = {
            page: 1,
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                total_siswa: '',
                classes: [],
                levels_id,
                listsiswa: [],
                classes_id: '',
              },
              error: {
                classes: '',
                levels: '',
                listsiswa:'',
              },
            },
            param:{},
            total_siswa: '',
            filtered: false,
          };
         
        }
      
        componentDidMount() {
          const { handleGetCodeOfAccount, location, handleGetClasses, handleGetLevels, user } = this.props;
          const { school_unit } = user;
          const { levels_id } = school_unit;
          const paramTypes = ['classes','levels', 'classrooms'];
          const { content } = this.state;
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
           if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
          this._getPeriodsOptions();  
        //   this._studentCount();      
          this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] }});     
          
        }
      
        componentDidUpdate(prevProps) {
          // const { user } = this.props;
         // const { workingUnit } = user;
         // const { user: prevUser } = prevProps;
         // const { workingUnit: prevWorkingUnit } = prevUser;
     
         // if (!isEqual(workingUnit, prevWorkingUnit)) {
         //   this.handleGetData();
         // }
         if (!isEqual(prevProps, this.props)) {
           this._onSearchContent({ page: 1 });
         }
       }

       

    //    async _studentCount(payload = {}) {
    //     const { handleStudentCount ,user} = this.props;
    //     const { organizations_id, school_unit } = user;
    //     const { school_unit_id, levels_id } = school_unit
    //     const res = await handleStudentCount({...payload,organizations_id: organizations_id, school_unit_id: school_unit.id});
    //     // filters.organizations_id = organizations_id;
    //     // filters.school_unit_id = school_unit_id;
        
    //     this.setState(prevState => ({
    //       ...prevState,
    //         total_siswa: res,
    //     }));
    //   }

       onClickCetak() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit', { isEdit: true });
      }

      onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/pendaftaran', { data: val, isEdit: true });
      }

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics ,user } = this.props;
        const {organizations_id} = user;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }

          async _getParamOptions(type, filters={}) {
            const {getParamOptions, user} = this.props;
            const {organizations_id} = user;
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }

          async _getClassesOptions(filters={}) {
            const { getClassesOptions, user } = this.props;
            const {school_unit} = user;
            const { levels_id } = school_unit;
            filters.levels_id = levels_id;
            filters.units_id = school_unit.id;

            const res = await getClassesOptions(filters);
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                classes_prm: res,
              },
            }));
           
          }

          async _getPeriodsOptions() {
            const { getPeriodsOptions } = this.props;
            const res = await getPeriodsOptions();
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                periods: res,
              },
            }));
            
          }

        _onFormChange(event) {
          const { user } = this.props;
          const { school_unit, organizations_id } = user;
          const { id } = school_unit;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          },() => {
            if (name === 'levels_id') {
              this._getClassesOptions({ filters: { levels_id: [value] }} );
            }
            if (name === 'classes_id') {
              this._getParamOptionsAcademics('classrooms', { classes_id: value, organizations_id }  );
            }
            
          });
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }

        _onClickExcel() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas/cetak-excel');
        }
      
        async _onSearchContent(params = {}) {
            const { filters, form } = this.state;
            const { value } = form;
            const { subject_id } = value;
            this.setState(
              {
                content: initialContent,
              },
              async () => {
                try {
                  const {
                    handleListStudentHistory,
                    handleListReportStudent,
                    handleListAchievement,
                    user,
                  } = this.props;
                  const { school_unit, workingUnit, id, user_group, organizations_id } =
                    user;
                  const { levels_id, units_id } = school_unit;
                  const result = await handleListStudentHistory({
                    ...params,
                    organizations_id: organizations_id,
                    units_id: school_unit.id,
                    filters,
                  });
                  // }
                  this.setState((prevState) => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      value: {
                        ...prevState.form.value,
                        listsiswa: result,
                      },
                    },
                  }));
                } catch (err) {
                  // if (this.isMount) {
                  this.setState((prevState) => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      value: {
                        ...prevState.form.value,
                        listsiswa: [],
                      },
                    },
                  }));
                }
                // }
              }
            );
          }
        renderExcel(listsiswa) {
          const {form} = this.state;
          const {value} = form;
  
          let excelTbl = [];
          map(listsiswa, (list, idx) => {
            let items = Object.create({});
            items.nisn = list.nisn; 
            items.no_va = list.no_va; 
            items.students_name = list.students_name; 
            items.genders = list.genders;
            items.dob = list.dob;
            items.classrooms = list.classrooms;
            items.address = list.address;
    
            excelTbl.push(items);
          });
  
          let rows = [];
          rows.push([
          <ExcelFile element={<Button  style={{height:30, width:400, marginRight: 9 }}> Export Excel</Button>}>
            <ExcelSheet data={excelTbl} name="excel">
              {/* <ExcelColumn label="Kode" value="code3"/> */}
              <ExcelColumn style={{fontWeight:'bold'}} label="NISN" value="nisn"/>
              <ExcelColumn style={{fontWeight:'bold'}} label="No VA" value="no_va"/>
              <ExcelColumn style={{fontWeight:'bold'}} label="Nama Lengkap Siswa" value="students_name"/>
              <ExcelColumn style={{fontWeight:'bold'}} label="L/P" value="genders"/>
              <ExcelColumn style={{fontWeight:'bold'}} label="Tempat & Tanggal Lahir" value="dob"/>
              <ExcelColumn style={{fontWeight:'bold'}} label="Kelas" value="classrooms"/>
              <ExcelColumn style={{fontWeight:'bold'}} label="Alamat" value="address"/>
            </ExcelSheet>
          </ExcelFile>
          ]);
          return rows;
  
        }
      
        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          });
        }
      
       

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () => this._onSearchContent());
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        
    _onClickNext() {
        window.scrollTo(0, 0);
        this.setState(prevState => ({
          page: prevState.page + 1,
        }));
      }

      _renderButtons(data) {
        const { user } = this.props;
        const {user_group} = user;
        const button = {};
        
        return (
          <td>
            <div className="table__actions">
                 <Button
                 onClick={() => this.onClickEdit(data)}
                 title='Edit'
               />
             
            </div>
          </td>
        );
      }

  
      render() {

        const {param, form,
            page, list = {},
            selected, checkedAll, content, 
            filters, listAmount, total_siswa } = this.state;
        const {listsiswa} = form.value;
        const {
          classrooms_id = '',
          status_id = '',
          period = '',
        } = filters;
        const { classes = {}, levels = {}, user } = this.props;
        const { organizations_id, school_unit } = user;

        const presence_status = [
                { label: 'Mengikuti', value: 'mengikuti'},
                { label: 'Tidak Mengikuti', value: 'tidak'},
              ]
        const status = [
          { label: 'Hadir', value: 'HADIR'},
          { label: 'Sakit', value: 'SAKIT'},
          { label: 'Izin', value: 'IZIN'},
          { label: 'Alpha', value: 'ALPHA'},

        ]
        const academic_year = [
          { label: '3019', value: '3019'},
          { label: '3030', value: '3030'},
          { label: '3031', value: '3031'},
        ]
        const componentRef = React.createRef();
        return (
          <div className="manage-registration">
            <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={period}
                  error={form.error.period || ""}

                />
             
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                    type="text"
                    name="levels_id"
                    label="Unit"
                    // disabled
                    placeholder="Pilih Unit"
                    data={param.levels}
                    onChange={this._onFormChange}
                    value={form.value.levels_id}
                    />
                
              </div>
            </div>
        
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <Select
                    type="text"
                    name="classes_id"
                    label="Tingkat Kelas"
                    placeholder="Pilih Kelas"
                    data={param.classes_prm}
                    onChange={this._onFormChange}
                    value={form.value.classes_id}
                    error={form.error.classes_id || ""}
                  />
              
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                    <Select
                    type="text"
                    name="classrooms_id"
                    label="Kelas"
                    placeholder="Pilih Kelas"
                    data={param.classrooms}
                    onChange={this._onChangeFilter}
                    value={classrooms_id}
                    />
                {/* <Select
                  type="text"
                  name="classrooms_id"
                  label="Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                  value={classrooms_id}
                /> */}
              </div>
            </div>
            </div>
              <div style={{marginLeft: '0rem'}} className="student-list__button">
                {this.renderExcel(listsiswa)}
              </div>

              <div className="manage-registration">
                <div className="fund-request__content">
                  <table className="table" ref={componentRef}>
                    <thead>
                      <tr>
                        {
                            map(STUDENT_TABLE_EXCEL_UNIT_FIELDS.label, (field, idx) => (
                              <th key={`table_th_${idx}`}>{field}</th>
                            ))
                          }
                      </tr>
                    </thead>
                    <tbody>
                      {
                          map(listsiswa, (list, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {
                              map(STUDENT_TABLE_EXCEL_UNIT_FIELDS.value, (field, fieldIdx) => {
                                if (fieldIdx === 0) {
                                  return (
                                    <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                                  );
                                }
                                if (field.attribute === 'students_name') {
                                  return (
                                    <td key={`table_${fieldIdx}_${idx}`}>
                                      <Link to={{
                                        pathname: '/dashboard/kesiswaan/laporan/cetak-buku-induk',
                                        state: {...list},
                                      }}>
                                        {list.students_name}
                                      </Link></td>
                                      // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                                  );
                                }
                                return (
                                  <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                    {formatData(list, field)}
                                  </td>
                                );
                              })
                              }
                              {/* {this._renderButtons(list)} */}
                            </tr>
                          ))
                        }
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="user-management__table-footer">
                <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                </p>
                <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this._onChangePage}
                />
            </div> */}
        </div>
            );
          }
    }
DaftarRiwayatSiswaExcel.propTypes  = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DaftarRiwayatSiswaExcel.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
