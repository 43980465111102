import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class PengisianDataPrestasi extends Component {
  constructor(props){
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
  
  this.state= {
    form: {
      values: {
        classes: '',
        nissiswa: '',
        namasiswa: '',
        tanggalprestasi: '',
        keterangan: '',
        alasan:'',
        sanksi:'',
        jmlpoint: '',
      },
      error: {
        classes: '',
      }
    }
  }

  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }
render() {
  const { onChange, form, classes } = this.props;
  return (
    <div className="data-pelanggaran__custom-form">
      <div className="data-pelanggaran__custom-form-column">
        <div className="data-pelanggaran__custom-form-row__field">
          <Input
            type="text"
            name="nissiswa"
            label="NIS Siswa"
            onChange={this._onFormChange}
            value={form.value.nissiswa}
            error={form.error.nissiswa || ''}
          />
          
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Input
            type="text"
            name="namasiswa"
            label="Nama Siswa"
            onChange={this._onFormChange}
            value={form.value.namasiswa}
            error={form.error.namasiswa || ''}
          />
         </div>
         
        
         <div className="data-pelanggaran__custom-form-row">
         <Select
              type="text"
              name="classes"
              label="Kelas"
              data={classes.list}
              onChange={this._onFormChange}
              value={form.value.classes}
              error={form.error.classes || ''}
              placeholder={'Pilihan'}
            />
          
          
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Input
            type="date"
            name="tanggalprestasi"
            label="Tanggal Prestasi"
            onChange={this._onFormChange}
            value={form.value.tanggalprestasi}
            error={form.error.tanggalprestasi || ''}
          />
         </div>
         
         <div className="data-pelanggaran__custom-form-row">
         <Select
            name="prestasi"
            label="Prestasi Ke"
            onChange={this._onFormChange}
            data={ '1', 'kelas7a'}
            placeholder={'New Entry'}
            value={form.value.prestasi}
            error={form.error.prestasi || ''}
          />

        <div className="data-pelanggaran__custom-form-row__field"></div>
          <Select
            name="jenisprestasi"
            label="Jenis Prestasi"
            onChange={this._onFormChange}
            data={ '1', 'kelas7a'}
            value={form.value.jenisprestasi}
            error={form.error.jenisprestasi || ''}
          />
          </div>

          <div className="data-pelanggaran__custom-form-row">
         <Input
            type="text"
            name="jmlpoint"
            label="Jumlah Point"
            onChange={this._onFormChange}
            value={form.value.jmlpoint}
            error={form.error.jmlpoint || ''}
          />

        <div className="data-pelanggaran__custom-form-row__field"></div>
        <Textarea
            type="text"
            name="keterangan"
            label="Keterangan"
            onChange={this._onFormChange}
            value={form.value.keterangan}
            error={form.error.keterangan || ''}
          />
          </div>
      </div>
    </div>
  );
}
  
}
PengisianDataPrestasi.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
PengisianDataPrestasi.defaultProps = {
  onChange: noop,
  classes: [],
};
