import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import {
  Input,
  Select,
  FileInput,
  SearchSelect,
  Textarea,
  InputDate
} from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";

export default class CatatSiswaPindahForm2 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);

    this.state = {
      form: {
        values: {
          classrooms: "",
          nis: "",
          studentname: "",
          tanggal: "",
          statusmutasi: "",
          nosuratpindah: "",
          tatusabsen: "",
        },
        error: {
          classrooms: "",
          nis: "",
          studentname: "",
          tanggal: "",
          statusmutasi: "",
          nosuratpindah: "",
          statusabsen: "",
        },
      },
    };
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => {
      console.log(this.state);
    });

    onChange(e);
  }

  render() {
    const { onChange, form, classrooms, levels, onFormChange, param, isEdit } =
      this.props;
    const status_mutasi = [
      { label: "Dipindahkan dari sekolah ini", value: "DiPindahkan" },
      { label: "Keluar dari sekolah ini", value: "Keluar" },
      { label: "Pindah dari sekolah ini", value: "Pindah" },
    ];
    const status_absen = [
      { label: "Tetap DItampilkan Di Absen (dengan tanda ***)", value: "1" },
      { label: "Tidak Perlu Ditampilkan DI Absen", value: "2" },
    ];
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            {/*           
          <Input
              type="text"
              name="nis"
              label="NIS/Siswa"
              onChange={onFormChange}
              value={form.value.nis || ''}
              error={form.error.nis || ''}
            /> */}
            <Select
              name="classrooms_id"
              label="Kelas *"
              onChange={onFormChange}
              data={param.classrooms}
              placeholder={"Pilihan"}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ""}
              disabled={form.value.id?true:false}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            {(form.value.id && isEdit)?
             <Input
             name="student_name"
             label="Nama Siswa *"
             value={form.value.student_name ? form.value.student_name : ""}
             onChange={onFormChange}
             error={form.error.student_name || ""}
             readOnly
           />:
            // <Select
            //   name="students_id"
            //   label="Nama Siswa *"
            //   onChange={onFormChange}
            //   data={param.student}
            //   placeholder={"Pilihan"}
            //   value={form.value.students_id}
            //   error={form.error.students_id || ""}
            // />
            <SearchSelect
              noMargin
              async={false}
              name="students_id"
              list={param.student}
              inputArray
              onClick={onFormChange}
              placeholder="Pilih Nama Siswa"
              value={form.value.students_id}
              error={form.error.students_id || ""}
              rightIcon="icon-search"
              label="Nama Siswa *"
              disabled={form.value.classrooms_id?false:true}
          />
  }
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            {/* <SearchSelect
              noMargin
              async={false}
              name="students_id"
              list={param.student}
              inputArray
              onClick={onFormChange}
              placeholder="Pilih Nama Siswa"
              value={form.value.students_id}
              // labelName="label"
              // valueName="value"
              rightIcon="icon-search"
              label= "Nama Siswa"
              /> */}

            <Select
              name="student_mutations_id"
              label="Status Mutasi *"
              data={status_mutasi}
              placeholder={"Pilihan"}
              onChange={onFormChange}
              value={form.value.student_mutations_id}
              error={form.error.student_mutations_id || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="transfer_letter_number"
              label="Nomor Surat Pindah *"
              onChange={onFormChange}
              //data={levels.list}
              placeholder="Pilihan"
              isRequired
              value={form.value.transfer_letter_number}
              error={form.error.transfer_letter_number || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <InputDate
              type="date"
              name="date"
              label="Tanggal *"
              onChange={onFormChange}
              value={form.value.date}
              error={form.error.date || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
           
              <Input
              type="text"
              name="description"
              label="Keterangan Pindah *"
              isRequired
              onChange={onFormChange}
              value={form.value.description}
              error={form.error.description || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Input
              type="text"
              name="transfer_to"
              label="Akan Pindah Ke "
              onChange={onFormChange}
              value={form.value.transfer_to}
              error={form.error.transfer_to || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
          
          </div>
        </div>

      </div>
    );
  }
}
CatatSiswaPindahForm2.propTypes = {
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classrooms: PropTypes.array,
  levels: PropTypes.array,
};
CatatSiswaPindahForm2.defaultProps = {
  onChange: noop,
  onFormChange: noop,
  classrooms: [],
  levels: [],
};
