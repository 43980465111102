import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import { TABLE_LIST_PELANGGARAN_TABLE_FIELDS} from '../../../constants/Student/displiner.constant';

export default class ListGroupPelanggaran extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickEdit = this._onClickEdit.bind(this);
        this._onClickAddGroup = this._onClickAddGroup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
    
      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-pelanggaran', { data: '' });
      }
    
      _onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/pendaftaran', { data: val });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group-pelanggaran');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas, handleDeleteKelas,coa } = this.props;
        return (
          <div className="student-list">
            <h1 style={{color:'blue'}}>List Grup Pelanggaran</h1>
            <hr></hr>
            <div className="student-list__header">
              <div className="student-list__button">
                <Button
                  title="Buat Grup Pelanggaran"
                  onClick={this._onClickAddGroup}
                />
              </div>
    
    
            </div>
    
            <div className="budget__content">
              <div className="budget__search">
              <ListForm
              coa={coa}
              form={form}
              formFields={SEARCH_FIELDS}
              onFormChange={this._onFormChange}
              />
    
              </div>
              <ContentTable
                onSearchContent={handleGetKelas}
                tableFields={TABLE_LIST_PELANGGARAN_TABLE_FIELDS}
                onClickEdit={this._onClickEdit}
                onClickDelete={handleDeleteKelas}
              />
            </div>
          </div>
        );
      }
}
ListGroupPelanggaran.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ListGroupPelanggaran.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
