import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Input, Select, Pagination } from '../../../components/base/index';
import language from '../../../languages';
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { TEACHER_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class DaftarGuru extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onSearchContent = this._onSearchContent.bind(this);
      
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this.handleGetData = this.handleGetData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this._onChangeFilter = this._onChangeFilter.bind(this);
        this._onChangeListAmount = this._onChangeListAmount.bind(this);

        const {user} = this.props;
        const { school_unit } = user;
        const { levels_id } = school_unit;
        this.state = {
          content: initialContent,
          list: [],
          isPrint: true,
          workflow: {},
          listAmount: 5,
            form: {
                value: {
                  levels_id,
                  paginate:'',
                },
                error: {
                  paginate:'',

        
                },
              },
        }
      }

      componentDidMount() {
        const { content } = this.state;
        const { user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;

        if (isEmpty(content.list)) {
          this._onSearchContent({ page: 1 });
        }
      }
    
      componentDidUpdate(prevProps) {
        const { location } = this.props;
        const { state = {} } = location;
        const { isEdit = false, data } = state;
        const { id } = state.data || "";
        if (!isEqual(prevProps, this.props)) {
          this._onSearchContent({ page: 1 });
        }
      }
    
      async _onSearchContent(params = {}) {
        const { filters, form} = this.state;
        const { value } = form;
        const { paginate } = value;
        console.log(paginate)
        this.setState({
          content: initialContent,
        }, async () => {
          try {
            const { handleListTeacherExcel, user } = this.props;
            const { organizations_id, school_unit } = user;
            const { units_id } = school_unit;
            let res = {};
            if (paginate == undefined){
              res =  await handleListTeacherExcel({ 
                ...params, 
                filters, 
                organizations_id: organizations_id, 
                units_id: school_unit.id, 
                paginate: paginate
               });
            } else {
              res =  await handleListTeacherExcel({ 
                ...params, 
                filters, 
                organizations_id: organizations_id, 
                units_id: school_unit.id, 
                paginate: paginate
               });
            }

            const {
              result = [],
              currentPage = 1, 
              total = 0, 
              totalPage = 1,
            } = res;

            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
            // }
          } catch (err) {
            // if (this.isMount) {
              this.setState({
                content: {
                  loading: false,
                  list: [],
                  currentPage: 1,
                  total: 1,
                  totalPage: 1,
                  error: true,
                },
              });
            }
          // }
        });
      }

      
      async handleGetData(payload = {}) {
        const { handleListTeacher, handleListScheduleStudents, user } = this.props;
        const { school_unit, workingUnit, id, user_group, profile, organizations_id } = user;
        const {units_id} = school_unit;
        let unit_id = units_id;
    
        if (unit_id === null && workingUnit && workingUnit.id) {
          unit_id = workingUnit.id;
        }
    
        // if(user_group.id === 2){
          const res = await handleListTeacher({
            ...payload, 
            organizations_id: organizations_id, 
            // units_id
          });
          this.setState({
            list: res,
          });
        // }
        
      }

      onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/mata-pelajaran/tambah-daftar-guru', { data: val, isEdit: true });
      }

      async onClickDelete(id) {
        const { handleDeleteListTeacher } = this.props;
        const res = await handleDeleteListTeacher(id);
        if (res) {
          // this.handleGetData();
          this._onSearchContent();

        }
      }

      onChangePage(page) {
        const { listAmount, keywords } = this.state;
        const offset = listAmount * (page - 1);
        this._onSearchContent({
          limit: listAmount,
          keywords,
          offset,
          page,
        });
      }

      _onChangeListAmount(event) {
        const { keywords } = this.state;
        this.setState({
          listAmount: Number(event.target.value),
        });
        this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
      }

      _onChangeFilter(e) {
        const { target } = e;
        const { value, name } = target;
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }), () => this._onSearchContent());
      }

      _renderButtons(data) {
        const { user } = this.props;
        const { user_group } = user;
        return (
          <td>
            <div className="table__actions">
              
               <Button
               onClick={() => this.onClickEdit(data)}
               title='Edit'
             />
           
               {/* <Button
               onClick={() => this.onClickDelete(data)}
               title='Delete'
             />
            */}
             
            
            </div>
          </td>
        );
      }

      _onClickAdd() {
       const {history} = this.props;
       history.push('/dashboard/kurikulum/mata-pelajaran/tambah-daftar-guru', { isEdit: true });

      }
    
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { pelajaran } = prevState.form.value;
          const list = prevState.form.value.pelajaran;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            pelajaran: list
          };
        });
      }

      renderExcel(content) {
        const {form} = this.state;
        const {value} = form;
    

        let excelTbl = [];
        map(content, (list, idx) => {
        console.log(list.nip)

          let items = Object.create({});
          items.nip = list.nip;
          items.nuptk = list.nuptk;
          items.name = list.name; 
          items.genders = list.genders;
          items.pob_dob = list.pob_dob;
          excelTbl.push(items);
        });
    
        let rows = [];
        rows.push([
        <ExcelFile element={<Button  style={{height:30, width:400, marginRight: 9 }}> Ekspor Excel</Button>}>
          <ExcelSheet data={excelTbl} name="excel">
            {/* <ExcelColumn label="Kode" value="code3"/> */}
            {/* <ExcelColumn style={{fontWeight:'bold'}} label="User Id" value=""/> */}
            <ExcelColumn style={{fontWeight:'bold'}} label="NIP" value="nip"/>
            <ExcelColumn style={{fontWeight:'bold'}} label="NUPTK" value="nuptk"/>
            <ExcelColumn style={{fontWeight:'bold'}} label="Nama Guru" value="name"/>
            <ExcelColumn style={{fontWeight:'bold'}} label="P/L" value="genders"/>
            <ExcelColumn style={{fontWeight:'bold'}} label="Tempat Tanggal Lahir" value="pob_dob"/>
            
          </ExcelSheet>
        </ExcelFile>
        ]);
        return rows;
    
      }

      
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        },
        () => {
          this._onSearchContent();
        }
      );
    }

   
      
    
      render() {
        const {form, list = {}, content, isPrint} = this.state;
        const {value, error} = form;
        const { pelajaran } = form.value;
        const { handleGetKelas, handleDeleteKelas,coa, classes,hari } = this.props;
        const paginate = [
          { label: "10", value: "10"},
          { label: "20", value: "20"},
          { label: "50", value: "50"},
          { label: "100", value: "100"}]
          const componentRef = React.createRef();
          const printTest = `
          @media print {
            @page {
              page-break-inside: avoid;
            }
            * {
              -webkit-print-color-adjust: exact;
            }
          }`;
      
        return (
            <div className="student-list__button">
            {/* <Button
              title="Tambah Daftar Guru"
              onClick={this._onClickAdd}
            /> */}
            <h1>Daftar Guru</h1>
            <hr></hr>
            <br></br>
            <div className="manage-registration">
              <div className="absensi-rekap__custom-form">
                <div className="absensi-rekap__custom-form-row">
                  <div className="absensi-rekap__custom-form-row__field">
                  <Select
                    name="paginate"
                    label="Sort data"
                    placeholder="Pilihan"
                    data={paginate}
                    onChange={this._onFormChange}
                    value={form.value.paginate}
                    error={form.error.paginate || ''}
                  />
                  </div>
                </div>
               
            </div>
            </div>
            <br></br>
            <div className="beginning-balance__button-wrapper">
          <ReactToPrint
            pageStyle={printTest}
            onBeforeGetContent={()=> {
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.setState(
                    { isPrint: false },
                    resolve
                  );
                }, 500);
              });
            }}
            onAfterPrint={()=> this.setState({isPrint: true})}
            trigger={() => (
              // <div className="manage-registration__footer">
                <Button title="Cetak/Simpan PDF" />
              // </div>
            )}
            content={() => componentRef.current}
          />
            <ReactHTMLTableToExcel
              id="export-to-xls-button"
              className="button "
              table="printable"
              filename={`Daftar Guru`}
              // sheet={currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}
              buttonText="Unduh ke Excel"
            />
              {/* <div style={{marginLeft: '0rem'}} className="student-list__button">
                {this.renderExcel(content.list)}
              </div> */}
          </div>
           <div className="fund-request__content" ref={componentRef}>
          <table className="table" id="printable">
            <thead>
              <tr>
                 
                {
                    map(TEACHER_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(content.list, (list, idx) => (
                    
                    <tr key={`budget_row_${idx}`}>
                      {
                        
                      map(TEACHER_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                          );
                        }
                        if (field.attribute === "nip"){
                          return (
                            <td style={{textAlign:'center'}}>{list.nip}</td>
                          );
                        }
                        if (field.attribute === 'matpel') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>{list.matpel?list.matpel:' -'}</td>
                              // {/* <Link to={'www.google.com'}>{list.hyperlink}</Link> </td> */}
                          );
                        }
                        if (field.attribute === 'classes') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>{list.classes?list.classes:' -'}</td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        if (field.attribute === 'ekskul') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>{list.ekskul?list.ekskul:' -'}</td>
                              // {/* <Link to={'www.google.com'}>{list.hyperlink}</Link> </td> */}
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+list.hyperlink} target="_blank" rel="noopener noreferrer">{list.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{list.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(list, field)}
                          </td>
                        );
                      })
                      }
                      {/* {this._renderButtons(list)} */}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        {/* <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div> */}
        </div>
        );
      }
}
DaftarGuru.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DaftarGuru.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
