import { connect } from 'react-redux';
import PayrollReport from '../../../views/Personnel/Report/PayrollReport.view';
import { getPayrollReportHeader, getPayrollReport } from '../../../states/thunks/personnel.thunk';
import { getCoaOptions, getPerwakilanOptions, getUnitOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    perwakilan: state.options.perwakilan,
    user: state.user,
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPerwakilan: payload => dispatch(getPerwakilanOptions(payload)),
    handleGetUnit: payload => dispatch(getUnitOptions(payload)),
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetTableHeaders: payload => dispatch(getPayrollReportHeader(payload)),
    handleGetData: payload => dispatch(getPayrollReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollReport);
