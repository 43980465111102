import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { LAPORAN_ABSEN } from "../../../constants/student.constant";
import { ABSENSI_REKAP_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
  InputDate
} from "../../../components/base/index";
import LaporanAbsensiTable from "../components/LaporanAbsensiTable.component";
import { LAPORAN_8355_TABLE_UNIT_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Laporan8355 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this.onClickCetak = this.onClickCetak.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getHeadmaster = this._getHeadmaster.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          headmaster: "",
          classes: [],
          levels: [],
          classes_id: "",
        },
        error: {
          classes: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
      headmaster: "",
    };
  }
  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetClasses,
      handleGetLevels,
      user
    } = this.props;
    const paramTypes = ["classes", "classrooms", "levels"];
    const { content } = this.state;
    const { organizations_id, school_unit } = user;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPeriodsOptions({units_id: school_unit.id});
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
    this._getHeadmaster();

  }

  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
              period: data.value,
            }
          }
          }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  // on formchange untuk form
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _getHeadmaster(payload = {}) {
    const { getHeadmaster, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getHeadmaster({
      ...payload,
      organizations_id: organizations_id,
      units_id: school_unit.id,
    });
    // filters.organizations_id = organizations_id;
    // filters.units_id = units_id;

    this.setState((prevState) => ({
      ...prevState,
      headmaster: res,
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListLaporan8355,
            handleListReportStudent,
            handleListAchievement,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;

          const result = await handleListLaporan8355({
            ...params,
            organizations_id: organizations_id,
            filters,
            units_id: school_unit.id,
          });
          // }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        if (name === "subject_id") {
          this._onSearchContent();
        }

        // if (name === 'ekskul_id') {
        //   this._getTeacherEkskulOptions({ filters: { ekskul_id: value }} );
        // }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    // console.log(plafon);
  }

  async onClickCetak() {
    const { history } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    history.push("/dashboard/kesiswaan/laporan/cetak-laporan-8355");
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      headmaster
    } = this.state;
    const { classrooms_id = "", status_id = "", period = "" } = filters;
    const { listsiswa } = form.value;
    const { classes = {}, levels = {} } = this.props;
    const presence_status = [
      { label: "Mengikuti", value: "mengikuti" },
      { label: "Tidak Mengikuti", value: "tidak" },
    ];
    const status = [
      { label: "Hadir", value: "HADIR" },
      { label: "Sakit", value: "SAKIT" },
      { label: "Izin", value: "IZIN" },
      { label: "Alpha", value: "ALPHA" },
    ];
    const Period = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const componentRef = React.createRef();

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Laporan 8355</h1>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                type="text"
                name="period"
                label="Tahun Ajaran"
                placeholder="Pilih Tahun Ajaran"
                onChange={this._onFormChange}
                data={param.periods}
                value={form.value.period}
                error={form.error.period || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                placeholder="Pilih Unit"
                data={param.levels}
                disabled
                value={form.value.levels_id}
                onChange={this._onFormChange}
                error={form.error.levels_id || ""}
              />
            </div>
          </div>
          {/*             
            <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                    
                <Select
                  name="model8355"
                  label="Model 8355"
                  onChange={this._onFormChange}
                  //data={levels.list}
                  placeholder="Pilihan"
                  value={form.value.model8355}
                  error={form.error.model8355 || ''}
                />
                </div>  
              
                <div className="absensi-rekap__custom-form-column__field">
                <Select
                  name="status_siswa"
                  label="Status Siswa"
                  onChange={this._onFormChange}
                  //data={levels.list}
                  placeholder="Semua Siswa"
                  value={form.value.status_siswa}
                  error={form.error.status_siswa || ''}
                />
                </div>
            </div> */}
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="classrooms_id"
                label="Kelas"
                onChange={this._onChangeFilter}
                data={param.classrooms}
                placeholder={"Pilihan"}
                value={classrooms_id}
              />
            </div>
            <div className="absensi-rekap__custom-form-row-ganda__field">
              <InputDate
                type="date"
                name="tgl_cetak"
                label="Tanggal Cetak"
                onChange={this._onFormChange}
                //data={levels.list}
                value={form.value.tgl_cetak}
                error={form.error.tgl_cetak || ""}
              />

              {/* <div className="absensi-rekap__custom-form-row-datadiri">
                  <Input
                    type="text"
                    name="nip"
                    label="NIP"
                    onChange={this._onFormChange}
                    value={form.value.nip}
                    error={form.error.nip || ""}
                  />
                </div> */}
            </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Input
                type="text"
                name="nama_pengawas"
                label="Nama Pengawas"
                onChange={this._onFormChange}
                value={form.value.nama_pengawas}
                error={form.error.nama_pengawas || ""}
              />
              {/* <div className="absensi-rekap__custom-form-row-datadiri">
                  <Input
                    type="text"
                    name="nip2"
                    label="NIP"
                    onChange={this._onFormChange}
                    value={form.value.nip2}
                    error={form.error.nip2 || ""}
                  />
                </div> */}
            </div>

            <div className="absensi-rekap__custom-form-column__field">
              {/* <Input
                type="text"
                name="kepala_sekolah"
                label="Kepala Sekolah"
                onChange={this._onFormChange}
                value={form.value.kepala_sekolah}
                error={form.error.kepala_sekolah || ""}
              /> */}
            </div>
          </div>
        </div>

        <ReactToPrint
          trigger={() => (
            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form onSubmit={this._onSubmit} ref={componentRef}>
          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(LAPORAN_8355_TABLE_UNIT_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {map(listsiswa, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        LAPORAN_8355_TABLE_UNIT_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td style={{textAlign: "center"}} key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.attribute === "nisn"){
                            return (
                              <td style={{textAlign:'center'}}>{list.no_va}</td>
                            );
                          }
                          if (field.attribute === "no_va"){
                            return (
                              <td style={{textAlign:'center'}}>{list.no_va}</td>
                            );
                          }
                          if (field.attribute === "students_name") {
                            return (
                              <td style={{textTransform: "capitalize"}}>{capitalize(list.students_name)}</td>
                            )
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <a
                                  href={"https://" + list.hyperlink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {list.hyperlink}
                                </a>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {/* {this._renderButtons(list)} */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left">
              <table className="laporan-ki4__table-test">
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>Kepala Sekolah</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>{headmaster.full_name}</th>
                </tr>
              </table>
            </div>
            <div className="laporan-ki4__header-title3--right">
              <table className="laporan-ki4__table-test">
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th colSpan="2">Nama Pengawas</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>{form.value.nama_pengawas}</th>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
Laporan8355.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Laporan8355.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
