import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../components/base';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { STUDENT_TABLE_FIELDS } from '../../constants/student.constant';

export default class StudentList extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { data: '' });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { data: val });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/upload');
  }

  render() {
    const { handleGetStudent, handleDeleteStudent } = this.props;
    return (
      <div className="student-list">
        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Buat Pendaftaran"
              onClick={this._onClickAdd}
            />
          </div>
          <div className="student-list__button">
            <Button
              title="Upload File"
              onClick={this._onClickUpload}
            />
          </div>
        </div>
        <div className="result__content">
          <ContentTable
            onSearchContent={handleGetStudent}
            tableFields={STUDENT_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            onClickDelete={handleDeleteStudent}
          />
        </div>
      </div>
    );
  }
}
StudentList.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
StudentList.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
