import { connect } from 'react-redux';
import ManageH2HJournal from '../../../../views/Finance/Bookkeeping/H2HJournal/ManageH2HJournal.finance.view';
import {
  getH2HJournalById,
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetH2HJournal: payload => dispatch(getH2HJournalById(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageH2HJournal);
