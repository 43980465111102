import { connect } from 'react-redux';
import TambahAbsensiHarian from '../../../views/Student/absensi/TambahAbsensiHarian.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getLevels } from '../../../states/thunks/options.thunk';
import {getParamOptions, getParamOptionsAcademics, getPeriodsOptions, getPrmLevels} from '../../../states/thunks/options.thunk';
import { listStudentAttendance, saveAttendance, editAttendance, getAttendance} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleListStudentAttendance: (payload, goback) => dispatch(listStudentAttendance(payload, goback)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleSaveAttendance: (payload, goback) => dispatch(saveAttendance(payload, goback)),
    handleEditAttendance: (payload, goback) => dispatch(editAttendance(payload, goback)), 
    handleGetAttendance: payload => dispatch(getAttendance(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahAbsensiHarian);


