import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input, InputDate, Select } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";

export default class AsalSekolah extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._filterOptions = this._filterOptions.bind(this);

    this.state = {
      original: {
        sub_districts: props.sub_districts || [],
        districts: props.districts || [],
        cities: props.cities || [],
        provinces: props.provinces || [],
      },
      options: {
        sub_districts: [],
        districts: [],
        cities: [],
        provinces: [],
      },
      form: {
        value: {
          provinces2: "",
          cities2: "",
          districts: "",
          sub_districts: "",
          alamatsekolah: "",
          namasekolah: "",
          notelp: "",
          reason_move: "",
          levels: "",
          namasurat: "",
          tanggal: "",
          previous_school_prov_id: "",
          previous_school_city_id: "",
          previous_school_districts_id: "",
          sub_previous_school_districts_id: "",

          nomor_ijazah: "",
          tanggal_ijazah: "",
          nilai_ijazah: "",
          jumlah_pelajaran_ijazah: "",
          nama_orang_tua: "",
          nomor: "",
          tanggal: "",
          nilai: "",
          jumlah_pelajaran: "",
          jumlah_pelajaran: "",
          no_peserta: "",
        },
        error: {
          provinces2: "",
          cities2: "",
          districts: "",
          sub_districts: "",
          alamatsekolah: "",
          namasekolah: "",
          notelp: "",
          reason_move: "",
          levels: "",
          namasurat: "",
          tanggal: "",
          previous_school_prov_id: "",
          previous_school_city_id: "",

          nomor_ijazah: "",
          tanggal_ijazah: "",
          nilai_ijazah: "",
          jumlah_pelajaran_ijazah: "",
          nama_orang_tua: "",
          nomor: "",
          tanggal: "",
          nilai: "",
          jumlah_pelajaran: "",
          jumlah_pelajaran: "",
          no_peserta: "",
        },
      },
    };
  }

  // componentDidUpdate({
  //   provinces: prevP,
  //   cities: prevC,
  //   sub_districts: prevS,
  //   districts: prevD,
  // }) {
  //   const {
  //     provinces = [],
  //     cities = [],
  //     sub_districts = [],
  //     districts = [],
  //   } = this.props;
  //   if (
  //     JSON.stringify(provinces) !== JSON.stringify(prevP) ||
  //     JSON.stringify(cities) !== JSON.stringify(prevC) ||
  //     JSON.stringify(districts) !== JSON.stringify(prevD) ||
  //     JSON.stringify(sub_districts) !== JSON.stringify(prevS)
  //   )
  //     this.setState({
  //       original: {
  //         provinces,
  //         cities,
  //         districts,
  //         sub_districts,
  //       },
  //       options: {
  //         provinces,
  //         cities,
  //         districts,
  //         sub_districts,
  //       },
  //     });
  // }

  _onFormChange(ev) {
    const { onChange } = this.props;
    onChange(ev);
  }
  _filterOptions(name) {
    const { original } = this.state;
    const { form = {} } = this.props;
    const { value = {} } = form;

    if (name === "cities") {
      return original.cities.filter(
        (o) => o.data.provinces_id == value.previous_school_prov_id
      );
    } else if (name === "districts") {
      return original.districts.filter(
        (o) => o.data.cities_id == value.previous_school_city_id
      );
    } else if (name === "sub_districts") {
      return original.sub_districts.filter(
        (o) => o.data.districts_id == value.previous_school_districts_id
      );
    } else {
      return original.provinces;
    }
  }
  render() {
    const { options, original } = this.state;
    const { provinces, sub_districts, districts, cities } = options;
    const { form, idx, onChange, classes, param, isEdit } = this.props;
    
    const prm_raport = [
      { label: 'Ada', value: '1' },
      { label: 'Tidak Ada', value: '0' },
    ];
    const semester_data = [
      { label: 'Ganjil', value: 'ganjil'},
      { label: 'Genap', value: 'genap'},
    ]

    return (
      <div className="manage-registration__custom-form">
        <h1>Asal Sekolah/Sekolah Sebelumnya</h1>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="previous_school_name"
              label="Nama Sekolah *"
              onChange={this._onFormChange}
              value={form.value.previous_school_name}
              error={form.error.previous_school_name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Input
              type="text"
              name="previous_school_address"
              label="Alamat Sekolah *"
              onChange={this._onFormChange}
              value={form.value.previous_school_address}
              error={form.error.previous_school_address || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="previous_school_prov_id"
              label="Propinsi"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.previous_school_prov_id}
              error={form.error.previous_school_prov_id || ""}
              // data={this._filterOptions("provinces")}
            />
          </div>

          <div className="manage-registration__custom-form-column__field">
            {/* <Input
              name="previous_school_city_id"
              label="Kabupaten/Kota"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.previous_school_city_id}
              data={cities2.list}
            /> */}
          </div>
        </div>
        {form.value.previous_school_prov_id ? (
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              {/*             
             <Input
             name="previous_school_prov_id"
               label="Propinsi"
               onChange={this._onFormChange}
               placeholder="Pilihan"
               value={form.value.previous_school_prov_id}
               data={provinces2.list} 
            /> */}
            </div>
            <div className="manage-registration__custom-form-column-city__field">
              <Input
                name="previous_school_city_id"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.previous_school_city_id}
                error={form.error.previous_school_city_id || ""}
                // data={this._filterOptions("cities")}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {form.value.previous_school_city_id ? (
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              <Input
                name="previous_school_districts_id"
                label="Kecamatan"
                onChange={this._onFormChange}
                // data={this._filterOptions("districts")}
                placeholder="Pilihan"
                value={form.value.previous_school_districts_id}
                error={form.error.previous_school_districts_id || ""}
                //   error={form.error.jenisKelaminSodara1 || ''}
              />
            </div>

            <div className="manage-registration__custom-form-column__field">
              {/* <Input
                name="sub_districts"
                label="Kelurahan"
                onChange={this._onFormChange}
                data={sub_districts.list}
                placeholder="Pilihan"
                value={form.value.sub_districts}
                
              /> */}
            </div>
          </div>
        ) : (
          ""
        )}
        {form.value.previous_school_districts_id ? (
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              {/* <Input
                name="districts2"
                label="Kecamatan"
                onChange={this._onFormChange}
                data={districts2.list}
                placeholder="Pilihan"
                value={form.value.districts2}
              //   error={form.error.jenisKelaminSodara1 || ''}
              /> */}
            </div>

            <div className="manage-registration__custom-form-column-city__field">
              <Input
                name="previous_school_sub_districts_id"
                label="Kelurahan"
                onChange={this._onFormChange}
                // data={this._filterOptions("sub_districts")}
                placeholder="Pilihan"
                value={form.value.previous_school_sub_districts_id}
                error={form.error.previous_school_sub_districts_id || ""}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="previous_school_phone"
              label="Nomor Telepon"
              onChange={this._onFormChange}
              value={form.value.previous_school_phone}
              error={form.error.previous_school_phone || ""}
            />
          </div>

          <div className="manage-registration__custom-form-column__field">
            <Input
              type="text"
              name="reason_move"
              label="Alasan Pindah *"
              onChange={this._onFormChange}
              value={form.value.reason_move || ""}
              error={form.error.reason_move || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="long_study"
              label="Lama Belajar"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.long_study}
              error={form.error.long_study || ""}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="name_transfer_letter"
              label="Nomor Surat Pindah *"
              onChange={this._onFormChange}
              value={form.value.name_transfer_letter || ""}
              error={form.error.name_transfer_letter || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <InputDate
                type="date"
                name="date_of_transfer"
                label="Tanggal *"
                onChange={this._onFormChange}
                value={form.value.date_of_transfer || ""}
                error={form.error.date_of_transfer || ""}
                isRequired
              />
            </div>
          </div>
        </div>
        {/* <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Input
                    type="text"
                    name="nisn"
                    label="NISN"
                    onChange={this._onFormChange}
                    value={form.value.nisn || ''}
                    error={form.error.nisn || ''}
                  />
              </div>
          
            <div className="manage-registration__custom-form-column__field">
         
            </div>
          </div> */}

        {/* <h1>Raport</h1>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row-input__field">
            <Select
              name="raport"
              label="Raport"
              onChange={this._onFormChange}
              value={form.value.raport}
              error={form.error.raport || ""}
              placeholder={"Pilihan"}
              data={prm_raport} 
            />
          </div>
          {form.value.raport == 1 ? (
            <div className="manage-registration__custom-form-row__field">
              <Select
                name="raport_classes"
                label="Kelas"
                placeholder={'Pilihan'}
                onChange={this._onFormChange}
                data={param.classes}
                value={form.value.raport_classes}
                error={form.error.raport_classes || ""}
              />
              <div className="manage-registration__custom-form-row-datadiri">
                <Select
                  name="raport_semester"
                  label="Semester"
                  placeholder={'Pilihan'}
                  onChange={this._onFormChange}
                  data={semester_data}
                  value={form.value.raport_semester}
                  error={form.error.raport_semester || ""}
                />
              </div>
            </div>
          ) : (
            <div className="manage-registration__custom-form-row-input__field"></div>
          )}
        </div> */}
        
        {/* <div>
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row-input__field">
              <Input
                type="text"
                name="certificate_number"
                label="Nomor / Tanggal"
                onChange={this._onFormChange}
                value={form.value.certificate_number}
                error={form.error.certificate_number || ""}
              />
              <div className="manage-registration__custom-form-margin-left">
                <InputDate
                  type="date"
                  name="certificate_date"
                  label="Tanggal "
                  onChange={this._onFormChange}
                  value={form.value.certificate_date}
                  error={form.error.certificate_date || ""}
                />
              </div>
            </div>
  
            <div className="manage-registration__custom-form-row__field">
              <Input
                type="number"
                name="grade"
                label="Nilai"
                onChange={this._onFormChange}
                value={form.value.grade}
                error={form.error.grade || ""}
              />
              <div className="manage-registration__custom-form-row-datadiri">
                <Input
                  type="number"
                  name="number_of_subject_certificate"
                  label="Jumlah Pelajaran"
                  onChange={this._onFormChange}
                  value={form.value.number_of_subject_certificate}
                  error={form.error.number_of_subject_certificate || ""}
                />
              </div>
            </div>
          </div>
  
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row-input__field">
              <Input
                type="text"
                name="certificate_parent_name"
                label="Nomor Orang Tua(Sesuai Ijazah)"
                onChange={this._onFormChange}
                value={form.value.certificate_parent_name}
                error={form.error.certificate_parent_name || ""}
              />
              <div className="manage-registration__custom-form-margin-left">
              </div>
            </div>
          </div>
        </div> */}

        {/* <h1>SHUN</h1>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row-input__field">
            <Input
              type="text"
              name="shun_number"
              label="Nomor / Tanggal"
              onChange={this._onFormChange}
              value={form.value.shun_number}
              error={form.error.shun_number || ""}
            />
            <div className="manage-registration__custom-form-margin-left">
              <InputDate
                type="date"
                name="shun_date"
                label="Tanggal "
                onChange={this._onFormChange}
                value={form.value.shun_date}
                error={form.error.shun_date || ""}
              />
            </div>
          </div>

          <div className="manage-registration__custom-form-row-input__field">
            <Input
              type="number"
              name="grade_shun"
              label="Nilai"
              onChange={this._onFormChange}
              value={form.value.grade_shun}
              error={form.error.grade_shun || ""}
            />
            <div className="manage-registration__custom-form-margin-left">
              <Input
                type="number"
                name="number_of_subject_shun"
                label="Jumlah Pelajaran"
                onChange={this._onFormChange}
                value={form.value.number_of_subject_shun}
                error={form.error.number_of_subject_shun || ""}
              />
            </div>
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row-input__field">
            <Input
              type="text"
              name="participant_number"
              label="Nomor Peserta UAN"
              onChange={this._onFormChange}
              value={form.value.participant_number}
              error={form.error.participant_number || ""}
            />
            <div className="manage-registration__custom-form-margin-left">
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

AsalSekolah.propTypes = {
  //onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  provinces: PropTypes.array,
  cities: PropTypes.array,
  districts: PropTypes.array,
  sub_districts: PropTypes.array,
  previous_school_sub_districts_id: PropTypes.array,
  levels: PropTypes.array,
};
AsalSekolah.defaultProps = {
  //onFormChange: noop,
  onChange: noop,
  provinces: [],
  cities: [],
  districts: [],
  sub_districts: [],
  previous_school_sub_districts_id: [],
  levels: [],
};