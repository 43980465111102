import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { LAPORAN_DISPLINER } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Select,
  Input,
  InputDate,
} from "../../../components/base/index";
import LaporanDisplinerForm from "./components/LaporanDisplinerForm.component";
import { FOLDER__LAPORAN_DISPLINER_FIELDS } from "../../../constants/Student/student.constant";
import { FOLDER__LIST_CATAT_PRESTASI__REPORT_FIELDS } from "../../../constants/Student/student.constant";
import { FOLDER_CATAT_PELANGGARAN_REPORT_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { FOLDER__LIST_DISPLINER_CATAT_HARIAN_SISWA_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanDispliner extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    // this._searchCoa = this._searchCoa.bind(this);
    //this.deletePlafon = this.deletePlafon.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onCetak = this.onCetak.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          displiner: [],
          classes: [],
          levels: [],
          classes_id: "",
        },
        error: {
          displiner: "",
          classes: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetClasses,
      handleGetLevels,
      user
    } = this.props;
    const { school_unit } = user;
    const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptions(type);
      // this._getStudentOptions();
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    handleGetClasses();
    handleGetLevels();
    this._getPeriodsOptions({units_id: school_unit.id});
  }

  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              period: data.value,
            }
          }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { cetak } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListReportDispliner,
            handleListViolations,
            handleListAchievement,
            handleListReportDisplinerRecordDaily,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;
          let result = [];
          if (cetak === "1") {
            result = await handleListViolations({
              ...params,
              filters,
              // units_id,
              organizations_id: organizations_id,
            });
          } else if (cetak === "2") {
            result = await handleListAchievement({
              ...params,
              filters,
              // units_id,
              organizations_id: organizations_id,
            });
          } else if (cetak === "3") {
            result = await handleListReportDispliner({
              ...params,
              filters,
              // units_id,
              organizations_id: organizations_id,
            });
          } else if (cetak === "4") {
            result = await handleListReportDisplinerRecordDaily({
              ...params,
              filters,
              // units_id,
              organizations_id: organizations_id,
            });
          }

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                displiner: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                displiner: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async handleGetData(payload = {}) {
    const { handleListReportDispliner, user } = this.props;
    const { units_id, workingUnit } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleListReportDispliner({ ...payload });

    this.setState({
      list: res,
    });
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "classes_id") {
          this._getParamOptions("classes", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push(
      "/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas"
    );
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }
  onCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-buku-induk");
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content = {},
      filters,
      listAmount,
    } = this.state;
    const {
      classes_id = "",
      students_id = "",
      period = "",
      date = "",
      date_to = "",
    } = filters;
    const { classes = {}, levels = {} } = this.props;
    const { displiner } = form.value;
    const Period = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const Cetak = [
      { label: "Catat Pelanggaran", value: "1" },
      { label: "Catat Prestasi", value: "2" },
      { label: "Catat Harian Siswa", value: "4" },
      { label: "Semua", value: "3" },
    ];
    const Bentuk = [
      { label: "Persiswa", value: "1" },
      { label: "B", value: "2" },
      { label: "C", value: "3" },
    ];
    const TingkatKelas = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
    ];
    return (
      <div className="manage-registration">
        <div className="budget__title"></div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="cetak"
                label="Cetak"
                onChange={this._onFormChange}
                data={Cetak}
                value={form.value.cetak}
                error={form.error.cetak || ""}
                placeholder={"Pilihan"}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                type="text"
                name="period"
                label="Tahun Ajaran"
                placeholder="Pilih Tahun Ajaran"
                onChange={this._onChangeFilter}
                data={param.periods}
                value={period}
                error={form.error.period || ""}
              />
            </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <InputDate
                type="date"
                name="date"
                label="Dari Tanggal"
                onChange={this._onChangeFilter}
                value={date}
                error={form.error.date || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <InputDate
                type="date"
                name="date_to"
                label="Sampai Tanggal"
                onChange={this._onChangeFilter}
                value={date_to}
                error={form.error.date_to || ""}
              />
            </div>
          </div>

          {/* <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                  
           
              <Select
              placeholder="Pilih Kelas"
              label = "Tingkat Kelas"
              name="classes_id"
              value={classes_id}
              data={param.classes}
              onChange={this._onChangeFilter}
            />
              </div>  
             
              <div className="absensi-rekap__custom-form-column__field">
             
         </div>
         </div> */}
        </div>

        {form.value.cetak === "1" && (
          <>
            <h1>Table Catat Pelanggaran</h1>
            <div className="manage-registration">
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(
                        FOLDER_CATAT_PELANGGARAN_REPORT_TABLE_FIELDS.label,
                        (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {map(displiner, (data, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          FOLDER_CATAT_PELANGGARAN_REPORT_TABLE_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td style={{textAlign: "center"}} key={`table_index_${fieldIdx}_${idx}`}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "nisn"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.attribute === "no_va"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.attribute === "name") {
                              return (
                                <td style={{textTransform: "capitalize"}}>{capitalize(data.name)}</td>
                              )
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <a
                                    href={"https://" + data.hyperlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {data.hyperlink}
                                  </a>
                                </td>
                                // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                              );
                            }
                            return (
                              <td
                                className={
                                  field.type === "number" ? "nominal" : ""
                                }
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(data, field)}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {form.value.cetak === "2" && (
          <>
            <h1>Table Catat Prestasi</h1>
            <div className="manage-registration">
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(
                        FOLDER__LIST_CATAT_PRESTASI__REPORT_FIELDS.label,
                        (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {map(displiner, (data, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          FOLDER__LIST_CATAT_PRESTASI__REPORT_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td style={{textAlign: "center"}} key={`table_index_${fieldIdx}_${idx}`}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "nisn"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.attribute === "no_va"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.attribute === "name") {
                              return (
                                <td style={{textTransform: "capitalize"}}>{capitalize(data.name)}</td>
                              )
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <a
                                    href={"https://" + data.hyperlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {data.hyperlink}
                                  </a>
                                </td>
                                // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                              );
                            }
                            return (
                              <td
                                className={
                                  field.type === "number" ? "nominal" : ""
                                }
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(data, field)}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {form.value.cetak === "3" && (
          <>
            <h1>Table Semua</h1>
            <div className="manage-registration">
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      <th rowSpan={2}>No</th>
                      <th rowSpan={2}>NISN</th>
                      <th rowSpan={2}>No VA</th>
                      <th rowSpan={2}>Kelas</th>
                      <th rowSpan={2}>Nama Siswa</th>
                      <th rowSpan={2}>Tanggal</th>
                      <th colSpan={2}>Prestasi</th>
                      <th colSpan={2}>Pelanggaran</th>
                      <th colSpan={2}>Harian Siswa</th>
                    </tr>
                    <tr>
                      <th>Jenis Prestasi</th>
                      <th>Point</th>
                      <th>Jenis Pelanggaran</th>
                      <th>Point</th>
                      <th>Peristiwa</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(displiner, (data, idx) => (
                      <tr key={`budget_row_${idx}`}>
                          <td style={{textAlign: "center"}}>{(idx + 1)}</td>
                          <td style={{textAlign: "center"}}>{data.nisn}</td>
                          <td style={{textAlign: "center"}}>{data.no_va}</td>
                          <td>{data.classrooms_id}</td>
                          <td style={{textTransform: "capitalize"}}>{capitalize(data.name)}</td>
                          <td>{data.date}</td>
                          <td>{data.type_achievement}</td>
                          <td>{data.score_achievement}</td>
                          <td>{data.type_violation}</td>
                          <td>{data.score_violation}</td>
                          <td>{data.name_of_activities}</td>
                          <td>{data.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {form.value.cetak === "4" && (
          <>
            <h1>Table Catat Harian Siswa</h1>
            <div className="manage-registration">
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(
                        FOLDER__LIST_DISPLINER_CATAT_HARIAN_SISWA_FIELDS.label,
                        (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {map(displiner, (data, idx) => (
                      
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          FOLDER__LIST_DISPLINER_CATAT_HARIAN_SISWA_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td style={{textAlign: "center"}} key={`table_index_${fieldIdx}_${idx}`}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "nisn"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.attribute === "no_va"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.attribute === "name") {
                              return (
                                <td style={{textTransform: "capitalize"}}>{capitalize(data.name)}</td>
                              )
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <a
                                    href={"https://" + data.hyperlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {data.hyperlink}
                                  </a>
                                </td>
                                // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                              );
                            }
                            return (
                              <td
                                className={field.type === "number" ? "nominal" : ""}
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(data, field)}
                              </td>
                            );
                          }
                        )}
                        {/* {this._renderButtons(data)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
LaporanDispliner.propTypes = {
  handleGetLevels: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleGetClasses: PropTypes.func,
  history: PropTypes.object.isRequired,
  getStudentOptions: PropTypes.func,
};
LaporanDispliner.defaultProps = {
  handleGetLevels: noop,
  handleGetStudent: noop,
  HandleGetClasses: noop,
  getStudentOptions: noop,
};
