import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { FileInput, Input, Button, Select } from '../../../../components/base/index';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import ClassChecklist from './classchecklist.component';

const MySwal = withReactContent(Swal);

export default class tabelpengaturansoal extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._showModal = this._showModal.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

    _showModal() {
        console.log('test');
        MySwal.fire({
            size: 22000,
            title: <h2>Tambah Kelas</h2>,
            width: '4000px',
            height: '1000px',
            footer: '',
            html: this._renderTambahKelas(),
        });
    }

    _onCloseModal(detailState) {
        const { form } = this.state;
        const { value } = form;
        const { details } = value;
        const { selected, unchecked } = detailState;
    
        if (selected) {
          
        }
        if (unchecked) {
         
        }
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              details,
            }
          },
        }));
      }

    _renderTambahKelas() {
        console.log('test');
        return (
            <ClassChecklist
                onChange={this._onCloseModal}
            />
        );
    }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    console.log(this.props)
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name,
    } = this.props;
    console.log(`render props : ` + JSON.stringify(list))
    const Matpel = [
        { label: 'Bahasa Indonesia', value: '1' },
        { label: 'Matematika', value: '2' },
        { label: 'Pendidikan Kewarganegaraan', value: '3' },
        { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: '4' },
      ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Judul</th>
            <th>Tanggal</th>
            <th>Keterangan</th>
            <th>Mata Pelajaran</th>
            <th>Periode</th>
            <th>Kelas</th>
            <th>Upload Picture</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                    <Input
                        type="text"
                        name="judul"
                        inputArray
                        arrayPosition={idx}
                        fieldName='tabelpengaturansoal'
                        value={data.judul}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Input
                        type="date"
                        inputArray
                        arrayPosition={idx}
                        fieldName='tabelpengaturansoal'
                        name='date'
                        value={data.date}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Input
                        type="text"
                        arrayPosition={idx}
                        fieldName='tabelpengaturansoal'
                        name='keterangan'
                        value={data.keterangan}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Input
                        type="text"
                        inputArray
                        arrayPosition={idx}
                        fieldName='tabelpengaturansoal'
                        name='mata_pelajaran'
                        value={data.mata_pelajaran}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Input
                        type="number"
                        inputArray
                        arrayPosition={idx}
                        fieldName='tabelpengaturansoal'
                        name='periode'
                        value={data.periode}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Button
                        type="button"
                        title="Tambah Kelas"
                        onClick={this._showModal}
                    />
                </td>
                <td>
                    <FileInput
                        type="file"
                        name="upload"
                    />
                </td>
                <td>
                    <div className="table__actions">
                        <Button
                            type="button"
                            title="Hapus"
                            onClick={this._onDeleteList}
                        />
                        <Button
                            type="button"
                            title="Lanjut"
                        />
                    </div>
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={7}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

tabelpengaturansoal.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,

};
tabelpengaturansoal.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  plafond_name: [],
};
