import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function LembarMingguanForm2(props) {


  const { onFormChange, form } = props;
  const choseone = [
    { label: 'D - Dibimbing', value: 'bimbing'},
    { label: 'B - Berkembang', value: 'berkembang'},
    { label: 'M - Mampu', value: 'mampu'},
    { label: 'SM - Sangat Mampu', value: 'sangat_mampu'},
  ]
  // const pengisian = [
  //   { label: 'Sikap', value: 'sikap'},
  //   { label: 'Kognitif', value: 'kognitif'},
  // ]
  return (
    <div className="lembar-harian2__custom-form">
      <div className="lembar-harian2__custom-form-column">
        <div className="lembar-harian2__custom-form-column__field">
        {/* <Select
            name="content_type"
            label="Pengisian"
            placeholder={'Pilihan'}
            onChange={onFormChange}
            data={pengisian}
            value={form.value.content_type}
            error={form.error.content_type || ''}
          /> */}
        <div className="cetak-buku-induk__title">  
                        <p >A. : Sikap</p>
                      </div>
                      <div className="cetak-buku-induk__title2">  
                      <p>SUB : Sub menu yang dibuat</p>
                      
                      </div>
        </div>
        </div>
        <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-row__field">
          <p>Indikator pertama yang akan dinilai</p>
          
        </div>
        
        <div className="lembar-harian2__custom-form-width__field">
            
        <Select
           
           name="indicator_value"
           onChange={onFormChange}
           value={form.value.indicator_value || ''}
           error={form.error.indicator_value || ''}
           placeholder={'-Pilih-'}
           data={choseone}
         />
          
       
         
        </div>
        </div>

        <div className="lembar-harian2__custom-form-column">
        <div className="lembar-harian2__custom-form-column__field">
      
        <Input
                type='text'
                name="observation_result"
                value={form.value.observation_result}
                label="Hasil Observasi: "
                onChange={onFormChange}
            />
        <Input
            type='text'
            name="expectation"
            value={form.value.expectation}
            label="Harapan: "
            onChange={onFormChange}
        />
        </div>
        </div>

        <div className="lembar-harian2__custom-form-column">
        <div className="lembar-harian2__custom-form-column__field">
       
        <div className="cetak-buku-induk__title">  
                        <p >B. : Kognitif</p>
                      </div>
                      <div className="cetak-buku-induk__title2">  
                      <p>SUB : Sub menu yang dibuat</p>
                      
                      </div>
        </div>
        </div>
        <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-row__field">
          <p>Indikator pertama yang akan dinilai</p>
          
        </div>
        
        <div className="lembar-harian2__custom-form-width__field">
            
        <Select
           
           name="indicator_value_kognitif"
           onChange={onFormChange}
           value={form.value.indicator_value_kognitif || ''}
           error={form.error.indicator_value_kognitif || ''}
           placeholder={'-Pilih-'}
           data={choseone}
         />
          
        </div>
        </div>

        <div className="lembar-harian2__custom-form-column">
        <div className="lembar-harian2__custom-form-column__field">
      
        <Input
                type='text'
                name="observation_result_kognitif"
                value={form.value.observation_result_kognitif}
                label="Hasil Observasi: "
                onChange={onFormChange}
            />
        <Input
            type='text'
            name="expectation_kognitif"
            value={form.value.expectation_kognitif}
            label="Harapan: "
            onChange={onFormChange}
        />
        </div>
        </div>
        <div className="lembar-harian2__title">
            <h1>Catatan Guru</h1>
        </div>
            <Textarea
                type='text'
                name="teacher_notes"
                value={form.value.teacher_notes}
                onChange={onFormChange}
            />

        </div>
        
 
   
  );
}
LembarMingguanForm2.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LembarMingguanForm2.defaultProps = {
  onFormChange: noop,
};
