import { connect } from 'react-redux';
import TambahPengisianPsikAfek from '../../../views/Kurikulum/Ulangan/TambahPengisianPsikAfek.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getSubjectOptions,getTeacherSubjectsOptions, getParamOptionsAcademics, getTypeExam,
         getSubjectPsikAfekOptions, getPeriodsOptions, getClassesOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listStudentPsikAfek,  editPsikAfek, getPsikAfek, savePsikAfek, listIndicatorPsikAfek} from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getSubjectPsikAfekOptions: (payload) => dispatch(getSubjectPsikAfekOptions(payload)),
    getTypeExam: (payload) => dispatch(getTypeExam(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentPsikAfek: (payload, goback) => dispatch(listStudentPsikAfek(payload, goback)),
    getTeacherSubjectsOptions: (payload) => dispatch(getTeacherSubjectsOptions(payload)),
    handleSavePsikAfek: (payload, goback) => dispatch(savePsikAfek(payload, goback)),
    handleGetPsikAfek: payload => dispatch(getPsikAfek(payload)),
    handleEditPsikAfek: (payload, goBack) => dispatch(editPsikAfek(payload, goBack)),
    handleListIndicatorPsikAfek: (payload, goback) => dispatch(listIndicatorPsikAfek(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahPengisianPsikAfek);


