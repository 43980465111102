import { connect } from 'react-redux';
import BuatCatatanKonseling from '../../views/Student/BuatCatatanKonseling.student.view';
import { getParamOptions, getStudentOptions, getCounselingPlaceOptions, getClasses, getLevels, getParamOptionsAcademics, getSubjectOptions, getPrmLevels } from '../../states/thunks/options.thunk';
import { saveCounselingRecord, historyCounselingRecord, editCounselingRecord, getAnnouncement, getCounselingRecord } from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      user: state.user || {},
      classes: state.options.classes,
      levels: state.options.levels,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      handleGetAnnouncement: payload => dispatch(getAnnouncement(payload)),
      getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
      getCounselingPlaceOptions: (payload) => dispatch(getCounselingPlaceOptions(payload)),
      getClasses: (payload) => dispatch(getClasses(payload)),
      getLevels: (payload) => dispatch(getLevels(payload)),
      getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      handleSaveCounselingRecord: (payload, goback) => dispatch(saveCounselingRecord(payload, goback)),
      handleHistoryCounselingRecord: (payload, goback) => dispatch(historyCounselingRecord(payload, goback)),
      handleEditCounselingRecord: (payload, goback) => dispatch(editCounselingRecord(payload, goback)), 
      handleGetCounselingRecord: payload => dispatch(getCounselingRecord(payload)),    
      getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(BuatCatatanKonseling);