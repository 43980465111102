import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, isEqual, noop } from "lodash";
import { Input, Select, Textarea } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";
import {
  commaFormatted,
  normalizeAmount,
  manageGetRegistration,
} from "../../../utils/transformer.util";

export default class AlamatForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._filterOptions = this._filterOptions.bind(this);
    this.state = {
      original: {
        sub_districts: props.sub_districts || [],
        districts: props.districts || [],
        cities: props.cities || [],
        provinces: props.provinces || [],
      },
      options: {
        sub_districts: [],
        districts: [],
        cities: [],
        provinces: [],
      },
    };
    this.updated = false;
  }

  // componentDidUpdate({
  //   provinces: prevP,
  //   cities: prevC,
  //   sub_districts: prevS,
  //   districts: prevD,
  // }) {
  //   const {
  //     provinces = [],
  //     cities = [],
  //     sub_districts = [],
  //     districts = [],
  //   } = this.props;
  //   if (
  //     JSON.stringify(provinces) !== JSON.stringify(prevP) ||
  //     JSON.stringify(cities) !== JSON.stringify(prevC) ||
  //     JSON.stringify(districts) !== JSON.stringify(prevD) ||
  //     JSON.stringify(sub_districts) !== JSON.stringify(prevS)
  //   ) {
  //     this.setState({
  //       original: {
  //         provinces,
  //         cities,
  //         districts,
  //         sub_districts,
  //       },
  //       options: {
  //         provinces,
  //         cities,
  //         districts,
  //         sub_districts,
  //       },
  //     });
  //   }
  // }

  _onFormChange(ev) {
    const { onChange } = this.props;
    onChange(ev);
  }

  _filterOptions(name) {
    const { original } = this.state;
    const { form = {} } = this.props;
    const { value = {} } = form;

    if (name === "cities") {
      return original.cities.filter(
        (o) => o.data.provinces_id == value.provinces_id
      );
    } else if (name === "districts") {
      return original.districts.filter(
        (o) => o.data.cities_id == value.cities_id
      );
    } else if (name === "sub_districts") {
      return original.sub_districts.filter(
        (o) => o.data.districts_id == value.districts_id
      );
    } else {
      return original.provinces;
    }
  }

  render() {
    const { options, original } = this.state;
    const { provinces, sub_districts, districts, cities } = options;
    const { form, idx, onChange, classes, param, param2, isEdit } = this.props;

    if (form.value.province == "") {
      form.value.city = "";
    }
    if (form.value.city == "") {
      form.value.district = "";
    }
    if (form.value.district == "") {
      form.value.sub_district = "";
    }

    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="address"
            label="Alamat Tempat Tinggal *"
            onChange={this._onFormChange}
            value={form.value.address}
            error={form.error.address || ""}
            isRequired
          />
          {/* <Input
            type="number"
            name="entrance_year"
            label="Tahun Masuk *"
            onChange={this._onFormChange}
            value={form.value.entrance_year}
            error={form.error.entrance_year || ""}
            readOnly={!isEdit ? true : false}
            isRequired
          /> */}
          {/* <div className="manage-registration__custom-form-row-datadiri">
            <Select
              name="years_of_education"
              label="Tahun Pendidikan"
              onChange={this._onFormChange}
              data={param.years_educations}
              placeholder={'Pilihan'}
              value={form.value.years_of_education}
              error={form.error.years_of_education || ''}
            />
            </div> */}
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="rt"
              label="RT"
              onChange={this._onFormChange}
              value={form.value.rt}
              error={form.error.rt || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="number"
                name="rw"
                label="RW"
                onChange={this._onFormChange}
                value={form.value.rw}
                error={form.error.rw || ""}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="school_house_distance"
              label="Jarak Sekolah-Rumah"
              onChange={this._onFormChange}
              value={form.value.school_house_distance}
              error={form.error.school_house_distance || ""}
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              // name="provinces_id"
              name="province"
              // inputArray
              // arrayPosition={idx}
              label="Propinsi"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.province}
              error={form.error.province}
              // data={provinces}
              //   error={form.error.tempatLahirSaudara1 || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            {(form.value.province) && (
              <Input
                name="city"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.city}
                error={form.error.city || ""}
                // data={this._filterOptions("city")}
              />
            )}
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {(form.value.city) && (
              <Input
                name="district"
                label="Kecamatan"
                onChange={this._onFormChange}
                // data={this._filterOptions("district")}
                placeholder="Pilihan"
                value={form.value.district}
                error={form.error.district || ""}
              />
            )}
          </div>
          <div className="manage-registration__custom-form-row__field">
            {(form.value.district) && (
              <Input
                name="sub_district"
                label="Kelurahan"
                onChange={this._onFormChange}
                // data={this._filterOptions("sub_district")}
                placeholder="Pilihan"
                value={form.value.sub_district}
                error={form.error.sub_district || ""}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
AlamatForm.propTypes = {
  //   onFormChange: PropTypes.func,
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  handleGetProvinces: PropTypes.func,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  handleGetCities: PropTypes.array,
  provinces: PropTypes.array,
  districts: PropTypes.array,
  sub_districts: PropTypes.array,
  classes: PropTypes.array,
};
AlamatForm.defaultProps = {
  //   onFormChange: noop,
  onChange: noop,
  provinces: [],
  cities_id: [],
  districts_id: [],
  sub_districts: [],
  classes: [],
};
