import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop, isEqual } from "lodash";
import ListForm from "../../../components/ListForm/ListForm.component";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { KENAIKANKELAS_DATAKELAS_FORM_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import { Button, Select, Input, SearchSelect } from "../../../components/base";
import { PEMBAGIAN_KELAS_TABEL_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import { formatData } from "../../../utils/transformer.util";
import { errorAlert } from "../../../utils/alert.util";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class KelolaPembagianKelas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.addPembagianKelas = this.addPembagianKelas.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this._getNewStudentOptions = this._getNewStudentOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getClassroomsOptions = this._getClassroomsOptions.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._initializeData = this._initializeData.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit, units_id } = user;
    const { levels_id } = school_unit;
    this.state = {
      content: initialContent,
      listAmount: 5,
      list: [],
      filters: {},
      form: {
        value: {
          levels_id,
          organizations_id: organizations_id,
          pembagian_kelas: [
            {
              students_id: "",
              period: "",
              classrooms_id: "",
              date: "",
            },
          ],
        },
        error: {
          pembagian_kelas: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    this._initializeData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!id && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _initializeData() {
    const { content, filters } = this.state;
    const { location, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = location.state;
    const { id } = state.data || "";

    await this._getPeriodsOptions({units_id: school_unit.id});

    const paramTypes = ["classes", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    } else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    // paramTypes.forEach((type) => {
    this._getClassroomsOptions();
    this._getClassesOptions({
      filters: { levels_id: [levels_id], units_id: [school_unit.id] },
    });
    this._getNewStudentOptions();
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });
  }

  // async _getPrmLevels(filters = {}) {
  //   const { getPrmLevels, user } = this.props;
  //   const { organizations_id, school_unit } = user;
  //   const { units_id, levels_id } = school_unit;
  //   const res = await getPrmLevels(filters);
  //   filters.organizations_id = 3;

  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       levels: res,
  //     },
  //   }));
  // }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    filters.organizations_id = organizations_id;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              periods_id: data.value,
            }
          }
        }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id) {
    const { form, param } = this.state;
    const { value } = form;
    const { handleGetClassDiv, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    try {
      const payload = await handleGetClassDiv({ id });
      const { classrooms, classes_id } = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] } });
      this._getParamOptionsAcademics("classrooms", {
        classes_id: classes_id,
        organizations_id: organizations_id,
      });

      this.setState({
        form: {
          value: {
            id: payload.id,
            organizations_id: organizations_id,
            units_id: school_unit.id,
            periods_id: form.value.periods_id,
            classrooms_id: payload.classrooms_id,
            levels_id: levels_id,
            classes_id: payload.classes_id,
            pembagian_kelas: map(payload.pembagian_kelas, (detail) => ({
              name: detail.name,
              homeroom_teacher: detail.homeroom_teacher,
              capacity: detail.capacity,
              classrooms_id: detail.classrooms_id,
              nis: detail.nis,
              students_id: detail.students_id,
            })),
          },
          error: {
            pembagian_kelas: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getNewStudentOptions(filters = {}) {
    const { getNewStudentOptions } = this.props;
    const res = await getNewStudentOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getClassroomsOptions() {
    const { getClassroomsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { form } = this.state;
    const { classes_id, levels_id, periods_id, classrooms_id } = form.value;
    const res = await getClassroomsOptions({
      classes_id: classes_id,
      levels_id: levels_id,
      periods_id: periods_id,
      units_id: school_unit.id,
    });
    
    const selectedClassroom = classrooms_id ? res.find((item) => item.value == classrooms_id) : "";

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          capacity: selectedClassroom
            ? selectedClassroom.attributes.capacity
            : "",
        },
      },
      param: {
        ...prevState.param,
        classrooms: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;

    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  // on formchange untuk form
  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id" || name === "periods_id") {
          this._getClassroomsOptions("classrooms", {
            classes_id: value,
            organizations_id: organizations_id,
          });
        }
        if (name === "classrooms_id") {
          const param = this.state.param.classrooms;
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                capacity: param.find((i) => i.value == value) ? param.find((i) => i.value == value).attributes.capacity : "",
              },
            },
          }));
        }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async handleGetData(payload = {}) {
    const { handleListClassDivStudent, handleListAnnouncementStudent, user } =
      this.props;
    const { units_id, workingUnit, id, user_group, profile, organizations_id } =
      user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
    const res = await handleListClassDivStudent({
      ...payload,
      organizations_id: organizations_id,
    });
    // this.setState(prevState => ({
    //   ...prevState,
    //   form: {
    //     ...prevState.form,
    //     value: {
    //       ...prevState.form.value,
    //       pembagiankelas: res,
    //     }
    //   }
    // }));
    // }
    this.setState({
      list: res,
    });
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list,
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveClassDiv } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    if (
      dataForm.name &&
      dataForm.publish_date &&
      dataForm.tgl_selesai &&
      dataForm.levels &&
      dataForm.classrooms
    ) {
      canSaveA = true;
    } else {
      canSaveA = false;
    }
    // if (canSaveA == false){
    //   // alert("Daya Tampung Sudah Melebihi Batas");
    //   errorAlert({
    //     title: 'Daya Tampung Sudah Melebihi Batas',
    //     // errorMessage: 'Bagian yang harus diisi ditandai dengan tanda bintang (*)'
    //   });
    // } else {
    await handleSaveClassDiv(value);
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa");
    // }
  }

  addPembagianKelas(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push(
      "/dashboard/tata-usaha/manajemen-kelas/kelola-pembagian-kelas"
    );
  }

  deletePlafon(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ people: array });
    }
  }

  async onEdit() {
    const { history, handleEditClassDiv } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditClassDiv(value);
    history.push("/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas");
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    try {
      const { handleListClassDivStudent, user } = this.props;
      const { organizations_id, school_unit } = user;
      const { levels_id, units_id } = school_unit;

      const result = await handleListClassDivStudent({
        ...params,
        organizations_id: organizations_id,
        units_id: school_unit.id,
        filters,
        classrooms_id: value.classrooms_id,
      });
      const { classes = [], data = [] } = result;
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pembagian_kelas: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pembagian_kelas: [],
          },
        },
      }));
    }
    // }
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      classes,
    } = this.state;
    const { disable, location, user } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { value } = form;
    const { students_id = "" } = filters;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];

    const { pembagian_kelas } = form.value;
    const { classes_id = "" } = filters;
    if (form.value.classrooms_id && !isEmpty(param.classrooms)) {
      form.value.classrooms_name = param.classrooms.find((item) => item.value == form.value.classrooms_id).label;
    }

    if (data && data.no_va){
      form.value.no_va = data.no_va
    }
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            {/* <Select
            name="periods_id"
            label="Tahun Ajaran"
            placeholder={'Pilihan'}
            disabled
            onChange={this._onFormChange}
            data={ param.periods}
            value={form.value.periods_id}
            error={form.error.periods_id || ''}
          /> */}
            <Input
              name="no_va"
              label="No. VA"
              readOnly
              onChange={this._onFormChange}
              value={data ? data.no_va : ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-row__field">
            {/* <Select
              type="text"
              name="levels_id"
              label="Unit"
              placeholder="Pilih Unit"
              disabled
              data={param.levels}
              onChange={this._onFormChange}
              value={form.value.levels_id}
            /> */}
            <Input
              name="name"
              label="Nama"
              value={data ? data.name : ""}
              onChange={this._onFormChange}
              error={form.error.name || ""}
              readOnly
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              placeholder="Pilih Tingkat Kelas"
              label="Tingkat Kelas"
              name="classes_id"
              value={form.value.classes_id}
              data={param.classes_prm}
              onChange={this._onFormChange}
            />
          </div>
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              placeholder="Pilih Kelas"
              label="Kelas"
              name="classrooms_id"
              value={form.value.classrooms_id}
              data={param.classrooms}
              onChange={this._onFormChange}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              label="Daya Tampung"
              name="capacity"
              value={form.value.capacity}
              data={param.classrooms}
              onChange={this._onFormChange}
              disabled
            />
          </div>
          <div className="absensi-rekap__custom-form-row__field"></div>
        </div>
        <div></div>
        <div className="fund-request__content">
          {/* <table className="table">
           
            <thead>
              <tr>
                {
                    map(PEMBAGIAN_KELAS_TABEL_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                  ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(pembagian_kelas, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(PEMBAGIAN_KELAS_TABEL_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.attribute === 'checkbox') {
                          return (


                            <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                            type="checkbox"
                            name="checkbox"
                            data-input-array={true}
                            data-array-position={idx}
                            data-field-name='pembagian_kelas'
                            onChange={this._onFormChange}
                            checked={list.checkbox}
                            value={field.value}
                          />  </td>
                              );
                            }
                          
                        

                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                    </tr>
                  ))
                }
            </tbody>
          </table> */}
        </div>
        <div className="student-list__button">
          {/* {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />: */}
          <Button type="button" title="Simpan" onClick={this.onSubmit} />
          {/* } */}
        </div>
      </div>
    );
  }
}
KelolaPembagianKelas.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
KelolaPembagianKelas.defaultProps = {
  handleGetClasses: noop,
  handleGetLevels: noop,
  user: null,
};
