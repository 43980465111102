import { connect } from 'react-redux';
import LaporanUPD from '../../../../views/Finance/Spp/LaporanSPP/LaporanUPD.view';
import { listRecapPayments, listRecapPaymentsUKG, listRecapPaymentsDPP, 
  listRecapPaymentsTGK, listRegisFeeReport } from '../../../../states/thunks/academics.thunk';
import { getPrmLevels, getParamOptionsAcademics, getYearsOfEducationsOptions, getPrmPaymentsNon, getPeriodsOptions, getSchoolUnitsOptions, getPrmPaymentsNickGeneral, getParamPerwakilan } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleListRegisFeeReport: (payload, goback) => dispatch(listRegisFeeReport(payload, goback)),
    handleListRecapPayments: (payload, goback) => dispatch(listRecapPayments(payload, goback)),
    handleListRecapPaymentsUKG: (payload, goback) => dispatch(listRecapPaymentsUKG(payload, goback)),
    handleListRecapPaymentsDPP: (payload, goback) => dispatch(listRecapPaymentsDPP(payload, goback)),
    handleListRecapPaymentsTGK: (payload, goback) => dispatch(listRecapPaymentsTGK(payload, goback)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmPaymentsNickGeneral: (payload) => dispatch(getPrmPaymentsNickGeneral(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getParamPerwakilan: (payload) => dispatch(getParamPerwakilan(payload)),
 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanUPD);
