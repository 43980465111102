import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';

export default class ManageH2HJournal extends PureComponent {
  constructor(props) {
    super(props);
    this._setForm = this._setForm.bind(this);

    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;

    this._setForm(params.id);
  }

  async _setForm(id) {
    const { handleGetH2HJournal } = this.props;
    const res = await handleGetH2HJournal({ id });
    this.setState({ data: res });
  }

  render() {
    const { data } = this.state;
    const { details = [] } = data;
    return (
      <div className="manage-H2H-journal">
        <div>
          <h1>Jurnal H2H</h1>
          <h2>Tanggal: {dateFormat(data.date, 'D-MM-YYYY')}</h2>
          <table className="cash-journal-report__table">
            <thead>
              <tr>
                <th>No. </th>
                <th>Kode Akun</th>
                <th>Deskripsi</th>
                <th>Nominal</th>
              </tr>
            </thead>
            <tbody>
              { details.map((detail, index) => ((
                <tr>
                  <td>{index}</td>
                  <td>{detail.code_of_account}</td>
                  <td>{detail.title}</td>
                  <td className="nominal">{commaFormatted(detail.nominal)}</td>
                </tr>
              )))
              }
            </tbody>
            <tfoot>
              <tr className="total">
                <td colSpan={3}>Total</td>
                <td className="nominal">{commaFormatted(details.reduce((a, b) => a + b.nominal, 0))}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}

ManageH2HJournal.propTypes = {
  location: PropTypes.object.isRequired,
  handleGetH2HJournal: PropTypes.func,
};
ManageH2HJournal.defaultProps = {
  handleGetH2HJournal: () => {},
};
