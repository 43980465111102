import { connect } from 'react-redux';
import TambahDataGuru from '../../views/Settings/TambahDataGuru.view';
import {getUsernameOptions, getUserGroups, getSchoolUnitsOptions, getParamOptionsAcademics,
  getPeriodsOptions, getClassesOptions
} from '../../states/thunks/options.thunk';
import {saveListTeacher, getListTeacher, editListTeacher} from '../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUsernameOptions: (payload) => dispatch(getUsernameOptions(payload)),
    getUserGroups: (payload) => dispatch(getUserGroups(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    handleEditListTeacher: (payload, goBack) => dispatch(editListTeacher(payload, goBack)),
    handleSaveListTeacher: (payload, goBack) => dispatch(saveListTeacher(payload, goBack)),    
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetListTeacher: payload => dispatch(getListTeacher(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
};
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahDataGuru);
