import { connect } from 'react-redux';
import CreateInvoiceTidakRutin from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoiceTidakRutin.view';
import { getStudent } from '../../../../states/thunks/student.thunk';
import { saveInvoiceNonRutin, editInvoiceNonRutin, saveInvoiceNonRutinGeneral, editInvoiceNonRutinGeneral } from '../../../../states/thunks/academics.thunk'
import { getYearsOfEducationsOptions, getPrmPaymentsNon, getPrmPaymentsNonGeneral } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch){
  return{
    handleGetStudent: () => dispatch(getStudent()),
    handleSaveInvoiceNonRutin: (payload, goback) => dispatch(saveInvoiceNonRutin(payload, goback)),
    handleEditInvoiceNonRutin: (payload, goback) => dispatch(editInvoiceNonRutin(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    handleSaveInvoiceNonRutinGeneral: (payload, goback) => dispatch(saveInvoiceNonRutinGeneral(payload, goback)),
    handleEditInvoiceNonRutinGeneral: (payload, goback) => dispatch(editInvoiceNonRutinGeneral(payload, goback)),
    getPrmPaymentsNonGeneral: (payload) => dispatch(getPrmPaymentsNonGeneral(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps) (CreateInvoiceTidakRutin);