import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import {
  FileInput,
  Input,
  Select,
  Textarea,
  Button,
  SelectMultiple,
  InputDate
} from "../../../../components/base/index";
import { Multiselect } from "multiselect-react-dropdown";

export default class JadwalKelasForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
    };
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  async onUpload(event) {
    const { handleUploadScheduleFile, onFormChange, idx } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const upload = files[0];
    if (upload) {
      const filename = upload.name;
      const res = await handleUploadScheduleFile({
        file: upload,
      });
      onFormChange({
        target: {
          name: "file",
          value: {
            path: res.path,
            url: res.url,
            filename,
          },
          dataset: {
            ...dataset,
            inputArray: false,
          },
        },
      });
    }
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name,
        value,
        dataset: {},
      },
    });
  }

  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  _onFormChangeFile(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  componentDidMount() {
    console.log(JSON.stringify(this.props));
  }
  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }
  render() {
    const { onFormChange, form, param, data } = this.props;
    const { value } = form;
    return (
      <div className="buat-pelanggaran__custom-form">
        <div className="buat-pelanggaran__custom-form-column">
          <div className="buat-pelanggaran__custom-form-column__field">
            <FileInput
              data-input-type="file"
              noMargin
              type="file"
              name="upload"
              label="Upload File"
              onChange={this.onUpload}
              onRemoveFile={this.removeFile}
              fileName={value.upload ? value.upload : ""}
              displayName={value.upload ? value.upload : "default"}
            />
            <Input
              type="text"
              name="name"
              label="Judul*"
              onChange={onFormChange}
              value={form.value.name}
              error={form.error.name || ""}
            />
            <Textarea
              type="text"
              name="description"
              label="Deskripsi"
              onChange={onFormChange}
              value={form.value.description}
              error={form.error.description || ""}
            />
            <InputDate
              type="date"
              name="publish_date"
              label="Tanggal Mulai*"
              onChange={onFormChange}
              value={form.value.publish_date}
              error={form.error.publish_date || ""}
            />
            <InputDate
              type="date"
              name="tgl_selesai"
              label="Tanggal Selesai*"
              onChange={onFormChange}
              value={form.value.tgl_selesai}
              error={form.error.tgl_selesai || ""}
            />
            <Select
              name="levels"
              disabled
              label="Pilih Unit*"
              placeholder={"Pilihan"}
              data={param.levels}
              onChange={onFormChange}
              value={form.value.levels}
              error={form.error.levels || ""}
            />
            {/* <Select
            name="classrooms"
            label="Pilih Kelas*"
            placeholder={"Pilihan"}
            data={param.classrooms}
            onChange={onFormChange}
            value={form.value.classrooms}
            error={form.error.classrooms || ''}
          /> */}
            <p>Pilih Kelas*</p>

            <SelectMultiple
              name="classrooms"
              style={{ width: "50%" }}
              options={param.classrooms}
              disable={form.value.levels ? false : true}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              //  selectedAll={this.selectAll()}
              values={form.value.classrooms}
              onRemove={(value) => this._onRemoveMulti(value, "classrooms")}
              // selectedValues={form.value.classrooms}
              onSelect={(value) => this._onChangeMulti(value, "classrooms")}
            />
            <p>Siswa</p>

            <SelectMultiple
              name="students"
              style={{ width: "50%" }}
              options={param.student}
              // disable	={form.value.classrooms?false:true}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              values={form.value.students}
              //  selectAllText="Select All"
              // ref={this.selectAllItems()}
              // selectedValues={form.value.students}
              // value={form.value.students}
              onRemove={(value) => this._onRemoveMulti(value, "students")}
              // error={form.error.students || ''}
              onSelect={(value) => this._onChangeMulti(value, "students")}
            />

            <p>Karyawan</p>

            <SelectMultiple
              name="teachers"
              style={{ width: "50%" }}
              options={param.teacher}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              values={form.value.teachers}
              //  selectAllText="Select All"
              // ref={this.selectAllItems()}
              // selectedValues={form.value.students}
              // value={form.value.students}
              onRemove={(value) => this._onRemoveMulti(value, "teachers")}
              // error={form.error.students || ''}
              onSelect={(value) => this._onChangeMulti(value, "teachers")}
            />
          </div>
        </div>
      </div>
    );
  }
}
JadwalKelasForm.propTypes = {
  onFormChange: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onUpload: PropTypes.func,
  showEdit: PropTypes.bool,
  classrooms: PropTypes.array,
  form: PropTypes.object.isRequired,
  student: PropTypes.array,
};
JadwalKelasForm.defaultProps = {
  student: [],
  classrooms: [],
  showEdit: true,
  onFormChange: noop,
  onRemoveFile: noop,
};
