import NilaiRaport from '../../../views/Kurikulum/Raport/NilaiRaport.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses,getParamOptionsAcademics, getPeriodsOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import {  listRaportSiswa, listRaportSubject} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
    return{
        classes: state.options.classes,
        result: state.result,
        user: state.user || {},
        levels: state.options.levels,
    }
}

function mapDispatchToProps(dispatch){ 
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        handleListRaportSiswa: (payload, goback) => dispatch(listRaportSiswa(payload, goback)),
        handleListRaportSubject: (payload, goback) => dispatch(listRaportSubject(payload, goback)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
        
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (NilaiRaport);