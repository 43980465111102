import React from "react";
import PropTypes from "prop-types";
import { noop, map, isEqual, isEmpty, capitalize } from "lodash";
import { Button, Pagination } from "../../components/base";
import BaseTable from "../../components/BaseTable/BaseTable.component";
import { formatData, normalizeAmount } from "../../utils/transformer.util";
import language from "../../languages";
import { Input, Select, SearchSelect } from "../../components/base/index";
import { errorAlert } from "../../utils/alert.util";
import { LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS } from "../../constants/Student/student.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ListPendaftaranPPDB extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickRutin = this.onClickRutin.bind(this);
    this.onClickTidakRutin = this.onClickTidakRutin.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.getYearsOfEducationsOptions =
      this.getYearsOfEducationsOptions.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this._getSchoolUnitsManageOptions =
      this._getSchoolUnitsManageOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // periods_id: "",
          levels: [],
          classrooms_id: "",
          units_id: "",
          user_id: user.id,
        },
        error: {
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    const { content, form, filters } = this.state;
    let savedForm = JSON.parse(window.localStorage.getItem("manageForm")) || "";
    let savedFilters =
      JSON.parse(window.localStorage.getItem("manageFilters")) || filters;
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    this._initializeData(savedFilters);
    if (savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedFilters.units_id ? savedFilters.units_id : "",
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedFilters.units_id ? savedFilters.units_id : "",
          // entrance_year : savedFilters.entrance_year,
        },
      }));

      // this._getParamOptionsAcademics("classrooms", {
      //   levels_id: savedForm.value.levels_id,
      //   classes_id: savedForm.value.classes_id,
      //   units_id: savedForm.value.units_id.value,
      //   organizations_id,
      // });

      if (user_group.name === "pusat") {
        this.getYearsOfEducationsOptions({
          units_id: savedFilters.units_id ? savedFilters.units_id.value : "",
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { content } = this.state;
    const { school_unit, user_group } = user;

    if (user_group.name !== "pusat") {
      await this.getYearsOfEducationsOptions({ units_id: school_unit.id });
      this._onSearchContent();
    } else {
      await this._getSchoolUnitsManageOptions();
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            // entrance_year: savedFilters.entrance_year,
          },
        }),
        () => {
          // commonActions.setLoading({ show: true });
          this._onSearchContent();
        }
      );
    }
  }

  async _getSchoolUnitsManageOptions(filters = {}) {
    const { getSchoolUnitsManageOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsManageOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _onChangeFilter(e) {
    const { user, getYearsOfEducationsOptions } = this.props;
    const { form, filters } = this.state;
    const { organizations_id, school_unit } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
        // param: {
        //   ...prevState.param,
        //   student: res,
        // },
      }),
      () => {
        window.localStorage.setItem(
          "manageForm",
          JSON.stringify(this.state.form)
        );
        window.localStorage.setItem(
          "manageFilters",
          JSON.stringify(this.state.filters)
        );
        if (name === "units_id") {
          this.getYearsOfEducationsOptions({ units_id: value.value });

          // const param = this.state.param.units;
          // const periods = param.find((i) => i.value == value.value)
          //   ? param.find((i) => i.value == value.value).periods
          //   : "";
          // console.log(periods, "Tahun Periods");

          // let periods_value = {};

          // map(periods, (data, idx) => {
          //   var date = new Date();
          //   var start_date = new Date(data.attributes.start_date);
          //   if (date > start_date) {
          //     // tahun ajaran selanjutnya
          //     if (periods[idx + 1]) {
          //       periods_value = periods[idx + 1].value;
          //     }
          //   }
          // });

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                units_id: value,
              },
            },
            filters: {
              ...prevState.filters,
              // entrance_year:''
              // periods: periods_value,
            },
          }));
        }
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { students_id = "", entrance_year, units_id } = filters;
    const {
      handleListPPDBOffline,
      user,
      handleListStudentsPPDBF07,
      handleListStudentsPPDB,
      handleListStudentsClassDivGeneral,
      handleListStudentsPPDBRicci,
      handleListPPDBOfflineUPD
    } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    const { levels_id } = school_unit;

    var year = "";
    if (entrance_year) {
      year = entrance_year.toString().substring(2);
    }
    // let ent = JSON.parse(window.localStorage.getItem('entranceYear')) || "";
    // let savedForm = JSON.parse(window.localStorage.getItem('manageForm')) || "";
    // let savedFilters = JSON.parse(window.localStorage.getItem('manageFilters')) || filters;
    // console.log(ent)


    console.log(this.state.param.units);
    console.log(units_id);

    let periods_value = {};
    if (user_group.name == "pusat") {
      const param = this.state.param.units;
      const periods = units_id ?
        param.find((i) => i.value == units_id.value)
          ? param.find((i) => i.value == units_id.value).periods
          : ""
        : "";
      console.log(periods, "Tahun Periods");

      map(periods, (data, idx) => {
        var date = new Date();
        var start_date = new Date(data.attributes.start_date);
        if (date > start_date) {
          // tahun ajaran selanjutnya
          if (periods[idx + 1]) {
            periods_value = periods[idx + 1].value;
          }
        }
      });
    }

    const periods_year = periods_value
      ? periods_value.toString().substring(2)
      : "";
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {

          const subdomain_pst = units_id ? units_id.attributes.subdomain : "";
          const ppdb_online_pst = units_id
            ? units_id.attributes.ppdb_online
            : "";
          const ppdb_name_pst = units_id ? units_id.attributes.ppdb_name : "";
          const unit_code_pst = units_id ? units_id.attributes.unit_code : "";
          const units_id_pst = units_id ? units_id.value : "";

          const ppdb_online =
            user_group.name == "pusat"
              ? ppdb_online_pst
              : school_unit.ppdb_online;
          const subdomain =
            user_group.name == "pusat" ? subdomain_pst : school_unit.subdomain;
          const unit_code =
            user_group.name == "pusat" ? unit_code_pst : school_unit.unit_code;

          let result = {};
          if (school_unit.ppdb_online == 0 && organizations_id == 3) {
            result = await handleListPPDBOfflineUPD({
              ...params,
              year: year,
              organizations_id: organizations_id,
              units_id:
                user_group.name == "pusat" ? units_id_pst : school_unit.id,
            });
          } else if (school_unit.ppdb_online == 0 && organizations_id !== 3) {
            result = await handleListPPDBOffline({
              ...params,
              year: year,
              organizations_id: organizations_id,
              units_id:
                user_group.name == "pusat" ? units_id_pst : school_unit.id,
            });
          }
          else if (
            school_unit.ppdb_online == 1 &&
            school_unit.unit_code == "F07" &&
            school_unit.subdomain == null
          ) {
            result = await handleListStudentsPPDBF07({
              ...params,
              year: year,
              organizations_id: organizations_id,
              units_id:
                user_group.name == "pusat" ? units_id_pst : school_unit.id,
            });
          } else if (
            school_unit.ppdb_online == 1 &&
            school_unit.unit_code !== "F07" &&
            school_unit.subdomain == null &&
            organizations_id == 3
          ) {
            result = await handleListStudentsPPDB({
              ...params,
              // year: year,
              organizations_id: organizations_id,
              units_id:
                user_group.name == "pusat" ? units_id_pst : school_unit.id,
              filters: { year: year },
            });
          } else if (
            school_unit.ppdb_online == 1 &&
            school_unit.subdomain !== null &&
            school_unit.unit_code !== "F07"
          ) {
            result = await handleListStudentsClassDivGeneral({
              ...params,
              filters,
              year: year,
              subdomain:
                user_group.name == "pusat"
                  ? subdomain_pst
                  : school_unit.subdomain,
            });
          } else if (
            (school_unit.ppdb_online == 1 &&
              school_unit.subdomain == null &&
              school_unit.unit_code !== "F07" &&
              organizations_id == 4) ||
            organizations_id == 5
          ) {
            result = await handleListStudentsPPDBRicci({
              ...params,
              // year: year,
              type:
                user_group.name == "pusat"
                  ? ppdb_name_pst
                  : school_unit.ppdb_name,
              organizations_id: organizations_id,
              units_id:
                user_group.name == "pusat" ? units_id_pst : school_unit.id,
              filters:
                user_group.name == "pusat"
                  ? { year: periods_year }
                  : { year: year },
            });
          }

          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage: 1,
              total: 1,
              totalPage: 1,
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  async getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions, user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    const { form } = this.state;
    const { units_id } = form.value;
    // let savedForm = JSON.parse(window.localStorage.getItem("manageForm")) || "";
    // let savedFilters =
    //   JSON.parse(window.localStorage.getItem("manageFilters")) || filters;

    // console.log(savedFilters.units_id)
    // console.log(savedForm)

    // if (user_group.name !== "pusat") {
    //   filters.organizations_id = organizations_id;
    //   {
    //     savedFilters.units_id
    //       ? (filters.units_id = savedFilters.units_id.value)
    //       : (filters.units_id = school_unit.id);
    //   }
    // }
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date);
      if (date > start_date) {
        // tahun ajaran selanjutnya
        if (res[idx + 1]) {
          this.setState((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              entrance_year: res[idx + 1].value,
            },
          }));
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { school_unit, organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id,
          });
        }
      }
    );
  }

  handleGetData(payload = {}) {
    const { handleListPPDBOffline, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile, organizations_id } =
      user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    // const res = await handleListPPDBOffline({
    //   ...payload,
    //   user_id: id,
    //   organizations_id: organizations_id,
    // });

    this.setState({
      // list: res,
    });
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions, user } = this.props;
    const { school_unit } = user;
    const res = await getStudentOptions(filters);
    filters.units_id = school_unit.id;
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran-ppdb");
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran-ppdb", {
      data: val,
      isEdit: true,
    });
  }

  onClickRutin(val) {
    const { history } = this.props;
    const { form } = this.state;
    val.units_id = form.value.units_id.value;

    if (val.no_va == "" || val.no_va == null) {
      errorAlert({
        title: "No VA belum tersedia.",
        errorMessage: "Mohon masukkan nomor pendaftaran di Data Siswa",
      });
    } else {
      history.push("/dashboard/tagihan-siswa/create-invoice/rutin/ppdb", {
        data: val,
        units: form.value.units_id,
      });
    }
  }

  onClickTidakRutin(val) {
    const { history } = this.props;
    const { form } = this.state;
    val.units_id = form.value.units_id.value;

    if (val.no_va == "" || val.no_va == null) {
      errorAlert({
        title: "No VA belum tersedia.",
        errorMessage: "Mohon masukkan nomor pendaftaran di Data Siswa",
      });
    } else {
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin", {
        data: val,
      });
    }
  }

  onClickTidakRutinRICCI(val) {
    const { history } = this.props;
    const { form } = this.state;
    val.units_id = form.value.units_id.value;
    if (val.no_va == "" || val.no_va == null) {
      errorAlert({
        title: "No VA belum tersedia.",
        errorMessage: "Mohon masukkan nomor pendaftaran di Data Siswa",
      });
    } else {
      history.push(
        "/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general",
        {
          data: val,
        }
      );
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { filters } = this.state;
    const { form } = this.state;
    const { user } = this.props;
    const { school_unit, organizations_id } = user;

    data.name = data.fullname;
    data.entrance_year = filters.entrance_year;

    const dataTrInvoices = data.tr_invoices && data.tr_invoices.length !== 0 ? true : false;
    const org_ypl = organizations_id == 3 && dataTrInvoices == true ? true : false;

    return (
      <td>
        <div className="table__actions">
          {data.upload ? (
            <Button
              onClick={() => this.onClickDownload(data)}
              title="Download"
            />
          ) : (
            <div></div>
          )}
          {/* {user_group.id === 2? */}
          {school_unit.ppdb_online == 0 ? (
            <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          ) : (
            ""
          )}
          <Button
            onClick={() => this.onClickRutin(data)}
            title={organizations_id == 3 ? "SPP" : "RUTIN"}
            disabled={org_ypl == true ? true : false}
          // {organizations_id == 3
          //   ?
          //   disabled = {data.tr_invoices?data.tr_invoices.length:''}
          //   :''
          // }

          />
          <Button
            onClick={() =>
              organizations_id == 3
                ? this.onClickTidakRutin(data)
                : this.onClickTidakRutinRICCI(data)
            }
            title={organizations_id == 3 ? "DPP/UPP" : "NON RUTIN"}
            disabled={org_ypl == true ? true : false}


          />
        </div>
      </td>
    );
  }

  render() {
    const { list = {}, content, param, filters, form } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group, school_unit } = user;
    const { permissions } = user_group;
    const { value } = form;

    const dataset = {
      toggle: "modal",
    };
    // window.localStorage.setItem('entranceYear', JSON.stringify(filters.entrance_year));

    return (
      <div className="fund-request">
        <div className="beginning-balance__button-wrapper">
          {school_unit.ppdb_online == 0 ? (
            <Button
              title="Tambah Siswa"
              onClick={this.onClickAdd}
              data={dataset}
            />
          ) : (
            ""
          )}
        </div>
        <br></br>
        <div className="isi-bidang__custom-form">
          {user_group.name === "pusat" ? (
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="units_id"
                  list={param.units}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Unit"
                  value={form.value.units_id}
                  rightIcon="icon-search"
                  label="Pilih Unit"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="isi-bidang__custom-form-column">
            <div className="isi-bidang__custom-form-row__field">
              <Select
                name="entrance_year"
                label="Tahun Ajaran"
                placeholder={"Pilihan"}
                onChange={this._onChangeFilter}
                data={param.periods}
                value={filters.entrance_year}
                disabled
              />

              <div className="isi-bidang__custom-form-row__field"></div>
              {/* <SearchSelect
                  noMargin
                  async={false}
                  name="students_id"
                  list={param.student}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Nama Siswa"
                  value={filters.students_id}
                  // labelName="label"
                  // valueName="value"
                  rightIcon="icon-search"
                  label= "Nama Siswa"
                  /> */}
            </div>
          </div>
        </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td
                            style={{ textAlign: "center" }}
                            key={`table_index_${fieldIdx}_${idx}`}
                          >
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "nisn") {
                        return (
                          <td style={{ textAlign: "center" }}>{data.nisn}</td>
                        );
                      }
                      if (field.attribute === "no_va") {
                        return (
                          <td style={{ textAlign: "center" }}>{data.no_va}</td>
                        );
                      }
                      if (field.type === "link") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <a
                              href={"https://" + data.hyperlink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.hyperlink}
                            </a>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      if (field.attribute === "fullname") {
                        return (
                          <td
                            key={`table_index_${fieldIdx}_${idx}`}
                            style={{ textTransform: "capitalize" }}
                          >
                            {capitalize(data.fullname)}
                          </td>
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(data, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
ListPendaftaranPPDB.propTypes = {
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
ListPendaftaranPPDB.defaultProps = {
  handleDelete: noop,
  user: null,
};
