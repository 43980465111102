import React, { PureComponent } from "react";
import PropTypes, { string } from "prop-types";
import { noop, isEmpty } from "lodash";
import { Button, Input } from "../../../components/base";
import DataHonorTidakRutinForm from "../components/DataHonorTidakRutinForm.component";
import DataPotonganYayasanForm from "../components/DataPotonganYayasanForm.component";
import DataPotonganDiLuarYayasanForm from "../components/DataPotonganDiLuarYayasanForm.component";

export default class PayrollSlipInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {
          pph21: "0",
          honor_tidak_rutin: [],
          potongan_yayasan: [],
          potongan_luar_yayasan: [],
        },
        error: {
          pph21: "",
          honor_tidak_rutin: [],
          potongan_yayasan: [],
          potongan_luar_yayasan: [],
        },
      },
    };
  }

  componentDidMount() {
    this.setInitInputData();
  }

  setInitInputData = async () => {
    const { handleGetPayrollData } = this.props;
    const { nip, payroll_id } = this.props.location.state.data;
    const data = await handleGetPayrollData({
      nip: nip,
      id_payroll: payroll_id,
    });
    console.log(data)
    let temp_potongan_yayasan = [
      { name: "Pot.Kesehatan", nominal: data.pot_restitusi },
      {
        name: "Pot.BPJS Kesehatan",
        nominal: data.pot_bpjs_kesehatan,
      },
      { name: "Pot.BPJS Ketenagakerjaan JP", nominal: data.pot_jp },
      { name: "Pot.BPJS Ketenagakerjaan JHT", nominal: data.pot_jht },
      { name: "Pot.Yadapen", nominal: data.pot_yadapen },
      { name: "Pot.IDP", nominal: data.idp },
      { name: "Pot.IDS", nominal: data.ids },
      { name: "Pot.IDS Kematian", nominal: data.idsk },
      { name: "Pot.Khalwat", nominal: data.khalawat },
    ];

    const dataset = { ...this.state.form };
    dataset.value.potongan_yayasan = temp_potongan_yayasan;
    this.setState({ form: dataset });
  };

  _onFormChange = (event) => {
    const { name, value, dataset, checked, type, label } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputArray) {
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;

        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  };

  //honor tidak rutin
  _onClickAddListData = (fieldName) => {
    const { form } = this.state;
    var temp = this.state.form.value[fieldName];
    temp.push({
      name: "",
      nominal: 0,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          fieldName,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  };

  _onDeleteListData = (idx, fieldName) => {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];

      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          [fieldName]: list,
        },
      };
    });
  };

  //umum
  _onClickBack = () => {
    const { history, location } = this.props;
    let val = location.state.data;
    history.push(`/dashboard/personalia/laporan/slip-gaji/${val.nip}`, {
      data: val,
    });
  };

  _onSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { handleStorePayrollData, history, location } = this.props;
    const { nip, payroll_id } = this.props.location.state.data;
    const { isEdit = false } = location.state;
    console.log(history.goBack);
    // console.log(this.state)
    if (isEdit) {
    } else {
      handleStorePayrollData(
        { nip: nip.nip, id_payroll: payroll_id, ...form.value },
        history.goBack,
        isEdit
      );
    }
  };

  render() {
    const { form } = this.state;
    const { value, error } = form;
    return (
      <div className="manage-information-letter">
        <div>
          <h1 className="manage-information-letter__title">Slip Gaji</h1>
        </div>
        <Input
          type="number"
          name="pph21"
          value={value.pph21}
          label="Pph21"
          onChange={this._onFormChange}
        />
        <p>Honor Tidak Rutin</p>
        <DataHonorTidakRutinForm
          fieldName={"honor_tidak_rutin"}
          onAddList={this._onClickAddListData}
          onChange={this._onFormChange}
          onDeleteList={this._onDeleteListData}
          list={value.honor_tidak_rutin}
        />
        <p>Potongan Yayasan</p>
        <DataPotonganYayasanForm
          fieldName={"potongan_yayasan"}
          onAddList={this._onClickAddListData}
          onChange={this._onFormChange}
          onDeleteList={this._onDeleteListData}
          list={value.potongan_yayasan}
        />
        <p>Potongan Di Luar Yayasan</p>
        <DataPotonganDiLuarYayasanForm
          fieldName={"potongan_luar_yayasan"}
          onAddList={this._onClickAddListData}
          onChange={this._onFormChange}
          onDeleteList={this._onDeleteListData}
          list={value.potongan_luar_yayasan}
        />
        <form onSubmit={this._onSubmit}>
          <div>
            <Button type="submit" title="Simpan" />
          </div>
        </form>
        <br />
        <Button title="Kembali" onClick={() => this._onClickBack()} />
        <br />
      </div>
    );
  }
}
