import { connect } from 'react-redux';
import LaporanPutusSekolah from '../../../views/Akademi/LaporanIndividual/LaporanPutusSekolah.view';
import { getParamOptionsAcademics, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listReportPutusSekolah, getHeadmaster } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
    return{
        user: state.user,
        result: state.result,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleListReportPutusSekolah: (payload) => dispatch(listReportPutusSekolah(payload)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
        getHeadmaster: (payload) => dispatch(getHeadmaster(payload)),
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPutusSekolah);