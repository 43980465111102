import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { LAPORAN_ABSEN } from "../../../constants/student.constant";
import { ABSENSI_REKAP_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../../components/base/index";
import LaporanAbsensiTable from "../components/LaporanAbsensiTable.component";
import { STUDENT_TABLE_UNIT_FIELDS } from "../../../constants/student.constant";
import language from "../../../languages";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JumlahSiswaKls extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this.onClickCetak = this.onClickCetak.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getParamPerwakilan = this._getParamPerwakilan.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onClickExcel = this._onClickExcel.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          total_siswa: "",
          classes: [],
          levels_id,
          listsiswa: [],
          classes_id: "",
          user_id: user.id,
          units_id: "",
          perwakilan_id: "",
        },
        error: {
          classes: "",
          levels: "",
          listsiswa: "",
        },
      },
      param: {},
      filtered: false,
      total_siswa: "",
    };
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetClasses,
      handleGetLevels,
      user,
    } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;

    let savedFilters =
      JSON.parse(window.localStorage.getItem("jumlahFilters")) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem("jumlahForm")) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    this._initializeData(savedFilters);
    if (savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id,
            classes_id: savedForm.value.classes_id,
            // period: filters.period,
            // update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id,
        },
      }));
      this._getParamOptionsAcademics("classrooms", {
        levels_id: savedForm.value.levels_id,
        classes_id: savedForm.value.classes_id,
        units_id: savedForm.value.units_id.value,
        organizations_id,
      });

      if (user_group.name === "pusat" || user_group.name === "perwakilan") {
        this._getClassesOptions({
          filters: {
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id.value,
          },
        });
        this._getPeriodsOptions({ units_id: savedForm.value.units_id.value });
        this._getParamPerwakilan();
      }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    const paramTypes = ["levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });

    if (user_group.name !== "pusat") {
      // const paramTypes = ["classrooms", "levels"];
      // paramTypes.forEach((type) => {
      //   this._getParamOptionsAcademics(type);
      // });
      this._getClassesOptions({
        filters: { levels_id: [levels_id], units_id: [school_unit.id] },
      });
      await this._getPeriodsOptions({ units_id: school_unit.id });
    }
    if(user_group.name == 'perwakilan'){
      await this._getSchoolUnitsOptions({perwakilan_id: school_unit.perwakilan_id})
    }
    else{
    await this._getSchoolUnitsOptions();
    await this._getParamPerwakilan();
    }
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          period: savedFilters.period,
          classrooms_id: savedFilters.classrooms_id,
          // savedFilters
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  onClickCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit", {
      isEdit: true,
    });
  }

  _onClickExcel() {
    const { history } = this.props;
    const { form, filters } = this.state;
    history.push(
      "/dashboard/kesiswaan/laporan/daftar-siswa-aktif/cetak-excel",
      {
        isEdit: true,
        data: {
          classes_id: form.value.classes_id,
          classrooms_id: filters.classrooms_id,
        },
      }
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { school_unit, organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  // on formchange untuk form
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    // if (user_group.name !== 'pusat'){

    // filters.levels_id = levels_id;
    // filters.units_id = school_unit.id;
    // }
    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }
  async _getParamPerwakilan(filters = {}) {
    const { getParamPerwakilan, user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    const res = await getParamPerwakilan(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        perwakilan_prm: res,
      },
    }));
  }


  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    // map(res, (data, idx) => {
    //   var date = new Date();
    //   var start_date = new Date(data.attributes.start_date)
    //   if (date > start_date) {
    //       // tahun ajaran sekarang
    //       this.setState(prevState => ({
    //         ...prevState,
    //         filters: {
    //           ...prevState.filters,
    //           period: data.value,
    //         }
    //       }))
    //   }
    // });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }


  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (name === "classes_id") {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              classrooms_id: "",
            },
          }));
        }

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        // if (name === "levels_id") {
        //   this._getClassesOptions({ filters: { levels_id: [value] } });
        // }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id: organizations_id,
          });
        }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id, classrooms_id, period } = value;
    const { units_id, perwakilan_id } = filters;

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListStudentCountClass,
            handleListStudentCountUnits,
            handleListStudentCountPerwakilan,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id } = school_unit;
          const units =
            user_group.name == "pusat" || user_group.name == "perwakilan"? units_id.value : school_unit.id;
            const perwakilanValue = perwakilan_id?perwakilan_id.value:'';
            // const perwakilan = user_group.name == "perwakilan" ?school_unit.perwakilan_id : perwakilan_id.value;
            let result = {};
            console.log(school_unit.perwakilan_id)
            console.log(perwakilan_id)
            console.log(user_group.name == "perwakilan" ?school_unit.perwakilan_id : perwakilanValue)
          if(!units_id && filters.period != 'undefined'){
            console.log("test 1")

            result = await handleListStudentCountClass({
              ...params,
              organizations_id: organizations_id,
              units_id: units,
              period: filters.period,
              filters,
            });
          } else if(units_id.value !== "all")  {
            console.log("test 2")
            result = await handleListStudentCountClass({
              ...params,
              organizations_id: organizations_id,
              units_id: units,
              period: filters.period,
              filters,
            });
          } else if(perwakilanValue === "all" && units_id.value === "all" ) {
            result = await handleListStudentCountPerwakilan({
              ...params,
              organizations_id: organizations_id,
              units_id: units,
              period: filters.period,
              filters,
            });
          }
           else if(units_id.value === "all") {
            console.log("test 3")
            result = await handleListStudentCountUnits({
              ...params,
              organizations_id: organizations_id,
              period: filters.period,
              perwakilan_id: user_group.name == "perwakilan" ?school_unit.perwakilan_id : perwakilanValue,
              filters,
            });
          }
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
            console.log("masuk C")
            console.log(err)
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem(
          "jumlahFilters",
          JSON.stringify(this.state.filters)
        );
        window.localStorage.setItem(
          "jumlahForm",
          JSON.stringify(this.state.form)
        );
        if (name == "period" && user_group.name !== 'pusat') {
          this._onSearchContent();
        }
        

        if (name === 'perwakilan_id'){
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                perwakilan_id: value,
                units_id: "",
              },
            },
          }));
          this._getSchoolUnitsOptions({perwakilan_id: value.value})
          // if(value.value == 'all'){
          // this._onSearchContent();
          // }
        }
        if (name === "units_id") {
          const param = this.state.param.units;
          const levels_units = param.find((i) => i.value == value.value)
            ? param.find((i) => i.value == value.value).attributes.levels_id
            : "";

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                levels_id: levels_units,
                units_id: value,
              },
            },
          }));
          this._getParamOptionsAcademics("classrooms", {
            levels_id: levels_units,
            organizations_id: organizations_id,
            units_id: value.value,
          });
          this._getClassesOptions({
            filters: { levels_id: [levels_units], units_id: [value.value] },
          });

          this._getPeriodsOptions({ units_id: value.value });
        }

        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran", {
      data: val,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
        </div>
      </td>
    );
  }

  renderExcel(listsiswa) {
    const { form } = this.state;
    const { value } = form;

    let excelTbl = [];
    map(listsiswa, (list, idx) => {
      let items = Object.create({});
      items.name = list.name
      items.unit_code = list.unit_code
      items.total_student = list.total_student
      
      excelTbl.push(items);
    });

    let rows = [];
    rows.push([
      form.value.units_id.value == 'all'?

      <ExcelFile
        element={
          <Button style={{ height: 30, width: 400, marginRight: 9 }}>
            {" "}
            Ekspor Excel
          </Button>
        }
      >
        <ExcelSheet data={excelTbl} name="excel">
            <ExcelColumn
            style={{ fontWeight: "bold" }}
            label="Kelas"
            value="name"
          />
            <ExcelColumn
            style={{ fontWeight: "bold" }}
            label="Kode Unit"
            value="unit_code"
            />
            <ExcelColumn
            style={{ fontWeight: "bold" }}
            label="Jumlah Siswa"
            value="total_student"
          />
        </ExcelSheet>
      </ExcelFile> 
      :
      
      <ExcelFile
        element={
          <Button style={{ height: 30, width: 400, marginRight: 9 }}>
            {" "}
            Ekspor Excel
          </Button>
        }
      >
        <ExcelSheet data={excelTbl} name="excel">
          <ExcelColumn
          style={{ fontWeight: "bold" }}
          label="Kelas"
          value="name"
          />
          <ExcelColumn
          style={{ fontWeight: "bold" }}
          label="Jumlah Siswa"
          value="total_student"
          />
          </ExcelSheet>
        </ExcelFile>

    ]);
    return rows;
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
    } = this.state;
    const { listsiswa } = form.value;
    const { classrooms_id = "", status_id = "", period = "", units_id } = filters;
    // console.log(total_siswa);
    const { classes = {}, levels = {}, user } = this.props;
    const { school_unit, user_group } = user;

    const All = [{value: 'all', label: "Semua Perwakilan", attributes: {}}]
    const AllUnit = [{value: 'all', label: "Semua Unit", attributes: {}}]
    let total = 0;
    let totalres = 0;

    const componentRef = React.createRef();
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    const units_label = units_id?units_id.label:'';
    const prm_period = param.periods ? param.periods : '';
    const prm_units = param.units ? param.units : '';
    const prm_perwakilan= param.perwakilan_prm ? param.perwakilan_prm : '';
    const period_label = prm_period?prm_period.find((i) => i.value == period)?prm_period.find((i) => i.value == period).label:'':'';
    const units_all = [...AllUnit, ...prm_units]
    const perwakilan_all = [...All, ...prm_perwakilan]
    const years = []
    const thisyear = new Date().getFullYear();
    for(var i=2021; i<=thisyear; i++) {
      var nextyear=Number(i)+1
      years.push({
        label: i+"-"+nextyear,
        value: i,
      });
    }

    const period_label_all = years.find(i => i.value == period)?years.find(i => i.value == period).label:''
   
    return (
      <div className="manage-registration">
        <h1>Jumlah Siswa</h1>
        <div className="absensi-rekap__custom-form">
          {user_group.name === "pusat" || user_group.name === "perwakilan" ? (
            <div>
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              {user_group.name == "pusat"?
                <SearchSelect
                  noMargin
                  async={false}
                  name="perwakilan_id"
                  list={perwakilan_all}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Perwakilan"
                  value={form.value.perwakilan_id}
                  rightIcon="icon-search"
                  label="Pilih Perwakilan"
                />
               :''}
              </div>
            </div>
            
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="units_id"
                  list={units_all}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Unit"
                  value={form.value.units_id}
                  rightIcon="icon-search"
                  label="Pilih Unit"
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={form.value.units_id.value === 'all' ? years:param.periods}
                  placeholder={"Pilihan"}
                  value={period}
                />
              </div>
            </div>
            </div>
          ) : (
            <>
              <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                  <Select
                    name="period"
                    label="Tahun Ajaran"
                    onChange={this._onChangeFilter}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={period}
                  />
                </div>
                <div className="absensi-rekap__custom-form-column__field">
                  <Select
                    type="text"
                    name="levels_id"
                    label="Unit"
                    placeholder="Pilih Unit"
                    data={param.levels}
                    onChange={this._onFormChange}
                    value={form.value.levels_id}
                    disabled
                  />
                </div>
              </div>
            </>
          )}
          
        </div>
        <div className="beginning-balance__button-wrapper">
          <ReactToPrint
          pageStyle={printTest}
          onBeforeGetContent={()=> {
            return new Promise((resolve) => {
              setTimeout(() => {
                this.setState(
                  { isPrint: false },
                  resolve
                );
              }, 500);
            });
          }}
          onAfterPrint={()=> this.setState({isPrint: true})}
            trigger={() => (
                <Button title="Cetak" />
            )}
            content={() => componentRef.current}
          />
          {/* <Button
              title="Ekspor Excel"
              onClick={this._onClickExcel}
            /> */}
            &nbsp;
            {form.value.perwakilan_id.value == 'all'?
            <div style={{marginLeft: '0rem'}} className="student-list__button">
              <ReactHTMLTableToExcel
                id="export-to-xls-button"
                className="button "
                table="printable"
                filename={`Jumlah Siswa Semua Perwakilan Tahun ajaran ${period_label_all}`}
                sheet={period_label_all}
                buttonText="Unduh ke Excel"
              />
            </div>
          :
            <div style={{marginLeft: '0rem'}} className="student-list__button">
              {this.renderExcel(listsiswa)}
            </div>
            }
            
              
          </div>
        {period ? (
        <form style={{ pageBreakInside: 'avoid', paddingTop: '20px' }} ref={componentRef}>
          <div className="manage-registration">
          <table className="laporan-ki4__table-test" style={{width:"unset"}}>
            <tr>
              <td>Jumlah Total Siswa {user_group.name === 'pusat' || user_group.name === 'perwakilan' ? units_label : school_unit.name} </td>
            </tr>
            <tr>
              <td>Tahun Ajaran {form.value.units_id.value == 'all' ? period_label_all : period_label}</td>
            </tr>
          </table>
            <div className="fund-request__content">
              {form.value.perwakilan_id.value == 'all'?
               <table className="table" id="printable" ref={componentRef}>
                <thead>
                 {/* <tr className="grey">
                 <th colSpan={5}>{list.name}</th>
                 
                </tr> */}
                 <tr className="grey">
                 <th>Kode Perwakilan</th>
                 <th>Nama Perwakilan</th>
                 <th>Kode Unit</th>
                 <th>Nama Unit</th>
                 <th>Jumlah Siswa</th>
                 
                </tr>
               </thead>
               {map(listsiswa, (list, idx) => (
                <>
                <tbody>      
                  {console.log(list.res)}
                  <tr>
                  <td colSpan={5}>{list.name}</td>

                  </tr>  
                <tr key={`budget_row_${idx}`}>
                  <td rowSpan={list.res?list.res.length+1:0}>{list.code}</td>
                  <td rowSpan={list.res?list.res.length+1:0}>{list.name}</td>
                </tr>
                {(() => {
                  totalres = 0
                })()}
                {map(list.res, (res) => (
                  <tr>
                    <td>{res.unit_code}</td>
                    <td>{res.name}</td>
                    <td>{res.total_student}</td>
                  {(() => {
                  totalres = totalres + res.total_student
                  total = total + res.total_student
                })()}
                </tr>
                ))}
                 <tr>
                     <td colSpan={4}>Total Siswa {list.name}</td>
                     <td>{totalres}</td>
                   </tr>
                </tbody>
             
                   </>
                 ))}
                   <tr>
                     <td colSpan={4}>Total Jumlah Siswa</td>
                     <td>{total}</td>
                   </tr>
               
             </table>
             :
             <table className="table" ref={componentRef}>
             <thead>
               <tr className="grey">
                <th>No.</th>
                {form.value.units_id.value == 'all'?
                   <th>Nama Unit</th>
                   :
                   <th>Kelas</th>
                   }
                
                {form.value.units_id.value == 'all'?
                   <th>Kode Unit</th>
                   :''
                   }
                <th>Jumlah Siswa</th>
               </tr>
             </thead>
             <tbody>
               {console.log(listsiswa)}
               {map(listsiswa, (list, idx) => (
                
                 <tr key={`budget_row_${idx}`}>
                 
                   <td>{idx + 1}</td>
                   <td>{list.name}</td>
                   {form.value.units_id.value == 'all'?
                   <td>{list.unit_code}</td>
                   :''
                   }
                   <td>{list.total_student}</td>
                   {(() => {
                   total = total + list.total_student
                 })()}
                 </tr>
               ))}
                 <tr>
                   <td colSpan={form.value.units_id.value == 'all'?3:2}>Total Jumlah Siswa</td>
                   <td>{total}</td>
                 </tr>
             </tbody>
           </table>
              }
             
            </div>
          </div>
      </form>

        ) : (
          ""
        )}
      </div>
    );
  }
}
JumlahSiswaKls.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
JumlahSiswaKls.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
