import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';

export default class ViewAdjustmentJournal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: '',
      source: {},
      journal_number: '',
      source_details: [],
      corrections: [],
      reversal: [],
      total_reversal_debit: 0,
      total_reversal_credit: 0,
      total_correction_debit: 0,
      total_correction_credit: 0,
      total_debit: 0,
      total_credit: 0,
      corrected_ids: [],
    };
  }

  async componentDidMount() {
    const { handleGetAdjustmentJournal, location } = this.props;
    const { journal_number } = location.state;
    const res = await handleGetAdjustmentJournal({ journal_number });

    this.setState(res);
  }

  render() {
    const {
      date, source,
      source_details, corrections, reversal,
      journal_number, total_debit, total_credit,
      total_correction_debit, total_correction_credit,
      total_reversal_debit, total_reversal_credit,
      corrected_ids,
    } = this.state;

    return (
      <div className="view-adjustment-journal">
        <div className="row">
          <div className="col-xs-6">
            <p><b>Tanggal Jurnal</b>
              &nbsp;{ dateFormat(date, 'D-M-Y') }
            </p>
          </div>
          <div className="col-xs-6">
            <p><b>Nomor Jurnal</b>
              &nbsp;{journal_number}
            </p>
          </div>
        </div>
        <table className="view-adjustment-journal-table-container">
          <tr>
            <td width="50%">
              <p><b>Jurnal Sumber</b> {source.journal_number}</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Kode</th>
                    <th>Keterangan</th>
                    <th>Debit</th>
                    <th>Kredit</th>
                  </tr>
                </thead>
                <tbody>
                  {source_details.map(journal => ((
                    <tr className={corrected_ids.includes(journal.id) ? 'selected' : ''}>
                      <td>
                        {journal.code_of_account}
                      </td>
                      <td>
                        {journal.description}
                      </td>
                      <td className="nominal">
                        {commaFormatted(journal.debit)}
                      </td>
                      <td className="nominal">
                        {commaFormatted(journal.credit)}
                      </td>
                    </tr>
                  )))
                  }
                </tbody>
                <tfoot>
                  <tr className="total">
                    <td colSpan="2">Total</td>
                    <td className="nominal">{commaFormatted(total_debit)}</td>
                    <td className="nominal">{commaFormatted(total_credit)}</td>
                  </tr>
                </tfoot>
              </table>
            </td>
            <td width="50%">
              <p><b>Jurnal Pembalik</b> {journal_number}</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Kode</th>
                    <th>Keterangan</th>
                    <th>Debit</th>
                    <th>Kredit</th>
                  </tr>
                </thead>
                <tbody>
                  {reversal.map(journal => ((
                    <tr>
                      <td>
                        {journal.code_of_account}
                      </td>
                      <td>
                        {journal.description}
                      </td>
                      <td className="nominal">
                        {commaFormatted(journal.debit)}
                      </td>
                      <td className="nominal">
                        {commaFormatted(journal.credit)}
                      </td>
                    </tr>
                  )))
                  }
                </tbody>
                <tfoot>
                  <tr className="total">
                    <td colSpan="2">Total</td>
                    <td className="nominal">{commaFormatted(total_reversal_debit)}</td>
                    <td className="nominal">{commaFormatted(total_reversal_credit)}</td>
                  </tr>
                </tfoot>
              </table>
              <p><b>Jurnal Koreksi</b> {journal_number}</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Kode</th>
                    <th>Keterangan</th>
                    <th>Debit</th>
                    <th>Kredit</th>
                  </tr>
                </thead>
                <tbody>
                  {corrections.map(journal => ((
                    <tr>
                      <td>
                        {journal.code_of_account}
                      </td>
                      <td>
                        {journal.description}
                      </td>
                      <td className="nominal">
                        {commaFormatted(journal.debit)}
                      </td>
                      <td className="nominal">
                        {commaFormatted(journal.credit)}
                      </td>
                    </tr>
                  )))
                  }
                </tbody>
                <tfoot>
                  <tr className="total">
                    <td colSpan="2">Total</td>
                    <td className="nominal">{commaFormatted(total_correction_debit)}</td>
                    <td className="nominal">{commaFormatted(total_correction_credit)}</td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

ViewAdjustmentJournal.propTypes = {
  handleGetAdjustmentJournal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
