import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';
import data_kelas from '../../assets/img/akademi/administrasi/data-kelas.png';

export default class Finance extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickBudgeting = this.onClickBudgeting.bind(this);
    this.onClickLaporanIndividual = this.onClickLaporanIndividual.bind(this);
  }

  onClickBudgeting() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas');
  }

  onClickLaporanIndividual() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/laporan-individual-sekolah');
  }

  render() {
    return (
      <div className="finance">
        {/* <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={data_kelas} alt="ManajemenKelas" />
            <h3>Manajemen Kelas</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickLaporanIndividual}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Laporan Individual Sekolah</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
Finance.propTypes = {
  history: PropTypes.object.isRequired,
};
