import React, { PureComponent } from "react";
import PropTypes, { string } from "prop-types";
import { noop,isEmpty } from "lodash";
import Button from "../../../components/base/Button/Button.component";
import {Input, Select,Textarea2} from '../../../../src/components/base';
import TembusanForm from '../components/DataTembusanSKForm.component';
import {validateSTForm} from '../../../utils/validation.util';
import { errorAlert } from '../../../../src/utils/alert.util';
import { format } from "date-fns";

export default class ManageInformationLetter extends PureComponent {
  constructor(props) {
    super(props);
    const date = new Date();
    const formatdate = format(date, "yyyy-MM-dd");
    this._onFormChange = this._onFormChange.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      disabled: true,
      created_by: '',
      nip : undefined,
      datades : undefined,
      datagol : undefined,
      datajabatan : undefined,
      datakepegawaian: undefined,
      datapegawai: undefined,
      dataaktifnonaktif: undefined,
      create_by : undefined,
      form: {
        value: {
          id_st_description:'',
          //keterangan_sk:'',
          tanggal_pembuatan_st:formatdate,
          nama_ketua_yayasan : '',
          //pangkat_golongan:'',
          //pangkat :'',
          status_kepegawaian : '',
          //aktif_non_aktif : '',
          //tanggal_berlaku_sk:formatdate,
          //lama_bekerja_tahun:'',
          //lama_bekerja_bulan:'',
          //gaji_pokok:'',
          jabatan:'',
          //nama_tabel_gaji:'',
          //keterangan:'',
          unit_kerja:'',
          honorarium:'',
          tanggal_mulai:formatdate,
          tanggal_akhir:formatdate,
          tembusan:[]
        },
        error: {
          id_st_description:'',
          //keterangan_sk:'',
          tanggal_pembuatan_st:'',
          nama_ketua_yayasan : '',
          //pangkat_golongan:'',
          //pangkat :'',
          status_kepegawaian : '',
          //aktif_non_aktif : '',
          //tanggal_berlaku_sk:'',
          //lama_bekerja_tahun:'',
          //lama_bekerja_bulan:'',
          //gaji_pokok:'',
          jabatan:'',
          //nama_tabel_gaji:'',
          //keterangan:'',
          unit_kerja:'',
          honorarium:'',
          tanggal_mulai:'',
          tanggal_akhir:'',
          tembusan:[]
        }
      },
      errortembusan : '',
      no_st :'',
    };

    this._onClickAddTembusan = this._onClickAddTembusan.bind(this);
    this._onDeleteListTembusan = this._onDeleteListTembusan.bind(this);
    this._onClickToSKList = this._onClickToSKList.bind(this);
  }

  componentDidMount() {
    this.onGetData();
  }

  async onGetData(){
    const { location } = this.props;
    const { isEdit = false, data } = location.state;
      console.log(this.props.location.state.data)
    const{handelgetDataDeskripsi,handelgetDataGolongan,
      handleGetDataPegawai, handelgetStatusKepegawaianST,handelgetDataJabatan, handleGetAktifNonAktifOptions,
    } = this.props;
    const datadeskripsi = await handelgetDataDeskripsi();
    const nip = this.props.location.state.data.nip;
    const unit_kerja = {unitkerja : this.props.location.state.data.unit_kerja};
    const datagolongan = await handelgetDataGolongan(unit_kerja);
    const datakepegawaian = await handelgetStatusKepegawaianST(unit_kerja);
    const dataaktifnonaktif = await handleGetAktifNonAktifOptions(unit_kerja);
    const datapegawai = await handleGetDataPegawai({nip:nip});
    const datajabatan = await handelgetDataJabatan(unit_kerja);

    this.setState({datades: datadeskripsi,datagol : datagolongan, datakepegawaian: datakepegawaian,
       nip : nip, dataaktifnonaktif : dataaktifnonaktif, datapegawai : datapegawai.result, datajabatan:datajabatan
    }, () =>{
      if(this.state.datapegawai.status_kepegawaian.id_status_kepegawaian !='YAY-HN' && this.state.datapegawai.status_kepegawaian.id_status_kepegawaian != 'YAY-OR'){
        this.setState({datades: this.state.datades.slice(1,this.state.datades.length)});
      }
      
      this.setState({created_by: this.props.user.id});
      if (isEdit) {
        this._setForm(location.state.data.id);
      }
    })
  }

async _setForm(id){
      const {handleGetDataSK,handelgetDataJabatan, handelgetStatusKepegawaianST} = this.props;
      const nip = this.props.location.state.data.nip;
      const unit_kerja = this.props.location.state.data.unit_kerja;
      const data_sk = await handleGetDataSK({
          id:id,
          nip : nip,
          unitkerja : unit_kerja
      });
      const data = data_sk.result;
      console.log(data)
      const datakepegawaian = await handelgetStatusKepegawaianST({unitkerja:data.unit_kerja, id_st: data.id_st_description});
      console.log(datakepegawaian)
      const datajabatan = await handelgetDataJabatan({unitkerja:data.unit_kerja});
      let datatembusan = data.tembusan.map((item, index) =>{
        return(
            {counter: item.counter, note: item.note}     
      )});
      // let golongan = data.pangkat_golongan+","+data.gaji_pokok;

    const dataset = {...this.state.form}
    dataset.value.id_st_description = data.id_st_description;
    dataset.value.tanggal_pembuatan_st = data.tanggal_pembuatan_st;
    // dataset.value.keterangan_sk = data.keterangan_sk;
    dataset.value.nama_ketua_yayasan = data.nama_ketua_yayasan;
    // dataset.value.keterangan = data.keterangan;
    // dataset.value.pangkat = golongan;
    // dataset.value.pangkat_golongan = data.pangkat_golongan;
    // dataset.value.gaji_pokok = data.gaji_pokok;
    dataset.value.status_kepegawaian = data.status_kepegawaian;
    // dataset.value.aktif_non_aktif = data.aktif_non_aktif;
    dataset.value.jabatan = data.jabatan;
    // dataset.value.nama_tabel_gaji = data.nama_tabel_gaji;
    // dataset.value.tanggal_berlaku_sk = data.tanggal_berlaku_sk;
    // dataset.value.lama_bekerja_tahun = data.lama_bekerja_tahun;
    // dataset.value.lama_bekerja_bulan = data.lama_bekerja_bulan;
    dataset.value.unit_kerja = data.unit_kerja;
    dataset.value.honorarium = data.honorarium;
    dataset.value.tanggal_mulai = data.tanggal_mulai;
    dataset.value.tanggal_akhir = data.tanggal_akhir;
    dataset.value.tembusan = datatembusan;
    this.setState({form : dataset, no_st : data.no_st, datajabatan:datajabatan, datakepegawaian: datakepegawaian});
}

async _onFormChange(event) {
  const{handelgetDataJabatan, handleGetHonorarium
  } = this.props;
  const nip = this.props.location.state.data.nip;
  const { name, value, dataset, checked, type, label} = event.target;
  const { inputType = 'text', inputArray = false, 
            arrayPosition = 0, fieldName,
          } = dataset;
    
    console.log(name, value)

    if (name === 'unit_kerja') {
      let datajabatan = await handelgetDataJabatan({
        unitkerja: value
      });
      const dataset = {...this.state.form}
      dataset.value.honorarium = '';
      dataset.value.jabatan = '';
      this.setState({datajabatan:datajabatan, form: dataset})
    }

    if (name === 'jabatan') {
      let honorarium = await handleGetHonorarium({
        nip : nip,
        id_st_description: this.state.form.value.id_st_description,
        jabatan : value,
        status_kepegawaian: this.state.form.value.status_kepegawaian,
        unitkerja: this.state.form.value.unit_kerja,
      });
      console.log(honorarium)
      const dataset = {...this.state.form}
      dataset.value.honorarium = honorarium.result;
      this.setState({form : dataset});
    }

    if( name === 'id_st_description' ){
      const{handelgetStatusKepegawaianST} = this.props;
      const unit_kerja = {unitkerja : this.props.location.state.data.unit_kerja, id_st: value};
      const datakepegawaian = await handelgetStatusKepegawaianST(unit_kerja);
      const dataset = {...this.state.form}
      dataset.value.honorarium = '';
      dataset.value.jabatan = '';
      dataset.value.unit_kerja = '';

      value==1?
      dataset.value.status_kepegawaian = '':dataset.value.status_kepegawaian = this.state.datapegawai.status_kepegawaian.id_status_kepegawaian;
      value==1?
      dataset.value.unit_kerja ='': dataset.value.unit_kerja = this.state.datapegawai.unit_kerja.id_unit_kerja;
      this.setState({datakepegawaian:datakepegawaian, form: dataset})
    }

    if (name ==='status_kepegawaian'){
      const dataset = {...this.state.form}
      dataset.value.honorarium = '';
      dataset.value.jabatan = '';
      dataset.value.unit_kerja = '';
      this.setState({form: dataset})
    }

    // if( name === 'pangkat' ){
    //   console.log(typeof(value))
    //   const data = {...this.state.form}
    //   let temp = value.split(",")
    //   data.value.honorarium = temp[1]
    //   data.value.pangkat_golongan = temp[0]
    //   data.error.honorarium = ""
    //   this.setState({form:data})
    // }
    
    if(fieldName == 'tembusan'){ 
      this.setState({errortembusan:''})
    }

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if (inputType === 'number') {
        // formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }

        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        
        if(fieldName === 'tembusan' && name === 'note') {
          newList[arrayPosition]['counter'] = parseInt(arrayPosition) + 1;
        }

        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }

        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }


  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {}
      }
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    console.log(form);
    const { handleManageInformationLetter, history, location } = this.props;
    const { data, isEdit = false } = location.state;
    const created_by = this.state.created_by;
    const {error,flag} = validateSTForm(form);
    if(flag){
      // console.log("valid")
      if (isEdit) {
        let id = location.state.data.id;
        handleManageInformationLetter(
          { nip: data.nip, id: id, no_st :this.state.no_st , ...form.value },
          history.goBack,
          isEdit
        ); 
      }else{
        handleManageInformationLetter(
          { nip: data.nip, ...form.value },
          history.goBack,
          isEdit
        );
      }
    }else{
      console.log(error)
      // console.log("invalid")
      if(error.error.lama_bekerja_bulan == "Bulan kerja salah"){
        errorAlert({title: 'Lama bulan kerja yang diisikan salah',});
      }else{
        errorAlert({title: 'Data yang diisikan tidak lengkap',});
      }
      this.setState({form : error});
      if(isEmpty(this.state.form.value.tembusan) || this.state.form.value.tembusan[0].note === '' ){
        this.state.errortembusan = 'Silahkan isi tembusan';
      }
    }
  }

  //buat tembusan
  _onClickAddTembusan(data) {
    const { form } = this.state;  
    const {tembusan} = form.value;
    
    //kalau bentuk array ngga boleh diubah langsung begini, bisa efek ke array yang lain, harus di clone dulu
    tembusan.push({
      counter: '',
      note: '',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tembusan,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onDeleteListTembusan(idx) {
    this.setState((prevState) => {
      const { tembusan } = prevState.form.value;
      const list = prevState.form.value.tembusan;

      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          tembusan: list,
        }
      };
    });
  }

  _onClickToSKList(){
    const { history,location } = this.props;
    let val = location.state.data;
    history.push(`/dashboard/personalia/surat-tugas/${val.nip}`,{ data: val });
  }

  render() {
    const { form } = this.state;
    const { value,error } = form;
    const { unitkerja = {}, 
    } = this.props;
    return (
      <div className="manage-information-letter">
        <div>
          <h1 className="manage-information-letter__title">Surat Tugas</h1>
        </div>
        <div>
          <Select
            name="id_st_description"
            label="Deskripsi Surat Tugas"
            value = {value.id_st_description}
            data = {this.state.datades}
            onChange={this._onFormChange}
            placeholder="Pilih Deskripsi Surat Tugas"
          />
          <div className="manage-information-letter__error">{this.state.form.error.id_st_description}</div>
          {/* <Textarea2
            name="keterangan_sk"
            label="Isi Deskripsi SK"
            value={value.keterangan_sk}
            onChange={this._onFormChange}
            style={{height:100}}
          />
          <div className="manage-information-letter__error">{this.state.form.error.keterangan_sk}</div> */}
          {/* <Input 
            type="date"
            name="tanggal_pembuatan_sk"
            value={value.tanggal_pembuatan_sk}
            label="Tanggal Surat Ditetapkan"
            onChange={this._onFormChange}
            style={{width:100}}
          />
          <div className="manage-information-letter__error">{this.state.form.error.tanggal_berlaku_sk}</div> */}
          <Input
            name="nama_ketua_yayasan"
            value={value.nama_ketua_yayasan}
            label="Nama Ketua Yayasan"
            onChange={this._onFormChange}
          />
          <div className="manage-information-letter__error">{this.state.form.error.nama_ketua_yayasan}</div>
          {/* <Textarea2
            name="keterangan"
            value={value.keterangan}
            label="Keterangan Lain-lain"
            onChange={this._onFormChange}
            style={{height:100}}
          />
          <div className="manage-information-letter__error">{this.state.form.error.keterangan}</div> */}
        </div>
        <h2>DATA BARU</h2><br/>
        <div>
          {value.id_st_description==1&&this.state.datapegawai?
          <Select
            name="status_kepegawaian"
            value={value.status_kepegawaian}
            label="Status Kepegawaian"
            placeholder="Pilih Status Kepegawaian"
            data={this.state.datakepegawaian}
            onChange={this._onFormChange}
          />
          :
          <Input
            name="status_kepegawaian"
            value={this.state.datapegawai?this.state.datapegawai.status_kepegawaian.name:''}
            label="Status Kepegawaian"
            onChange={this._onFormChange}
            readOnly
          />
          
          }
          <div className="manage-information-letter__error">{this.state.form.error.status_kepegawaian}</div>
          {/* <Select
            name="aktif_non_aktif"
            value={value.aktif_non_aktif}
            label="Aktif/Non Aktif"
            placeholder="Pilih Aktif/Non Aktif"
            data={this.state.dataaktifnonaktif}
            onChange={this._onFormChange}
          />
          <div className="manage-information-letter__error">{this.state.form.error.aktif_non_aktif}</div>
          <Select
            name="pangkat"
            value={value.pangkat}
            label="Pangkat/gol.ruang gaji"
            placeholder="Pilih Pangkat /  Gol"
            data={this.state.datagol}
            onChange={this._onFormChange}
          />
          <div className="manage-information-letter__error">{this.state.form.error.pangkat}</div> */}
          {value.id_st_description==1&&this.state.datapegawai?
          <Select 
            name="unit_kerja"
            value={value.unit_kerja}
            label="Unit Kerja"
            placeholder="Pilih Unit Kerja"
            data={unitkerja.list}
            onChange={this._onFormChange}
          />
          :
          <Input
            name="unit_kerja"
            value={this.state.datapegawai?this.state.datapegawai.unit_kerja.name:''}
            label="Unit Kerja"
            onChange={this._onFormChange}
            readOnly
          />
          }
          <div className="manage-information-letter__error">{this.state.form.error.unit_kerja}</div>
          <Select 
            name="jabatan"
            value={value.jabatan}
            label="Jabatan"
            placeholder="Pilih Jabatan"
            data={this.state.datajabatan}
            onChange={this._onFormChange}
            isDisabled={this.state.disabled}
          />
          <div className="manage-information-letter__error">{this.state.form.error.jabatan}</div>
          
          <div className="manage-information-letter__tab">
            <div style={{flex:3}}>
                <Input 
                  name="honorarium"
                  value={value.honorarium}
                  label="Honorarium"
                  onChange={this._onFormChange}
                  readOnly
                />
                <div className="manage-information-letter__error">{this.state.form.error.honorarium}</div>
            </div>
            {/* <div className="manage-information-letter__divider" ></div>
            <div style={{flex:3}}>
                <Input 
                  name="nama_tabel_gaji"
                  value={value.nama_tabel_gaji}
                  label="Nama Tabel Gaji"
                  onChange={this._onFormChange}
                />
                <div className="manage-information-letter__error">{this.state.form.error.nama_tabel_gaji}</div>
            </div> */}
          </div>
          
          <div className="manage-information-letter__tab">
            <div style={{flex:2}}>
              <Input 
                type="date"
                name="tanggal_pembuatan_st"
                value={value.tanggal_berlaku_sk}
                label="Tanggal Pembuatan Surat"
                onChange={this._onFormChange}
              />
              <div className="manage-information-letter__error">{this.state.form.error.tanggal_pembuatan_st}</div>  
            </div>
            <div className="manage-information-letter__divider" ></div>
            <div style={{flex:2}}>
              <Input 
                type ="date"
                name="tanggal_mulai"
                // min = "0"
                value={value.tanggal_mulai}
                label="Tanggal Mulai"
                onChange={this._onFormChange}
              />
              <div className="manage-information-letter__error">{this.state.form.error.tanggal_mulai}</div>
            </div>
            <div className="manage-information-letter__divider" ></div>
            <div style={{flex:2}}>
              <Input 
                type ="date"
                name="tanggal_akhir"
                min ="0"
                value={value.tanggal_akhir}
                label="Tanggal Akhir"
                onChange={this._onFormChange}
              />
              <div className="manage-information-letter__error">{this.state.form.error.tanggal_akhir}</div>
            </div>
          </div>
        </div>
        
        <div className="manage-information-letter__error">{this.state.errortembusan} </div>
          <TembusanForm
            onAddList={this._onClickAddTembusan}
            onChange={this._onFormChange}
            onDeleteList = {this._onDeleteListTembusan}
            list = {value.tembusan}
          />
        
        <form onSubmit={this._onSubmit}>
          <div>
            <Button type="submit" title="Simpan" />
          </div>
        </form>
        <br/>
        <Button 
          title="Kembali"
          onClick = {()=>this._onClickToSKList()}
        />
        <br/>
      </div>
    );
  }
}
ManageInformationLetter.propTypes = {
  handleManageInformationLetter: PropTypes.func,
  handelgetDataDeskripsi: PropTypes.func,
  handelgetDataGolongan: PropTypes.func,
  handelgetStatusKepegawaianST: PropTypes.func,
  handleGetAktifNonAktifOptions: PropTypes.func,
  handelgetDataJabatan : PropTypes.func,
  handleGetDataSK : PropTypes.func,
  handleGetUnitKerjaOptions : PropTypes.func,
  handleGetRegionOptions : PropTypes.func,
  handleGetHonorarium : PropTypes.func,
  handleGetDataPegawai : PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
ManageInformationLetter.defaultProps = {
  handelgetDataDeskripsi:noop,
  handleManageInformationLetter: noop,
  handelgetDataGolongan : noop,
  handelgetStatusKepegawaianST : noop,
  handleGetAktifNonAktifOptions : noop,
  handelgetDataJabatan : noop,
  handleGetDataSK : noop,
  handleGetUnitKerjaOptions : noop,
  handleGetRegionOptions : noop,
  handleGetHonorarium : noop,
  handleGetDataPegawai : noop,
};
