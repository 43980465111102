import { connect } from 'react-redux';
import PembagianKelas from '../../../views/Akademi/DataKelas/PembagianKelas.view';
import { addBudget } from '../../../states/thunks/finance.thunk';
import { getClasses, getPeriodsOptions, getLevels, getParamOptions, getParamOptionsAcademics, getPrmLevels  } from '../../../states/thunks/options.thunk';
import { listClassDiv  } from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetClasses: (payload) => dispatch(getClasses(payload)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleListClassDiv: (payload, goBack) => dispatch(listClassDiv(payload, goBack)),
    handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PembagianKelas);