import { connect } from 'react-redux';
import DataMatpel from '../../views/Settings/DataMatpel.view';
import {getSubjectOptions, getParamOptions,getClassesOptions, getParamOptionsAcademics, getPrmLevels, getPeriodsOptions} from '../../states/thunks/options.thunk';
import { listKodeMatpel, deleteKodeMatpel} from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      handleListKodeMatpel: (payload, goBack) => dispatch(listKodeMatpel(payload, goBack)),
      getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
      handleDeleteSubjectDetail: (payload, goback) => dispatch(deleteKodeMatpel(payload, goback)),
      getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
      getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  
};
}

export default connect(mapStateToProps, mapDispatchToProps)(DataMatpel);
