import { connect } from 'react-redux';
import DataGuru from '../../views/Settings/DataGuru.view';
import {getUsernameOptions, getUserGroups, getSchoolUnitsOptions} from '../../states/thunks/options.thunk';
import { listResetPassword, resetPassword, listTeacher, setIsBlocked, listAnnouncement, deleteListTeacher} from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleListTeacher: (payload, goback) => dispatch(listTeacher(payload, goback)),
    getUsernameOptions: (payload) => dispatch(getUsernameOptions(payload)),
    getUserGroups: (payload) => dispatch(getUserGroups(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    handleSaveBlockUnits: (payload, goback) => dispatch(setIsBlocked(payload, goback)),
    handleListAnnouncement: (payload, goback) => dispatch(listAnnouncement(payload, goback)),
    handleDeleteListTeacher: (payload, goBack) => dispatch(deleteListTeacher(payload, goBack)),
  
};
}

export default connect(mapStateToProps, mapDispatchToProps)(DataGuru);
