import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, has, find } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_HARIAN } from "../../../constants/student.constant";
import { ABSENSI_HARIAN_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Input,
  Checkbox,
  Pagination,
  Select,
} from "../../../components/base/index";
import language from "../../../languages";
import { PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { PENGISIAN_PSIK__AFEK_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class NilaiKognitif extends Component {
  constructor(props) {
    super(props);
    this._onClickUpload = this._onClickUpload.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    // this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._renderButtonsPsik = this._renderButtonsPsik.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: organizations_id,
          nilaikognitif: [
            {
              id: "",
              score_students: "",
              students_id: "",
            },
          ],
        },
        error: {
          classes: "",
          levels: "",
          nilaikognitif: "",
        },
      },
      param: {},
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "classrooms", "levels"];

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getSubjectOptions({ filters: { units_id: [units_id] } });
    this._getPeriodsOptions({units_id: school_unit.id});
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });
    
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;

    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              period: data.value,
            }
          }))
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { menu } = value;
    console.log(value)
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListNilaiKognitif, handleListPsikAfek, user } =
            this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;
          let res = {};
          if (menu === undefined) {
            res = await handleListNilaiKognitif({
              ...params,
              organizations_id: organizations_id,
               units_id:school_unit.id,
              filters,
            });
          } else if (menu === "1") {
            res = await handleListNilaiKognitif({
              ...params,
              organizations_id: organizations_id,
              units_id:school_unit.id,
              filters,
            });
          } else if (menu == "2") {
            res = await handleListPsikAfek({
              ...params,
              organizations_id: organizations_id,
              units_id:school_unit.id,
              filters,
            });
          }

          // console.log(res);
          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = res;

          if (result) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif",
      { data: val, isEdit: true }
    );
  }

  onClickEditPsik(val) {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/tambah-pengisian-psik-afek", {
      data: val,
      isEdit: true,
    });
  }

  onClickDelete(idx) {
    this.setState((prevState) => {
      const { nilaikognitif } = prevState.form.value;
      const list = prevState.form.value.nilaikognitif;
      list.splice(idx, 1);
      return {
        ...prevState,
        nilaikognitif: list,
      };
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        // if (name === 'levels_id') {
        //   this._getParamOptions( 'classes',  { levels_id: value, organizations_id: 3 } );
        // }
        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions, user } = this.props;
    const { school_unit } = user;
    const res = await getSubjectOptions(filters);
    filters.units_id = school_unit.id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  // async handleGetData(payload = {}) {
  //   const { handleListStudentAttendance, handleListAnnouncementStudent, user } = this.props;
  //   const { units_id, workingUnit, id, user_group, profile } = user;
  //   let unit_id = units_id;

  //   if (unit_id === null && workingUnit && workingUnit.id) {
  //     unit_id = workingUnit.id;
  //   }
  //     const res = await handleListStudentAttendance({...payload, user_id: id, organizations_id: 3});

  //   this.setState(prevState => ({
  //     ...prevState,
  //     form: {
  //       ...prevState.form,
  //       value: {
  //         ...prevState.form.value,
  //         absensiharian: res,
  //       }
  //     }
  //   }));
  // }
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        // if (name === "levels_id") {
        //   this._getParamOptionsAcademics("classrooms", {
        //     levels_id: value,
        //     organizations_id: 3,
        //   });
        // }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id,
          });
          this._getSubjectOptions({ filters: { classes_id: value } });
        }
        this._onSearchContent();
      }
    );
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
        </div>
      </td>
    );
  }

  _renderButtonsPsik(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEditPsik(data)} title="Edit" />
        </div>
      </td>
    );
  }
  _onClickUpload() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian");
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      nominal: 0,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/tambah-pengisian-psik-afek", {
      isEdit: true,
    });
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif",
      { isEdit: true }
    );
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  async onSubmit() {
    const { history, handleSaveAttendance } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveAttendance(value);
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap");
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }
  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
    } = this.state;
    const { absensiharian } = form.value;
    const componentRef = React.createRef();
    const {
      classrooms_id = "",
      semester = "",
      Menu = "",
      subject_id = "",
      period = "",
    } = filters;
    const { location } = this.props;
    const loadingProps = { show: content.loading };
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const prmsemester = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];
    const menu = [
      { label: "Nilai Kognitif", value: "1" },
      { label: "Nilai PSIK & AFEK", value: "2" },
    ];
    return (
      <div className="student-list">
        <div className="beginning-balance__button-wrapper">
          {/* <Button
            title="Pengisian Psik & Afek"
            onClick={this.onClickAdd}
          /> */}
          <Button
            title="Tambah Nilai"
            onClick={this.onClickAddFolder}
          />
        </div>
        <br></br>
        <br></br>
        <div className="nilai-kognitif__custom-form">
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
              <Select
                label="Tahun Ajaran"
                name="period"
                onChange={this._onChangeFilter}
                placeholder="Pilihan"
                data={param.periods}
                value={period}
              />
            </div>

            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                disabled
                placeholder="Pilih Unit"
                data={param.levels}
                value={form.value.levels_id}
                onChange={this._onFormChange}
              />
            </div>
          </div>
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
              <Select
                name="semester"
                label="Semester"
                onChange={this._onChangeFilter}
                value={semester}
                data={prmsemester}
                placeholder={"Pilihan"}
              />
            </div>
            <div className="data-pelanggaran__custom-form-row__field">
              <Select
                name="classes_id"
                label="Tingkat Kelas"
                onChange={this._onFormChange}
                data={param.classes}
                placeholder={"Pilihan"}
                value={form.value.classes_id}
                error={form.error.classes_id || ""}
              />
              <div className="data-pelanggaran__custom-form-row__field"></div>
              <Select
                name="classrooms_id"
                label="Kelas"
                onChange={this._onChangeFilter}
                placeholder="Pilih Kelas"
                data={param.classrooms}
                value={classrooms_id}
              />
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
              <Select
                label="Mata Pelajaran"
                name="subject_id"
                placeholder="Pilihan"
                data={param.subject}
                onChange={this._onChangeFilter}
                value={subject_id}
              />
            </div>

            {/* <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Select
                  name="menu"
                  label="Menu"
                  onChange={this._onFormChange}
                  placeholder={"Pilihan"}
                  value={form.value.menu}
                  error={form.error.menu || ""}
                  data={menu}
                />
              </div>
            </div> */}
          </div>

          <div className="nilai-kognitif__custom-form-row"></div>
        </div>
        {form.value.menu == null && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtons(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this.onChangePage}
              />
            </div>
          </>
        )}
        {form.value.menu === "1" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtons(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this.onChangePage}
              />
            </div>
          </>
        )}
        {form.value.menu === "2" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      PENGISIAN_PSIK__AFEK_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        PENGISIAN_PSIK__AFEK_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsPsik(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this.onChangePage}
              />
            </div>
          </>
        )}
        <div className="user-management__table-footer"></div>
      </div>
    );
  }
}
NilaiKognitif.propTypes = {
  getParamOptions: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetNilaiKognitif: PropTypes.func,
};
NilaiKognitif.defaultProps = {
  getParamOptions: noop,
  handleGetNilaiKognitif: noop,
};
