import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty, isEqual } from "lodash";
import { Button, Input, SearchSelect, Select, Pagination } from "../../../components/base";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { NIS_SISWA_SEARCH } from "../../../constants/student.constant";
import { BATAL_SISWA_KELUAR_TABLE } from "../../../constants/student.constant";
import { commaFormatted, formatData } from "../../../utils/transformer.util";
import { CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  from: 1,
  error: false,
};
export default class CatatSiswaPindah extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    // this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getStudentMoveOptions = this._getStudentMoveOptions.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);

    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {},
        error: {},
      },
      param: "",
    };
  }

  // componentDidMount() {
  //   const { location, handleGetClasses, user } = this.props;
  //   const { content, form } = this.state;
  //   const { organizations_id, school_unit } = user;
  //   const { state = {} } = location;
  //   const { id } = state.data || "";
  //   const { isEdit = false, data } = state;
  //   console.log(form.value)
  //   const paramTypes = ["classes", "levels", "classrooms"];
  //   if (isEdit && id) {
  //     this.setState({ id: id });
  //     this._setForm(data.id);
  //   }
  //   paramTypes.forEach((type) => {
  //     this._getParamOptionsAcademics(type);
  //   });
  //   // this._getStudentOptions();
  //   this._getStudentMoveOptions({units_id: [school_unit.id] });
  //   if (isEmpty(content.list)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  //   this._getPeriodsOptions({units_id: school_unit.id});
  // }

  componentDidMount() {
    const { content, filters, form } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem('changeFilters')) || filters;
    // if (savedForm != "" && user.id != savedForm.value.user_id) {
    //   savedForm = "";
    //   savedFilters = filters;
    // }
    this._initializeData(savedFilters);
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this._onSearchContent({ page: 1 });
      // this._getStudentMoveOptions();
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { content} = this.state;
    const { school_unit } = user;
    const paramTypes = ['classrooms','levels', 'classrooms'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    
    await this._getPeriodsOptions({units_id: school_unit.id});

    if (isEmpty(content.list)) {
      await this._onSearchContent({ page: 1 });
    }
    this.setState({filters: savedFilters}, () => { 
      this._getStudentMoveOptions( {units_id: [school_unit.id] });
    });
    
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/mutasi-keluar2", {
      isEdit: true,
    });
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              period: data.value,
          },
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              period: data.value,
          }
        }
          }))
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }


  _onFormChange(event) {
    const { name, value, dataset, files } = event.target;
    const { inputType = "text" } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === "number") {
        formattedValue = commaFormatted(value);
      }
      if (inputType === "file") {
        [formattedValue] = files;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: "",
          },
        },
      };
    });
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran", { data: "" });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/mutasi-keluar2", {
      data: val,
      isEdit: true,
    });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/upload");
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { school_unit, organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const {organizations_id} = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentMoveOptions() {
    const { getStudentMoveOptions, user } = this.props;
    const { filters, form } = this.state;
    const {
      period = '',
    } = filters;
    const { school_unit } = user;
    filters.units_id = school_unit.id;
    filters.period = form.value.period; 

    const res = await getStudentMoveOptions({
      filters: {period: form.value.period},
      units_id: school_unit.id,
    });
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }



  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const {
      period = '',
      students_id = '',
    } = filters;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListMoveRecord, user } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;
          // let students_value = students_id?students_id.value:'';
          // console.log(students_value)
          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
            from = 1,
          } = await handleListMoveRecord({
            ...params,
            ...filters,
            organizations_id: organizations_id,
            filters: {period: period, students_id: students_id.value},
            units_id: school_unit.id,
          });
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
              from
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              from: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangeFilter(e) {
    const { form, filters } = this.state;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('changeFilters', JSON.stringify(this.state.filters));
        this._onSearchContent();
      }
        // if (name === 'students_id') {
        //   this._getStudentMoveOptions({ filters: { period: filters.period }} );
        // }
      
    );
  }

  onClickPrint(val) {
    const { history, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    history.push("/dashboard/kesiswaan/data-siswa/cetak-surat-pindah", {
      data: val,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          {/* <Button onClick={() => this.onClickPrint(data)} title="Cetak" /> */}

          {/* </div> */}
          {/* } */}
        </div>
      </td>
    );
  }

  render() {
    const { onChange,  classes } = this.props;
    const { content, list, param, filters = {}, form } = this.state;
    const dataset = {
      toggle: "modal",
    };
    const { classrooms_id = "", status_id = "", period = "" } = filters;

    // console.log(form.value)
    return (
      <div className="fund-request">
        <div className="beginning-balance__button-wrapper">
          <Button title="Tambah Mutasi Keluar" onClick={this.onClickAdd} />
        </div>
        <div className="beginning-balance__button-wrapper">
            {/* <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                data={param.periods}
                placeholder={"Pilihan"}
                value={period}
              /> */}
          <SearchSelect
            noMargin
            async={false}
            name="students_id"
            list={param.student}
            inputArray
            onClick={this._onChangeFilter}
            placeholder="Pilih Nama Siswa"
            value={filters.students_id}
            // labelName="label"
            // valueName="value"
            rightIcon="icon-search"
            label="Nama Siswa"
          />
        </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => { 
                      if (fieldIdx === 0) {
                        return (
                          <td style={{textAlign:'center'}}key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + content.from}
                          </td>
                        );
                      }
                      if (field.attribute === "nisn"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.attribute === "no_va"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.type === "link") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Link
                              to={
                                "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                              }
                            >
                              {list.name}
                            </Link>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(list)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this._onChangePage}
          />
        </div>
      </div>
    );
  }
}
CatatSiswaPindah.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CatatSiswaPindah.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
