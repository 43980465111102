import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import { Input, Button, Select, SelectMultiple, Textarea } from "../../../../components/base";

export default class AnekdotSubTemaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*",
      },
    };
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name, inputArray, fieldName, arrayPosition, arraySub) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name,
      value,
      dataset: {
        inputArray,
        fieldName,
        arraySub,
        arrayPosition,
      },
    }});
  }

  _onRemoveMulti(selectedList, removedItem, inputArray, fieldName, arrayPosition, arraySub) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name: removedItem,
      value: selectedList,
      dataset: {
        inputArray,
        fieldName,
        arraySub,
        arrayPosition,
      },
    }});
  }

  render() {
    const { sub_tema, indikator, error, onChange, param, subdata, idxsub, fieldName, form, onFormChange } = this.props;
    return (
      <table className="manage-rapbs-plafon__form-table table-indikator">
        <thead>
          <tr>
            <th>No</th>
            <th>KD</th>
            <th>Indikator</th>
            <th>Catatan</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(subdata[idxsub].indikator, (data, idx) => (
            <tr key={`plafon_journal_data__${idx}_${idxsub}`}>
              <td style={{textAlign:"center"}}>{idx + 1}</td>
              <td>
                <SelectMultiple
                  name="kd"
                  style={{width:"50%"}}
                  options={param.kd_prm}
                  label="Hari"
                  displayValue="label" // Property name to display in the dropdown options
                  showCheckbox={true}
                  inputArray
                  fieldName='indikator'
                  array_position={idx}
                  values={data.kd}
                  onRemove={value => this._onRemoveMulti(value, 'kd', true, 'indikator', idx, idxsub)}
                  onSelect={value => this._onChangeMulti(value, 'kd', true, 'indikator', idx, idxsub)} 
                />
              </td>
              <td>
                {
                  <Input
                    type="text"
                    value={data.name}
                    data-input-array
                    arrayPosition={idx}
                    data-array-sub={idxsub}
                    data-array-position={idx}
                    data-field-name="indikator"
                    name="name"
                    onChange={onFormChange}
                  />
                }
              </td>
              <td>
                <Textarea
                  type="text"
                  value={data.note}
                  data-input-array
                  arrayPosition={idx}
                  data-array-sub={idxsub}
                  data-array-position={idx}
                  data-field-name="indikator"
                  name="note"
                  onChange={onFormChange}
                  rows={3}
                />
              </td>
              <td>
                <Button onClick={() => this._onDeleteList({idx, idxsub})} icon="icon-trash-o"></Button>
              </td>
            </tr>
          ))}
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={7}>
              <div>
                <Button icon="icon-plus" onClick={this._onAddList} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

AnekdotSubTemaForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
};
AnekdotSubTemaForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onFormChange: noop,
  onChange: noop,
};
