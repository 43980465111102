import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { Button, Pagination, Input, Select } from "../../../components/base/index";
import language from "../../../languages";
import { TABLE_LIST_SUB_TEMA_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Anekdot_SubTema extends Component {
  constructor(props) {
    super(props);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPrmTema = this._getPrmTema.bind(this);

    const { user } = this.props;
    const { organizations_id } = user;

    this.state = {
      page: 1,
      list: [],
      listAmount: 5,
      form: {
        value: {
          organizations_id: organizations_id,
        },
        error: {
          classrooms: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    this._getPrmTema();
    this.handleGetData();
  }

  async _getPrmTema(filters = {}) {
    const { getPrmTema, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmTema(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_tema: res,
      },
    }));
  }

  async handleGetData(payload = {}) {
    const { handleListSubTema, user } = this.props;
    const { units_id, workingUnit, id, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    const res = await handleListSubTema({
      ...payload,
      user_id: id,
      organizations_id: organizations_id,
    });

    this.setState({
      list: res,
    });
  }

  async _setForm(id) {
    const { handleGetSubTema } = this.props;
    try {
      const payload = await handleGetSubTema({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            name: payload.name,
            tema_id: payload.tema_id,
          },
          error: {
          },
        },
      });
    } catch (err) {
    }
  }

  _renderButtons(data) {;
    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          <Button onClick={() => this.onClickDelete(data)} title="Hapus" />
        </div>
      </td>
    );
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema", {
      data: val,
      isEdit: true,
    });
    history.go(0);
  }

  async onClickDelete(id) {
    const { handleDeleteSubTema } = this.props;
    const res = await handleDeleteSubTema(id);
    if (res) {
      this.handleGetData();
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveSubTema } = this.props;
    const { form } = this.state;
    const { value } = form;
    await handleSaveSubTema(value);
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema");
    history.go(0);
  }

  async onEdit() {
    const { history, handleEditSubTema } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditSubTema(value);
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema");
    history.go(0);
  }

  render() {
    const { form, list, param = {} } = this.state;
    return (
      <div className="add-budget">
        <form onSubmit={this.onSubmit}>
          <h1 style={{ color: "blue" }}>Sub Tema Form</h1>
          <hr></hr>
          
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="tema_id"
                label="Tema"
                placeholder={"Pilihan"}
                onChange={this._onFormChange}
                data={param.prm_tema}
                value={form.value.tema_id}
                error={form.error.tema_id || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Input
                label="Nama Sub Tema"
                name="name"
                value={form.value.name}
                error={form.error.name || ""}
                onChange={this._onFormChange}
              />
            </div>
          </div>
          <div className="student-list__button">
            {this.state.id ? (
              <Button type="button" title="Update" onClick={this.onEdit} />
            ) : (
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            )}
          </div>
        </form>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  TABLE_LIST_SUB_TEMA_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    TABLE_LIST_SUB_TEMA_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(data, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
Anekdot_SubTema.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Anekdot_SubTema.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
