import { connect } from 'react-redux';
import LaporanSekolah from '../../../views/Akademi/DataKelas/LaporanSekolah.view';
// import { getParamOptions } from '../../../states/thunks/options.thunk';
import { getHeadmaster, listTenagaPendidik, listTotalSiswa, listTotalSiswaMajor } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHeadmaster: (payload) => dispatch(getHeadmaster(payload)),
    handleListTenagaPendidik: (payload, type) => dispatch(listTenagaPendidik(payload, type)),
    handleListTotalSiswa: (payload, type) => dispatch(listTotalSiswa(payload, type)),
    handleListTotalSiswaMajor: (payload, type) => dispatch(listTotalSiswaMajor(payload, type)),
    // handleListUnits: (payload, goback) => dispatch(listUnits(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanSekolah);
