import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Tunggakan extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getYearsOfEducationsOptions = this._getYearsOfEducationsOptions.bind(this);
    this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    this._getMsTempsSiswaOptions = this._getMsTempsSiswaOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this._onClickSet = this._onClickSet.bind(this);
    this._getParamPerwakilan = this._getParamPerwakilan.bind(this);
    
    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      filters:{},
      isPrint: true,
      isTable:true,
      form: {
        value: {
          levels_id,
          units_id:'',
          user_id: user.id,
        },
        error: {
        },
      },
      param: {},
    };
  }

   componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { levels_id } = school_unit;
    const { content, filters, form } = this.state;
    const { value } = form;
    let savedFilters = JSON.parse(window.localStorage.getItem('arrearsFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('arrearsForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    this._initializeData(savedFilters);
    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id,
        },
      }));
      // this._getParamOptionsAcademics("classrooms", {
      //   levels_id: savedForm.value.levels_id,
      //   units_id: savedForm.value.units_id.value,
      //   organizations_id,
      // });
     
      if (user_group.name === "pusat" || user_group.name === "perwakilan") {
         this._getYearsOfEducationsOptions({units_id: savedForm.value.units_id.value})
         this._getPeriodsOptions({units_id: savedForm.value.units_id.value});
         this._getMsTempsSiswaOptions({units_id: savedForm.value.units_id.attributes?savedForm.value.units_id.attributes.va_code:''});
        
        this._getParamPerwakilan();
        }
        // else {
        //   this._getParamOptionsAcademics("classrooms", {
        //     levels_id: savedForm.value.levels_id,
        //     organizations_id,
        //     // units_id: savedForm.value.units_id
        //   });
        // }
      }
  }

  async _initializeData(savedFilters = {}){
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user
    const paramTypesLevels = ["levels"];
    paramTypesLevels.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if(user_group.name !== "pusat" || user_group.name !== "perwakilan"){
      console.log("Bukan Pusat")
      // const paramTypes = ["classrooms"];
      this._getParamOptionsAcademics("classrooms", {
            levels_id: school_unit.levels_id,
            organizations_id,
            units_id:school_unit.id
          });
      this._getYearsOfEducationsOptions({units_id: school_unit.id });
      this._getPeriodsOptions({units_id: school_unit.id});
      this._getStudentSPPOptions({units_id: school_unit.id});
      this._getMsTempsSiswaOptions({units_id: school_unit.va_code});
    }
    if(user_group.name == 'perwakilan'){
      await this._getSchoolUnitsOptions({perwakilan_id: school_unit.perwakilan_id})
      await this.getPrmPaymentsNon();
    }
    else{
    await this._getSchoolUnitsOptions();
    await this.getPrmPaymentsNon();
    await this._getParamPerwakilan();
    }
    if (user_group.name == 'pusat' || user_group.name == 'perwakilan'){
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          period2: savedFilters.period2,
          // classrooms_id : savedFilters.classrooms_id,
          period : savedFilters.period,
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
  }

  _onClickSet(){
    this.setState(
      (prevState) => ({
        ...prevState,
        isTable: false,
        filters: {
          ...prevState.filters,
        },
      }),
      () => {
        this._onSearchContent()
      }
    );
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  async _getParamPerwakilan(filters = {}) {
    const { getParamPerwakilan, user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    const res = await getParamPerwakilan(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        perwakilan_prm: res,
      },
    }));
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { form, filters} = this.state;
    const { organizations_id, user_group} = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('arrearsFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('arrearsForm', JSON.stringify(this.state.form));
         
        if (name === 'perwakilan_id'){
          this.setState((prevState) => ({
            ...prevState,
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                perwakilan_id: value,
                units_id: "",
              },
            },
          }));
          this._getSchoolUnitsOptions({perwakilan_id: value.value})
          // if(value.value == 'all'){
          // this._onSearchContent();
          // }
        }
        if (name === 'units_id') {
          delete filters.tunggakan;
          const param = this.state.param.units;
          const levels_units = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.levels_id:"";
          const va_code = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.va_code:"";
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                period: "",
                period2: "",
                currents_year: "",
                levels_id: levels_units,
                units_id : value,
                tunggakanakumulasi: [],
                tunggakankelas: [],
                tunggakansiswa: [],
  
              }
            },
            filters : {
              ...prevState.filters,
              students_id: [],
              tunggakan: "",
              jenis:"",
              period: "",
              period2: "",
              classrooms_id: "",
            }
          }));
          
        
          // if(user_group.name == "pusat" || user_group.name == 'perwakilan'){
            this._getPeriodsOptions({units_id: value.value});
            this._getYearsOfEducationsOptions({units_id: value.value});
            this._getStudentSPPOptions({units_id: value.value});
            this._getMsTempsSiswaOptions({units_id: va_code});
            this._getParamOptionsAcademics("classrooms", {
              levels_id: levels_units, organizations_id: organizations_id, units_id: value.value
            });
          }
        // }
        if( name === "jenis"){
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                // period2:"",
                tunggakankelas: [],
                tunggakanakumulasi: [],
              }
            },
            filters : {
              ...prevState.filters,
              classrooms_id:'',
              payments_id:'',
              // period2:'',
            }
          }));
        }
        if( name === "tunggakan" || name === "students_id"){
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
          }));
        }
        // this._onSearchContent();
      }
    );
  }

  async _onSearchContent() {
    const { filters, form, savedSiswa, savedKelas, savedAkumulasi, param } = this.state;
    const { tunggakan, classrooms_id, period, period2, students_id, jenis, payments_id, perwakilan_id } = filters;
    const { currents_year, currents_year2, year } = form.value

    const years = []
    const thisyear = new Date().getFullYear();
    for(var i=2021; i<=thisyear; i++) {
      var nextyear=Number(i)+1
      years.push({
        label: i+"-"+nextyear,
        value: i,
      });
    }

    console.log(savedAkumulasi)
    console.log(savedKelas)

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListArrearsStudents, handleListArrearsClasses, handleListArrearsAccumulations, 
            handleListArrearsStudentsGeneral, handleListArrearsClassesGeneral, handleListArrearsAccumulationsGeneral, 
            handleListArrearsClassesNon, handleListArrearsAccumulationsNon, handleListArrearsAccumulationsUnitRutin,
            handleListArrearsAccumulationsUnitNonRutin, handleListArrearsAccumulationsPerwakilanRutin, handleListArrearsAccumulationsPerwakilanNonRutin } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id, user_group} = user;
          const units_id = school_unit.id;
          let savedFilters = JSON.parse(window.localStorage.getItem('arrearsFilters')) || filters;
          let savedForm = JSON.parse(window.localStorage.getItem('arrearsForm')) || "";
          let period_label = years.find((i) => i.value == period2) ? years.find((i) => i.value == period2).label : ""
          const units_value = user_group.name == "pusat" || user_group.name == "perwakilan" ? savedForm.value.units_id.value : school_unit.id ;
          // const perwakilan_value = savedForm.value.perwakilan_id?savedForm.value.perwakilan_id.value:school_unit.perwakilan_id;
          const perwakilanValue = perwakilan_id?perwakilan_id.value:'';  
          const perwakilan_value = user_group.name == "perwakilan" ?school_unit.perwakilan_id : perwakilanValue;
          console.log(perwakilan_value)
          console.log(units_value)
          console.log(savedForm)
          
          let listname = ""
          let result = {};

          if (tunggakan === "siswa" && students_id) {
            if (!(savedSiswa && savedSiswa.students_id == students_id)) {
              if ( organizations_id == 3 ){
                this.setState((prevState) => ({
                  ...prevState,
                  savedSiswa: {
                    students_id: students_id
                  },
                }));
                listname = "tunggakansiswa"
                result = await handleListArrearsStudents({
                  students_id : students_id.value,
                  currents_year : year,
                  organizations_id : organizations_id,
                });
              }
              else {
                this.setState((prevState) => ({
                  ...prevState,
                  savedSiswa: {
                    students_id: students_id
                  },
                }));
                listname = "tunggakansiswa"
                result = await handleListArrearsStudentsGeneral({
                  students_id : students_id.value,
                  currents_year : year,
                  organizations_id : organizations_id,
                });
              }
            
            }
          } 
          else if (tunggakan === "kelas" && units_value !== "all_unit" && jenis == "rutin" && period && classrooms_id) {
            if (!(savedKelas && savedKelas.jenis == jenis && savedKelas.period == period && savedKelas.classrooms_id == classrooms_id)) {
              // if (organizations_id == 3){
                this.setState((prevState) => ({
                  ...prevState,
                  savedKelas: {
                    period : period,
                    classrooms_id : classrooms_id,
                    jenis: jenis,

                  },
                }));
                listname = "tunggakankelas"
                result = await handleListArrearsClasses({
                  // currents_year : period,
                  period : period,
                  currents_year : currents_year ? currents_year : year,
                  classrooms_id : classrooms_id,
                  organizations_id : organizations_id,
                  filters
                });
            }
          }
          else if (tunggakan === "kelas" && units_value !== "all_unit" && jenis == "nonrutin" && period && classrooms_id && payments_id ) {
            if (!(savedKelas && savedKelas.jenis == jenis && savedKelas.period == period && savedKelas.classrooms_id == classrooms_id && savedKelas.payments_id == payments_id)) {
              // if (organizations_id == 3){
                this.setState((prevState) => ({
                  ...prevState,
                  savedKelas: {
                    period : period,
                    classrooms_id : classrooms_id,
                    payments_id: payments_id,
                    jenis: jenis,

                  },
                }));
                listname = "tunggakankelas"
                result = await handleListArrearsClassesNon({
                  // currents_year : period,
                  period : period,
                  currents_year : currents_year ? currents_year : year,
                  classrooms_id : classrooms_id,
                  organizations_id : organizations_id,
                  payments_id: payments_id,
                  filters
                });
            }
          }
          else if (tunggakan === "akumulasi" && perwakilan_value !== "all" && units_value !== "all_unit" && jenis == "rutin" && period2) {
            if (!(savedAkumulasi && savedAkumulasi.jenis == jenis && savedAkumulasi.period2 == period2)) {
                this.setState((prevState) => ({
                  ...prevState,
                  savedAkumulasi: {
                    period2: period2,
                    jenis: jenis,
                    // units_value: units_value
                  },
                }));
                console.log("Masuk perwakilan")
                listname = "tunggakanakumulasi"
                result = await handleListArrearsAccumulations({
                  period2 : period2,
                  currents_year : currents_year2 ? currents_year2 : year,
                  units_id : units_value,
                  organizations_id : organizations_id,
                });
             }
          }
          else if (tunggakan === "akumulasi" && perwakilan_value !== "all" && units_value !== "all_unit" && jenis == "nonrutin" && period2 && payments_id) {
            if (!(savedAkumulasi && savedAkumulasi.jenis == jenis && savedAkumulasi.period2 == period2 && savedAkumulasi.payments_id == payments_id)) {
              if (organizations_id == 3){
                this.setState((prevState) => ({
                  ...prevState,
                  savedAkumulasi: {
                    period2: period2,
                    payments_id: payments_id,
                    jenis: jenis,
                    // units_value: units_value
                  },
                }));
                listname = "tunggakanakumulasi"
                result = await handleListArrearsAccumulationsNon({
                  period2 : period2,
                  payments_id: payments_id,
                  units_id : units_value,
                  organizations_id : organizations_id,
                  filters : {period2: period2}
                });
              }
            }
          }
          else if (tunggakan === "akumulasi" && perwakilan_value !== "all" && units_value === "all_unit" && jenis == 'rutin' && period2) {
            if (!(savedAkumulasi && savedAkumulasi.perwakilan_value !== "all" && savedAkumulasi.units_value == "all_unit" && savedAkumulasi.jenis == jenis && savedAkumulasi.period2 == period2)) {
              console.log("Masuk kesini")
                this.setState((prevState) => ({
                  ...prevState,
                  savedAkumulasi: {
                    period2 : period2,
                    jenis: jenis,
                    units_value: units_value,
                    perwakilan_value: perwakilan_value
                  },
                }));
                listname = "tunggakanakumulasi"
                result = await handleListArrearsAccumulationsUnitRutin({
                  period2 : period_label,
                  currents_year : period2,
                  perwakilan_id: perwakilan_value,
                  units_id : units_value,
                  organizations_id : organizations_id,
                });
            }
          }
          else if (tunggakan === "akumulasi" && perwakilan_value !== "all" && units_value === "all_unit" && jenis == 'nonrutin' && period2 && payments_id) {
            if (!(savedAkumulasi && savedAkumulasi.perwakilan_value !== "all" && savedAkumulasi.units_value == "all_unit" && savedAkumulasi.jenis == jenis && savedAkumulasi.period2 == period2 && savedAkumulasi.payments_id == payments_id)) {
             
                this.setState((prevState) => ({
                  ...prevState,
                  savedAkumulasi: {
                    payments_id: payments_id,
                    period2 : period2,
                    jenis: jenis,
                    units_value: units_value,
                    perwakilan_value: perwakilan_value
                  },
                }));
                listname = "tunggakanakumulasi"
                result = await handleListArrearsAccumulationsUnitNonRutin({
                  period2 : period_label,
                  currents_year : period2,
                  payments_id: payments_id,
                  perwakilan_id: perwakilan_value,
                  units_id : units_value,
                  organizations_id : organizations_id,
                });
            }
          }
          else if (tunggakan === "akumulasi" && perwakilan_value === "all" && units_value === "all_unit" && jenis == 'rutin' && period2) {
            if (!(savedAkumulasi && savedAkumulasi.perwakilan_value == "all" && savedAkumulasi.units_value == "all_unit" && savedAkumulasi.jenis == jenis && savedAkumulasi.period2 == period2)) {
             
                this.setState((prevState) => ({
                  ...prevState,
                  savedAkumulasi: {
                    period2 : period2,
                    jenis: jenis,
                    units_value: units_value,
                    perwakilan_value: perwakilan_value
                  },
                }));
                listname = "tunggakanakumulasi"
                result = await handleListArrearsAccumulationsPerwakilanRutin({
                  period2 : period_label,
                  currents_year : period2,
                  units_id : units_value,
                  organizations_id : organizations_id,
                });
            }
          }
          else if (tunggakan === "akumulasi" && perwakilan_value === "all" && units_value === "all_unit" && jenis == 'nonrutin' && period2 && payments_id) {
            if (!(savedAkumulasi && savedAkumulasi.perwakilan_value == "all" && savedAkumulasi.units_value == "all_unit" && savedAkumulasi.jenis == jenis && savedAkumulasi.period2 == period2 && savedAkumulasi.payments_id == payments_id)) {
             
                this.setState((prevState) => ({
                  ...prevState,
                  savedAkumulasi: {
                    payments_id: payments_id,
                    period2 : period2,
                    jenis: jenis,
                    units_value: units_value,
                    perwakilan_value: perwakilan_value
                  },
                }));
                listname = "tunggakanakumulasi"
                result = await handleListArrearsAccumulationsPerwakilanNonRutin({
                  period2 : period_label,
                  currents_year : period2,
                  payments_id: payments_id,
                  perwakilan_id: perwakilan_id.value,
                  units_id : units_value,
                  organizations_id : organizations_id,
                });
            }
          }
          
          if (listname != "") {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  [listname]: result,
                },
              },
            }));
          }
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                tunggakansiswa: [],
                tunggakankelas: [],
                tunggakanakumulasi: [],
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async _getSchoolUnitsOptions(filters={}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id); 
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    // const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
      // delete filters.units_id;

    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value : {
              ...prevState.form.value,
              year: data.value,
            }
          },
        }))
      }
    });

    // this.setState((prevState) => ({
    //   ...prevState,
    //   param: {
    //     ...prevState.param,
    //     periods: res,
    //   },
    // }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  // async _getStudentOptions(filters = {}) {
  //   const { getStudentOptions } = this.props;
  //   const res = await getStudentOptions(filters);
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       student: res,
  //     },
  //   }));
  // }
  async _getMsTempsSiswaOptions(filters = {}) {
    const { getMsTempsSiswaOptions } = this.props;
    const res = await getMsTempsSiswaOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getStudentSPPOptions(filters = {}) {
    const { getStudentSPPOptions } = this.props;
    const res = await getStudentSPPOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student_prm: res,
      },
    }));
  }

  render() {
    const { form, filters, param, page, isPrint, data_idx, isTable } = this.state;
    const { classrooms_id = "", tunggakan, period = "", period2 = "", students_id, payments_id, jenis } = filters;
    const { tunggakansiswa, tunggakankelas, tunggakanakumulasi } = form.value
    const { user } = this.props
    const { school_unit, organizations_id, user_group } = user;
    const { prm_payments } = param;
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }

    const prm_jenis = [
      { label: organizations_id ==3?'SPP':'RUTIN', value: 'rutin'},
      { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'nonrutin'},
    ];

    const componentRef = React.createRef();
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    const prm_tunggakan = [
      { label: 'Siswa', value: 'siswa'},
      { label: 'Kelas', value: 'kelas'},
      { label: 'Akumulasi', value: 'akumulasi'},
    ];
    const prm_tunggakan_unit = [
      { label: 'Akumulasi', value: 'akumulasi'},
    ];
    const AllUnit = [
      { label: 'Semua Unit', value: 'all_unit'},
    ];
    const All = [{value: 'all', label: "Semua Perwakilan", attributes: {}}]

    var ttl_siswaNonRutin = 0;
    var ttl_siswaThnLalu = 0;
    var ttl_siswaRutin = 0;
    if(!isEmpty(tunggakansiswa)) {
      ttl_siswaThnLalu = !isEmpty(tunggakansiswa[0].arrears_students_last_year) ? ((tunggakansiswa[0].arrears_students_last_year[0].total_tunggakan_last_year)) : 0;
    }
    let student_name =""
    let student_va =""
    if (!isEmpty(students_id)){
      let str=students_id.label
      let result = str.indexOf("-");
      student_name = str.substring(result+2, str.length)
      student_va = str.substring(0, result-1)
    }

    var ttl_klsThnlalu = 0;
    var ttl_klsDPP = 0;
    var ttl_klsUPP = 0;
    var ttl_klsUPA = 0;
    var ttl_klsSPP = 0;
    var ttl_klsSPP2 = 0;
    var ttl_persen = 0;
    var rata2 = 0;
    var toFixed = 0;
    var ttl_kls = 0;

    var ttl_akmThnlalu = 0;
    var ttl_akmDPP = 0;
    var ttl_akmUPP = 0;
    var ttl_akmUPA = 0;
    var ttl_akmSPP = 0;
    var ttl_akm = 0;

    var payments_name;

    let length = tunggakankelas?tunggakankelas.length:0;
    let persen_length = 0;

    const prm_units = param.units ? param.units : '';
    const units_all = [...AllUnit, ...prm_units]
    const years = []
    const thisyear = new Date().getFullYear();
    for(var i=2021; i<=thisyear; i++) {
      var nextyear=Number(i)+1
      years.push({
        label: i+"-"+nextyear,
        value: i,
      });
    }

    

    map(param.prm_payments, (data, idx) => {
      if (data.value == payments_id) {
        payments_name = data.label
      }
    })

    let period_label = '';

    if(period) {
       period_label = param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""
      if (period_label && period_label != "") {
        let period_idx = period_label.indexOf("-");
        form.value.currents_year = period_label.substring(0, period_idx)
      }
    }
    if(period2) {
       period_label = param.periods.find((i) => i.value == period2) ? param.periods.find((i) => i.value == period2).label : ""
      if (period_label && period_label != "") {
        let period_idx = period_label.indexOf("-");
        form.value.currents_year2 = period_label.substring(0, period_idx)
      }
    }
    let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';
    const prm_perwakilan= param.perwakilan_prm ? param.perwakilan_prm : '';
    const perwakilan_all = [...All, ...prm_perwakilan]
    console.log(isTable)

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Tunggakan</h1>
          <hr></hr>
          <br></br>
        </div>

        <div className="absensi-rekap__custom-form">
        {(user_group.name === 'pusat' || user_group.name === "perwakilan")? 
              <div>
                 <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              {user_group.name == "pusat"?
               
                <SearchSelect
                  noMargin
                  async={false}
                  name="perwakilan_id"
                  list={perwakilan_all}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Perwakilan"
                  value={form.value.perwakilan_id}
                  rightIcon="icon-search"
                  label="Pilih Perwakilan"
                />
                :''}
              </div>
            </div>
              <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                  <SearchSelect
                    noMargin
                    async={false}
                    name="units_id"
                    list={units_all}
                    inputArray
                    onClick={this._onChangeFilter}
                    placeholder="Pilih Unit"
                    value={form.value.units_id}
                    rightIcon="icon-search"
                    label="Pilih Unit"
                  />
              </div>
            </div>
            </div>
            :''}
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              <Select
                name="tunggakan"
                label="Tunggakan"
                onChange={this._onChangeFilter}
                data={form.value.units_id.value == 'all_unit' ? prm_tunggakan_unit : prm_tunggakan}
                placeholder="Pilihan"
                value={tunggakan}
              />
            </div>
            <div className="manage-registration__custom-form-row__field">
            {tunggakan == "akumulasi" && form.value.units_id.value == 'all_unit'?
              <Select
              name="jenis"
              label="Jenis Pembayaran"
              onChange={this._onChangeFilter}
              data={prm_jenis}
              placeholder="Pilihan"
              value={jenis}
            />
            :''}
            </div>
          </div>
          {tunggakan == "kelas" || tunggakan == "akumulasi" ?
          <>
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                {form.value.units_id.value !== 'all_unit'?
                <Select
                  type="text"
                  name="levels_id"
                  label="Unit"
                  placeholder="Pilih Unit"
                  data={param.levels}
                  disabled
                  value={form.value.levels_id}
                  onChange={this._onFormChange}
                  error={form.error.levels_id || ""}
                />
                :''}
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                {(tunggakan == "kelas" && form.value.levels_id) && (
                  <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={period}
                />
                )}
                {(tunggakan == "akumulasi" && form.value.levels_id) && (
               
                  <Select
                    name="jenis"
                    label="Jenis Pembayaran"
                    onChange={this._onChangeFilter}
                    data={prm_jenis}
                    placeholder="Pilihan"
                    value={jenis}
                  />
                )}
              </div>
            </div>
            
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                {(tunggakan == "kelas" && period) && (
                  <Select
                    name="jenis"
                    label="Jenis Pembayaran"
                    onChange={this._onChangeFilter}
                    data={prm_jenis}
                    placeholder="Pilihan"
                    value={jenis}
                  />
                )}
                {(tunggakan == "akumulasi" && jenis == "rutin") && (
                     <Select
                    name="period2"
                    label="Tahun Ajaran"
                    onChange={this._onChangeFilter}
                    data={form.value.units_id.value == 'all_unit' ? years : param.periods}
                    placeholder={"Pilihan"}
                    value={period2}
                  />
                )}
                {(tunggakan == "akumulasi" && jenis == "nonrutin") && (
                    <Select
                    name="period2"
                    label="Tahun Ajaran"
                    onChange={this._onChangeFilter}
                    data={form.value.units_id.value == 'all_unit' ? years : param.periods}
                    placeholder={"Pilihan"}
                    value={period2}
                  />
                )}
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                  {(tunggakan == "kelas" && jenis == "rutin" ) && (
                  <Select
                  type="text"
                  name="classrooms_id"
                  label="Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                  value={classrooms_id}
                />
                )}
                {(tunggakan == "kelas" && jenis == "nonrutin") && (
                  <Select
                  type="text"
                  name="classrooms_id"
                  label="Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                  value={classrooms_id}
                />
              )}
              {(tunggakan == "akumulasi" && jenis == "nonrutin" && period2)  && (
                     
                     <Select
                      name="payments_id"
                      label="Nama Pembayaran"
                      onChange={this._onChangeFilter}
                      data={organizations_id == 3 ? prm_payments : prm_payments_main}
                      placeholder={"Pilihan"}
                      value={payments_id}
                    />
                )}
                </div>
            </div>
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                {(tunggakan == "kelas" && period && jenis === "nonrutin") && (
                   <Select
                   name="payments_id"
                   label="Nama Pembayaran"
                   onChange={this._onChangeFilter}
                   data={organizations_id == 3 ? prm_payments : prm_payments_main}
                   placeholder={"Pilihan"}
                   value={payments_id}
                 />
                )}
                  
              </div>
              </div>
            </>
            :''}
             {tunggakan == "siswa" && (
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="students_id"
                  list={organizations_id == 3?param.student:param.student_prm}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Siswa"
                  value={students_id}
                  rightIcon="icon-search"
                  label="Siswa"
                />
              </div>
            </div>
          )}
          <br></br>
         
             <Button
              className="button_save"
              title="Proses"
              onClick={this._onClickSet}
              />
          <hr></hr>
          <br></br>

          {(tunggakan && tunggakan != "" && !isTable) && (
            <div className="beginning-balance__button-wrapper">
              <ReactToPrint
                pageStyle={printTest}
                onBeforeGetContent={()=> {
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      this.setState(
                        { isPrint: false },
                        resolve
                      );
                    }, 500);
                  });
                }}
                onAfterPrint={()=> this.setState({isPrint: true})}
                trigger={() => (
                  <Button title="Cetak" />
                )}
                content={() => componentRef.current}
              />
            </div>
          )}

          <br></br>
         

          {/* {tunggakan == "akumulasi" && (
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Select
                  name="period2"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={period2}
                />
              </div>
              <div className="manage-registration__custom-form-row__field">
              </div>
            </div>
          )} */}
          <br></br>
        </div>

        <form style={{ pageBreakInside: 'avoid' }} ref={componentRef}>
          {(tunggakan=="siswa" && !isEmpty(tunggakansiswa) && !isTable) && (
            <div className="cetak-buku-induk2__header-title3">
              <div className="cetak-buku-induk2__header-title3--left">
                {!isPrint && (
                  <>
                    <table className="table-report-header">
                      <tr>
                        <th rowSpan="2">
                          <img style={{width:"70px"}} src={logo} />
                        </th>
                        <th style={{ paddingRight: '10rem' }}>
                          <h2>{school_unit.name}</h2>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ paddingRight: '10rem' }}>
                          <h3>Tunggakan Siswa</h3>
                        </th>
                      </tr>
                    </table>
                    <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                      <tr>
                        <td>No. VA </td>
                        <td>&emsp; : {student_va}</td>
                      </tr>
                      <tr>
                        <td>Nama Siswa</td>
                        <td>&emsp; : {student_name}</td>
                      </tr>
                    </table>
                  </>
                )}
                <br></br>
                <table className="cetak-buku-induk2__table-test" style={{width:"unset"}}>
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan={5}>
                      <div className="cetak-buku-induk2__title">
                        <p>Tagihan Sekali Setahun</p>
                      </div>
                    </td>
                  </tr>
                  {!isEmpty(tunggakansiswa[0].arrears_students_non_rutin) && (
                    map(tunggakansiswa[0].arrears_students_non_rutin, (list, idx) => (
                      <tr>
                        {(() => {
                          ttl_siswaNonRutin = ttl_siswaNonRutin + Number(list.total_tunggakan)
                        })()}
                        <td>- </td>
                        <td colSpan={2}>{list.nick_name} </td>
                        <td>Rp. &emsp;</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.total_tunggakan)}</td>
                      </tr>
                    ))
                  )}
                  <tr style={{fontWeight:"bold"}}>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="cetak-buku-induk2__title">
                        <p>&emsp; Jumlah Tunggakan Sekali Setahun &emsp;&emsp;</p>
                      </div>
                    </td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>{commaFormatted(ttl_siswaNonRutin)}</td>
                  </tr>
                  <br></br>
                  
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan={5}>
                      <div className="cetak-buku-induk2__title">
                        <p>Tagihan Rutin Bulanan</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>a. </td>
                    <td colSpan={2}>Akumulasi Tahun Ajaran Sebelumnya &emsp;&emsp;</td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>
                      {commaFormatted(ttl_siswaThnLalu)}
                    </td>
                  </tr>
                  <tr>
                    <td>b. </td>
                    <td colSpan={4}>Akumulasi Tahun Ajaran Berjalan &emsp;&emsp;</td>
                  </tr>
                  {!isEmpty(tunggakansiswa[0].arrears_students_rutin) && (
                    map(tunggakansiswa[0].arrears_students_rutin, (list, idx) => (
                      <tr>
                        {(() => {
                          if (list.payments_date != null) {
                            list.nominal = 0;
                          }
                          ttl_siswaRutin = ttl_siswaRutin + Number(list.nominal);
                        })()}
                        <td></td>
                        <td>- </td>
                        <td>SPP {list.month} </td>
                        <td>Rp. &emsp;</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.nominal)}</td>
                      </tr>
                    ))
                  )}
                  <tr style={{fontWeight:"bold"}}>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="cetak-buku-induk2__title">
                        <p>&emsp; Jumlah Tunggakan Rutin Bulanan &emsp;&emsp;</p>
                      </div>
                    </td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>{commaFormatted(ttl_siswaRutin)}</td>
                  </tr>
                  <tr style={{fontWeight:"bold"}}>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="cetak-buku-induk2__title">
                        <p>&emsp; Total Tunggakan &emsp;&emsp;</p>
                      </div>
                    </td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>{commaFormatted(Number(ttl_siswaNonRutin)+Number(ttl_siswaThnLalu)+Number(ttl_siswaRutin))}</td>
                  </tr>
                  <br></br>
                  
                </table>
              </div>
            </div>
          )}
          {(tunggakan=="kelas" 
          // && !isEmpty(tunggakankelas)
          ) && (
            <div className="cetak-buku-induk2__header-title3">
              <div className="cetak-buku-induk2__header-title3--left" style={{marginLeft:"unset"}}>
                {!isPrint && (
                  <>
                    <table className="table-report-header">
                      <tr>
                        <th rowSpan="2">
                          <img style={{width:"70px"}} src={logo} />
                        </th>
                        <th style={{ paddingRight: '10rem' }}>
                          <h2>{school_unit.name}</h2>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ paddingRight: '10rem' }}>
                          <h3>Tunggakan Kelas</h3>
                        </th>
                      </tr>
                    </table>
                    <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                      <tr>
                        <td>Jenis Tunggakan</td>
                        <td>&emsp; : {jenis == "rutin"? "SPP" : payments_name}</td>
                      </tr>
                      <tr>
                        <td>Tahun Ajaran </td>
                        {/* <td>&emsp; : {period+"/"+(Number(period)+1)}</td> */}
                        <td>&emsp; : {param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""}</td>
                      </tr>
                      
                    </table>
                  </>
                )}
                <br></br>
                {(jenis == "rutin" && classrooms_id && !isTable) && (
                  <table className="tablespp">
                  <thead>
                    <tr>
                      <th style={{width:5}} rowSpan={2}>No</th>
                      <th style={{width:15}} rowSpan={2}>No. VA</th>
                      <th style={{width:30}} rowSpan={2}>Nama</th>
                      <th style={{width:50}} colSpan={5}>Tunggakan SPP</th>
                    </tr>
                    <tr>
                      <th style={{width:10}}>Σ Tahun Lalu</th>
                      <th style={{width:10}}>YHD</th>
                      <th style={{width:10}}>YSD (Penerimaan)</th>
                      <th style={{width:10}}>Total Tunggakan</th>
                      <th style={{width:10}}>%</th>
                    </tr>
                  </thead>
                  <tbody>

                    {map(tunggakankelas, (list, idx) => (
                      <tr>
                        {(() => {
                          list.listthnlalu = list.last_year?list.last_year[0]:0
                          list.thnlalu = list.listthnlalu? list.last_year[0].total_tunggakan_last_year : 0
                          // list.dpp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP").total_tunggakan : 0)
                          // list.upp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP").total_tunggakan : 0)
                          // list.upa = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA").total_tunggakan : 0)
                          list.spp = list.YHD[0] ? list.YHD[0].total_tunggakan_spp : 0
                          list.spp2 = list.YSD[0] ? list.YSD[0].total_tunggakan_spp : 0
                          list.jmlh = Number(list.thnlalu) + Number(list.spp) - Number(list.spp2)
                          list.persentase = (Number(list.jmlh) / (Number(list.thnlalu) + Number(list.spp))) * 100
                          list.persentase_ttl = list.persentase ? list.persentase : 0;
                          if(list.persentase_ttl !== 0){
                            persen_length++;
                          }
                          //ttl_klsThnlalu = tunggakan tahun lalu perkelas
                          //ttl_klsSPP = YHD perkelas
                          //ttl_klsSPP2 = YSD perkelas
                          //ttl_kls = total tunggakan
                          ttl_klsThnlalu = ttl_klsThnlalu + Number(list.thnlalu);
                          ttl_klsSPP = ttl_klsSPP + Number(list.spp);
                          ttl_klsSPP2 = ttl_klsSPP2 + Number(list.spp2);
                          ttl_kls = ttl_kls + list.jmlh;
                          ttl_persen = Number(ttl_kls) / (ttl_klsThnlalu + ttl_klsSPP ) * 100;
                          // rata2 = Number(ttl_persen) / Number(persen_length);
                          toFixed = ttl_persen ? ttl_persen.toFixed() : 0;
                        })()}
                          {console.log(ttl_persen)}

                        <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                          {idx + 1}
                        </td>
                        <td style={{textAlign:"center"}}>{list.no_va}</td>
                        <td style={{textTransform: "capitalize"}}>{capitalize(list.name)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.thnlalu)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp2)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.jmlh)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.persentase_ttl)}%</td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr>
                      <th colSpan={3}>Total</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsThnlalu)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP2)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_kls)}</th>
                      <th style={{textAlign:"right"}}>{toFixed}%</th>
                    </tr>
                  </thead>
                </table>
                )}
              
              {(jenis == "nonrutin" && payments_id && !isTable) && (
                <table className="tablespp">
                  <thead>
                    <tr>
                      <th style={{width:5}} rowSpan={2}>No</th>
                      <th style={{width:15}} rowSpan={2}>No. VA</th>
                      <th style={{width:30}} rowSpan={2}>Nama</th>
                      <th style={{width:50}} colSpan={4}>Tunggakan {payments_name}</th>
                    </tr>
                    <tr>
                      <th style={{width:15}}>YHD</th>
                      <th style={{width:15}}>YSD (Penerimaan)</th>
                      <th style={{width:15}}>Total Tunggakan</th>
                      <th style={{width:10}}>%</th>
                    </tr>
                  </thead>
                  <tbody>
                  {map(tunggakankelas, (list, idx) => (
                      <tr>
                        {(() => {
                         
                          // list.dpp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP").total_tunggakan : 0)
                          // list.upp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP").total_tunggakan : 0)
                          // list.upa = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA").total_tunggakan : 0)
                          list.spp = list.YHD[0] ? list.YHD[0].total_tunggakan_spp : 0
                          list.spp2 = list.YSD[0] ? list.YSD[0].total_tunggakan_spp : 0
                          list.jmlh = Number(list.spp) - Number(list.spp2)
                          list.persentase = (Number(list.jmlh) / (Number(list.spp))) * 100
                          list.persentase_ttl = list.persentase ? list.persentase : 0;
                          if(list.persentase_ttl !== 0){
                            persen_length++;
                          }
                          //ttl_klsSPP = YHD perkelas
                          //ttl_klsSPP2 = YSD perkelas
                          //ttl_kls = total tunggakan
                          ttl_klsSPP = ttl_klsSPP + Number(list.spp);
                          ttl_klsSPP2 = ttl_klsSPP2 + Number(list.spp2);
                          // rata2 = Number(ttl_persen) / Number(persen_length);
                          ttl_kls = ttl_kls + list.jmlh;
                          ttl_persen = Number(ttl_kls) / (ttl_klsSPP ) * 100;
                          toFixed = ttl_persen ? ttl_persen.toFixed() : 0;
                        })()}
                        <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                          {idx + 1}
                        </td>
                        <td style={{textAlign:"center"}}>{list.no_va}</td>
                        <td style={{textTransform: "capitalize"}}>{capitalize(list.name)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp2)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.jmlh)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.persentase_ttl)}%</td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr>
                      <th colSpan={3}>Total</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP2)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_kls)}</th>
                      <th style={{textAlign:"right"}}>{toFixed}%</th>
                    </tr>
                  </thead>
                </table>
              )}
                
              </div>
            </div>
          )}
          {(tunggakan=="akumulasi"
          // !isEmpty(tunggakanakumulasi
          //   )
            ) && (
            <div className="cetak-buku-induk2__header-title3">
              <div className="cetak-buku-induk2__header-title3--left" style={{marginLeft:"unset"}}>
                {!isPrint && (
                  <>
                    <table className="table-report-header">
                      <tr>
                        <th rowSpan="2">
                          <img style={{width:"70px"}} src={logo} />
                        </th>
                        <th style={{ paddingRight: '10rem' }}>
                          <h2>{school_unit.name}</h2>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ paddingRight: '10rem' }}>
                          <h3>Tunggakan Akumulasi</h3>
                        </th>
                      </tr>
                    </table>
                    <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                      <tr>
                        <td>Jenis Tunggakan</td>
                        <td>&emsp; : {jenis == "rutin"? "SPP" : payments_name}</td>
                      </tr>
                      <tr>
                        <td>Tahun Ajaran </td>
                        <td>&emsp; : {param.periods.find((i) => i.value == period2) ? param.periods.find((i) => i.value == period2).label : ""}</td>
                      </tr>
                    </table>
                  </>
                )}
                <br></br>
                {(jenis == "rutin" && period2 && !isTable) && (
                <table className="tablespp">
                  <thead>
                    <tr>
                      <th rowSpan={2}>No</th>
                      <th style={{width:30}} rowSpan={2}> {form.value.units_id.value == 'all_unit'?"Unit" : "Kelas"}</th>
                      <th style={{width:70}} colSpan={5}>Tunggakan SPP</th>
                    </tr>
                    <tr>
                      <th style={{width:15}}>Σ Tahun Lalu</th>
                      <th style={{width:15}}>YHD</th>
                      <th style={{width:15}}>YSD (Penerimaan)</th>
                      <th style={{width:15}}>Total Tunggakan</th>
                      <th style={{width:5}}>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(tunggakanakumulasi, (list, idx) => (
                      <tr>
  
                        {(() => {
                          list.thnlalu = list.last_year? list.last_year : 0
                          // list.dpp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP").total_tunggakan : 0)
                          // list.upp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP").total_tunggakan : 0)
                          // list.upa = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA").total_tunggakan : 0)
                          list.spp = list.YHD ? list.YHD : 0
                          list.spp2 = list.YSD ? list.YSD : 0
                          list.jmlh = Number(list.thnlalu) + Number(list.spp) - Number(list.spp2)
                          list.persentase = (Number(list.jmlh) / (Number(list.thnlalu) + Number(list.spp))) * 100
                          list.persentase_ttl = list.persentase ? list.persentase : 0;

                          if(list.persentase_ttl !== 0){
                            persen_length++;
                          }
                          //ttl_klsThnlalu = tunggakan tahun lalu perkelas
                          //ttl_klsSPP = YHD perkelas
                          //ttl_klsSPP2 = YSD perkelas
                          //ttl_kls = total tunggakan
                          ttl_klsThnlalu = ttl_klsThnlalu + Number(list.thnlalu);
                          ttl_klsSPP = ttl_klsSPP + Number(list.spp);
                          ttl_klsSPP2 = ttl_klsSPP2 + Number(list.spp2);
                          // rata2 = Number(ttl_persen) / Number(persen_length);
                          ttl_kls = ttl_kls + list.jmlh;
                          ttl_persen = Number(ttl_kls) / (ttl_klsThnlalu + ttl_klsSPP ) * 100;
                          toFixed = ttl_persen ? ttl_persen.toFixed() : 0;
                        })()}
                        <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                          {idx + 1}
                        </td>
                        <td>{(list.name)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.thnlalu)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp2)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.jmlh)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.persentase_ttl)}%</td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsThnlalu)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP2)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_kls)}</th>
                      <th style={{textAlign:"right"}}>{toFixed}%</th>
                    </tr>
                  </thead>
                </table>
                )}
                
                {(jenis == "nonrutin" && payments_id && !isTable) && (
                  <table className="tablespp">
                  <thead>
                    <tr>
                      <th rowSpan={2}>No</th>
                      <th style={{width:30}} rowSpan={2}> {form.value.units_id.value == 'all_unit'?"Unit" : "Kelas"}</th>
                      <th style={{width:70}} colSpan={4}>Tunggakan {payments_name}</th>
                    </tr>
                    <tr>
                      <th style={{width:15}}>YHD</th>
                      <th style={{width:15}}>YSD (Penerimaan)</th>
                      <th style={{width:15}}>Total Tunggakan</th>
                      <th style={{width:15}}>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(tunggakanakumulasi, (list, idx) => (
                      <tr>
                        {(() => {
                          list.spp = list.YHD ? list.YHD : 0
                          list.spp2 = list.YSD ? list.YSD : 0

                          list.jmlh = Number(list.spp) - Number(list.spp2)
                          list.persentase = (Number(list.jmlh) / (Number(list.spp))) * 100
                          list.persentase_ttl = list.persentase ? list.persentase : 0;

                          if(list.persentase_ttl !== 0){
                            persen_length++;
                          }

                          ttl_klsSPP = ttl_klsSPP + Number(list.spp);
                          ttl_klsSPP2 = ttl_klsSPP2 + Number(list.spp2);
                          // rata2 = Number(ttl_persen) / Number(persen_length);
                          ttl_kls = ttl_kls + list.jmlh;
                          ttl_persen = Number(ttl_kls) / (ttl_klsSPP ) * 100;
                          toFixed = ttl_persen ? ttl_persen.toFixed() : 0;
                        })()}
                        <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                          {idx + 1}
                        </td>
                        <td>{list.name}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp2)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.jmlh)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.persentase_ttl)}%</td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr>
                    <th colSpan={2}>Total</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP2)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_kls)}</th>
                      <th style={{textAlign:"right"}}>{toFixed}%</th>
                    </tr>
                  </thead>
                </table>
                )}
              </div>
            </div>
          )}
        </form>

      </div>
    );
  }
}
Tunggakan.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Tunggakan.defaultProps = {
  handleGetStudent: noop,
};
