import { connect } from 'react-redux';
import ViewAdjustmentJournal
 from '../../../../views/Finance/Bookkeeping/AdjustmentJournal/ViewAdjustmentJournal.bookkeeping.view';
import {
  getAdjustmentJournalById,
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournalById(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAdjustmentJournal);
