import { connect } from 'react-redux';
import RekapPenyesuaian from '../../../../views/Finance/Spp/LaporanSPP/RekapPenyesuaian.view';
import { listAdjustmentRecapUnits, listAdjustmentRecapAllUnits, listAdjustmentRecapPerwakilan, listAdjustmentRecapUnitsRutin, listAdjustmentRecapAllUnitsRutin, listAdjustmentRecapPerwakilanRutin } from '../../../../states/thunks/academics.thunk';
import { getPrmLevels, getParamOptionsAcademics, getYearsOfEducationsOptions, getPrmPaymentsNon, getPeriodsOptions, getSchoolUnitsOptions, getPrmPaymentsNonGeneral, getParamPerwakilan } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleListAdjustmentRecapUnits: (payload, goback) => dispatch(listAdjustmentRecapUnits(payload, goback)),
    handleListAdjustmentRecapAllUnits: (payload, goback) => dispatch(listAdjustmentRecapAllUnits(payload, goback)),
    handleListAdjustmentRecapPerwakilan: (payload, goback) => dispatch(listAdjustmentRecapPerwakilan(payload, goback)),
    handleListAdjustmentRecapUnitsRutin: (payload, goback) => dispatch(listAdjustmentRecapUnitsRutin(payload, goback)),
    handleListAdjustmentRecapAllUnitsRutin: (payload, goback) => dispatch(listAdjustmentRecapAllUnitsRutin(payload, goback)),
    handleListAdjustmentRecapPerwakilanRutin: (payload, goback) => dispatch(listAdjustmentRecapPerwakilanRutin(payload, goback)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmPaymentsNonGeneral: (payload) => dispatch(getPrmPaymentsNonGeneral(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getParamPerwakilan: (payload) => dispatch(getParamPerwakilan(payload)),
 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RekapPenyesuaian);
