import { connect } from 'react-redux';
import PembatalanSiswa from '../../../views/Akademi/DataKelas/PembatalanSiswa.view';
import { listStudentsPPDB, saveClassDivPPDB, listStudentsClassDiv, saveNextClassDiv, listPPDBOffline, saveClassPPDBOffline, 
        listStudentsPPDBF07, listStudentsClassDivGeneral, listStudentClassroomsNull, listStudentGraduate, 
        listStudentPPDBReject, listStudentsPPDBAll, deleteApiAuth, listStudentPPDBGeneralReject} from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getPrmLevels, getStudentPPDBF07, getClassesOptions, getNewStudentOptions, getClassroomOptions, getStudentPPDB, getStudentNextClass, 
        getParamOptionsAcademics, getNewStudentPPDBOptions, getYearsOfEducationsOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListStudentsPPDB: (payload, goback) => dispatch(listStudentsPPDB(payload, goback)),
    handleListStudentsPPDBF07: (payload, goback) => dispatch(listStudentsPPDBF07(payload, goback)),
    handleSaveClassDivPPDB: (payload, goback) => dispatch(saveClassDivPPDB(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getNewStudentOptions: (payload) => dispatch(getNewStudentOptions(payload)),
    getClassroomsOptions: (payload) => dispatch(getClassroomOptions(payload)),
    getStudentPPDBOptions: (payload) => dispatch(getStudentPPDB(payload)),
    getStudentPPDBF07Options: (payload) => dispatch(getStudentPPDBF07(payload)),
    getStudentNextClassOptions: (payload) => dispatch(getStudentNextClass(payload)),
    getNewStudentPPDBOptions: (payload) => dispatch(getNewStudentPPDBOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentsClassDiv: (payload, goback) => dispatch(listStudentsClassDiv(payload, goback)),
    handleSaveNextClassDiv: (payload, goback) => dispatch(saveNextClassDiv(payload, goback)),
    handleListPPDBOffline:(payload, goback) => dispatch(listPPDBOffline(payload, goback)),
    handleSaveClassPPDBOffline:(payload, goback) => dispatch(saveClassPPDBOffline(payload, goback)),
    handleListStudentsClassDivGeneral:(payload, goback) => dispatch(listStudentsClassDivGeneral(payload, goback)),
    handleListStudentClassroomsNull:(payload, goback) => dispatch(listStudentClassroomsNull(payload, goback)),
    handleListStudentGraduate:(payload, goback) => dispatch(listStudentGraduate(payload, goback)),
    handleListStudentPPDBReject:(payload, goback) => dispatch(listStudentPPDBReject(payload, goback)),
    handleListStudentPPDBAll:(payload, goback) => dispatch(listStudentsPPDBAll(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    handleDeleteApiAuth: (payload) => dispatch(deleteApiAuth(payload)),
    handlelistStudentPPDBGeneralReject:(payload, goback) => dispatch(listStudentPPDBGeneralReject(payload, goback)),
  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PembatalanSiswa);