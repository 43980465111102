import { connect } from 'react-redux';
import Identitas from '../../../views/Akademi/DataKelas/Identitas.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { listUnits } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleListUnits: (payload, goback) => dispatch(listUnits(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Identitas);
