import { connect } from 'react-redux';
import TambahIdentitas from '../../../views/Akademi/DataKelas/TambahIdentitas.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { getProvinces, getCities, getDistricts, getSubDistricts, getTeacherOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { uploadImageFileStudents, saveUnits, getUnits, editUnits } from '../../../states/thunks/student.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    provinces: state.options.provinces,
    cities: state.options.cities,
    districts: state.options.districts,
    sub_districts: state.options.sub_districts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetProvinces: payload => dispatch(getProvinces(payload)),
    handleGetCities: payload => dispatch(getCities(payload)),
    handleGetDistricts: payload => dispatch(getDistricts(payload)),
    handleGetSubDistricts: payload => dispatch(getSubDistricts(payload)),
    getTeacherOptions: (payload) => dispatch(getTeacherOptions(payload)),
    handleUploadImageFileStudents: (payload, goback) => dispatch(uploadImageFileStudents(payload, goback)),
    handleSaveUnits: (payload, goback) => dispatch(saveUnits(payload, goback)),
    handleGetUnits: payload => dispatch(getUnits(payload)),
    handleEditUnits: (payload, goback) => dispatch(editUnits(payload, goback)), 
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahIdentitas);
