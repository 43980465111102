import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function PengisianNilaiAfekForm (props) {


  const { onFormChange, form } = props;
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Input
            name="mapel"
            label="Mata Pelajaran"
            onChange={onFormChange}
            data={ '01', 'kelas7a'}
            disabled
            value={form.value.mapel}
            error={form.error.mapel || ''}
            style={{backgroundColor:'#999'}}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
        <Input
                type='text'
                name="score_max"
                disabled
                label="Skor Maks"
                onChange={onFormChange}
                value={form.value.score_max}
                error={form.error.score_max || ''}
                style={{backgroundColor:'#999'}}
                placeholder={'100'}
            />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Select
            name="jenis_ulangan"
            label="Jenis Ulangan"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'UH/Ulangan Harian'}
            value={form.value.jenis_ulangan}
            error={form.error.jenis_ulangan || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
        <Textarea
                type='text'
                name="nilai"
                label="Nilai"
                disabled
                onChange={onFormChange}
                style={{backgroundColor:'#999'}}
                placeholder={'1 = Sangat Kurang, 2 =  Kurang, 3 = Cukup, 4 = Baik, 5 = Sangat Baik'}
                value={form.value.nilai}
                error={form.error.nilai || ''}
            />
          
       
         
        </div>
        </div>

        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Input
                type='classes'
                name="namaSaudara1"
                label="Kelas"
                disabled
                onChange={onFormChange}
                style={{backgroundColor:'#999'}}
                value={form.value.classes}
                error={form.error.classes || ''}
            />
        </div>
         <div className="nilai-kognitif__custom-form-column__field">
            
         <Input
                type='text'
                name="kkm"
                label="KKM"
                onChange={onFormChange}
                style={{backgroundColor:'#999'}}
                value={form.value.kkm}
                error={form.error.kkm || ''}
            />
          
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row2">
        <div className="nilai-kognitif__custom-form-row2__field">
        <Select 
                type='guru'
                name="namaSaudara1"
                label="Guru"
                onChange={onFormChange}
                value={form.value.guru}
                error={form.error.guru || ''}
            />
        </div>
         <div className="nilai-kognitif__custom-form-column__field">
            
            <Input
                type='text'
                name="materi"
                label="Materi"
                disabled
                onChange={onFormChange}
                value={form.value.materi}
                error={form.error.materi || ''}
                style={{backgroundColor:'#999'}}
            />
          
        </div>
        </div>
        </div>
        
 
   
  );
}
PengisianNilaiAfekForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianNilaiAfekForm.defaultProps = {
  onFormChange: noop,
};
