import { connect } from 'react-redux';
import CetakSuratPindah from '../../../views/Student/DataSiswa/CetakSuratPindah.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getStudentOptions, getParamOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { getMoveRecord} from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetMoveRecord: payload => dispatch(getMoveRecord(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CetakSuratPindah);


