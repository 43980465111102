import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { STUDENT_UNIT_2_FORM_FIELDS } from "../../../constants/student.constant";
import { DAFTAR_NILAI_FORM_FIELDS } from "../../../constants/kurikulum/ulangan.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { Button } from "../../../components/base/index";
import BuatKodeMatpelForm from "./components/BuatKodeMatpelForm.component";
import BuatKodeMatpelTable from "./components/BuatKodeMatpelTable.component";

export default class BuatKodeMatpel extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getMajorOptions = this._getMajorOptions.bind(this);
    this._getSubjectTypeOptions = this._getSubjectTypeOptions.bind(this);
    this._getSubjectGroupOptions = this._getSubjectGroupOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);


    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    this.state = {
      form: {
        value: {
          units_id: school_unit.id,
          levels_id,
          organizations_id: organizations_id,

        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    const { handleGetClasses, location, getLevels, getClasses, user } =
      this.props;
    const { school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = location.state;
    const { id } = state.data || "";
    handleGetClasses();
    const paramTypes = ["classes", "classrooms", "levels"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
      // this._getStudentOptions();
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    this._getMajorOptions({ filters: { units_id: [school_unit.id] } });
    this._getSubjectTypeOptions();
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
    this._getSubjectGroupOptions();
    this._getPeriodsOptions({units_id: school_unit.id});
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
  }

  async _getSubjectTypeOptions() {
    const { getSubjectTypeOptions } = this.props;
    const res = await getSubjectTypeOptions();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        tipe_matpel: res,
      },
    }));
  }

  async _getSubjectGroupOptions() {
    const { getSubjectGroupOptions } = this.props;
    const res = await getSubjectGroupOptions();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        group_matpel: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    // filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user, location, handleGetSubjectDetail } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = location.state;
    const { id } = state.data || "";

    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
          // tahun ajaran sekarang
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
              academic_year: data.value,
            }
          }
          }))
        
       
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetSubjectDetail } = this.props;
    try {
      const payload = await handleGetSubjectDetail({ id });
      const { classes, levels_id } = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] } });

      this.setState({
        form: {
          value: {
            id: payload.id,
            code_subject: payload.code_subject,
            name: payload.name,
            nick_name: payload.nick_name,
            classrooms_id: payload.classrooms_id,
            group: payload.group,
            major_id: payload.major_id,
            include_ekskul: payload.include_ekskul,
            academic_year: payload.academic_year,
            tipe_matpel: payload.tipe_matpel,
            sikap: payload.sikap,
            kkm: payload.kkm,
            levels_id: payload.levels_id,
            classes_id: payload.classes_id,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getMajorOptions(filters = {}) {
    const { getMajorOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const res = await getMajorOptions(filters);
    filters.units_id = school_unit.id;

    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        major: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id: organizations_id,
          });
        }
      }
    );
  }

  async onSubmit() {
    const { history, handleSaveSubjectDetail } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    // const dataForm = form.value;
    // if(dataForm.levels_id && dataForm.other_class_name && dataForm.majors_id
    //   && dataForm.homeroom_teacher){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
    await handleSaveSubjectDetail(value);
    history.push("/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran");
    // }
  }

  async onEdit() {
    const { history, handleEditSubjectDetail } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditSubjectDetail(value);
    history.push("/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran");
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  }

  render() {
    const { form, param } = this.state;
    const { classes = {} } = this.props;
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Buat Kode Mata Pelajaran </h1>
          <hr></hr>
        </div>

        <div className="result__content">
          <BuatKodeMatpelForm
            param={param}
            form={form}
            onFormChange={this._onFormChange}
          />
        </div>

        {/*             
          <NilaiPsikTable
              form={form}
              onFormChange={this._onFormChange}
            /> */}

        <div className="manage-registration__footer">
          <br></br>
          {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
          )}
        </div>
      </div>
    );
  }
}
BuatKodeMatpel.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
  getSubjectTypeOptions: PropTypes.func,
};
BuatKodeMatpel.defaultProps = {
  handleGetClasses: noop,
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
  getSubjectTypeOptions: noop,
};
