import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { LAPORAN_ABSEN } from "../../../constants/student.constant";
import { ABSENSI_REKAP_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../../components/base/index";
import { LAPORAN_8355_TABLE_UNIT_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanGuruD4S1 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this.onClickCetak = this.onClickCetak.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getHeadmaster = this._getHeadmaster.bind(this);
    
    
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;


    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          headmaster: "",
          // levels_id,
          classes: [],
          levels: [],
          classes_id: "",
        },
        error: {
          classes: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
      headmaster: "",
    };
  }
  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetClasses,
      handleGetLevels,
      user
    } = this.props;
    const { organizations_id, school_unit } = user;
    const paramTypes = ["classes", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
    this._getHeadmaster();

  }

  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getHeadmaster(payload = {}) {
    const { getHeadmaster, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getHeadmaster({
      ...payload,
      organizations_id: organizations_id,
      units_id: school_unit.id,
    });
    // filters.organizations_id = organizations_id;
    // filters.units_id = units_id;

    this.setState((prevState) => ({
      ...prevState,
      headmaster: res,
    }));
  }

  
  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  // on formchange untuk form
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id, pendidikan_guru } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListReportGuruD4S1,
            handleListReportGuruS3,
            handleListReportGuruBelumSertifikat,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;
          let result = {};
          if (pendidikan_guru === undefined){
             result = await handleListReportGuruD4S1({
              ...params,
              organizations_id: organizations_id,
              filters,
              unit_code: school_unit.unit_code
            });
          }
          if (pendidikan_guru === '<D4/S1'){
             result = await handleListReportGuruD4S1({
              ...params,
              organizations_id: organizations_id,
              filters,
              unit_code: school_unit.unit_code
            });
          }
          if (pendidikan_guru === 'S1 - S3'){
             result = await handleListReportGuruS3({
              ...params,
              organizations_id: organizations_id,
              filters,
              unit_code: school_unit.unit_code
            });
          }
          if (pendidikan_guru === 'BELUM BERSERTIFIKAT PENDIDIK'){
             result = await handleListReportGuruBelumSertifikat({
              ...params,
              organizations_id: organizations_id,
              filters,
              unit_code: school_unit.unit_code
            });
          }
          
          // }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listteacher: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listteacher: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const {user} = this.props;
    const {organizations_id} = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
          this._onSearchContent();

        // if (name === 'ekskul_id') {
        //   this._getTeacherEkskulOptions({ filters: { ekskul_id: value }} );
        // }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    // console.log(plafon);
  }

  async onClickCetak() {
    const { history } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    history.push("/dashboard/kesiswaan/laporan/cetak-laporan-8355");
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    console.log(name);
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      headmaster
    } = this.state;
    const { classrooms_id = "", status_id = "", period = "" } = filters;
    const { listteacher } = form.value;
    const { classes = {}, levels = {}, user } = this.props;
   
    const { school_unit } = user;
    const school_unit_name = school_unit.name.toUpperCase();
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];

    var month = monthNames[new Date().getMonth()];
    var year = new Date().getFullYear();
    const span = <span>&#10003;</span>;

    const presence_status = [
      { label: "Mengikuti", value: "mengikuti" },
      { label: "Tidak Mengikuti", value: "tidak" },
    ];
    const status = [
      { label: "Hadir", value: "HADIR" },
      { label: "Sakit", value: "SAKIT" },
      { label: "Izin", value: "IZIN" },
      { label: "Alpha", value: "ALPHA" },
    ];
    const Period = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];

    const reason = [
      { label: 'Miskin', value: 'Miskin'},
      { label: 'Nikah', value: 'Nikah'},
      { label: 'Bekerja', value: 'Bekerja'},
      { label: 'Lainnya', value: 'Lainnya'},

    ];

    const pendidikan_guru = [
      { label: '<D4/S1', value: '<D4/S1'},
      { label: 'S1 - S3', value: 'S1 - S3'},
      { label: 'BELUM BERSERTIFIKAT PENDIDIK', value: 'BELUM BERSERTIFIKAT PENDIDIK'},
    ];
    const kurang = '<D4/S1';

    const componentRef = React.createRef();

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Laporan Individual Pendidikan Guru {form.value.pendidikan_guru}</h1>
          <hr></hr>
          <br></br>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
            <Select
                type="text"
                name="pendidikan_guru"
                label="Pilih Laporan"
                placeholder="Pilih Unit"
                data={pendidikan_guru}
                // disabled
                value={form.value.pendidikan_guru}
                onChange={this._onFormChange}
                error={form.error.pendidikan_guru || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
            {/* <Select
                name="classrooms_id"
                label="Kelas"
                onChange={this._onChangeFilter}
                data={param.classrooms}
                placeholder={"Pilihan"}
                value={classrooms_id}
              /> */}
            </div>
          </div>
         
        </div>

        <ReactToPrint
          trigger={() => (
            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form
          onSubmit={this._onSubmit}
          ref={componentRef}
        >
          <div className="manage-registration">
          <h1 style={{textAlign:'center', fontFamily: 'Calibri'}}>DATA DUKUNG LAPORAN INDIVIDUAL PENDIDIKAN GURU {form.value.pendidikan_guru}</h1>
            <h1 style={{textAlign:'center', fontFamily: 'Calibri'}}>{school_unit_name}</h1>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                   <th rowSpan={2}>NO</th>
                   <th rowSpan={2}>NAMA GURU</th>
                   <th rowSpan={2}>L/P</th>
                   <th rowSpan={2}>NIK GURU</th>
                   <th rowSpan={2}>STATUS</th>
                   <th colSpan={5}>ALAMAT GURU</th>
                   <th rowSpan={2}>PENDIDIKAN TERAKHIR</th>
                  </tr>
                  <tr>
                   <th>RT</th>
                   <th>RW</th>
                   <th>Keluarahan / Desa</th>
                   <th>Kecamatan</th>
                   <th>Kota / Kabupaten</th>
                  </tr>
                </thead>
                {form.value.pendidikan_guru == null && (
                  <>
                  <tbody>
              
                    {map(listteacher, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                          <td style={{textAlign:"center"}}>{idx+1}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(list.nama_lengkap)}</td>
                          <td>{list.jenis_kelamin}</td>
                          <td>{list.no_ktp}</td>
                          <td>{list.status_lable}</td>
                          <td>{list.rt}</td>
                          <td>{list.rw}</td>
                          <td>{list.kelurahan}</td>
                          <td>{list.kecamatan}</td>
                          <td>{list.kotamadya_kabupaten}</td>
                          <td>{list.pendidikan_terakhir}</td>
                      </tr>
                    ))}
                  </tbody>
                  </>
                )}
                  {form.value.pendidikan_guru == '<D4/S1' && (
                  <>
                  <tbody>
              
                    {map(listteacher, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                          <td style={{textAlign:"center"}}>{idx+1}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(list.nama_lengkap)}</td>
                          <td>{list.jenis_kelamin}</td>
                          <td>{list.no_ktp}</td>
                          <td>{list.status_lable}</td>
                          <td>{list.rt}</td>
                          <td>{list.rw}</td>
                          <td>{list.kelurahan}</td>
                          <td>{list.kecamatan}</td>
                          <td>{list.kotamadya_kabupaten}</td>
                          <td>{list.pendidikan_terakhir}</td>
                      </tr>
                    ))}
                  </tbody>
                  </>
                )}
                  {form.value.pendidikan_guru == 'S1 - S3' && (
                  <>
                  <tbody>
              
                    {map(listteacher, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                          <td style={{textAlign:"center"}}>{idx+1}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(list.nama_lengkap)}</td>
                          <td>{list.jenis_kelamin}</td>
                          <td>{list.no_ktp}</td>
                          <td>{list.status_lable}</td>
                          <td>{list.rt}</td>
                          <td>{list.rw}</td>
                          <td>{list.kelurahan}</td>
                          <td>{list.kecamatan}</td>
                          <td>{list.kotamadya_kabupaten}</td>
                          <td>{list.pendidikan_terakhir}</td>
                      </tr>
                    ))}
                  </tbody>
                  </>
                )}
                   {form.value.pendidikan_guru == 'BELUM BERSERTIFIKAT PENDIDIK' && (
                  <>
                  <tbody>
              
                    {map(listteacher, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                          <td style={{textAlign:"center"}}>{idx+1}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(list.nama_lengkap)}</td>
                          <td>{list.jenis_kelamin}</td>
                          <td>{list.no_ktp}</td>
                          <td>{list.status_lable}</td>
                          <td>{list.rt}</td>
                          <td>{list.rw}</td>
                          <td>{list.kelurahan}</td>
                          <td>{list.kecamatan}</td>
                          <td>{list.kotamadya_kabupaten}</td>
                          <td>{list.pendidikan_terakhir}</td>
                      </tr>
                    ))}
                  </tbody>
                  </>
                )}
              </table>
            </div>
            <table style={{marginLeft:'2rem', width: '100%'}}>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', fontWeight:'bold', fontStyle: 'italic', width: '70%'}}>Keterangan</td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', width: '30%', textAlign: 'center'}}>Surakarta,  &nbsp; &nbsp; {month} {year}</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}>1. L/P : Laki-laki / Perempuan</td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', fontWeight:'bold', textAlign: 'center'}}>KEPALA {school_unit_name}</td>
              
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}>2. NIK : Nomor Induk Kependudukan</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}>3. Status : Diisi dengan Guru Tetap atau Guru Tidak Tetap</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}>4. Pendidikan Terakhir : Diisi dengan D1 Manajemen, D3 Teknik komputer atau lainnya</td>
              </tr>
            </table>

            <table style={{marginLeft:'2rem', width: '100%'}}>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', fontWeight:'bold', fontStyle: 'italic', width: '70%'}}></td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', width: '30%', textAlign: 'center'}}></td>
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', fontWeight:'bold', textAlign: 'center'}}></td>
              
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}></td>
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', fontWeight:'bold', textAlign: 'center', textDecoration: 'underline'}}>{headmaster.full_name}</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Calibri", fontSize: 12, width:'20%', fontWeight:'bold', textAlign: 'center'}}>NIP.---</td>
              </tr>
            </table>
          </div>
        </form>
      </div>
    );
  }
}
LaporanGuruD4S1.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanGuruD4S1.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
