import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../utils/transformer.util';
import Button from '../../components/base/Button/Button.component';
import { printLaporanDispliner } from '../../utils/print.util';
import ListForm from '../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../constants/student.constant';
import { Input, Select } from '../../components/base/index';

function onClickPrint(header, content, footer) {
  printLaporanDispliner(header, content, footer);
}


export default class HistoryKonseling extends PureComponent {
   
    constructor(props) {
      super(props);
      this.state = {
        journal: {},
      };
      this.onGetDetails = this.onGetDetails.bind(this);
      this._onFormChange = this._onFormChange.bind(this);
    }
  
    componentDidMount() {
      this.onGetDetails();
    }
  
    async onGetDetails() {
      const { handleGetCashJournal, match } = this.props;
      const { id } = match.params;
      try {
        const payload = await handleGetCashJournal({ id });
        this.setState({
          journal: payload,
        });
      } catch (err) {
        // err action
      }
    }

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }
    

  
    render() {
        const { journal } = this.state;
        return (
          <div >
            <div id="konseling" className="konseling">
              {
                  <div className="konseling__content">
                    <div className="konseling__header">
                        <div className="konseling__header-title">
                        <div className="konseling__header-title--column">
                          {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                         <h1>History Konseling</h1>
                        </div>
                        </div>
                        <div className="konseling__header-title2">
                        <div className="konseling__header-title2--left">
  
                        <p>Nama Siswa    : </p>
                        <p>NIS / Kelas  :</p>
                        <p>Alamat       :</p>
                        </div>
                       
                        
                        <div className="konseling__header-title2--right">
                          
                        <p>Tanggal Lahir :</p>
                        <p>Tahun Ajaran  :</p>
                        <p>Telpon   :</p>
                        </div>
                        </div>
                      
    
                    </div>
                    <div className="konseling__body">
                      <table className="konseling__table">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Tgl Konseling</th>
                            <th>Catatan Khusus</th>
                            <th>Inti Masalah</th>
                            <th>Penyelesaian</th>
                            <th>Keterangan</th>
                            <th>Kesimpulan</th>
                          </tr>
                        </thead>
                        {/* <tbody>
                          {
                          map(journal.details, (list, idx) => (
                            <tr key={`journal_detail_${idx}`}>
                              <td>{idx + 1}</td>
                              <td>{list.code_of_account}</td>
                              <td>{list.parameter_code.title}</td>
                              <td>{list.description}</td>
                              <td className="nominal">{commaFormatted(list.amount)}</td>
                            </tr>
                          ))
                        }
                        </tbody> */}
                        {/* <tfoot>
                          <tr>
                            <td colSpan="3" className="nominal">TOTAL BRUTO</td>
                            <td className="nominal">{commaFormatted(journal.total)}</td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="nominal">POTONGAN PAJAK</td>
                            <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="nominal">TOTAL NETTO</td>
                            <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                          </tr>
                        </tfoot> */}
                      </table>
                    </div>
                    {/* <div className="konseling__footer">
                      <div className="konseling__footer-bottom">
                        <div>
                          <p>Diserahkan oleh:</p>
                          <p>{journal.submitted_by}</p>
                        </div>
                        <div>
                          <p>Diterima oleh:</p>
                          <p>{journal.accepted_by}</p>
                        </div>
                        <div>
                          <p>Dibukukan oleh:</p>
                          <p>{journal.booked_by}</p>
                        </div>
                        <div>
                          <p>Diketahui oleh:</p>
                          <p>Name 3</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
               
              }
            </div>
          </div>
        );
      }
  }
  HistoryKonseling.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  HistoryKonseling.defaultProps = {
    handleGetCashJournal: noop,
  };
  