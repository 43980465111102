import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop, has, find } from 'lodash';
import { Checkbox, Input } from '../../../../components/base';
import { commaFormatted } from '../../../../utils/transformer.util';

export default class classchecklist extends Component {
  constructor(props) {
    super(props);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this._setRef = this._setRef.bind(this);
    this._setNewState = this._setNewState.bind(this);
    this.state = {
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
  }

   componentWillUnmount() {
    const { onChange } = this.props;
    onChange(this.state);
  }

  _onCheckAll(event) {
    const { target } = event;
    let newState = { ...this.state };
    this.setState(prevState => ({
      ...prevState,
      ...newState,
      checkedAll: target.checked,
    }));
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState
    });
  }



  render() {
    const {
      form,
      list
    } = this.props;
    const { selected, checkedAll } = this.state;
    return (
      <div className="manage-fund-request__form-table">
        <table cellPadding="0" cellSpacing="0" border="0" className="table">
          <thead>
            <tr>
              <th rowSpan={2}>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
              <th rowSpan={2}>No.</th>
              <th rowSpan={2}>Kelas</th>
            </tr>
          </thead>
          <tbody>
            {
              map(list, (data, idx) => (
                
                <tr key={`manage-fund-request_transaction__${idx}`}>
                  <td>
                  <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selected"
                      onChange={this._onCheckDetail}
                      value={data.id}
                      checked={has(selected, data.id) ? true : false}
                      ref={this._setRef}
                    />
                  </td>
                  <td>
                    {parseInt(idx)+1}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
classchecklist.propTypes = {
  budgets: PropTypes.object,
  onChange: PropTypes.func,
  selectedBudget: PropTypes.object,
  amount: PropTypes.string,
  selectedBudgetDetails: PropTypes.object,
};
classchecklist.defaultProps = {
  budgets: {},
  onChange: noop,
  selectedBudget: {},
  amount: '',
  selectedBudgetDetails: {}
};
