export const TABLE_EKSKUL_TABLE_FIELDS={
    label: [
        'No',
        'Kode Ekskul',
        'Nama Ekskul',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'ekskul_code'},
        {type: 'string', attribute:'name'},
    ]
 
 
 };

 const DAFTAR_EKSKUL_FORM_PAGE1 =[{

    type:'select',
    name:'tahunajaran',
    label:'Tahun Ajaran',
    data:[
        {label: '2019-2020', value:'tahun1'},
    ]
 },{
    type:'select',
    name:'bentuk',
    label:'Bentuk',
    placeholder: 'Daftar Peserta Ekskul / Kelas Siswa',
    data:[
        {label: '2019-2020', value:'tahun1'},
    ]

 },{
    type:'select',
    name:'tingkat',
    label:'Tingkat/Kelas',
    data:[
        {label: 'Semua Kelas', value:'semuakelas'},
    ]
 },{

    type:'select',
    name:'urutan',
    label:'Urutan',
    data:[
        {label: 'Berdasarkan Abjad', value:'abjad'},
    ]

 }];

 const DAFTAR_EKSKUL_FORM_PAGE2 =[{

    type:'select',
    name:'kelas',
    label:'Kelas',
    data:[
        {label: '7A', value:'Kelas 7a'},
    ],
 }];

 export const DAFTAR_EKSKUL= {
    1: DAFTAR_EKSKUL_FORM_PAGE1,
    2: DAFTAR_EKSKUL_FORM_PAGE2,
  };

  export const PENGISIAN_NILAI_EKSKUL_FORM =[{

    type:'select',
    name:'tahunajaran',
    label:'Tahun Ajaran',
    data:[
        {label: '2019-2020', value:'tahun1'},
    ]
 },{
    type:'select',
    name:'kelas',
    label:'Kelas',
    placeholder: 'Pilihan',
    data:[
        {label: '7A', value:'kelas7'},
    ]

 },{
    type:'select',
    name:'periode',
    label:'Periode',
    data:[
        {label: 'Semester 1', value:'semester1'},
    ]
 },{
    type:'date',
    name:'tanggal',
    label:'Tanggal',
    
  

 }];