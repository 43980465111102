import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import { STUDENT_UNIT_2_FORM_FIELDS } from '../../../constants/student.constant';
import {  DAFTAR_NILAI_FORM_FIELDS} from '../../../constants/kurikulum/ulangan.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import  PengisianNilaiKognitif from './components/PengisianNilaiKognitif.component';
import  DaftarNilaiPraktekForm from './components/DaftarNilaiPraktekForm.component';
import  NilaiKognitifTable from './components/NilaiKognitifTable.component';
import NilaiKognitifForm from './components/NilaiKognitifForm.component';

export default class PengisianNilai extends PureComponent {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this.state = {
        form: {
          value: {},
          error: {},
        },
      };
    }
  
    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        files,
      } = event.target;
      const { inputType = 'text' } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        if (inputType === 'number') {
          formattedValue = commaFormatted(value);
        }
        if (inputType === 'file') {
          [formattedValue] = files;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              [name]: formattedValue,
            },
            error: {
              ...prevState.form.error,
              [name]: '',
            },
          },
        };
      });
    }
  
    _onClickNext() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit');
      }
    
  
    render() {
      const { form } = this.state;
      return (
        
        <div className="student-list">
          <div className="budget__title">
          <h1>Pengisian Nilai </h1>
          <hr></hr>
          </div>
          <form onSubmit={this._onSubmit}>

            <div className="result__content">
            <PengisianNilaiKognitif
                form={form}
                onFormChange={this._onFormChange}
            />
            </div>
            
            
          <NilaiKognitifTable
              form={form}
              onFormChange={this._onFormChange}
            />
        
        <div className="manage-registration__footer">
                    <Button
                    onClick={this._onClickSubmit}
                    title="Submit"
                    />
                </div>
                </form>
        </div>

      );
    }
  }
PengisianNilai.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
PengisianNilai.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
