import { connect } from 'react-redux';
import ManagePotonganIntern from '../../../views/Personnel/Payroll/ManagePotonganIntern.view';
import {
  getPayrollReportHeader, getPotonganInternList, postPotonganIntern, createPotonganIntern,
} from '../../../states/thunks/personnel.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetTableHeaders: payload => dispatch(getPayrollReportHeader(payload)),
    handleGetData: payload => dispatch(getPotonganInternList(payload)),
    handlePost: payload => dispatch(postPotonganIntern(payload)),
    handleSave: payload => dispatch(createPotonganIntern(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePotonganIntern);
