import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty, isEqual } from "lodash";
import { Button, Input, SearchSelect } from "../../../components/base";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { NIS_SISWA_SEARCH } from "../../../constants/student.constant";
import { BATAL_SISWA_KELUAR_TABLE } from "../../../constants/student.constant";
import { commaFormatted, formatData } from "../../../utils/transformer.util";
import { CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class CatatSiswaKeluar extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    // this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getStudentExitOptions = this._getStudentExitOptions.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);

    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {},
        error: {},
      },
      param: "",
    };
  }

  componentDidMount() {
    const { location, handleGetClasses } = this.props;
    const { content } = this.state;
    const { state = {} } = location;
    const { id } = state.data || "";
    const { isEdit = false, data } = state;
    const paramTypes = ["classes", "levels", "classrooms"];
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    // this._getStudentOptions();
    this._getStudentExitOptions();
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/catat-siswa-keluar2", {});
  }

  _onFormChange(event) {
    const { name, value, dataset, files } = event.target;
    const { inputType = "text" } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === "number") {
        formattedValue = commaFormatted(value);
      }
      if (inputType === "file") {
        [formattedValue] = files;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: "",
          },
        },
      };
    });
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran", { data: "" });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/catat-siswa-keluar2", {
      data: val,
      isEdit: true,
    });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/upload");
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { school_unit, organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentExitOptions(filters = {}) {
    const { getStudentExitOptions } = this.props;
    const res = await getStudentExitOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetClassData } = this.props;
    try {
      const payload = await handleGetClassData({ id });

      this.setState({
        form: {
          value: {
            id: payload.id,
            prm_classes_id: payload.prm_classes_id,
            name: payload.name,
            other_class_name: payload.other_class_name,
            capacity: payload.capacity,
            majors_id: payload.majors_id,
            homeroom_teacher: payload.homeroom_teacher,
            subtitute_homeroom_teacher: payload.subtitute_homeroom_teacher,
            report_periods: payload.report_periods,
            levels_id: payload.levels_id,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListExitRecord, user } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;
          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = await handleListExitRecord({
            ...params,
            organizations_id: organizations_id,
            filters,
            units_id: school_unit.id,
          });
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  onClickPrint(val) {
    const { history, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    history.push("/dashboard/kesiswaan/data-siswa/cetak-surat-pindah", {
      data: val,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          {/* <Button onClick={() => this.onClickPrint(data)} title="Print" /> */}

          {/* </div> */}
          {/* } */}
        </div>
      </td>
    );
  }

  render() {
    const { onChange, form, classes } = this.props;
    const { content, list, param, filters = {} } = this.state;
    const dataset = {
      toggle: "modal",
    };
    return (
      <div className="fund-request">
        <div className="beginning-balance__button-wrapper">
          <Button title="Tambah Catat Siswa Keluar" onClick={this.onClickAdd} />
        </div>
        <div className="beginning-balance__button-wrapper">
          <SearchSelect
            noMargin
            async={false}
            name="students_id"
            list={param.student}
            inputArray
            onClick={this._onChangeFilter}
            placeholder="Pilih Nama Siswa"
            value={filters.students_id}
            // labelName="label"
            // valueName="value"
            rightIcon="icon-search"
            label="Nama Siswa"
          />
        </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td style={{textAlign:'center'}}key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "nisn"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.attribute === "no_va"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.type === "link") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Link
                              to={
                                "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                              }
                            >
                              {list.name}
                            </Link>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(list)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          {/* <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p> */}
          {/* <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          /> */}
        </div>
      </div>
    );
  }
}
CatatSiswaKeluar.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CatatSiswaKeluar.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
