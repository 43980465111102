import { connect } from 'react-redux';
import PayrollList from '../../../views/Personnel/Payroll/PayrollList.payroll.view';
import { getPayroll } from '../../../states/thunks/personnel.thunk';
import { getStaff } from '../../../states/thunks/personnel.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayroll: payload => dispatch(getPayroll(payload)),
    handleGetStaff: payload => dispatch(getStaff(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollList);
