import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from '../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';

export default class CashJournalClosing extends Component {
  constructor(props) {
    super(props);
    const currentTime = new Date();
    this._setForm = this._setForm.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._calculateTotals = this._calculateTotals.bind(this);
    this._getData = this._getData.bind(this);
    this._getBalance = this._getBalance.bind(this);
    this.state = {
      totals: {
        papers: 0,
        coins: 0,
        others: 0,
        record: 0,
      },
      form: {
        value: {
          timestamp: currentTime,
          balance: 0,
          papers: {},
          coins: {},
          others: {
            0: { note: '', value: 0 },
            1: { note: '', value: 0 },
            2: { note: '', value: 0 },
          },
          record: {
            incomes: 0,
            expenses: 0,
            receipts: 0,
          },
        },
      },
    };
  }

  componentDidMount() {
    this._calculateTotals();
    this._getBalance();
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { saveCashClosingJournal } = this.props;
    const { form } = this.state;
    const { value } = form;

    const res = await saveCashClosingJournal({ ...value });
    return res;
  }

  async _getData() {
    const { getCashClosingJournal } = this.props;
    const res = await getCashClosingJournal();

    return res;
  }

  async _getBalance() {
    const { getCashClosingJournalLastBalance } = this.props;
    const { form } = this.state;
    const { timestamp } = form.value;

    const res = await getCashClosingJournalLastBalance({
      date: `${timestamp.getFullYear()}-${timestamp.getMonth() + 1}-${timestamp.getDate()}`,
    });

    const balance = res.find(o => o.code_of_account === 11101);

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          balance: Number(balance.balance),
        },
      },
    }), () => {
      this._calculateTotals();
    });
  }

  _setForm() {
    this.setState({});
  }

  _calculateTotals() {
    const { form } = this.state;
    const {
      papers, coins, others, record, balance = 0,
    } = form.value;
    const totals = {
      papers: 0,
      coins: 0,
      others: 0,
      record: 0,
    };

    if (Object.keys(papers).length !== 0) {
      Object.keys(papers).forEach((key) => {
        totals.papers += (papers[key] * key);
      });
    }

    if (Object.keys(coins).length !== 0) {
      Object.keys(coins).forEach((key) => {
        totals.coins += (coins[key] * key);
      });
    }

    if (Object.keys(others).length !== 0) {
      Object.keys(others).forEach((key) => {
        totals.others += others[key].value;
      });
    }

    if (Object.keys(record).length !== 0) {
      totals.record += balance + record.incomes - record.expenses - record.receipts;
    }

    this.setState({ totals });
  }

  _onChange(e) {
    const { target } = e;
    const { dataset, value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          [name]: {
            ...prevState.form.value[name],
            [dataset.key]: name === 'others' ? { ...prevState.form.value[name][dataset.key], [dataset.content]: normalizeAmount(value) } : normalizeAmount(value),
          },
        },
      },
    }), () => {
      this._calculateTotals();
    });
  }

  render() {
    const { form, totals } = this.state;
    const {
      papers, coins, others = [{}], record, timestamp, balance
    } = form.value;
    return (
      <div className="cash-journal-closing">
        <form onSubmit={this._onSubmit}>
          <div className="cash-journal-closing-header">
            <table>
              <tbody>
                <tr>
                  <td className="label">Unit Kerja</td>
                  <td className="field">:</td>
                </tr>
                <tr>
                  <td className="label">Alamat Unit Kerja</td>
                  <td className="field">:</td>
                </tr>
                <tr>
                  <td className="label">Jenis Dana/Kas:</td>
                  <td className="field">:</td>
                </tr>
                <tr>
                  <td className="label">Tanggal Perhitungan Kas</td>
                  <td className="field">
                    : {`${timestamp.getDate().toString().padStart(2, '0')}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getFullYear()}`}
                  </td>
                </tr>
                <tr>
                  <td className="label">Waktu Perhitungan Kas</td>
                  <td className="field">
                    : {`${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="content-table">
            <table>
              <tbody>
                <tr>
                  <th colSpan="11"><b>Perhitungan saldo kas sebagai berikut:</b></th>
                </tr>
                <tr>
                  <td className="list-number">I. </td>
                  <td colSpan="11"> Saldo Kas menurut catatan</td>
                </tr>
                <tr>
                  <td className="list-number" />
                  <td className="sub" />
                  <td colSpan="4">Saldo buku harian tanggal</td>
                  <td colSpan="2">
                    {`${timestamp.getDate().toString().padStart(2, '0')}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getFullYear()}`}
                  </td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(balance) }</td>
                </tr>
                <tr>
                  <td className="list-number" />
                  <td className="sub" />
                  <td colSpan="6">Ditambah: Penerimaan yang belum dibukukan</td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">
                    <Input
                      noMargin
                      name="record"
                      type="text"
                      data-key="incomes"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={record.incomes}
                      edit
                      width="20px"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="list-number" />
                  <td className="sub" />
                  <td colSpan="6">Dikurangi: Pengeluaran yang belum dibukukan</td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">
                    <Input
                      noMargin
                      name="record"
                      type="text"
                      data-key="expenses"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={record.expenses}
                      edit
                      width="20px"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="list-number" />
                  <td className="sub" />
                  <td colSpan="6">Bon Sementara</td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">
                    <Input
                      noMargin
                      name="record"
                      type="text"
                      data-key="receipts"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={record.receipts}
                      edit
                      width="20px"
                    />
                  </td>
                </tr>
                <tr className="total">
                  <td className="list-number" />
                  <td className="sub" />
                  <td colSpan="8" className="title">Saldo seharusnya pada saat perhitungan</td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(totals.record) }</td>
                </tr>
                <tr>
                  <td className="list-number">II. </td>
                  <td colSpan="11">Perhitungan Kas Tunai:</td>
                </tr>
                <tr>
                  <td colSpan="2" />
                  <td className="sub-list">a.</td>
                  <td colSpan="8">Uang Kertas</td>
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="100000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[100000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">100.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[100000] ? commaFormatted(papers[100000] * 100000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="50000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[50000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">50.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[50000] ? commaFormatted(papers[50000] * 50000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="20000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[20000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">20.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[20000] ? commaFormatted(papers[20000] * 20000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="10000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[10000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">10.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[10000] ? commaFormatted(papers[10000] * 10000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="5000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[5000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">5.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[5000] ? commaFormatted(papers[5000] * 5000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="2000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[2000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">2.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[2000] ? commaFormatted(papers[2000] * 2000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="papers"
                      type="text"
                      data-key="1000"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={papers[1000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>lembar</td>
                  <td>@ Rp. </td>
                  <td className="number">1.000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{papers[1000] ? commaFormatted(papers[1000] * 1000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-total">
                  <td colSpan="3" />
                  <td colSpan="4" className="title">Jumlah uang kertas</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(totals.papers) }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="2" />
                  <td className="sub-list">b.</td>
                  <td colSpan="8">Uang Logam</td>
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="coins"
                      type="text"
                      data-key="1000"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={coins[1000]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>keping</td>
                  <td>@ Rp. </td>
                  <td className="number">1000</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{coins[1000] ? commaFormatted(coins[1000] * 1000) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="coins"
                      type="text"
                      data-key="500"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={coins[500]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>keping</td>
                  <td>@ Rp. </td>
                  <td className="number">500</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{coins[500] ? commaFormatted(coins[500] * 500) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="coins"
                      type="text"
                      data-key="100"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      isNumber
                      value={coins[100]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>keping</td>
                  <td>@ Rp. </td>
                  <td className="number">100</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{coins[100] ? commaFormatted(coins[100] * 100) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="coins"
                      type="text"
                      data-key="50"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={coins[50]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>keping</td>
                  <td>@ Rp. </td>
                  <td className="number">50</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{coins[50] ? commaFormatted(coins[50] * 50) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>
                    <Input
                      noMargin
                      className="counter"
                      name="coins"
                      type="text"
                      data-key="25"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={coins[25]}
                      edit
                      width="20px"
                    />
                  </td>
                  <td>keping</td>
                  <td>@ Rp. </td>
                  <td className="number">25</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{coins[25] ? commaFormatted(coins[25] * 25) : 0 }</td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-total">
                  <td colSpan="3" />
                  <td colSpan="4" className="title">Jumlah uang logam</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(totals.coins) }</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="2" />
                  <td className="sub-list">c.</td>
                  <td colSpan="8">Cek dan setara kas lainnya</td>
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>1.</td>
                  <td colSpan="4">
                    <Input
                      noMargin
                      name="others"
                      type="text"
                      data-key="0"
                      data-content="note"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      value={others[0].note || ''}
                      edit
                    />
                  </td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">
                    <Input
                      noMargin
                      name="others"
                      type="text"
                      data-key="0"
                      data-content="value"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={others[0].value || 0}
                      edit
                      width="20px"
                    />
                  </td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>2.</td>
                  <td colSpan="4">
                    <Input
                      noMargin
                      name="others"
                      type="text"
                      data-key="1"
                      data-content="note"
                      onChange={this._onChange}
                      data-input-type="text"
                      placeholder=""
                      value={others[1].note || ''}
                      edit
                    />
                  </td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">
                    <Input
                      noMargin
                      name="others"
                      type="text"
                      data-key="1"
                      data-content="value"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={others[1].value || 0}
                      edit
                    />
                  </td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td colSpan="3" />
                  <td>3.</td>
                  <td colSpan="4">
                    <Input
                      noMargin
                      name="others"
                      type="text"
                      data-key="2"
                      data-content="note"
                      data-input-type="text"
                      onChange={this._onChange}
                      placeholder=""
                      value={others[2].note || ''}
                      edit
                    />
                  </td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">
                    <Input
                      noMargin
                      name="others"
                      type="text"
                      data-key="2"
                      data-content="value"
                      data-input-type="text"
                      placeholder=""
                      onChange={this._onChange}
                      isNumber
                      value={others[2].value || 0}
                      edit
                      width="20px"
                    />
                  </td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-total">
                  <td colSpan="3" />
                  <td colSpan="4" className="title">Jumlah Cek, Bon Sementara</td>
                  <td className="space" />
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(totals.others) }</td>
                  <td />
                  <td />
                </tr>
                <tr className="total">
                  <td colSpan="3" />
                  <td colSpan="7" className="title">Total Kas menurut perhitungan</td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(totals.papers + totals.coins + totals.others) }</td>
                </tr>
                <tr className="total">
                  <td colSpan="3" />
                  <td colSpan="7" className="title">Selisih Saldo Kas</td>
                  <td className="row-total-label">Rp. </td>
                  <td className="row-total-field">{ commaFormatted(totals.record - totals.papers - totals.coins - totals.others) }</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div>
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}


CashJournalClosing.propTypes = {};
CashJournalClosing.defaultProps = {};
