import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Button,
  Select,
  Input,
  SearchSelect,
} from "../../../../components/base";
import {
  PEMBAYARAN_NONRUTIN_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import { errorAlert } from "../../../../utils/alert.util";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Pembayaran extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChecked = this._onChecked.bind(this);
    this._onUnchecked = this._onUnchecked.bind(this);
    this._onUncheckedAll = this._onUncheckedAll.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onShowDetail = this.onShowDetail.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    // this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    this._getMsTempsSiswaOptions = this._getMsTempsSiswaOptions.bind(this);
    this._getPrmStudentGeneralOptions = this._getPrmStudentGeneralOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {
        // jenis: 'rutin'
      },
      list: [],
      form: {
        value: {
          detail_checked: [],
          subtotal: "",
          total: "",
          classes: [],
          levels_id,
          periods: "",
          listinvoice: [],
          listinvoice2: [
            {
              paymentdetails: []
            }
          ],
          listinvoiceUPD:[],
          classes_id: "",
          last_name: "",
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
          payload: [],
          cek_payment: "",
          detail_checked: [],
        },
        error: {
          classes: "",
          levels: "",
          listinvoice: "",
          listinvoice2: "",
          listinvoiceUPD: "",
          detail_checked: "",
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id} = user;
    const { content } = this.state
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    if(organizations_id == 3){
      this._getMsTempsSiswaOptions({units_id: [school_unit.va_code] });
    }
    else {
      this._getPrmStudentGeneralOptions({units_id: [school_unit.id] });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (!isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  async _onFormChange(event) {
    const { user } = this.props;
    const { form } = this.state
    const { cek_payment } = form.value
    const { school_unit } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arrayList,
      paymentId,
    } = dataset;

    if ((fieldName === "listinvoicenon" || fieldName === "listinvoice") && checked == true) {
      await this._onUncheckedAll()
    }
    if (fieldName === "listinvoicenon" && paymentId !== cek_payment){
      // await this._onUncheckedAll()
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            cek_payment: paymentId,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }    
    if (name === "pg_code"){
      var admin_fee=Number(event.target.getAttribute("admin_fee"));
      var admin_percent=Number(event.target.getAttribute("admin_percent"));
      var bank_name=event.target.getAttribute("bank_name");
      if(admin_fee == 0 && admin_percent != 0) {
        admin_fee = Math.round(form.value.subtotal*(admin_percent/100));
      }
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            admin_fee: admin_fee,
            bank_name: bank_name,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }
    this.setState(
      (prevState) => {

        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {          
          if(fieldName === "listinvoicenon"){
            newList = prevState.form.value.listinvoice2[arrayList].paymentdetails;
            newListError = prevState.form.error[fieldName] || [];
            newList[arrayPosition][name] = formattedValue;
          }else {
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
          }
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }

        if (fieldName === "listinvoicenon") {
          if (type === "checkbox") {
            formattedValue = checked ? "true" : "";
            if (formattedValue == "true") {
              for(let i=0; i<=arrayPosition; i++) {
                this._onChecked(prevState.form.value.listinvoice2[arrayList].paymentdetails[i]);
              }
            } else {
              this._onUnchecked(arrayPosition);
            }
          }
        } else {
          if (type === "checkbox") {
            formattedValue = checked ? "true" : "";
            if (formattedValue == "true") {
              for(let i=0; i<=arrayPosition; i++) {
                this._onChecked(prevState.form.value[fieldName][i]);
              }
            } else {
              this._onUnchecked(arrayPosition);
            }
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  _onChecked(data) {
    const { form, filters } = this.state;
    const { detail_checked } = form.value;

    let periode = ""

    if (filters.jenis == "rutin") {
      periode= data.tr_payments_details[0].periode
    }

    detail_checked.push({
      invoices_id: data.invoices_id,
      temps_id: data.temps_id,
      month: data.month,
      cicilan: data.cicilan,
      nominal: data.nominal,
      periode: periode,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          detail_checked,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onUnchecked(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(idx, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  _onUncheckedAll() {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(0, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  openModalRincian() {
    MySwal.fire({
      title: <p>Rincian Pembayaran</p>,
      width: "350px",
      html: this.renderModalContent(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  

  async onShowDetail(data) {
    const { form, filters } = this.state;
    const { detail_checked } = form.value;
    const { jenis } = filters;
    console.log(data)
    detail_checked.push({
      invoices_id: data.invoices_id,
      temps_id: data.temps_id,
      month: data.month,
      cicilan: data.cicilan,
      nominal: data.nominal,
      // periode: periode,
    });
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          detail_checked,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    this.openModalShowDetail(data); // buka modal detail
  }

  openModalShowDetail(data) {
    MySwal.fire({
      title: <p>Rincian Pembayaran</p>,
      width: "350px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  // fungsi bayar dengan checklist
  async onSubmit() {
    const { form, filters } = this.state;
    const { detail_checked } = form.value;
    const { jenis } = filters;


    if(isEmpty(detail_checked) && jenis !== 'upd'){
      errorAlert({
        title: 'Tagihan belum di pilih.',
        errorMessage: 'Mohon dipilih terlebih dahulu'
      });
    }else{
      // this.saveTemp();
      this.openModalRincian();
    }
  }
  
  // fungsi bayar setelah lihat rincian
  async onSendPayment(data) {
    const { history, handlePaymentsOffline, handlePaymentsOfflineGeneral, user } = this.props
    const { form, filters } = this.state;
    const { organizations_id, school_unit } = user;
    const { unit_code } = school_unit;
    const { value } = form;
    const { students_id, jenis } = filters
    value.temps_id = students_id.value
    if(organizations_id == 3){
      await handlePaymentsOffline({...value, unit_code:unit_code, date: new Date().toJSON().slice(0, 10)});
    }
    else {
      await handlePaymentsOfflineGeneral(value);
    }
    MySwal.close();
    history.push("/dashboard/tagihan-siswa/riwayat", { isEdit: true, students_id: students_id,  payments_type: "offline"});
    // history.go(0)
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { students_id, jenis } = filters;
    this._onUncheckedAll();
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListInvoiceRutin, handleListInvoiceNonRutin, 
            handleListPaymentGateway, handleListInvoiceRutinGeneral, handleListInvoiceNonRutinGeneral, handleListInvoiceRegis } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id, profile } = user;
          const { units_id } = school_unit.id;
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (month < 7) {
            year -= 1;
          }
          let result = {};
          let result2 = {};
          let resultUPD = {};

          if (jenis === "rutin" && organizations_id == 3) {
            result = await handleListInvoiceRutin({
              ...params,
              temps_id: students_id.value,
              filters,
            });
          } else if (jenis === "rutin" && organizations_id != 3) {
            result = await handleListInvoiceRutinGeneral({
              ...params,
              temps_id: students_id.value,
              filters,
            });
          }
           else if (jenis === "nonrutin" && organizations_id == 3) {
            result2 = await handleListInvoiceNonRutin({
              ...params,
              temps_id: students_id.value,
              filters,
            });
          }
           else if (jenis === "nonrutin" && organizations_id != 3) {
            result2 = await handleListInvoiceNonRutinGeneral({
              ...params,
              temps_id: students_id.value,
              filters,
            });
          }
           else if (jenis === "upd" && organizations_id == 3) {
            console.log("MASUK UPD")
            resultUPD = await handleListInvoiceRegis({
              ...params,
              temps_id: students_id.value,
              filters,
            });
          }
          const result3 = await handleListPaymentGateway({
            ...params,
            filters,
          });
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listinvoice: result,
                listinvoice2: result2,
                listinvoiceUPD: resultUPD,
                listbank: result3,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listinvoice: [],
                listinvoice2: [],
                listinvoiceUPD: [],
              },
            },
          }));
        }
      }
    );
  }

  async _getMsTempsSiswaOptions(filters = {}) {
    const { getMsTempsSiswaOptions } = this.props;
    const res = await getMsTempsSiswaOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getPrmStudentGeneralOptions(filters = {}) {
    const { getPrmStudentGeneralOptions } = this.props;
    const res = await getPrmStudentGeneralOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  renderModalContent() {
    const { error, onChange, fieldName, plafond_name, nis, rincian } =
      this.props;
    const { list, form, param, filters } = this.state;
    const { jenis } = filters;
    const { detail_checked, total } = form.value;
    
    var last = ""

    return (
      <div>
        <hr></hr>
        <br></br>
        <table
          className="manage-rapbs-plafon__form-table table-modal"
          style={{ marginBottom: "5px" }}
        >
          <thead>
            <tr>
              <th style={{ border: "none" }}>Jumlah</th>
              <th style={{ border: "none", textAlign: "right" }}>
                Rp. {commaFormatted(total)}
              </th>
            </tr>
          </thead>
        </table>
        <table className="manage-rapbs-plafon__form-table table-modal">
          <thead>
            <tr>
              <th colSpan="2">Detail Pembayaran</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <table className="cetak-buku-induk2__table-test">
                  {map(detail_checked, (list, idx) => {
                    if (jenis === "nonrutin") {
                      last = list.invoices_id.substr(list.invoices_id.length-2, list.invoices_id.length)
                      if (last === "00") {
                        list.month = "DP"
                      } else {
                        list.month = list.cicilan
                      }
                    }
                    return (
                      <tr key={`budget_row_${idx}`}>
                        <td style={{ textAlign: "left" }}>
                          {list.month}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          Rp. {commaFormatted(list.nominal)}
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="table__actions">
          <Button 
            onClick={() => MySwal.close()}
            title="Tutup"
          />
          <Button
            onClick={() => this.onSendPayment()}
            title="Bayar"
          />
        </div>
      </div>
    );
  }

  renderModalContentShowDetail(data) {
    const { error, onChange, fieldName, plafond_name, nis, rincian } =
      this.props;
    const { list, form, param, filters } = this.state;
    const { jenis } = filters;
    const { detail_checked, total } = form.value;
    
    var last = ""

    console.log(data)
    return (
      <div>
        <hr></hr>
        <br></br>
        <table
          className="manage-rapbs-plafon__form-table table-modal"
          style={{ marginBottom: "5px" }}
        >
          <thead>
            <tr>
              <th style={{ border: "none" }}>Jumlah</th>
              <th style={{ border: "none", textAlign: "right" }}>
                Rp. {commaFormatted(data.nominal)}
              </th>
            </tr>
          </thead>
        </table>
        <table className="manage-rapbs-plafon__form-table table-modal">
          <thead>
            <tr>
              <th colSpan="2">Detail Pembayaran</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <table className="cetak-buku-induk2__table-test">
                 
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          Uang Pendaftaran
                        </td>
                        <td style={{ textAlign: "right" }}>
                          Rp. {commaFormatted(data.nominal)}
                        </td>
                      </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="table__actions">
          <Button 
            onClick={() => MySwal.close()}
            title="Tutup"
          />
          <Button
            onClick={() => this.onSendPayment()}
            title="Bayar"
          />
        </div>
      </div>
    );
  }
  
  render() {
    const { param, form, list = {}, filters, expanded } = this.state;
    const { user } = this.props;
    const { organizations_id } = user;
    const { listinvoice, listinvoice2, detail_checked, cek_payment, listinvoiceUPD } = form.value;
    const { jenis } = filters;
    const prm_jenis = [
      { label: organizations_id ==3?'SPP':'RUTIN', value: 'rutin'},
      { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'nonrutin'},
      { label: organizations_id ==3?'UPD':'', value: 'upd'},
    ];

    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }

    form.value.subtotal = 0;
    {
      map(detail_checked,(data, idx) =>
        (form.value.subtotal += Number(form.value.detail_checked[idx].nominal))
      );
    }
    form.value.total = form.value.subtotal + form.value.admin_fee;

    var last = ""

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Pembayaran </h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Siswa"
                value={filters.students_id}
                rightIcon="icon-search"
                label="Siswa"
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              {(filters.students_id) && (
                <Select
                  name="jenis"
                  label="Jenis Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_jenis}
                  placeholder="Pilihan"
                  value={jenis}
                />
              )}
            </div>
          </div>
        </div>
        <br></br>
        {(() => {
          if (jenis === "rutin"){
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">{organizations_id == 3?'SPP':'RUTIN'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      {map(listinvoice, (data, idx) => (
                        <table className="create-invoice-rutin__table-test">
                          <tr>
                            {(() => {
                              if (data.payments_date !== null || data.faspay_id !== null || listinvoice[0].faspay_id !== null) { 
                                return <th></th>;
                              } 
                              else {
                                return (
                                  <th>
                                    <Input
                                      // style={{ height: "unset" }}
                                      type="checkbox"
                                      name="status"
                                      data-input-array={true}
                                      data-array-position={idx}
                                      data-field-name="listinvoice"
                                      onChange={this._onFormChange}
                                      checked={detail_checked[idx] ? true : ""}
                                      value={data.status}
                                    />
                                  </th>
                                );
                              }
                            })()}
                            <th>
                              <ExpansionPanel expanded={expanded === 'panel'+idx} onChange={this.handleChange('panel'+idx)} style={{backgroundColor: "#f5f5f5"}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                  <Typography>
                                    <h2>{data.month}</h2> <br></br>
                                    <h2>Rp. {commaFormatted(data.nominal)}</h2>
                                  </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{flexDirection: "column"}}>
                                  <Typography>
                                    <hr></hr>
                                    <table className="create-invoice-rutin__table-detail">
                                      {map(data.tr_payments_details, (data_detail, idx_detail) => (
                                        <tr>
                                          <td style={{ textAlign: "left" }}>
                                            {data_detail.name}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            Rp. {commaFormatted(data_detail.nominal)}
                                          </td>
                                        </tr>
                                      ))}
                                    </table>
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </th>
                            {(() => {
                              if (data.payments_date !== null){
                                return <th className="status" style={{color:"green", textAlign:"center"}}>LUNAS</th>;
                              } else if (data.faspay_id !== null) {
                                return (
                                  <th className="status">
                                    <div className="table__actions">
                                      <Button onClick={() => this.onShowDetail(data)} title="Lihat Tagihan" />
                                    </div>
                                  </th>
                                )
                              } else {
                                return <th className="status"></th>;
                              }
                            })()}
                          </tr>
                        </table>
                      ))}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="manage-rapbs-plafon__add-more">
                    <th style={{border: "unset"}}>
                      Total: &emsp; Rp. {commaFormatted(form.value.subtotal)}
                    </th>
                    <td>
                      <Button onClick={this.onSubmit}>Lihat Rincian</Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            )
          } else if (jenis === "nonrutin") {
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">{organizations_id == 3?'DPP/UPP':'NON RUTIN'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      {map(listinvoice2, (datalist, idxlist) => (
                        !isEmpty(datalist.paymentdetails) && (
                          <div>
                            <h4 style={{textAlign: "center"}}>{datalist.payment_name}</h4>
                            <table className="table" style={{marginBottom: "5px"}}>
                              <thead>
                                <tr>
                                  {map(
                                    PEMBAYARAN_NONRUTIN_TABLE_FIELD_LIST.label,
                                    (field, idx) => (
                                      <th
                                        key={`table_th_${idx}`}
                                        style={{
                                          background: "#909090",
                                          color: "#FFFFFF",
                                          textAlign: "center",
                                        }}
                                      >
                                        {field}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {map(datalist.paymentdetails, (data, idx) => ( 
                                  <tr key={`budget_row_${idx}`}>
                                    {map(
                                      PEMBAYARAN_NONRUTIN_TABLE_FIELD_LIST.value,
                                      (field, fieldIdx) => {
                                        if (field.attribute === "status") {
                                          // if (data.payments_date !== null || data.faspay_id !== null || datalist.paymentdetails[0].faspay_id !== null) { 
                                          //   return <td></td>;
                                          // } else {
                                            // if (idx === 0 || (detail_checked[idx - 1] && cek_payment == datalist.payments_id)) {
                                              return (
                                                <td key={`table_${fieldIdx}_${idx}`}>
                                                  <Input
                                                    style={{ height: "unset" }}
                                                    type="checkbox"
                                                    name="status"
                                                    data-input-array={true}
                                                    data-array-position={idx}
                                                    data-field-name="listinvoicenon"
                                                    data-array-list={idxlist}
                                                    data-payment-id={datalist.payments_id}
                                                    onChange={this._onFormChange}
                                                    // checked={data.status ? list.status : ""}
                                                    // value={data.status ? list.status : field.value}
                                                    checked={(cek_payment == datalist.payments_id && detail_checked[idx]) ? true : ""}
                                                    value={data.status}
                                                  />
                                                </td>
                                              );
                                            // } else {
                                            //   return <td></td>;
                                            // }
                                          // }
                                        }
                                        if (field.attribute === "month") {
                                          last = data.invoices_id.substr(data.invoices_id.length-2, data.invoices_id.length)
                                          if (last === "00") {
                                            return <td>DP</td>
                                          } else {
                                            return <td>{data.cicilan}</td>
                                          }
                                        }
  
                                        return (
                                          <td
                                            className={
                                              field.type === "number" ? "nominal" : ""
                                            }
                                            key={`table_${fieldIdx}_${idx}`}
                                          >
                                            {formatData(data, field)}
                                          </td>
                                        );
                                      }
                                    )}
                                    {(() => {
                                      if (data.payments_date !== null){
                                        return <td style={{color:"green", textAlign:"center"}}>LUNAS</td>;
                                      // } else if (data.faspay_id !== null) {
                                      //   return this._renderDetailButtons(data);
                                      } else {
                                        // if (idx === 0) {
                                        //   return this._renderButtons(data);
                                        // } else {
                                          return <td></td>;
                                        // }
                                      }
                                    })()}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <br></br>
                          </div>
                        )
                      ))}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="manage-rapbs-plafon__add-more">
                    <th style={{border: "unset"}}>
                      Total: &emsp; Rp. {commaFormatted(form.value.subtotal)}
                    </th>
                    <td>
                      <Button onClick={this.onSubmit}>Lihat Rincian</Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            )
          } else if (jenis === "upd") {
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">UPD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      {map(listinvoiceUPD, (datalist, idxlist) => (
                          <div>
                            <h4 style={{textAlign: "center"}}>{datalist.payment_name}</h4>
                            <table className="table" style={{marginBottom: "5px"}}>
                              <thead>
                                <tr>
                                      <th
                                        key={`table_th_${idxlist}`}
                                        style={{
                                          background: "#909090",
                                          color: "#FFFFFF",
                                          textAlign: "center",
                                          width: "80%"
                                        }}
                                      >
                                        No Invoice
                                      </th>
                                      <th  key={`table_th_${idxlist}`}
                                        style={{
                                          background: "#909090",
                                          color: "#FFFFFF",
                                          textAlign: "center",
                                          width: "20%"

                                        }}>Nominal</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr key={`budget_row_${idxlist}`}>
                                        
                                        <td>{datalist.invoices_id} </td>
                                        <td>{datalist.nominal} </td>
                                  
                                  </tr>
                              </tbody>
                            </table>
                            <br></br>
                          </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="manage-rapbs-plafon__add-more">
                    {/* <th style={{border: "unset"}}>
                      Total: &emsp; Rp. {commaFormatted(form.value.subtotal)}
                    </th> */}
                    {!isEmpty(listinvoiceUPD) && (
                      <td>
                      <Button onClick={()=>this.onShowDetail(listinvoiceUPD[0])}>Lihat Rincian</Button>
                    </td>
                    )}
                    
                  </tr>
                </tfoot>
              </table>
            )
          }
        })()}
        <br></br>
      </div>
    );
  }
}
Pembayaran.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Pembayaran.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
