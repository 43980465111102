import { connect } from 'react-redux';
import Pembayaran from '../../../../views/Finance/Spp/Pembayaran/Pembayaran.view';
import { getStudentSPPOptions, getMsTempsSiswaOptions, getPrmStudentGeneral } from '../../../../states/thunks/options.thunk';
import { listInvoiceRutin, listInvoiceNonRutin, listPaymentGateway, paymentsOffline, listInvoiceRutinGeneral, 
  listInvoiceNonRutinGeneral, paymentsOfflineGeneral, listInvoiceRegis} from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    handleListInvoiceRutin: (payload, goback) => dispatch(listInvoiceRutin(payload, goback)),
    handleListInvoiceNonRutin: (payload, goback) => dispatch(listInvoiceNonRutin(payload, goback)),
    handleListInvoiceRegis: (payload, goback) => dispatch(listInvoiceRegis(payload, goback)),
    handleListInvoiceRutinGeneral: (payload, goback) => dispatch(listInvoiceRutinGeneral(payload, goback)),
    handleListInvoiceNonRutinGeneral: (payload, goback) => dispatch(listInvoiceNonRutinGeneral(payload, goback)),
    handleListPaymentGateway: (payload, goback) => dispatch(listPaymentGateway(payload, goback)),
    handlePaymentsOffline: (payload, goback) => dispatch(paymentsOffline(payload, goback)),
    handlePaymentsOfflineGeneral: (payload, goback) => dispatch(paymentsOfflineGeneral(payload, goback)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    getPrmStudentGeneralOptions: (payload) => dispatch(getPrmStudentGeneral(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Pembayaran);



