import React, { Component } from 'react';
import { Input, Select, FileInput } from '../../../../components/base';
import PropTypes from 'prop-types';
export default class IdentitasSekolah2 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.state = {
      form: {
        value: {

        },
        error: {

        },
      },
    };
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    const change = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => { console.log(this.state); });

    onChange(e);
  }

  async onUpload(event) {
    const {
      handleUploadImageFileStudents,
      onChange,
      idx,
      onFormChange,
      showEdit,
    } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const upload_image = files[0];

    if (upload_image) {
      const filename = upload_image.name;
      const res = await handleUploadImageFileStudents({
        file: upload_image,
      });
      onChange({
        target: {
          name: "file",
          value: JSON.stringify({
            path: res.path,
            url: res.url,
            filename,
          }),
          dataset: {
            ...dataset,
            inputArray: false,
          },
        },
      });
    }
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  render() {
    const {
      form, provinces, idx, cities, districts, sub_districts, onChange, classes, param,
    } = this.props;
    const { value } = form;
    const accreditation = [
      { label: 'A', value: '1'},
      { label: 'B', value: '2'},
      { label: 'C', value: '3'},
      { label: 'TT', value: '4'}
    ];
    const school_time = [
      { label: 'Pagi', value: '1'},
      { label: 'Siang', value: '2'},
      { label: 'Kombinasi', value: '3'}
    ];
    const school_cluster = [
      { label: 'Inti', value: '1'},
      { label: 'Imbas', value: '2'},
      { label: 'Belum Ikut', value: '3'}
    ];
    const school_category = [
      { label: 'SD SPM (Standar Pelayan Minimal)', value: '1'},
      { label: 'SD RSSN (Rintisan Sekolah Standar Nasional)', value: '2'},
      { label: 'SD SSN (Sekolah Standar Nasional)', value: '3'},
      { label: 'SD RSBI (Rintisan Sekolah Bertaraf Indonesia)', value: '4'},
      { label: 'SD SBI (Sekolah Bertaraf Indonesia)', value: '5'},
      { label: 'SD-SMP Satu Atap', value: '6'},
    ];
    const school_based_management = [
      { label: 'Ya', value: '1'},
      { label: 'Tidak', value: '2'},
    ];
    const school_curriculum = [
      { label: 'Sekolah Penggerak', value: '1'},
      { label: 'KTSP', value: '2'},
      { label: '2013', value: '3'},
    ];
    const school_internet_connection = [
      { label: 'Ya', value: '1'},
      { label: 'Tidak', value: '2'},
    ];
    const school_boss_fund = [
      { label: 'Ya', value: '1'},
      { label: 'Tidak', value: '2'},
    ];
    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_accreditation"
              label="Status Akreditasi Sekolah"
              data = {accreditation}
              placeholder = {'Pilihan'}
              onChange={this._onFormChange}
              value={form.value.school_accreditation}
              error={form.error.school_accreditation || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_time"
              label="Waktu Penyelenggaraan"
              placeholder='Pilihan'
              data = {school_time}
              onChange={this._onFormChange}
              value={form.value.school_time}
              error={form.error.school_time || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_cluster"
              label="Gugus Sekolah"
              data = {school_cluster}
              placeholder = {'Pilihan'}
              onChange={this._onFormChange}
              value={form.value.school_cluster}
              error={form.error.school_cluster || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_category"
              label="Kategori Sekolah"
              placeholder='Pilihan'
              data = {school_category}
              onChange={this._onFormChange}
              value={form.value.school_category}
              error={form.error.school_category || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_based_management"
              label="Apakah Sekolah ini telah melaksanakan Manajemen Berbasis Sekolah (MBS)"
              data = {school_based_management}
              placeholder = {'Pilihan'}
              onChange={this._onFormChange}
              value={form.value.school_based_management}
              error={form.error.school_based_management || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_curriculum"
              label="Kurikulum yang Digunakan"
              placeholder='Pilihan'
              data = {school_curriculum}
              onChange={this._onFormChange}
              value={form.value.school_curriculum}
              error={form.error.school_curriculum || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_internet_connection"
              label="Apakah Sekolah ini mempunyai koneksi internet?"
              data = {school_internet_connection}
              placeholder = {'Pilihan'}
              onChange={this._onFormChange}
              value={form.value.school_internet_connection}
              error={form.error.school_internet_connection || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="school_boss_fund"
              label="Apakah Sekolah ini menerima dana  BOS?"
              placeholder='Pilihan'
              data = {school_boss_fund}
              onChange={this._onFormChange}
              value={form.value.school_boss_fund}
              error={form.error.school_boss_fund || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="study_time"
              label="Waktu Belajar"
              placeholder = {'Pilihan'}
              onChange={this._onFormChange}
              value={form.value.study_time}
              error={form.error.study_time || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="study_group"
              label="Rombongan Belajar"
              placeholder = {'Pilihan'}
              onChange={this._onFormChange}
              value={form.value.study_group}
              error={form.error.study_group || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {/* <FileInput
              data-input-type="file"
              noMargin
              type="file"
              name="upload_image"
              label="Upload File Foto"
              onChange={this.onUpload}
              onRemoveFile={this.removeFile}
              fileName={value.upload_image ? value.upload_image : ""}
              displayName={
                value.upload_image ? value.upload_image : "default"
              }
            /> */}
          </div>
          <div className="manage-registration__custom-form-row__field">
          </div>
        </div>
        {/* <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="dimulaidarikelas"
              label="Di Mulai Dari Kelas"
              onChange={this._onFormChange}
              value={form.value.dimulaidarikelas}
              error={form.error.dimulaidarikelas || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="lamapendidikan"
              label="Lama Pendidikan (Tahun)"
              onChange={this._onFormChange}
              value={form.value.lamapendidikan || ''}
              error={form.error.lamapendidikan || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="jmljampelajaranperhari"
              label="Jumlah Jam Pelajaran per Hari"
              onChange={this._onFormChange}
              value={form.value.jmljampelajaranperhari || ''}
              error={form.error.jmljampelajaranperhari || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="use_nis_nisn"
              label="Menggunakan NIS/NISN"
              placeholder="Pilihan"
              data={param.nisn_options}
              onChange={this._onFormChange}
              value={form.value.use_nis_nisn || ''}
              error={form.error.use_nis_nisn || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <div className="manage-registration__custom-form-row__field">
              <Input
                type="text"
                name="urutannisdimulaidari"
                label="Urutan NIS Dimulai Dari"
                onChange={this._onFormChange}
                value={form.value.urutannisdimulaidari || ''}
                error={form.error.urutannisdimulaidari || ''}
              />
            </div>
            <div className="manage-registration__custom-form-row__field">
              <Input
                type="number"
                name="bykkarakter"
                label="Sebanyak (Karakter)"
                onChange={this._onFormChange}
                value={form.value.jmljampelajaranperhari || ''}
                error={form.error.jmljampelajaranperhari || ''}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="nilaitertinggi"
              label="Nilai Tertinggi"
              placeholder="Pilihan"
              data={param.highest_grade}
              onChange={this._onFormChange}
              value={form.value.nilaitertinggi || ''}
              error={form.error.nilaitertinggi || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="nilaikurangterendah"
              label="Nilai Kurang Terendah"
              onChange={this._onFormChange}
              value={form.value.nilaikurangterendah || ''}
              error={form.error.nilaikurangterendah || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="provinces_id"
              label="Provinsi Default"
              placeholder="Pilihan"
              data={provinces.list}
              onChange={this._onFormChange}
              value={form.value.provinces_id || ''}
              error={form.error.provinces_id || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="jeniskelamin"
              label="Jenis Kelamin"
              placeholder="Pilihan"
              data={param.genders}
              onChange={this._onFormChange}
              value={form.value.jeniskelamin || ''}
              error={form.error.jeniskelamin || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="agamadefault"
              label="Agama Default"
              placeholder="Pilihan"
              data={param.religions}
              onChange={this._onFormChange}
              value={form.value.agamadefault || ''}
              error={form.error.agamadefault || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="warganegaradefault"
              label="Warga Negara Default"
              placeholder="Pilihan"
              data={param.citizenships}
              onChange={this._onFormChange}
              value={form.value.warganegaradefault || ''}
              error={form.error.warganegaradefault || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="tempatlahirdefault"
              label="Tempat Lahir Default"
              onChange={this._onFormChange}
              value={form.value.tempatlahirdefault || ''}
              error={form.error.tempatlahirdefault || ''}
            />
          </div>
        </div> */}

      </div>
    );
  }
}

IdentitasSekolah2.propTypes = {
  provinces : PropTypes.array,
};

IdentitasSekolah2.defaultProps = {
  provinces: [],
};
