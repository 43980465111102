import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';
import jadwal from '../../assets/img/akademi/kurikulum/jadwal.png';
import mata_pelajaran from '../../assets/img/akademi/kurikulum/mata-pelajaran.png';
import pengumuman from '../../assets/img/akademi/kurikulum/pengumuman.png';
import raport from '../../assets/img/akademi/kurikulum/raport.png';
import ulangan from '../../assets/img/akademi/kurikulum/ulangan.png';

export default class Kurikulum extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPengumuman = this.onClickPengumuman.bind(this);
    this.onClickJadwal = this.onClickJadwal.bind(this);
    this.onClickJadwalPerKelas = this.onClickJadwalPerKelas.bind(this);
    this.onClickUlangan = this.onClickUlangan.bind(this);
    this.onClickMataPelajaran = this.onClickMataPelajaran.bind(this);
    this.onClickBobotPenilaian = this.onClickBobotPenilaian.bind(this);
    this.onClickRencanaPenilaian = this.onClickRencanaPenilaian.bind(this);
    this.onClickRaport = this.onClickRaport.bind(this);
    this.onClickAnalisa = this.onClickAnalisa.bind(this);
  }

  onClickPengumuman() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/pengumuman');
  }
  onClickJadwal() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/jadwal');
  }
  onClickJadwalPerKelas() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/jadwal/jadwal-per-kelas');
  }
  onClickUlangan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan');
  }

  onClickMataPelajaran() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran');
  }

  onClickBobotPenilaian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/bobot-penilaian');
  }
  onClickRencanaPenilaian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian');
  }

  onClickRaport() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/raport');
  }

  onClickAnalisa() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/analisa');
  }

  render() {
    return (
      <div className="finance">
        {/* <div className="finance__list">
          <button onClick={this.onClickUlangan}>
            <img src={ulangan} alt="Ulangan" />
            <h3>Ulangan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPengumuman}>
            <img src={pengumuman} alt="Pengumuman" />
            <h3>Pengumuman</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickJadwal}>
            <img src={jadwal} alt="Jadwal" />
            <h3>Jadwal</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickJadwalPerKelas}>
            <img src={jadwal} alt="Jadwal" />
            <h3>Jadwal Kelas & Karyawan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickMataPelajaran}>
            <img src={mata_pelajaran} alt="Matapelajaran" />
            <h3>Mata Pelajaran</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickBobotPenilaian}>
            <img src={budgeting} alt="BobotPenilaian" />
            <h3>Bobot Penilaian</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickRencanaPenilaian}>
            <img src={budgeting} alt="RencanaPenilaian" />
            <h3>Rencana Penilaian</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickRaport}>
            <img src={raport} alt="Raport" />
            <h3>Raport</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickAnalisa}>
            <img src={budgeting} alt="Analisa" />
            <h3>Analisa</h3>
          </button>
        </div> */}

      </div>
    );
  }
}
Kurikulum.propTypes = {
  history: PropTypes.object.isRequired,
};
