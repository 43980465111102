import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, findIndex, isEqual } from "lodash";
import { Pagination, Table, Loading, Button } from "../base";
import language from "../../languages";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ContentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: initialContent,
      listAmount: 5,
    };
    this.isMount = false;
    this.onSearchContent = this.onSearchContent.bind(this);
    this.onChangeListAmount = this.onChangeListAmount.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    this.isMount = true;
    const { content } = this.state;
    const { initialParams } = this.props;
    if (isEmpty(content.list)) {
      this.onSearchContent({ page: 1, ...initialParams });
    }
  }

  componentDidUpdate(prevProps) {
    const { user, initialParams } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.onSearchContent({ page: 1, ...initialParams });
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  onSearchContent(params) {
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { onSearchContent, user } = this.props;
          const { prm_school_units_id, workingUnit, organizations_id, school_unit } = user;
          
          let unit_id = prm_school_units_id;

          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }

          const result = await onSearchContent({
            ...params,
            units_id: school_unit.id,
            organizations_id: organizations_id,
          });

          
          if (this.isMount) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage: 1,
                total: 0,
                totalPage: 1,
              },
            });
          }
        } catch (err) {
          if (this.isMount) {
            this.setState({
              content: {
                loading: false,
                list: [],
                currentPage: 1,
                total: 1,
                totalPage: 1,
                error: true,
              },
            });
          }
        }
      }
    );
  }

  onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this.onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  render() {
    const { listAmount, content } = this.state;
    const {
      tableFields,
      onClickAdd,
      onClickEdit,
      onClickView,
      showActions,
      customThead,
      customAction,
      showView,
      showEdit,
      showDelete,
    } = this.props;
    const loadingProps = { show: content.loading };
    return (
      <div className="content-table">
        {content.list.length == 0 ? (
          <Button
            title="Buat Identitas Sekolah"
            onClick={onClickAdd}
          />
        ) : (
          content.loading ? (
            <Loading loading={loadingProps} />
          ) : (
            <div>
              <br></br>
              <Table
                showActions={showActions}
                showView={showView}
                showEdit={showEdit}
                showDelete={showDelete}
                fields={tableFields}
                data={content.list}
                currentPage={content.currentPage}
                listAmount={listAmount}
                onClickView={onClickView}
                onClickEdit={onClickEdit}
                customThead={customThead}
                customAction={customAction}
              />
              <div className="user-management__table-footer">
                <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                </p>
                <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this.onChangePage}
                />
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}
ContentTable.propTypes = {
  customThead: PropTypes.node,
  customAction: PropTypes.array,
  showActions: PropTypes.bool,
  showView: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onSearchContent: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickView: PropTypes.func,
  onClickEdit: PropTypes.func,
  tableFields: PropTypes.object.isRequired,
  initialParams: PropTypes.object,
  user: PropTypes.object,
};
ContentTable.defaultProps = {
  customThead: null,
  customAction: [],
  showActions: true,
  showView: true,
  showEdit: true,
  showDelete: true,
  onSearchContent: noop,
  onClickAdd: noop,
  onClickView: noop,
  onClickEdit: noop,
  initialParams: {},
  user: {},
};
