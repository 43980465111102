import { connect } from 'react-redux';
import PembatalanSiswaKeluar from '../../../views/Student/DataSiswa/PembatalanSiswaKeluar.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getParamOptions, getStudentOptions, getPeriodsOptions } from '../../../states/thunks/options.thunk';
import { listExitRecord , activatedExitRecord} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){    
  return{
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleListExitRecord: (payload, goback) => dispatch(listExitRecord(payload, goback)),
    handleactivatedExitRecord: (payload, goback) => dispatch(activatedExitRecord(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PembatalanSiswaKeluar);


