import { connect } from 'react-redux';
import StaffList from '../../views/Personnel/StaffList.personnel.view';
import { getStaff, deleteStaff } from '../../states/thunks/personnel.thunk';
import { getDataPegawai } from '../../states/thunks/sk.thunks';

function mapStateToProps(state) {
  console.log(state);
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStaff: payload => dispatch(getStaff(payload)),
    handleDeleteStaff: (payload, goBack) => dispatch(deleteStaff(payload, goBack)),
    handleGetDataPegawai : payload => dispatch(getDataPegawai(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
