import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, FileInput, SearchSelect, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class CatatSiswaKeluarForm2 extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classrooms: '',
                nis:'',
                studentname: '',
                tanggal:'',
                statusmutasi: '',
                nosuratpindah: '',
                tatusabsen:'',
            },
            error: {
                classrooms: '',
                nis:'',
                studentname: '',
                tanggal:'',
                statusmutasi: '',
                nosuratpindah: '',
                statusabsen:'',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classrooms, levels, onFormChange, param } = this.props;
    const status_mutasi = [
      { label: 'Anak Putus Sekolah', value: 'Anak Putus Sekolah'},
      { label: 'Lulusan Tidak Melanjutkan', value: 'Lulusan Tidak Melanjutkan'},
    ]
    const reason = [
      { label: 'Miskin', value: 'Miskin'},
      { label: 'Nikah', value: 'Nikah'},
      { label: 'Bekerja', value: 'Bekerja'},
      { label: 'Lainnya', value: 'Lainnya'},

    ]
    console.log(form.value.status_mutasi)
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
{/*           
          <Input
              type="text"
              name="nis"
              label="NIS/Siswa"
              onChange={onFormChange}
              value={form.value.nis || ''}
              error={form.error.nis || ''}
            /> */}
            <Select
              name="classrooms_id"
              label="Putus Sekolah Kelas"
              onChange={onFormChange}
              data={param.classrooms}
              placeholder={'Pilihan'}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="students_id"
              label="Nama Siswa"
              onChange={onFormChange}
              data={param.student}
              placeholder={'Pilihan'}
              value={form.value.students_id}
              error={form.error.students_id || ''}

            />
         
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
            <Select
              name="status_mutasi"
              label="Status Mutasi"
              data={status_mutasi}
              placeholder={'Pilihan'}
              onChange={onFormChange}
              value={form.value.status_mutasi}
              error={form.error.status_mutasi || ''}
            />
            
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
          {
            form.value.status_mutasi === 'Anak Putus Sekolah'?
              <Select
                type="text"
                  name="reason"
                  label="Penyebab Putus Sekolah"
                  onChange={onFormChange}
                  data={reason}
                  placeholder="Pilihan"
                  value={form.value.reason}
                  error={form.error.reason || ''}
                /> 
                :
                <Select
                type="text"
                  name="reason"
                  label="Penyebab Tidak Melanjutkan"
                  onChange={onFormChange}
                  data={reason}
                  placeholder="Pilihan"
                  value={form.value.reason}
                  error={form.error.reason || ''}
                /> 
              }
         
         
          </div>
      </div>
    

      {/* <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Input
                type="text"
                name="district"
                label="Kelurahan/Desa"
                onChange={onFormChange}
                value={form.value.district}
                error={form.error.district || ''}
              />
          
          </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Input
            type="text"
            name="sub_district"
            label="Kecamatan "
            onChange={onFormChange}
            value={form.value.sub_district}
            error={form.error.sub_district || ''}
          />
          </div>
      </div>

      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Input
              type="text"
              name="cities"
              label="Kota/Kabupaten"
              onChange={onFormChange}
              value={form.value.cities}
              error={form.error.cities || ''}
            />
        
          </div>
          <div className="absensi-rekap__custom-form-column__field">
         
            
          </div>
      </div> */}
  </div>
          
          
          
   
     
    );
}
  
}
CatatSiswaKeluarForm2.propTypes = {
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classrooms: PropTypes.array,
  levels: PropTypes.array,
};
CatatSiswaKeluarForm2.defaultProps = {
  onChange: noop,
  onFormChange: noop,
  classrooms: [],
  levels: [],
};
