import KodeMatpel from '../../../views/Kurikulum/MataPelajaran/KodeMatpel.view.js';
import { connect } from 'react-redux';
import { getSubjectOptions, getParamOptions,getClassesOptions, getParamOptionsAcademics, getPrmLevels} from '../../../states/thunks/options.thunk';
import { listKodeMatpel, deleteKodeMatpel } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
    return {
      result: state.result,
      user: state.user,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {  
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      handleListKodeMatpel: (payload, goBack) => dispatch(listKodeMatpel(payload, goBack)),
      getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
      handleDeleteSubjectDetail: (payload, goback) => dispatch(deleteKodeMatpel(payload, goback)),
      getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
   
      };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(KodeMatpel);