import { connect } from 'react-redux';
import PreviewSuratKeputusan from '../../../views/Personnel/Letter/PreviewSuratKeputusan.letter.view';
import { getDataPegawai,getDataSK,submitSK } from '../../../states/thunks/sk.thunks';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handelgetDataPegawai: payload => dispatch(getDataPegawai(payload)),
    handleGetDataSK : payload => dispatch(getDataSK(payload)),
    handleSubmitSK : payload => dispatch(submitSK(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSuratKeputusan);
