import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, InputStaff, Button, Select } from '../../../components/base';

export default class DataAnak extends Component{
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteListAnak = this._onDeleteListAnak.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }

    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteListAnak(idx) {
        const { onDeleteListAnak, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteListAnak(idx, fieldName);
      }

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }

    render() {
        const {
          list, error, onChange,
          fieldName, pekerjaanOptions, pekerjaan_anak
        } = this.props;
        console.log(list)
        return (
           <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Anak</th>
                  <th>Tanggal Lahir</th>
                  <th>Pekerjaan</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_anak_form__${idx}`}>
                <td>{idx + 1}</td>
                <td className="anak_name">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='anak'
                    data-input-type="text"
                    name="anak_name"
                    onChange={onChange}
                    value={data.anak_name}
                  />
                </td>
                <td className="tgl_lahir_anak">
                  <InputStaff
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='anak'

                    type="date"
                    name="tgl_lahir_anak"
                    onChange={onChange}
                    value={data.tgl_lahir_anak}
                  />
                </td>
                <td>
                  <Select
                    noMargin
                    //value={data.pekerjaan}
                    placeholder="Pilih Pekerjaan"
                    data={pekerjaanOptions}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='anak'
                    name='pekerjaan_anak'
                    value={data.pekerjaan_anak}
                    onChange={this._onFormChange}
                  />
                </td>


                <td>
                  <Button onClick={() => this._onDeleteListAnak(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
        }
        <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                {list.length < 2  && (
                  <Button
                    icon="icon-plus"
                    onClick={this._onAddList}
                  />
                )}
              </div>
            </td>
          </tr>
        </tbody>
        </table>
        );
    }
}

DataAnak.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteListAnak: PropTypes.func,
    onChange: PropTypes.func,
    anak_name: PropTypes.array,
};

DataAnak.defaultProps = {
    onAddList: noop,
    onDeleteListAnak: noop,
    onChange: noop,
    anak_name:[],
};
