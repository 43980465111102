import { connect } from 'react-redux';
import PenerimaanSiswa from '../../views/Student/PenerimaanSiswa.student.view';

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PenerimaanSiswa);


