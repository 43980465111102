import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEmpty } from 'lodash';
import { Button, Input } from '../../../components/base';
import { formatData, normalizeAmount } from '../../../utils/transformer.util';
import { STATUS_TENAGA_PENDIDIK_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/identitas.constant';
import { validateRequiredFields } from "../../../utils/validation.util";

export default class LaporanSekolahStatus extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);

    this.state = {
      page: 1,
      list: [],
      form: {
        value: {},
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data);
    }

    this.handleGetData();
  }

  async _setForm(data) {
    try {
      this.setState({
        form: {
          value: {
            id: data.id,
            name: data.name,
          },
          error: {},
        },
      });
    } catch (err) {
      // err action
    }
  }

  async handleGetData() {
    const { handleListPrmTypeEducators } = this.props;
    const res = await handleListPrmTypeEducators();
    this.setState({
      list: res,
    });
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  async onClickSubmit() {
    const { handleSavePrmTypeEducators } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, ["name"])

    if (!isEmpty(error)) {
      this.setState((prevState) => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
    } else {
      const res = await handleSavePrmTypeEducators(value);
      if (res) {
        this.setState((prevState) => ({
          ...prevState,
          isEdit: false,
          form: {
            ...prevState.form,
            value: {},
          },
        }));
        this.handleGetData();
      }
    }
  }

  async onClickUpdate() {
    const { handleEditPrmTypeEducators } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, ["name"])
    
    if (!isEmpty(error)) {
      this.setState((prevState) => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
    } else {
      const res = await handleEditPrmTypeEducators(value);
      if (res) {
        this.setState((prevState) => ({
          ...prevState,
          isEdit: false,
          form: {
            ...prevState.form,
            value: {},
          },
        }));
        this.handleGetData();
      }
    }
  }

  async onClickEdit(val) {
    if (val) {
      this.setState({ isEdit: true });
      this._setForm(val);
    }
  }

  async onClickDelete(data) {
    const { handleDeletePrmTypeEducators } = this.props;
    const res = await handleDeletePrmTypeEducators(data);
    if (res) {
      this.handleGetData();
    }
  }

  _renderButtons(data) {
    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          {/* <Button onClick={() => this.onClickDelete(data)} title="Hapus" /> */}
        </div>
      </td>
    );
  }

  render() {
    const { form, list = [], isEdit } = this.state;

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Status Tenaga Pendidikan</h1>
          <hr></hr>
          <br></br>
        </div>
        <div className="kode-matpel__custom-form">
          <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
              <Input
                type="text"
                name="name"
                label="Nama Status Tenaga Pendidikan"
                onChange={this._onFormChange}
                value={form.value.name}
                error={form.error.name || ""}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="student-list__header">
          <div className="student-list__button">
            {isEdit ? (
              <Button
                type="button"
                title="Update"
                onClick={this.onClickUpdate}
              />
            ) : (
              <Button
                type="button"
                title="Simpan"
                onClick={this.onClickSubmit}
              />
            )}
          </div>
        </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(STATUS_TENAGA_PENDIDIK_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(list, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(STATUS_TENAGA_PENDIDIK_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`} style={{ textAlign: "center" }}>
                          {idx + 1}
                        </td>
                      );
                    }
                    return (
                      <td
                        className={field.type === "number" ? "nominal" : ""}
                        key={`table_${fieldIdx}_${idx}`}
                      >
                        {formatData(data, field)}
                      </td>
                    );
                  })}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

LaporanSekolahStatus.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
LaporanSekolahStatus.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
