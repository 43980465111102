import React, { PureComponent } from "react";
import PropTypes, { string } from "prop-types";
import { noop, isEmpty } from "lodash";
import { Button } from "../../../components/base";
import ContentTablePayrollList from "../../../components/ContentTable/ContentTablePayrollList.component";
import { PERSONAL_PAYROLL_LIST_TABLE_FIELDS } from "../../../constants/personnel.constant";

export default class ManagePayrollList extends PureComponent {
  constructor(props) {
    super(props);
    const { location } = props;
    const { nip } = location.state.data;
    this.initialParam = { nip };
    this.state = {};
    this.month = [
      "JANUARI",
      "FEBUARI",
      "MARET",
      "APRIL",
      "MEI",
      "JUNI",
      "JULI",
      "AGUSTUS",
      "SEPTEMBER",
      "OKTOBER",
      "NOVEMBER",
      "DESEMBER",
    ];
  }

  componentDidMount() {
    console.log(this.props);
  }

  //tombol payroll view
  _onClickView = (id) => {
    const { history } = this.props;
    const nip = this.initialParam.nip;
    history.push(`/dashboard/personalia/data-payroll/view-payroll`, {
      data: { payroll_id: id, nip: nip },
    });
  };

  //tombol slip
  _onClickInputSlip = (id) => {
    const { history } = this.props;
    const nip = this.initialParam.nip;
    history.push(`/dashboard/personalia/laporan/input-payroll`, {
      data: { payroll_id: id, nip: nip },
    });
  };

  _onClickPreviewSlip = (id,bulan,tahun) => {
    const { history } = this.props;
    const nip = this.initialParam.nip;
    let temp_bulan = this.month[parseInt(bulan)-1];
    history.push(`/dashboard/personalia/laporan/preview-payroll`, {
      data: { payroll_id: id, nip: nip, bulan:temp_bulan, tahun:tahun },
    });
  };

  //tombol umum
  _onClickBack = () => {
    const { history } = this.props;
    history.push("/dashboard/personalia/data-payroll/payroll");
  };

  render() {
    const { handleGetPayrollList, process } = this.props;
    return (
      <div>
        <Button title="Kembali" onClick={this._onClickBack} />
        <div className="information-letter__content">
          <ContentTablePayrollList
            process={process}
            initialParams={this.initialParam}
            onSearchContent={handleGetPayrollList}
            tableFields={PERSONAL_PAYROLL_LIST_TABLE_FIELDS}
            onClickView={this._onClickView}
            onClickPreviewSlip={this._onClickPreviewSlip}
            onClickInputSlip={this._onClickInputSlip}
          />
        </div>
      </div>
    );
  }
}
ManagePayrollList.propTypes = {
  handleGetPayrollList: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
ManagePayrollList.defaultProps = {
  handleGetPayrollList: noop,
};
