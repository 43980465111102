import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { Multiselect } from 'multiselect-react-dropdown';

export default class formpengaturansoal extends Component {
  constructor(props) {
    super(props);    
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  render(){
    const { onFormChange, form,param } = this.props;
    return (
        <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                    <Select
                        name="organization_id"
                        label="Organisasi"
                        onChange={this._onFormChange} 
                        value={form.value.organization_id}
                    />
                </div>
                <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="number"
                        name="number_mass"
                        label="Jumlah Table"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>
            </div>
            <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                    <Input
                        type="number"
                        name="periode_mass"
                        label="Periode/Hari"
                        onChange={this._onFormChange}
                        value={form.value.periode_mass}
                    />
                </div>  
                <div className="absensi-rekap__custom-form-column__field"> 
                    <Input
                        type="file"
                        name="upload"
                        label="Upload Picture"
                        onChange={this._onFormChange} 
                        value={form.value.upload}
                    />
                </div>
            </div>
            <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                    <Input
                        type="date"
                        name="date"
                        label="Tanggal"
                        onChange={this._onFormChange}
                        value={form.value.date}
                    />
                </div>  
            </div>
      </div>
    );
  }
}
formpengaturansoal.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
formpengaturansoal.defaultProps = {
  onFormChange: noop,
};