import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty, trim } from "lodash";
import { Input, Button, Select, SelectMultiple, Textarea } from "../../../../components/base";

export default class AnekdotSubTemaFormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*",
      },
    };
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name, inputArray, fieldName, arrayPosition, arraySub) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name,
      value,
      dataset: {
        inputArray,
        fieldName,
        arraySub,
        arrayPosition,
      },
    }});
  }

  _onRemoveMulti(selectedList, removedItem, inputArray, fieldName, arrayPosition, arraySub) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name: removedItem,
      value: selectedList,
      dataset: {
        inputArray,
        fieldName,
        arraySub,
        arrayPosition,
      },
    }});
  }

  render() {
    const { sub_tema, indikator, error, onChange, param, subdata, idxsub, fieldName, form, onFormChange } = this.props;
    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No</th>
            <th>KD</th>
            <th>Indikator</th>
            <th>Catatan</th>
          </tr>
        </thead>
        <tbody>
          {map(subdata[idxsub].indikator, (data, idx) => (
            <tr>
              <td>{idx + 1}</td>
              <td style={{width: "30%"}}>
                {map(subdata[idxsub].indikator[idx].kd, (datakd, idxkd) =>(
                  datakd.label
                ))}
              </td>
              <td style={{width: "30%"}}>{data.name}</td>
              <td style={{width: "30%"}}>{data.note}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

AnekdotSubTemaFormView.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
};
AnekdotSubTemaFormView.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onFormChange: noop,
  onChange: noop,
};