import React, { PureComponent } from "react";
import PropTypes, { string } from "prop-types";
import { noop, isEmpty } from "lodash";
import { Button } from "../../../components/base";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../components/PrintSlipPayroll.component";

export default class PayrollSlipPreview extends PureComponent {
  //umum
  _onClickBack = () => {
    const { history, location } = this.props;
    let val = location.state.data;
    history.push(`/dashboard/personalia/laporan/slip-gaji/${val.nip}`, {
      data: val,
    });
  };

  render() {
    const { handleGetPayrollData,location } = this.props;
    const { nip, payroll_id,bulan,tahun } = location.state.data;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <div className="preview_sk__content">
                    <ComponentToPrint
                      ref={(el) => (this.componentRef = el)}
                      nip={nip.nip}
                      bulan={bulan}
                      tahun={tahun}
                      payroll_id={payroll_id}
                      handleGetPayrollData={handleGetPayrollData}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="preview_sk__button">
          <ReactToPrint
            trigger={() => (
              <Button style={{ marginBottom: 5 }} title="Cetak" block />
            )}
            content={() => this.componentRef}
          />
          {/* <ReactToPdf  targetRef={() => this.componentRef} filename="SuratSK.pdf">
            {({toPdf}) => (
                <Button onClick={toPdf} 
                    title = "Simpan PDF" 
                />
            )}
        </ReactToPdf> */}
          <br />
          <Button
            style={{ marginBottom: 20 }}
            block
            title="Kembali"
            onClick={() => this._onClickBack()}
          />
        </div>
      </div>
    );
  }
}

PayrollSlipPreview.propTypes = {
  handleGetPayrollData: PropTypes.func,
};
PayrollSlipPreview.defaultProps = {
  handleGetPayrollData: noop,
};
