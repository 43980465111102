import { connect } from 'react-redux';
import TambahJadwalKelas from '../../../views/Kurikulum/Jadwal/TambahJadwalKelas.kurikulum.view';
import { getParamOptions, getStudentOptions, getParamOptionsAcademics, getTeacherOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import { getSchedule, saveSchedule, listSchedule, editSchedule, saveScheduleUsers, saveScheduleDetails, uploadScheduleFile } from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getTeacherOptions: (payload) => dispatch(getTeacherOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetSchedule: payload => dispatch(getSchedule(payload)),
    handleSaveSchedule: (payload, goBack) => dispatch(saveSchedule(payload, goBack)),    
    handleListSchedule: (payload, goBack) => dispatch(listSchedule(payload, goBack)),
    handleEditSchedule: (payload, goBack) => dispatch(editSchedule(payload, goBack)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    // handleSaveScheduleDetails: (payload, goBack) => dispatch(saveScheduleDetails(payload, goBack)),
    handleUploadScheduleFile: (payload, goBack) => dispatch(uploadScheduleFile(payload, goBack)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TambahJadwalKelas);