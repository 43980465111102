import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  map, noop, has, find,
} from 'lodash';
import { Checkbox } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';

export default class FundCheckList extends Component {
  constructor(props) {
    super(props);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this._setRef = this._setRef.bind(this);
    this._setNewState = this._setNewState.bind(this);
    this.state = {
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { selectedBudgetDetails, budgets } = this.props;

    this.setState({
      selected: { ...selectedBudgetDetails },
      unchecked: {},
      checkedAll:
        Object.keys(budgets).length === Object.keys(selectedBudgetDetails).length,
    });
  }

  componentWillUnmount() {
    const { onChange } = this.props;
    onChange(this.state);
  }

  _setRef(el) {
    if (el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  _setNewState(state = {}, checked = false, detailId = null) {
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = state;
    const newState = { ...state };

    if(!checked) {
      if(has(selectedBudgetDetails, detailId)) {
        newState.unchecked[detailId] = { ...selectedBudgetDetails[detailId] };
      }
      delete newState.selected[detailId];
    } else {
      newState.selected[detailId] = find(budgets, (o) => {
        return parseInt(o.id) === parseInt(detailId)
      });
      delete newState.unchecked[detailId];
    }

    return newState;
  }

  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    for (let detailId in detailIds) {
      detailIds[detailId].checked = isCheckedAll;

      newState = this._setNewState(newState, isCheckedAll, detailId);
    }

    this.setState(prevState => ({
      ...prevState,
      ...newState,
      checkedAll: target.checked,
    }));
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState
    });
  }

  render() {
    const {
      budgets, onChange,
      selectedBudget, amount,
    } = this.props;
    const { selected, checkedAll } = this.state;
    return (
      <div className="manage-fund-request__form-table">
        <table cellPadding="0" cellSpacing="0" border="0" className="table">
          <thead>
            <tr>
              <th>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
              <th>No.</th>
              <th>Kode</th>
              <th>Uraian</th>
              <th>Semester</th>
              <th>Jumlah Anggaran YPL</th>
              <th>Sisa Anggaran YPL</th>
            </tr>
          </thead>
          <tbody>
            {
              map(budgets, (budget, idx) => (
                <tr key={`manage-fund-request_transaction__${idx}`}>
                  <td>
                    <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={this._onCheckDetail}
                      value={budget.id}
                      checked={has(selected, budget.id) ? true : false}
                      ref={this._setRef}
                    />
                  </td>
                  <td>
                    {parseInt(idx)+1}
                  </td>
                  <td>
                    {budget.code_of_account}
                  </td>
                  <td>
                    {budget.desc}
                  </td>
                  <td>
                    {budget.semester}
                  </td>
                  <td>
                    {commaFormatted(budget.ypl)}
                  </td>
                  <td>
                    {
                      budget.remains
                        ? commaFormatted(budget.remains)
                        : commaFormatted(budget.ypl)
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
FundCheckList.propTypes = {
  budgets: PropTypes.object,
  onChange: PropTypes.func,
  selectedBudget: PropTypes.object,
  amount: PropTypes.string,
  selectedBudgetDetails: PropTypes.object,
};
FundCheckList.defaultProps = {
  budgets: {},
  onChange: noop,
  selectedBudget: {},
  amount: '',
  selectedBudgetDetails: {}
};
