import { connect } from 'react-redux';
import CashJournal from '../../../../views/Finance/Bookkeeping/CashJournal/CashJournal.finance.view';
import {
  deleteJournal, getCashJournal, postJournalPosting, postJournalUnposting,
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleDeleteJournal: payload => dispatch(deleteJournal(payload)),
    handleGetCashJournal: payload => dispatch(getCashJournal(payload)),
    handlePostJournalPosting: payload => dispatch(postJournalPosting(payload)),
    handlePostJournalUnposting: payload => dispatch(postJournalUnposting(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CashJournal);
