import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop } from "lodash";
import { Input, Button, Select } from "../../../components/base";

export default class DataPotonganYayasanForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const { list } = this.props;

    return (
      <table className="manage-staff__form-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Jenis Potongan</th>
            <th>Nominal</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {map(list, (data, idx) => (
            <tr key={`data_potongan_yayasan_form__${idx}`}>
              <td>{idx + 1}</td>
              <td>
                <Input
                  noMargin
                  data-input-array
                  data-array-position={idx}
                  data-field-name="potongan_yayasan"
                  data-input-type="text"
                  name="name"
                  onChange={this._onFormChange}
                  value={data.name}
                  edit = {idx < 9 ? false : true}
                />
              </td>
              <td>
                <Input
                  noMargin
                  data-input-array
                  data-array-position={idx}
                  data-field-name="potongan_yayasan"
                  data-input-type="number"
                  name="nominal"
                  type="number"
                  onChange={this._onFormChange}
                  value={data.nominal}
                  edit = {idx < 9 ? false : true}
                />
              </td>
              <td>
                <Button
                  onClick={() => this._onDeleteList(idx)}
                  icon="icon-trash-o"
                  disabled = {idx < 9}
                />
              </td>
            </tr>
          ))}
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button icon="icon-plus" onClick={()=>this._onAddList()} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

DataPotonganYayasanForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  tembusan: PropTypes.array,
};

DataPotonganYayasanForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  tembusan: [],
};
