import { connect } from 'react-redux';
import PreviewSuratKeputusan from '../../../views/Personnel/Letter/PreviewSuratTugas.letter.view';
import { getDataPegawai,getDataST,submitST } from '../../../states/thunks/sk.thunks';
import { getStatusKepegawaianOptions, getUnitKerjaOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handelgetDataPegawai: payload => dispatch(getDataPegawai(payload)),
    handleGetDataST : payload => dispatch(getDataST(payload)),
    handleSubmitST : payload => dispatch(submitST(payload)),
    handleGetStatusKepegawaianOptions: payload => dispatch(getStatusKepegawaianOptions(payload)),
    handleGetUnitKerjaOptions: payload => dispatch(getUnitKerjaOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSuratKeputusan);
