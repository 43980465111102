import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { SearchSelect, Button, Select } from '../../../components/base';
import { commaFormatted } from '../../../utils/transformer.util';

const generateYear = () => {
  const year = new Date().getFullYear().toString().substr(-2);
  const data = [];
  for (let i = 6; i <= parseInt(year, 10); i += 1) {
    const val = i.toString().padStart(2, '0');
    const nextVal = (i + 1).toString().padStart(2, '0');
    data.push({
      value: val,
      label: `2${val.padStart(3, 0)}/2${nextVal.padStart(3, 0)}`,
    });
  }
  return data;
};


export default class PaymentRecap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        classId: '',
        year: '',
      },
      classes: [],
      report: [],
    };
    this._getReport = this._getReport.bind(this);
    this._getOptions = this._getOptions.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    this._getOptions();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (prevWorkingUnit !== workingUnit) {
      this._getOptions();
    }
  }

  async _getOptions() {
    const { handleGetOptions } = this.props;
    const res = await handleGetOptions({
      type: 'class',
    });
    this.setState({
      classes: res,
    });
  }

  async _getReport() {
    const { handleGetReport } = this.props;
    const { filters } = this.state;
    const { classId = '', year } = filters;
    const res = await handleGetReport({
      class: classId,
      year,
    });
    this.setState({ report: res });
  }

  _onChange(event) {
    const { value, name } = event.target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => {
      if (name === 'year') {
        this._getOptions();
      }
      this._getReport();
    });
  }

  render() {
    const {
      filters, report = {}, classes = [],
    } = this.state;
    const {
      data = [], total_payments = 0, total_invoices = 0, total_diff = 0,
      total_monthly = [],
    } = report;
    const { year = 20, classId = '' } = filters;
    const { user } = this.props;
    const { school_unit = {} } = user;

    return (
      <div className="balance-report">
        <div>
          <div className="report__title">
            <h3>
              <Select
                type="date"
                label="Tahun Akademis"
                name="year"
                data={generateYear()}
                value={year}
                onChange={this._onChange}
                placeholder="Pilih tahun akademis"
              />
            </h3>
            <h3>
              <Select
                label="Kelas"
                name="classId"
                onChange={this._onChange}
                placeholder="Pilih kelas"
                data={classes}
                value={classId}
              />
            </h3>
            <h1>Kartu Pembayaran SPP</h1>
            <h3>{school_unit ? school_unit.name : ''}</h3>
            <h3>Tahun Ajaran 20{year}/20{year ? parseInt(year, 10) + 1 : 20 }</h3>
          </div>
          <div className="report__details">
            <table className="payment_report__recap">
              <thead>
                <tr>
                  <th className="index" rowSpan="2">No.</th>
                  <th className="name" rowSpan="2">Nama</th>
                  <th className="va_code" rowSpan="2">Kode VA</th>
                  <th className="nominal" rowSpan="2">Tagihan Awal</th>
                  <th colSpan="12">Bulan</th>
                  <th className="nominal" rowSpan="2">Total Penerimaan</th>
                  <th colSpan="2">Rekap Akhir</th>
                  <th className="remarks" rowSpan="2">Keterangan</th>
                </tr>
                <tr>
                  <th className="months">Jul</th>
                  <th className="months">Agu</th>
                  <th className="months">Sep</th>
                  <th className="months">Okt</th>
                  <th className="months">Nov</th>
                  <th className="months">Des</th>
                  <th className="months">Jan</th>
                  <th className="months">Feb</th>
                  <th className="months">Mar</th>
                  <th className="months">Apr</th>
                  <th className="months">Mei</th>
                  <th className="months">Jun</th>
                  <th className="recap">YHD</th>
                  <th className="recap">Tunggakan</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((value, index) => (
                    <tr key={`recap_${index + 1}_value`}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {value.name}
                      </td>
                      <td>
                        {value.id}
                      </td>
                      <td className="nominal">
                        {commaFormatted(value.amount)}
                      </td>
                      <td className="nominal">
                        {value.invoices[7] ? commaFormatted(value.invoices[7]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[8] ? commaFormatted(value.invoices[8]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[9] ? commaFormatted(value.invoices[9]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[10] ? commaFormatted(value.invoices[10]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[11] ? commaFormatted(value.invoices[11]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[12] ? commaFormatted(value.invoices[12]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[1] ? commaFormatted(value.invoices[1]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[2] ? commaFormatted(value.invoices[2]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[3] ? commaFormatted(value.invoices[3]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[4] ? commaFormatted(value.invoices[4]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[5] ? commaFormatted(value.invoices[5]) : ''}
                      </td>
                      <td className="nominal">
                        {value.invoices[6] ? commaFormatted(value.invoices[6]) : ''}
                      </td>
                      <td className="nominal">
                        {commaFormatted(value.total_payments)}
                      </td>
                      <td clasName="nominal">
                        {commaFormatted(value.total_invoices)}
                      </td>
                      <td className="nominal">
                        {commaFormatted(value.diff)}
                      </td>
                      <td>
                        {value.remarks}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot>
                <tr className="total">
                  <td colSpan={4}>Total</td>
                  <td className="nominal">{commaFormatted(total_monthly[6])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[7])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[8])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[9])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[10])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[11])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[0])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[1])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[2])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[3])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[4])}</td>
                  <td className="nominal">{commaFormatted(total_monthly[5])}</td>
                  <td className="nominal">{commaFormatted(total_payments)}</td>
                  <td className="nominal">{commaFormatted(total_invoices)}</td>
                  <td className="nominal">{commaFormatted(total_diff)}</td>
                  <td />
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
PaymentRecap.propTypes = {
  user: PropTypes.object,
  workingUnit: PropTypes.object,
  handleGetReport: PropTypes.func,
  handleGetOptions: PropTypes.func,
};
PaymentRecap.defaultProps = {
  user: {},
  workingUnit: {},
  handleGetReport: noop,
  handleGetOptions: noop,
};
