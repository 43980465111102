import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import catathariansiswa from '../../../assets/img/akademi/displiner/catat_harian.png';
import catatpelanggaran from '../../../assets/img/akademi/displiner/catat_pelanggaran.png';
import catatprestasi from '../../../assets/img/akademi/displiner/catat_prestasi.png';
import tablepelanggaran from '../../../assets/img/akademi/displiner/tabel_pelanggaran.png';
import tableprestasi from '../../../assets/img/akademi/displiner/tabel_prestasi.png';

export default class Displiner extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickTablePelanggaran = this.onClickTablePelanggaran.bind(this);
    this.onClickTablePrestasi = this.onClickTablePrestasi.bind(this);
    this.onClickAnalisaDispliner = this.onClickAnalisaDispliner.bind(this);
    this.onClickCatatPelanggaran = this.onClickCatatPelanggaran.bind(this);
    this.onClickCatatPrestasi = this.onClickCatatPrestasi.bind(this);
    this.onClickCatatHarianSiswa = this.onClickCatatHarianSiswa.bind(this);
  }

  onClickTablePelanggaran() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner/table-pelanggaran');
  }

  onClickTablePrestasi() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner/table-prestasi');
  }

  onClickAnalisaDispliner() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner/analisa-displiner');
  }

  onClickCatatPelanggaran() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner/catat-pelanggaran');
  }

  onClickCatatPrestasi() {
      const { history } = this.props;
      history.push('/dashboard/kesiswaan/displiner/catat-prestasi')
  }

  onClickCatatHarianSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner/catat-harian-siswa')
}

  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickTablePelanggaran}>
            <img src={tablepelanggaran} alt="TablePelanggaran" />
            <h3>Table Pelanggaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickTablePrestasi}>
            <img src={tableprestasi} alt="TablePrestasi" />
            <h3>Table Prestasi</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickAnalisaDispliner}>
            <img src={budgeting} alt="AnalisaDispliner" />
            <h3>Analisa Displiner</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickCatatPelanggaran}>
            <img src={catatpelanggaran} alt="CatatPelanggaran" />
            <h3>Catat Pelanggaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickCatatPrestasi}>
            <img src={catatprestasi} alt="CatatPrestasi" />
            <h3>Catat Prestasi</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickCatatHarianSiswa}>
            <img src={catathariansiswa} alt="CatatHarianSiswa" />
            <h3>Catat Harian Siswa</h3>
          </button>
        </div> */}
        
      </div>
    );
  }
}
Displiner.propTypes = {
  history: PropTypes.object.isRequired,
};
