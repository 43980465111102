import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop, has, isEqual } from 'lodash';
import { Input, Button } from '../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';

export default class BeginningBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      values: {},
      is_posted: false,
      totalKewajiban: 0,
      totalAktiva: 0,
      totalEkuitas: 0,
    };
    this._getReport = this._getReport.bind(this);
    this._onChangeDate = this._onChangeDate.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSave = this._onSave.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._calculateTotals = this._calculateTotals.bind(this);
  }

  componentDidMount() {
    this._getReport()
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (!isEqual(user, prevProps.user)) {
      this._getReport();
    }
  }

  _calculateTotals() {
    const { values, report } = this.state;
    const newReport = [...report ];
    let totalAktiva = 0;
    let totalKewajiban = 0;
    let totalEkuitas = 0;

    if (newReport) {
      newReport.forEach(list => {
        list.category.forEach(lc => {
          let totalCategory = 0;
          lc.group.forEach(lg => {
            let totalGroup = 0;
            lg.account.forEach(coa => {
              let amount = Number(values[coa.code]) || 0;
              totalGroup += amount;
            });
            lg.total = totalGroup;
            totalCategory += totalGroup;
          });
          lc.total = totalCategory;
        });
      });
    }

    this.setState(prevState => ({
      ...prevState,
      report: newReport,
    }));
  }

  async _getReport(val) {
    const {  handleGetBeginningBalance } = this.props;
    const res = await  handleGetBeginningBalance(val);

    let values = {};

    this.setState({
      report: res.coa,
      values: res.startingBalance,
      is_posted: res.is_posted,
    }, () => this._calculateTotals());
  }

  _onChangeDate(event) {
    const { value } = event.target;
    this.setState({ date: value });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;

    // console.log(this.state.form)
    const { coa } = dataset;

    this.setState((prevState) => {
      let formattedValue = normalizeAmount(value);

      return {
        ...prevState,
        values: {
          ...prevState.values,
          [coa]: formattedValue,
        }
      };
    }, () => {
      this._calculateTotals();
    });
  }


  async _onSubmit(e) {
    e.preventDefault();
    const { values } = this.state;
    const {
      handlePostBeginningBalance,
      history,
    } = this.props;
    const res = await handlePostBeginningBalance(values);

    if(res) {
      this._getReport();
    }
  }

  _onSave(e) {
    e.preventDefault();
    const { values } = this.state;
    const {
      handleSaveBeginningBalance,
      history,
    } = this.props;
    handleSaveBeginningBalance(values, history.goBack);
  }

  render() {
    const { report, nominal, values = {}, is_posted} = this.state;
    const { user } = this.props;
    const { user_groups_id, user_group, school_unit, workingUnit } = user;
    const userGroup = user_group.name;
    const { permissions } = user_group;
    const activeUnit =
      (workingUnit) ? workingUnit.title :
        (school_unit) ? school_unit.name : null;

    if(!activeUnit) {
      return (
        <div className="balance-report">
          <h1><i>Mohon pilih unit</i></h1>
        </div>
      )
    }

    if(!permissions.includes('create_starting_balance')){
    return (
      <div className="balance-report">
            <div>
              <div className="balance-report__title">
                <h1>Saldo Awal</h1>
                <h1>{activeUnit}</h1>
                <h3>Periode Tanggal 1 Januari 2020</h3>
              </div>
              <table className="balance-report__table">
                <tbody>
                  {
                  map(report, (list, idx) => ([
                    <tr key={`balance_category_report_${idx}`}>
                      <td className="balance-report__row-title" colSpan={4}>{list.title}</td>
                    </tr>,
                    map(list.category, (lc, idc) => ([
                      <tr key={`balance_group_report_${idc}`}>
                        <td colSpan={4}>{lc.title}</td>
                      </tr>,
                      map(lc.group, (lg, idg) => ([
                        <tr key={`balance_group_report_${idg}`}>
                          <td rowSpan={lg.account.length + 2}>&nbsp;</td>
                          {/* <td colSpan={3}>{lg.title}</td> */}
                        </tr>,
                        map(lg.account, (la, ida) => ([
                          <tr key={`balance_group_account_report_${ida}`}>
                            <td>{la.code}</td>
                            <td>{la.title}</td>
                            <td className="nominal">
                              {has(values, la.code) ? commaFormatted(values[la.code]) : 0}
                            </td>
                          </tr>,
                        ])),
                        <tr key={`balance_group_report__end_${idg}`}>
                          <td colSpan={2}>Total {lg.title}</td>
                          <td className="nominal">{lg.total}</td>
                        </tr>,
                      ])),
                      <tr key={`balance_category_report__separator_${idc}`}>
                        <td colSpan={2}>Total {lc.title}</td>
                        <td className="nominal">{lc.total}</td>
                      </tr>,
                    ])),
                    <tr key={`balance_category_report__separator_${idx}`}>
                      <td colSpan={4}>&nbsp;</td>
                    </tr>,
                  ]))
                  }
                </tbody>
              </table>
            </div>
      </div>
    );
          } else {
            return (
              <div className="balance-report">
                    <div>
                      <div className="balance-report__title">
                        <h1>Saldo Awal</h1>
                        <h1>{activeUnit}</h1>
                        <h3>Periode Tanggal 31 Desember { parseInt(new Date('Y'))-1 }</h3>
                      </div>
                      <table className="balance-report__table table">
                        <tbody>
                          {
                          map(report, (list, idx) => ([
                            <tr key={`balance_category_report_${idx}`}>
                              <td className="balance-report__row-title" colSpan={4}>{list.title}</td>
                            </tr>,
                            map(list.category, (lc, idc) => ([
                              <tr key={`balance_group_report_${idc}`}>
                                <td colSpan={3}>{lc.title}</td>
                              </tr>,
                              map(lc.group, (lg, idg) => ([
                                map(lg.account, (la, ida) => ([
                                  <tr key={`balance_group_account_report_${ida}`}>
                                    <td>{la.code}</td>
                                    <td>{la.title}</td>
                                    <td className="nominal">
                                      <Input
                                        isNumber
                                        edit={is_posted !== 1}
                                        name="amount"
                                        onChange={this._onFormChange}
                                        value={
                                          has(values, la.code) ?
                                            values[la.code] : 0
                                        }
                                        data-coa={la.code}
                                      />
                                    </td>
                                  </tr>,
                                ])),
                                <tr key={`balance_group_report__end_${idg}`}>

                                </tr>,
                              ])),
                              <tr key={`balance_category_report__separator_${idc}`}>
                                <td colSpan={2}>Total {lc.title}</td>
                                <td className="nominal">{commaFormatted(lc.total)}</td>
                              </tr>,
                            ])),
                            <tr key={`balance_category_report__separator_${idx}`}>
                              <td colSpan={3}>&nbsp;</td>
                            </tr>,
                          ]))
                          }
                        </tbody>
                      </table>
                    </div>

                    <div className="beginning-balance__button-wrapper">
                      { !is_posted &&  (<Button
                        onClick={this._onSave}
                        title="SAVE"
                        />)
                      }
                      { !is_posted && (
                         <Button
                         onClick={this._onSubmit}
                         title="POSTING"
                         />)
                       }
                       {
                         is_posted && (
                           <p><i>Saldo awal telah di posting</i></p>
                         )
                       }

                    </div>
              </div>
            );

          }
  }
}
BeginningBalance.propTypes = {
  handleGetBeginningBalance: PropTypes.func,
  handleSaveBeginningBalance: PropTypes.func,
  handlePostBeginningBalance: PropTypes.func,
  user: PropTypes.object,
};
BeginningBalance.defaultProps = {
  handleGetBeginningBalance: noop,
  handleSaveBeginningBalance: noop,
  handlePostBeginningBalance: noop,
  user: null,
};
