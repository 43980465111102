import { connect } from "react-redux";
import { getPayrollData } from "../../../states/thunks/personnel.thunk";
import PayrollSlipPreview from "../../../views/Personnel/Payroll/PayrollSlipPreview.view";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayrollData: (payload) => dispatch(getPayrollData(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSlipPreview);
