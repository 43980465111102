import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class CatatanKegiatan extends Component {
  constructor(props){
      super(props);
      this._onFormChange = this._onFormChange.bind(this);

      this.state ={
        form: {
            values: {
                classes: '',
            },
            error: {
                classes: '',
            }
        }
    }
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
  const { onChange, form, classes } = this.props;
  return (
    <div className="data-pelanggaran__custom-form">
      <div className="data-pelanggaran__custom-form-column">
        <div className="data-pelanggaran__custom-form-row__field">
          <Input
            type="text"
            name="nissiswa"
            label="NIS Siswa"
            onChange={this._onFormChange}
            value={form.value.nissiswa || ''}
            error={form.error.nissiswa || ''}
          />
          
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Input
            type="text"
            name="namasiswa"
            label="Nama Siswa"
            onChange={this._onFormChange}
            value={form.value.namasiswa || ''}
            error={form.error.namasiswa || ''}
          />
         </div>
         
        
         <div className="data-pelanggaran__custom-form-row">
          <Select
            name="classes"
            label="Kelas"
            onChange={this._onFormChange}
            data={classes.list}
            value={form.value.classes || ''}
            error={form.error.namasiswa || ''}
            placeholder={'Pilihan'}
          />
          
          
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Input
            type="date"
            name="tanggalprestasi"
            label="Tanggal Kegiatan"
            onChange={this._onFormChange}
            value={form.value.tanggalprestasi || ''}
            error={form.error.tanggalprestasi || ''}
          />
         </div>
         
         <div className="data-pelanggaran__custom-form-row">
         <Select
            name="kegiatan"
            label="Kegiatan Ke"
            onChange={this._onFormChange}
            data={ '1', 'kelas7a'}
            placeholder={'New Entry'}
            value={form.value.kegiatan || ''}
            error={form.error.kegiatan || ''}
          />

        <div className="data-pelanggaran__custom-form-row__field"></div>
          <Textarea
            name="namakegiatan"
            label="Nama Kegiatan"
            onChange={this._onFormChange}
            value={form.value.namakegiatan || ''}
            error={form.error.namakegiatan || ''}
           
          />
          </div>

          <div className="data-pelanggaran__custom-form-row">
         <Textarea
            type="text"
            name="keterangan"
            label="Keterangan"
            onChange={this._onFormChange}
            value={form.value.keterangan || ''}
            error={form.error.keterangan || ''}
          />

        <div className="data-pelanggaran__custom-form-row__field"></div>
        <Input
            type="text"
            name="point"
            label="Point"
            onChange={this._onFormChange}
            value={form.value.point || ''}
            error={form.error.point || ''}
          />
          </div>
      </div>
    </div>
  );

}

}
CatatanKegiatan.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
CatatanKegiatan.defaultProps = {
  oChange: noop,
  classes: [],
};
