import { connect } from 'react-redux';
import LaporanPembayaranTotal from '../../../../views/Finance/Spp/LaporanSPP/LaporanPembayaranTotal.view';
import { getStudentSPPOptions, getParamOptionsAcademics, getSchoolUnitsOptions, getPaymentsTypeOptions, getYearsOfEducationsOptions} from '../../../../states/thunks/options.thunk';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, historyDetailPaymentsH2H, reportTotalPayments, reportTotalPaymentsGeneral, reportPaymentGateway, reportPaymentH2h, reportPaymentOffline, reportTotalAdmin } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    // getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleReportTotalPayments: (payload, goback) => dispatch(reportTotalPayments(payload, goback)),
    handleReportTotalAdmin: (payload, goback) => dispatch(reportTotalAdmin(payload, goback)),
    handleReportTotalPaymentsGeneral: (payload, goback) => dispatch(reportTotalPaymentsGeneral(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPaymentsTypeOptions: (payload, goback) => dispatch(getPaymentsTypeOptions(payload, goback)),
    handleReportPaymentGateway: (payload, goback) => dispatch(reportPaymentGateway(payload, goback)),
    handleReportPaymentOffline: (payload, goback) => dispatch(reportPaymentOffline(payload, goback)),
    handleReportPaymentH2h: (payload, goback) => dispatch(reportPaymentH2h(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPembayaranTotal);
