import { connect } from "react-redux";
import ManagePayrollList from "../../../views/Personnel/Payroll/ManagePayrollList.view";
import { getPayrollList } from "../../../states/thunks/personnel.thunk";

function mapStateToProps(state) {
  return {
    user: state.user,
    process:"view",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayrollList: (payload) => dispatch(getPayrollList(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePayrollList);
