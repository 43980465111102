import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map, filter } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { CATAT_SISWA } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import CatatSiswaPindahForm2 from '../components/CatatSiswaPindahForm2.component';
import SearchNisCatat from '../components/SearchNisCatat.component';

export default class MutasiKeluar2 extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this.onSearchTema = this.onSearchTema.bind(this);
          //this.deletePlafon = this.deletePlafon.bind(this);
          //this._onAddList = this._onAddList.bind(this);
          this._getStudentPeriodOptions = this._getStudentPeriodOptions.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._setForm = this._setForm.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
          
          this.state = {
            tema:[],
            page: 1,
            form: {
              value: {
                classes_id:[]
              },
              error: {},
            },
            param:{},
            filtered: false,
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
        }
      
        componentDidMount() {
          const { location, handleGetClasses, handleGetLevels, getPrmClassesOptions, user  } = this.props;
          const { state = {} } = location;
          const { organizations_id, school_unit } = user;
          const {isEdit =  false, data} = location.state;
          const { id } = state.data || '';
          const paramTypes = ['levels', 'classes', 'classrooms'];
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          }
          this._getStudentPeriodOptions( {units_id: [school_unit.id] });
          this._getPeriodsOptions({units_id: school_unit.id});

         
         
        }

        componentDidUpdate(prevProps) {
          // const { user } = this.props;
          // const { workingUnit } = user;
          // const { user: prevUser } = prevProps;
          // const { workingUnit: prevWorkingUnit } = prevUser;
      
          // if (!isEqual(workingUnit, prevWorkingUnit)) {
          //   this.handleGetData();
          // }
          // if (!isEqual(prevProps, this.props)) {
          //   this._onSearchContent({ page: 1 });
          // }
        }

        async _setForm(id) {
          const { handleGetMoveRecord } = this.props;
          try {
            const payload = await handleGetMoveRecord({ id });
           
      console.log(payload)
            this.setState({
              form: {
                value: {
                  id: payload.id,
                  classrooms_id: payload.classrooms_id,
                  classrooms_name:payload.classrooms_name,
                  student_attendance_status_id: payload.student_attendance_status_id,
                  student_mutations_id: payload.student_mutations_id,
                  student_name: payload.student_name,
                  students_id:  payload.students_id,
                  date: payload.date,
                  transfer_to: payload.transfer_to,
                  transfer_letter_number: payload.transfer_letter_number,
                  parents_name: payload.parents_name,
                  genders_name: payload.genders_name,
                  description: payload.description,
                  address: payload.address,
                },
                error: {
                  // details: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics, user } = this.props;
          const { school_unit, organizations_id } = user;
          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
            filters.units_id = school_unit.id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _getParamOptions(type, filters={}) {
          const {getParamOptions, user} = this.props;
          const { organizations_id } = user;
          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptions(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        onSearchTema(keyword = null){
          // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:[];
          const { handleGetTemaOptions } = this.props;
          // handleGetTemaOptions({
          //   keyword
          //   // divisiValue
          // });
        }

        async _getPeriodsOptions(filters = {}) {
          const { getPeriodsOptions, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id, levels_id } = school_unit;
          const res = await getPeriodsOptions(filters);
          filters.organizations_id = organizations_id;
      
          map(res, (data, idx) => {
            var date = new Date();
            var start_date = new Date(data.attributes.start_date)
            if (date > start_date) {
              // tahun ajaran sekarang
              this.setState(prevState => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  period: data.value,
                },
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    period: data.value,
                }
              }
              }));
              // tahun ajaran selanjutnya
            }
          });
      
          this.setState((prevState) => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
        }

        async onSubmit() {
          const { history, handleSaveMoveRecord } = this.props;
          const { form, filters } = this.state;
          const { value } = form;     

          const error = validateRequiredFields(form.value, [
            "transfer_letter_number",
            "description",
            "date",
            "student_mutations_id",
            "students_id",
            "classrooms_id",
          ]);

          this.setState((prevState) => ({
            form: {
              value: prevState.form.value,
              error,
            },
          }));
          if (isEmpty(error)) {
            await handleSaveMoveRecord(value);
            history.push('/dashboard/kesiswaan/data-siswa/mutasi-keluar');
          }
          
           
          // }
        }
      
      
      
        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }

          async onEdit() {
            const { history, handleEditMoveRecord } = this.props;
            const { form } = this.state;
            const { value } = form;
            const error = validateRequiredFields(form.value, [
              "transfer_letter_number",
              "description",
              "date",
              "student_mutations_id",
              "students_id",
              "classrooms_id",
            ]);
            this.setState((prevState) => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              await handleEditMoveRecord(value);
              history.push('/dashboard/kesiswaan/data-siswa/mutasi-keluar');
            }
          
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }
        _onFormChange(event) {
          const { form, filters } = this.state;
          const { user } = this.props;
          const { organizations_id } = user;
          const { classes_id, levels_id, periods_id, classrooms_id } = form.value;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'levels') {
              this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id: organizations_id }} );
            }
            if (name === 'classrooms_id') {
              this._getStudentPeriodOptions({ filters: { classrooms_id: [value], organizations_id: organizations_id, period: filters.period }} );
            }
          
          });
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
        async _getStudentPeriodOptions(filters = {}) {
          const { getStudentPeriodOptions, user } = this.props;
          const { school_unit } = user;
          const { form } = this.state;
          const { classes_id, levels_id, periods_id, classrooms_id } = form.value;

          const res = await getStudentPeriodOptions(filters);
          filters.units_id = school_unit.id;
          // console.log(res)
          this.setState((prevState) => ({
            ...prevState,
            param: {
              ...prevState.param,
              student: res,
            },
          }));
        }

        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          },
          () => {
            if (name === "students_id") {
              console.log(value)
            }
          });
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
      
        render() {
          const { form, page, param } = this.state;
          const { coa, classes = {}, tema = {}, location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = location.state;
          const { id } = state.data || '';
          const {plafon} = form.value;
          return (
            <div className="manage-registration">
              {/* <form onSubmit={this.onSubmit}> */}
               
                    <h1>Mutasi Keluar</h1>
              
                    <hr></hr>
                
                   <CatatSiswaPindahForm2
                    param={param}
                    form={form}
                    isEdit={isEdit}
                    onFormChange={this._onFormChange}
                    
                    />

            <div className="manage-registration__footer">
            <div className="manage-registration__button-wrapper">
              
            
            {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
               
            </div>
          </div>

               
        
              {/* </form> */}
            </div>
          );
        }
}
MutasiKeluar2.propTypes = {
  
  onSearchTema: PropTypes.object,
  tema: PropTypes.object,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
MutasiKeluar2.defaultProps = {
  handleDeleteStudent: noop,
  onSearchTema:  noop,
  tema: noop,
};