import { connect } from 'react-redux';
import Payment from '../../../views/Finance/Report/Payment.report.view';
import { getPaymentReport } from '../../../states/thunks/report.thunk';
import { getPaymentOptions } from '../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetReport: payload => dispatch(getPaymentReport(payload)),
    handleGetOptions: payload => dispatch(getPaymentOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
