import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEqual, isEmpty } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import { formatData } from '../../../../utils/transformer.util';
import language from '../../../../languages';
import { FINANCE_BUDGET_TABLE_FIELDS } from '../../../../constants/finance/budgeting/budget.constant';

export default class Budget extends Component {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.getData = this.getData.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      list: [],
      listAmount: 5,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.getData();
    }
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-anggaran');
  }

  onClickEdit(val) {
    const { history, user } = this.props;
    const { unique_id, workflow } = val;
    const { user_group } = user;
    let lastWorkflow;
    let nextRole = 'Keuangan Sekolah';

    if(workflow.length > 0) {
      lastWorkflow = workflow[workflow.length -1];
      nextRole = lastWorkflow.next_role;
    }

    if((user_group.name === 'Keuangan Sekolah'
      || user_group.name === 'Bendahara Perwakilan'
      || user_group.name === 'Bendahara Pusat')) {
      if(lastWorkflow && user_group.name !== nextRole) {
        history.push('/dashboard/keuangan/penganggaran/rapbu', {
          code: '',
          type: 'RAPBU',
          account_unique_id: '',
          head_unique_id: unique_id,
        });
      } else {
        history.push('/dashboard/keuangan/penganggaran/tipe-anggaran', { data: val });
      }
    } else {
      history.push('/dashboard/keuangan/penganggaran/rapbu', {
        type: 'RAPBU',
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
      });
    }

  }

  onClickView(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/apbu', {
      account_unique_id: val.account.unique_id,
      head_unique_id: val.unique_id,
    });
  }

  async onClickDelete(val) {
    const { handleDeleteBudget } = this.props;

    const res = await handleDeleteBudget({
      head_unique_id: val.unique_id,
    });

    if (res) {
      this.getData();
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.getData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async getData(payload = {}) {
    const { handleGetBudget, user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleGetBudget({
      ...payload,
      unit_id,
    });

    this.setState({
      list: res,
    });
  }

  renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { workflow } = data;
    const lastWorkflow = workflow[workflow.length -1];
    if (!lastWorkflow || lastWorkflow === null) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
          {(user_group.name === 'Keuangan Sekolah' || user_group.name === 'Bendahara Perwakilan' || user_group.name === 'Bendahara Pusat' || user_group.name === 'Admin' || user_group.name === 'Super Admin')
              && (
              <Button
                onClick={() => this.onClickDelete(data)}
                title="Hapus"
              />
              )
            }
          </div>
        </td>
      );
    }
    if (!lastWorkflow.is_done && user_group && user_group.name === lastWorkflow.next_role) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
          </div>
        </td>
      );
    }
    if (lastWorkflow.is_done) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickView(data)}
              title="Lihat APBU"
            />
          </div>
        </td>
      );
    } else {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="View"
            />
          </div>
        </td>
      );
    }
  }

  render() {
    const { list } = this.state;
    const { user } = this.props;
    const { user_group } = user;

    return (
      <div className="budget">
        { (user_group.name === 'Keuangan Sekolah'
          || user_group.name === 'Bendahara Perwakilan'
          || user_group.name === 'Bendahara Pusat') &&
          (<Button
            title="Tambah Anggaran"
            onClick={this.onClickAdd}
          />)
        }
        <div className="budget__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(FINANCE_BUDGET_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(FINANCE_BUDGET_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this.renderButtons(data)}
                    </tr>
                  ))
                }
                { isEmpty(list.result) && (
                    <tr>
                      <td colSpan="4">
                        <i>Tidak ada data ditemukan</i>
                      </td>
                    </tr>
                  )
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
Budget.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
Budget.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
