import React, { PureComponent, Component, Fragment } from "react";
import { commaFormatted } from "../../../../src/utils/transformer.util";

export default class ComponentToPrint extends Component {
 constructor(props) {
   super(props);
   this.state = {
     nip: "",
     no_g: "",
     nama: "",
     unit_kerja: "",
     yayasan: "",
     gaji_pokok: "",
     gaji_pokok_tidak_tetap:"",
     tunjangan_keluarga: "",
     tunjangan_struktural: "",
     tunjangan_fungsional: "",
     tunjangan_khusus: "",
     tunjangan_pns: "",
     honor_tdk_rutin: [],
     potongan_yayasan: [],
     potongan_luar_yayasan: [],
     total_penerimaan: "",
     total_pengurangan: "",
     gaji_bruto: "",
     pph21: "",
     gaji_bersih: "",
     pot_restitusi: "",
     pot_yadapen: "",
     pot_bpjs_kesehatan: "",
     pot_jp: "",
     pot_jht: "",
     ids: "",
     idsk: "",
     idp: "",
     khalawat: "",
     potongan_cu: "",
     potongan_koperasi: "",
     intern: "",
     pembulatan:"",
     tunjangan_tendik:"",
   };
 }

 componentDidMount() {
   this.getPayrollData();
 }

 getPayrollData = async () => {
   const { nip, payroll_id, handleGetPayrollData } = this.props;
   const data = await handleGetPayrollData({
     nip: nip,
     id_payroll: payroll_id,
   });
   console.log(data);
   this.setState({
     nip: data.nip,
     no_g: data.no_g,
     nama: data.nama_lengkap,
     unit_kerja: data.unit_kerja,
     yayasan: data.unit_kerja.perwakilan,
     gaji_pokok: "Rp." + commaFormatted(data.gaji_pokok),
     gaji_pokok_tidak_tetap: "Rp." + commaFormatted(data.gaji_pokok_tidak_tetap),
     tunjangan_keluarga: "Rp." + commaFormatted(data.tunj_beras),
     tunjangan_struktural:
       "Rp." + commaFormatted(data.tunj_struktural),
     tunjangan_fungsional:
       "Rp." + commaFormatted(data.tunj_fungsional),
     tunjangan_khusus: "Rp." + commaFormatted(data.tunj_khusus_tk_sd),
     tunjangan_pns: "Rp." + commaFormatted("0"),
     honor_tdk_rutin: data.honor_tidak_rutin,
     potongan_yayasan: data.potongan_yayasan,
     potongan_luar_yayasan: data.potongan_luar_yayasan,
     total_penerimaan:
       "Rp." + commaFormatted(data.result_total_penerimaan),
     total_pengurangan:
       "Rp." + commaFormatted(data.result_total_pengurangan),
     //gaji_bruto: "Rp." + commaFormatted(data.gaji_bersih) + ",00",
     gaji_bruto: "Rp." + commaFormatted(data.jml_penerimaan_bersih),
     pph21: "Rp." + commaFormatted(data.pph21),
     gaji_bersih: "Rp." + commaFormatted(data.jml_penerimaan_bersih),
     pot_restitusi: "Rp." + commaFormatted(data.pot_restitusi),
     pot_yadapen: "Rp." + commaFormatted(data.pot_yadapen),
     pot_bpjs_kesehatan: "Rp." + commaFormatted(data.pot_bpjs_kesehatan),
     pot_jp: "Rp." + commaFormatted(data.pot_jp),
     pot_jht: "Rp." + commaFormatted(data.pot_jht),
     ids: "Rp." + commaFormatted(data.ids),
     idsk: "Rp." + commaFormatted(data.idsk),
     idp: "Rp." + commaFormatted(data.idp),
     khalawat: "Rp." + commaFormatted(data.khalawat),
     potongan_cu: "Rp." + commaFormatted(data.potongan_cu),
     potongan_koperasi: "Rp." + commaFormatted(data.potongan_koperasi),
     intern: "Rp." + commaFormatted(data.intern),
     pembulatan: "Rp." + commaFormatted(data.pembulatan),
     tunjangan_tendik: "Rp." + commaFormatted(data.tunj_khusus),
   });
 };

 render() {
   const { bulan, tahun } = this.props;
   const state = this.state;
   return (
     <div className=" preview_slip__content">
       <div
         id="divPrint"
         style={{
           width: "auto",
           breakAfter: "avoid-page",
           border: "0px solid black",
         }}
       >
         <div className=" preview_slip__content">
           {/* title */}
           <div className="preview_slip__header">
             <div className="preview_slip__header-title">
               <div className="preview_slip__header-title--column">
                 <div className="preview_slip__header-title--column--bold">
                   YAYASAN PANGUDI LUHUR
                 </div>
                 <div className="preview_slip__header-title--column--bold">
                   SLIP GAJI PEGAWAI
                 </div>
                 <div className="preview_slip__header-title--column--bold">
                   BULAN {bulan} {tahun}
                 </div>
               </div>
             </div>
             <br />
             <hr />
             <hr />
           </div>
           {/* header */}
           <div className="preview_slip__header-top">
             <div
               className="preview_slip__header-top--left"
               style={{ flex: 5.5 }}
             >
               <table className="preview_slip__table">
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>NO. INDUK</div>
                     <div style={{ flex: "0.5", textAlign: "center" }}>:</div>
                     <div style={{ flex: "7" }}>{state.nip}</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row" style={{ flex: "6" }}>
                     <div style={{ flex: "4.5" }}>NO. PEGAWAI</div>
                     <div style={{ flex: "0.5", textAlign: "center" }}>:</div>
                     <div style={{ flex: "7" }}>{state.no_g}</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row" style={{ flex: "6" }}>
                     <div style={{ flex: "4.5" }}>NAMA</div>
                     <div style={{ flex: "0.5", textAlign: "center" }}>:</div>
                     <div style={{ flex: "7" }}>{state.nama}</div>
                   </div>
                 </tr>
               </table>
             </div>
             <div
               className="preview_slip__header-top--right"
               style={{ flex: 6.5 }}
             >
               <table className="preview_slip__table">
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>UNIT YAYASAN</div>
                     <div style={{ flex: "1", textAlign: "center" }}>:</div>
                     <div style={{ flex: "6.5" }}>{state.yayasan.name}</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row" style={{ flex: "6" }}>
                     <div style={{ flex: "4.5" }}>KODE UNIT KERJA</div>
                     <div style={{ flex: "1", textAlign: "center" }}>:</div>
                     <div style={{ flex: "6.5" }}>
                       {state.unit_kerja.id_unit_kerja}
                     </div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row" style={{ flex: "6" }}>
                     <div style={{ flex: "4.5" }}>UNIT KERJA</div>
                     <div style={{ flex: "1", textAlign: "center" }}>:</div>
                     <div style={{ flex: "6.5" }}>{state.unit_kerja.name}</div>
                   </div>
                 </tr>
               </table>
             </div>
           </div>
           <hr style={{ marginLeft: "2rem", marginRight: "2rem" }} />
           <hr style={{ marginLeft: "2rem", marginRight: "2rem" }} />
           {/* body */}
           <div className="preview_slip__body">
             <div className="preview_slip__body--left" style={{ flex: 5.75 }}>
               <table className="preview_slip__table">
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>PENERIMAAN</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "7" }}>- Gaji Pokok</div>
                     <div style={{ flex: "0.5", textAlign: "center" }}>:</div>
                     <div style={{ flex: "4.5" }}>{state.gaji_pokok}</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "7" }}>- Gaji Pokok Tidak Tetap</div>
                     <div style={{ flex: "0.5", textAlign: "center" }}>:</div>
                     <div style={{ flex: "4.5" }}>{state.gaji_pokok_tidak_tetap}</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>- Tunjangan</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <ol>
                       <li key={"tujangan1"}>
                         <div className="preview_slip__row">
                           <div style={{ flex: "6.5" }}>
                             Tunjangan Keluarga
                           </div>
                           <div style={{ flex: "0.5", textAlign: "center" }}>
                             :
                           </div>
                           <div style={{ flex: "5" }}>
                             {state.tunjangan_keluarga}
                           </div>
                         </div>
                       </li>
                       <li key={"tujangan2"}>
                         <div className="preview_slip__row">
                           <div style={{ flex: "6.5" }}>
                             Tunjangan Struktural
                           </div>
                           <div style={{ flex: "0.5", textAlign: "center" }}>
                             :
                           </div>
                           <div style={{ flex: "5" }}>
                             {state.tunjangan_struktural}
                           </div>
                         </div>
                       </li>
                       <li key={"tujangan3"}>
                         <div className="preview_slip__row">
                           <div style={{ flex: "6.5" }}>
                             Tunjangan Fungsional
                           </div>
                           <div style={{ flex: "0.5", textAlign: "center" }}>
                             :
                           </div>
                           <div style={{ flex: "5" }}>
                             {state.tunjangan_fungsional}
                           </div>
                         </div>
                       </li>
                       <li key={"tujangan4"}>
                         <div className="preview_slip__row">
                           <div style={{ flex: "6.5" }}>Tunjangan Khusus</div>
                           <div style={{ flex: "0.5", textAlign: "center" }}>
                             :
                           </div>
                           <div style={{ flex: "5" }}>
                             {state.tunjangan_khusus}
                           </div>
                         </div>
                       </li>
                       <li key={"tujangan5"}>
                         <div className="preview_slip__row">
                           <div style={{ flex: "6.5" }}>Tunjangan Tendik</div>
                           <div style={{ flex: "0.5", textAlign: "center" }}>
                             :
                           </div>
                           <div style={{ flex: "5" }}>
                             {state.tunjangan_tendik}
                           </div>
                         </div>
                       </li>
                       <li key={"tujangan6"}>
                         <div className="preview_slip__row">
                           <div style={{ flex: "6.5" }}>Tunjangan PNS</div>
                           <div style={{ flex: "0.5", textAlign: "center" }}>
                             :
                           </div>
                           <div style={{ flex: "5" }}>
                             {state.tunjangan_pns}
                           </div>
                         </div>
                       </li>
                     </ol>
                   </div>
                 </tr>
                 {/* <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>- Honor Tidak Rutin</div>
                   </div>
                 </tr> */}
                 {/* <tr>
                   <div className="preview_slip__row">
                     <ol>
                       {state.honor_tdk_rutin.map((data, index) => {
                         return (
                           <li key={index}>
                             <div className="preview_slip__row">
                               <div style={{ flex: "6.5" }}>{data.name}</div>
                               <div
                                 style={{ flex: "0.5", textAlign: "center" }}
                               >
                                 :
                               </div>
                               <div style={{ flex: "5" }}>
                                 {`Rp. ${commaFormatted(data.nominal)},00`}
                               </div>
                             </div>
                           </li>
                         );
                       })}
                     </ol>
                   </div>
                 </tr> */}
               </table>
             </div>
             <div
               className="preview_slip__row"
               style={{ flex: 0.5, textAlign: "center" }}
             >
               <hr />
               <hr />
             </div>
             <div className="preview_slip__body--right" style={{ flex: 5.75 }}>
               <table className="preview_slip__table">
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>PENGURANGAN</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>- Potongan Yayasan</div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <ol>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.Kesehatan</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.pot_restitusi}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.BPJS Kesehatan</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.pot_bpjs_kesehatan}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.BPJS JP</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.pot_jp}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.BPJS JHT</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.pot_jht}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.Yadapen</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.pot_yadapen}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.IDP</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.idp}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.IDS</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.ids}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.IDS Kematian</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.idsk}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.Khalwat</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.khalawat}
                           </div>
                         </div>
                       </li>
                     </ol>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>
                       &nbsp;
                     </div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <div style={{ flex: "4.5" }}>
                       - Potongan di Luar Yayasan
                     </div>
                   </div>
                 </tr>
                 <tr>
                   <div className="preview_slip__row">
                     <ol>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.CU PL Kasih</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.potongan_cu}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.Kop.Perwkln</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.potongan_koperasi}
                           </div>
                         </div>
                       </li>
                       <li>
                         <div className="preview_slip__row">
                           <div style={{ flex: "7" }}>Pot.Intern</div>
                           <div
                             style={{ flex: "0.5", textAlign: "center" }}
                           >
                             :
                           </div>
                           <div style={{ flex: "4.5" }}>
                             {state.intern}
                           </div>
                         </div>
                       </li>
                     </ol>
                   </div>
                 </tr>
               </table>
             </div>
           </div>
           <br />
           <br />
           {/* footer */}
           <div className="preview_slip__footer">
             <div className="preview_slip__footer--left" style={{ flex: 6 }}>
               <div style={{ borderStyle: "double" }}>
                 <div className="preview_slip__row">
                   <div style={{ flex: "7", textAlign: "right" }}>
                     TOTAL PENERIMAAN
                   </div>
                   <div
                     className="preview_slip__footer--left--inside"
                     style={{ flex: "5" }}
                   >
                     {state.total_penerimaan}
                   </div>
                 </div>
                 <div className="preview_slip__row">
                   <div style={{ flex: "7", textAlign: "right" }}>
                     TOTAL PENGURANGAN
                   </div>
                   <div
                     className="preview_slip__footer--left--inside"
                     style={{ flex: "5" }}
                   >
                     {state.total_pengurangan}
                   </div>
                 </div>
                 <div className="preview_slip__row">
                   <div style={{ flex: "7", textAlign: "right" }}>
                     GAJI BRUTO
                   </div>
                   <div
                     className="preview_slip__footer--left--inside"
                     style={{ flex: "5" }}
                   >
                     {state.gaji_bruto}
                   </div>
                 </div>
                 <div className="preview_slip__row">
                   <div style={{ flex: "7", textAlign: "right" }}>
                     Pph 21
                   </div>
                   <div
                     className="preview_slip__footer--left--inside"
                     style={{ flex: "5" }}
                   >
                     {state.pph21}
                   </div>
                 </div>
                 <hr/>
                 <div className="preview_slip__row">
                   <div style={{ flex: "7", textAlign: "right" }}>
                     GAJI BERSIH
                   </div>
                   <div
                     className="preview_slip__footer--left--inside"
                     style={{ flex: "5" }}
                   >
                     {state.gaji_bersih}
                   </div>
                 </div>
                 <div className="preview_slip__row">
                   <div style={{ flex: "7", textAlign: "right" }}>
                     GAJI YANG DITERIMA
                   </div>
                   <div
                     className="preview_slip__footer--left--inside"
                     style={{ flex: "5" }}
                   >
                     {state.pembulatan}
                   </div>
                 </div>
               </div>
             </div>
             <div className="preview_slip__footer--right" style={{ flex: 6 }}>
               <div className="preview_slip__col">
                 <div style={{ textAlign: "center" }}>Mengetahui Ketua,</div>
                 <div style={{ textAlign: "center" }}>
                   {state.yayasan.name}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   );
 }
}
