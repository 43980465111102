import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import { STUDENT_UNIT_2_FORM_FIELDS } from '../../../constants/student.constant';
import {  DAFTAR_NILAI_FORM_FIELDS} from '../../../constants/kurikulum/ulangan.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import EditKodeMatpelForm from './components/EditKodeMatpelForm.component';
import EditKodeMatpelTable from './components/EditKodeMatpelTable.component';

export default class EditKodeMatpel extends PureComponent {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this.state = {
        form: {
          value: {
            classes:[],
          },
          error: {
            classes:'',
          },
        },
      };
    }
  componentDidMount(){
    const{handleGetClasses} = this.props;
    handleGetClasses();
  }
    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        files,
      } = event.target;
      const { inputType = 'text' } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        if (inputType === 'number') {
          formattedValue = commaFormatted(value);
        }
        if (inputType === 'file') {
          [formattedValue] = files;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              [name]: formattedValue,
            },
            error: {
              ...prevState.form.error,
              [name]: '',
            },
          },
        };
      });
    }
  
    _onClickNext() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit');
      }
    
  
    render() {
      const { form } = this.state;
      const{classes={}} = this.props;
      return (
        
        <div className="student-list">
          <div className="budget__title">
          <h1>Edit Kode Mata Pelajaran </h1>
          <hr></hr>
          </div>
          <form onSubmit={this._onSubmit}>

            <div className="result__content">
            <EditKodeMatpelForm
                classes={classes}
                form={form}
                onFormChange={this._onFormChange}
            />
            <EditKodeMatpelTable
             form={form}
             onFormChange={this._onFormChange}
            />
            </div>
           
        {/*             
          <NilaiPsikTable
              form={form}
              onFormChange={this._onFormChange}
            /> */}
        
        
        <div className="manage-registration__footer">
             <div className="manage-registration__button-wrapper">
              { <Button
               onClick={this._onSubmit}
              title="Edit"
            /> 
              }
              {
             <Button
             onClick={this._onClickCetak}
             title="Hapus"
           />  
           }

            </div>
            </div>
                </form>
        </div>

      );
    }
  }
EditKodeMatpel.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
  handleGetClasses: PropTypes.func,
};
EditKodeMatpel.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
  handleGetClasses: noop,
};
