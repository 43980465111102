import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import daftar_guru from '../../../assets/img/akademi/kurikulum/MataPelajaran/daftar-guru.png';
import jadwal_mata_pelajaran from '../../../assets/img/akademi/kurikulum/MataPelajaran/jadwal-mata-pelajaran.png';
import kode_mata_pelajaran from '../../../assets/img/akademi/kurikulum/MataPelajaran/kode-mata-pelajaran.png';

export default class MataPelajaran extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickKodeMatpel = this.onClickKodeMatpel.bind(this);
    this.onClickDaftarGuru = this.onClickDaftarGuru.bind(this);
    this.onClickJadwalMatpel = this.onClickJadwalMatpel.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickPsik = this.onClickPsik.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickMingguan = this.onClickMingguan.bind(this);
    this.onClickDaftarNilaiReport = this.onClickDaftarNilaiReport.bind(this);
  }

  onClickKodeMatpel() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran');
  }

  onClickDaftarGuru() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/daftar-guru');
  }

  onClickJadwalMatpel() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickPsik() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-psik-afek');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }

  onClickMingguan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-mingguan');
  }

  onClickDaftarNilaiReport() {
    const {history} = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai/report');
  }

  render() {
    return (
      <div className="finance">
        {/* <div className="finance__list">
          <button onClick={this.onClickKodeMatpel}>
            <img src={kode_mata_pelajaran} alt="kode" />
            <h3>Kode Mata Pelajaran</h3>
          </button>
        </div>

        <div className="finance__list">
          <button onClick={this.onClickDaftarGuru}>
            <img src={daftar_guru} alt="kode" />
            <h3>Daftar Guru</h3>
          </button>
        </div>

        <div className="finance__list">
          <button onClick={this.onClickJadwalMatpel}>
            <img src={jadwal_mata_pelajaran} alt="kode" />
            <h3>Jadwal Mata Pelajaran</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickNilaiKognitif}>
            <img src={budgeting} alt="NilaiKognitif" />
            <h3>Pengisian Nilai Kognitif</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPsik}>
            <img src={budgeting} alt="psikafek" />
            <h3>Pengisian Psik & Afek</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={budgeting} alt="observasiharian" />
            <h3>Pengisian Observasi Harian</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickMingguan}>
            <img src={budgeting} alt="observasimingguan" />
            <h3>Pengisian Observasi Mingguan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilaiReport}>
            <img src={budgeting} alt="daftarnilaireport"/>
            <h3>Daftar Nilai / Report</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
MataPelajaran.propTypes = {
  history: PropTypes.object.isRequired,
};
