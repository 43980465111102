import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { commaFormatted } from '../../../utils/transformer.util';

export default class InputStaff extends PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  setRef(input) {
    this.input = input;
  }

  render() {
    const {
      theme,
      type,
      label,
      placeholder,
      value,
      onChange,
      error,
      classNames,
      leftIcon,
      rightIcon,
      disabled,
      noMargin,
      isNumber,
      edit,
      ...others
    } = this.props;
    const wrapperClassnames = classnames([
      'input',
      !edit && 'input__preview',
      noMargin && 'input__no-margin',
    ]);
    const labelClassnames = classnames([
      'input__label',
      error && 'input__label--error',
    ]);
    const containerClassnames = classnames([
      'input__container',
      disabled && 'input__disabled',
      error && 'input__container--error',
    ]);
    const inputClassnames = classnames([
      `input_text inputtext_${theme}`,
      leftIcon && `input_text_${theme}--icon-left`,
      rightIcon && `input_text_${theme}--icon-right`,
      (theme === 'default' && leftIcon && rightIcon) && 'input__text--no-border',
      disabled && 'input__text--disabled',
      classNames,
    ]);
    const formattedValue = isNumber ? commaFormatted(value) : value;
    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          {
            edit ? (
              <input
                ref={this.setRef}
                type={type}
                placeholder={placeholder}
                value={formattedValue}
                onChange={onChange}
                format="dd-MM-y"
                className={`${inputClassnames} input__datepicker input__datepicker__default`}
                autoComplete="new-password"
                {...others}
              />
            ) : <p className="input__preview">{formattedValue}</p>
          }
          {
            leftIcon
            && (
              <span className={`input_icon-left inputicon-left_${theme}`}>
                {
                typeof leftIcon === 'string'
                  ? <i className={leftIcon} />
                  : leftIcon
              }
              </span>
            )
          }
          {
            rightIcon
            && (
              <span className={`input_icon-right inputicon-right_${theme}`}>
                {
                typeof rightIcon === 'string'
                  ? <i className={rightIcon} />
                  : rightIcon
              }
              </span>
            )
          }
        </div>
        {error && <p className="input__err">{error}</p>}
      </div>
    );
  }
}

InputStaff.propTypes = {
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  isNumber: PropTypes.bool,
  edit: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
InputStaff.defaultProps = {
  disabled: false,
  noMargin: false,
  isNumber: false,
  edit: true,
  theme: 'default',
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  leftIcon: '',
  rightIcon: '',
  value: '',
  onChange: noop,
};
