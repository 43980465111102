import Spp from '../../../views/Finance/Spp/Spp.view';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        user: state.user || {},
      };
  }

export default connect(mapStateToProps, null)(Spp);



