export const STUDENT_PAGE_1_FORM_FIELDS = [{
  type: 'title',
  text: 'Data Pribadi Siswa',
  full: true,
}, {
  type: 'text',
  name: 'nis',
  label: 'NIS / NIM',
  placeholder: '',
}, {
  type: 'text',
  name: 'namaLengkap',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'namaPanggilan',
  label: 'Nama Panggilan',
  placeholder: '',
}, {
  type: 'select',
  name: 'jenisKelamin',
  label: 'Jenis Kelamin',
  placeholder: '',
  data: [
    { label: 'Laki-Laki', value: 'lakilaki' },
    { label: 'Perempuan', value: 'perempuan' },
  ],
}, {
  type: 'dualInput',
  leftName: 'tinggiBadan',
  leftLabel: 'Tinggi Badan/ Berat Badan',
  leftPlaceholder: '',
  rightName: 'beratBadan',
  rightLabel: '\u00A0',
  rightPlaceholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempat',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahir',
  rightLabel: '\u00A0',
}, {
  type: 'select',
  name: 'golonganDarah',
  label: 'Golongan Darah',
  placeholder: 'Pilih Golongan Darah',
  data: [
    { label: 'A', value: 'a' },
    { label: 'B', value: 'b' },
    { label: 'O', value: 'o' },
    { label: 'AB', value: 'ab' },
  ],
}, {
  type: 'text',
  name: 'hobi',
  label: 'Hobi',
  placeholder: '',
}, {
  type: 'text',
  name: 'penyakit',
  label: 'Penyakit dan Kelainan (bila ada)',
  placeholder: '',
}, {
  type: 'select',
  name: 'agama',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Buddha', value: 'buddha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Islam', value: 'islam' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Konghuchu', value: 'konghuchu' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Lainnya', value: 'lainnya' },
  ],
}, {
  type: 'select',
  name: 'citizenships',
  label: 'Kewarganegaraan',
  placeholder: 'Pilihan',
  data: [
    { label: 'WNI', value: 'wni' },
    { label: 'WNA', value: 'wna' },
  ],
}, {
  type: 'text',
  name: 'anakKe',
  label: 'Anak Ke',
  placeholder: '',
}, {
  type: 'text',
  inputType: 'number',
  name: 'jumlahSaudaraKandung',
  label: 'Jumlah Saudara Kandung',
  placeholder: '',
}, {
  type: 'text',
  name: 'bahasa',
  label: 'Bahasa Yang Digunakan Sehari-hari',
  placeholder: '',
}, {
  type: 'text',
  name: 'statusAnak',
  label: 'Status Anak',
  placeholder: '',
}, {
  type: 'text',
  name: 'email',
  label: 'Email',
  placeholder: '',
}, 


 {
  type: 'text',
  name: 'noTelp',
  label: 'Nomor Telepon',
  placeholder: '',
}, {
  type: 'text',
  name: 'statusRumah',
  label: 'Status Kepemilikan Rumah',
  placeholder: '',
}, {
  type: 'text',
  name: 'jarak',
  label: 'Jarak Sekolah - Rumah',
  placeholder: '',
}, {
  type: 'select',
  name: 'unit',
  label: 'Unit',
  placeholder: 'Pilihan',
  data: [
    { label: 'TK', value: 'tk' },
    { label: 'SD', value: 'sd' },
    { label: 'SMP', value: 'smp' },
    { label: 'SMA', value: 'sma' },
  ],
}, {
  type: 'empty',
}];




/*
ORTU
*/
export const STUDENT_PAGE_2_FORM_FIELDS = [{
  type: 'title',
  text: 'Data Orang Tua / Wali',
  full: true,
}, { // AYAH
  type: 'title',
  text: 'Ayah',
  full: true,
}, {
  type: 'text',
  name: 'namaLengkapAyah',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'nikAyah',
  label: 'NIK',
  placeholder: '',
}, {
  type: 'select',
  name: 'agamaAyah',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraanAyah',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'pendidikanAkhirAyah',
  label: 'Pendidikan Terakhir',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempatLahirAyah',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahirAyah',
  rightLabel: '\u00A0',
}, {
  type: 'text',
  name: 'StatusAyah',
  label: 'Status / Kondisi',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatAyah',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanAyah',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanAyah',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenAyah',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiAyah',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpAyah',
  label: 'Nomor Telepon / HP',
  placeholder: '',
}, {
  type: 'text',
  name: 'pekerjaanAyah',
  label: 'Pekerjaan',
  placeholder: '',
}, {
  type: 'text',
  name: 'jabatanAyah',
  label: 'Jabatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'penghasilanAyah',
  label: 'Penghasilan',
  placeholder: '',
}, { // IBU
  type: 'title',
  text: 'Ibu',
  full: true,
}, {
  type: 'text',
  name: 'namaLengkapIbu',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'nikIbu',
  label: 'NIK',
  placeholder: '',
}, {
  type: 'select',
  name: 'agamaIbu',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraanIbu',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'pendidikanAkhirIbu',
  label: 'Pendidikan Terakhir',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempatLahirIbu',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahirIbu',
  rightLabel: '\u00A0',
}, {
  type: 'text',
  name: 'StatusIbu',
  label: 'Status / Kondisi',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatIbu',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanIbu',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanIbu',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenIbu',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiIbu',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpIbu',
  label: 'Nomor Telepon / HP',
  placeholder: '',
}, {
  type: 'text',
  name: 'pekerjaanIbu',
  label: 'Pekerjaan',
  placeholder: '',
}, {
  type: 'text',
  name: 'jabatanIbu',
  label: 'Jabatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'penghasilanIbu',
  label: 'Penghasilan',
  placeholder: '',
}, { // WALI
  type: 'title',
  text: 'Wali',
  full: true,
}, {
  type: 'text',
  name: 'namaLengkapWali',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'nikWali',
  label: 'NIK',
  placeholder: '',
}, {
  type: 'select',
  name: 'agamaWali',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraanWali',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'pendidikanAkhirWali',
  label: 'Pendidikan Terakhir',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempatLahirWali',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahirWali',
  rightLabel: '\u00A0',
}, {
  type: 'text',
  name: 'StatusWali',
  label: 'Status / Kondisi',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatWali',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanWali',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanWali',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenWali',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiWali',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpWali',
  label: 'Nomor Telepon / HP',
  placeholder: '',
}];

// export const STUDENT_FORM = {
//   1: STUDENT_PAGE_1_FORM_FIELDS,
//   2: STUDENT_PAGE_2_FORM_FIELDS,
// };
export const STUDENT_PAGE_ASAL_SEKOLAH = [
//   {
//   type: 'title',
//   text: 'Asal Sekolah / Sekolah Sebelumnya',
//   full: true,
// }, {
//   type: 'text',
//   name: 'namaSekolah',
//   label: 'Nama Sekolah',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'alamatSekolah',
//   label: 'Alamat Sekolah',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'kelurahanSekolah',
//   label: 'Kelurahan',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'kecamatanSekolah',
//   label: 'Kecamatan',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'propinsiSekolah',
//   label: 'Propinsi',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'kabupatenSekolah',
//   label: 'Kotamadya / Kabupaten',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'noTelpSekolah',
//   label: 'Nomor Telepon',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'alasan',
//   label: 'Alasan Pindah',
//   placeholder: '',
// }, {
//   type: 'text',
//   name: 'kelasSekolah',
//   label: 'Kelas',
//   placeholder: '',
// }, {
//   type: 'dualInput',
//   leftName: 'noSurat',
//   leftLabel: 'Nomor Surat Pindah / Tanggal',
//   leftPlaceholder: '',
//   rightType: 'date',
//   rightName: 'tanggalPindah',
//   rightLabel: '\u00A0',
// },
 {
  type: 'title',
  text: 'Ijazah',
  full: true,
}, {
  type: 'dualInput',
  leftName: 'noIjazah',
  leftLabel: 'Nomor / Tanggal',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalIjazah',
  rightLabel: '\u00A0',
}, {
  type: 'dualInput',
  leftName: 'nilai',
  leftLabel: 'Nilai / Jumlah Pelajaran',
  leftPlaceholder: '',
  rightName: 'jumlahPelajaran',
  rightLabel: '\u00A0',
  rightPlaceholder: '',
}, {
  type: 'text',
  name: 'namaOrtu',
  label: 'Nama Orang Tua (Sesuai Ijazah)',
  placeholder: '',
}, {
  type: 'empty',
}, {
  type: 'title',
  text: 'SHUN',
  full: true,
}, {
  type: 'dualInput',
  leftName: 'noShun',
  leftLabel: 'Nomor / Tanggal',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalShun',
  rightLabel: '\u00A0',
}, {
  type: 'dualInput',
  leftName: 'nilaiShun',
  leftLabel: 'Nilai / Jumlah Pelajaran',
  leftPlaceholder: '',
  rightName: 'jumlahPelajaranShun',
  rightLabel: '\u00A0',
  rightPlaceholder: '',
}, {
  type: 'empty',
}, {
  type: 'title',
  text: '\u00A0',
  full: true,
}, {
  type: 'text',
  name: 'noUan',
  label: 'Nomor Peserta UAN',
  placeholder: '',
}, {
  type: 'empty',
}];

export const STUDENT_UNIT_1_FORM_FIELDS = [{
 
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
 
  data: [
    { label: '2019-2020', value: 'tahun1' },
    { label: '2018-2019', value: 'tahun2' },
    
  ],
},{
  type: 'select',
  name: 'tingkat',
  label: 'Tingkat/Kelas',
 
  data: [
    { label: 'Semua Kelas', value: 'all' },
    { label: 'Kelas I A', value: 'kelas1' },
  ],

},{

  type: 'select',
  name: 'unit',
  label: 'Unit',
  placeholder: 'Pilih',
  data: [
    {label: 'Kelas 1A', value: 'kelas'},
  ]
},


];

export const STUDENT_UNIT_2_FORM_FIELDS = [{

  type: 'select',
  name: 'daftarnamasiswa',
  label: 'Daftar Nama Siswa Kelas',
  placeholder:'Pilih Kelas',
 
  data: [
    { label: '2019-2020', value: 'tahun1' },
    { label: '2018-2019', value: 'hindu' },
    
  ],

},{

  type: 'text',
  name: 'daftarnamasiswa',
  label: 'Wali Kelas',
 

},{
  type: 'select',
  name: 'bentuk',
  label: 'Bentuk',
 
  data: [
    { label: 'Normal/Standar', value: 'normal' },
    { label: '2018-2019', value: 'hindu' },
  ],
},{
  type: 'select',
  name: 'liatsiswa',
  label: 'List Siswa 1',
  placeholder: 'Pilih',
 
  data: [
    { label: 'Semua Kelas', value: 'all' },
    { label: 'Kelas I A', value: 'kelas1' },
  ],


},{
  type: 'select',
  name: 'liatsiswa',
  label: 'List Siswa 2',
  placeholder: 'Pilih',
 
  data: [
    { label: 'Semua Kelas', value: 'all' },
    { label: 'Kelas I A', value: 'kelas1' },
  ],

},{
  type: 'select',
  name: 'nisn',
  label: 'Tampilkan NISN',
 
  data: [
    { label: 'Ya', value: 'ya' },
    { label: 'Tidak', value: 'tidak' },
  ],

},{
  type: 'select',
  name: 'jeniskelamin',
  label: 'Tampilkan Jenis Kelamin',
 
  data: [
    { label: 'Ya', value: 'ya' },
    { label: 'Tidak', value: 'tidak' },
  ],


},{

  type: 'select',
  name: 'urutan',
  label: 'Urutan',
 
  data: [
    { label: 'Berdasarkan Abjad', value: 'ya' },
    { label: 'Berdasarkan Agama', value: 'agama' },
    { label: 'Berdasarkan Alamat', value: 'alamat' }
  ],
},{

  type: 'select',
  name: 'siswaygkeluar',
  label: 'Siswa Yang Keluar',
 
  data: [
    { label: 'Tampilkan ', value: 'ya' },
    { label: 'Tidak', value: 'tidak' },
  ],

}];

export const STUDENT_TABLE_UNIT_FIELDS = {
  label: [
    'No',
    'NISN',
    'No VA',
    'Nama Lengkap Siswa',
    'L/P',
    'Tempat & Tanggal Lahir',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'students_name' },
    { type: 'string', attribute: 'genders' },
    { type: 'string', attribute: 'dob' },
  ],
};

export const NIS_SISWA_SEARCH =[{

  type: 'selectCoa',
  name: 'code_of_account',
  label:'NIS Siswa',
  full:true,

}];

export const BATAL_SISWA_KELUAR_TABLE = {

label: [

  'NISN',
  'No VA',
  'Nama Lengkap',
  'Kelas',
  'Opsi',
],
value: [
  {},
  {type:'string', attribute:'nisn'},
  {type:'string', attribute:'no_va'},
  {type:'string', attribute:'namalengkap'},
  {type:'string', attribute:'kelas'},
  {type:'string', attribute:'opsi'},

],

}

const CATAT_SISWA_SEARCH =[{

  type: 'selectCoa',
  name: 'code_of_account',
  label:'NIS Siswa',
  full:true,

}];

const CATAT_SISWA_FORM =[{

  type: 'text',
  name: 'nis',
  label:'NIS Siswa',
  

},{
  type: 'text',
  name: 'namasiswa',
  label: 'Nama Siswa',

},{
  type: 'text',
  name: 'kelas',
  label: 'Kelas',

},{
  type: 'select',
  name: 'status',
  label: 'Status Mutasi',

  data: [
    {label : 'Dikeluarkan dari sekolah ini', value: 'keluar'},

  ],
},{
  
    type: 'text',
    name: 'nomorsurat',
    label: 'Nomor Surat Pindah',
  
},{

  type: 'date',
  name: 'tgl',
  label: 'Tanggal/Bulan/Tahun',
  placeholder: 'dd/mm/yyyy',
  dateFormat: 'DD/MM/YYYY',

},{
  type: 'text',
  name: 'keteranganpindah',
  label: 'Keterangan Pindah Ke',

},{
  type: 'select',
  name: 'status',
  label: 'Status Absen',

  data: [
    {label : 'Tetap Ditampilkan Di Absen (Dengan tanda ***)', value: 'keluar'},

  ],

}];



export const CATAT_SISWA = {
  1: CATAT_SISWA_SEARCH,
  2: CATAT_SISWA_FORM,
};



export const ADMISSION_REQUIRED_FIELD = ['parameter_code'];

export const STUDENT_TABLE_FIELDS = {
  label: [
    'No',
    'NISN',
    'No VA',
    'Tanggal Lahir',
    'Tempat Lahir',
    'Nama Lengkap',
    'Alamat',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'date', attribute: 'tanggalLahir' },
    { type: 'string', attribute: 'tempatLahir' },
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'alamat' },
  ],
};

export const STUDENT_TABLE_BY_NIK_FIELDS = {
  label: [
    'No',
    'Nama',
    'L/P',
    'No. Induk',
    'Tempat Lahir',
    'Tanggal Lahir',
    'Agama',
    'Nama Orang Tua / Wali',
    'Alamat',
    'Kelas',
    'Tanggal Masuk',
  ],
  value: [
    {},
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'jenisKelamin' },
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'tempatLahir' },
    { type: 'date', attribute: 'tanggalLahir' },
    { type: 'string', attribute: 'agama' },
    { type: 'string', attribute: 'namaOrangtua' },
    { type: 'string', attribute: 'alamat' },
    { type: 'string', attribute: 'kelas' },
    { type: 'date', attribute: 'tanggalMasuk' },
  ],
};

export const KLAPPER_TABLE_BY_NIK_FIELDS = {
  label: [
    'No',
    'Nama Siswa',
    'Nomor Induk',
    'L/P',
    'Tanggal Masuk',
    '16/17',
    '17/18',
    '18/19',
    '19/20',
    '20/21',
    'Tanggal Keluar',
    'Keterangan',
  ],
  value: [
    {},
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'lp' },
    { type: 'date', attribute: 'tanggalMasuk' },
    { type: 'string', attribute: '16' },
    { type: 'string', attribute: '17' },
    { type: 'string', attribute: '18' },
    { type: 'string', attribute: '19' },
    { type: 'string', attribute: '20' },
    { type: 'date', attribute: 'tanggalKeluar' },
    { type: 'string', attribute: 'keterangan' },
  ],
};

export const STUDENT_RESULT_MOCK = [{
  nis: '1111',
  // tanggalLahir: new Date(),
  tanggalLahir : '02-06-1990',
  tempatLahir: 'Jakarta',
  namaSiswa: 'Sri',
  alamat: 'Pramuka jakarta',
  jenisKelamin: 'P',
  agama: 'Islam',
  namaOrangtua: 'nugroho',
  kelas: 'X B/01',
  // tanggalMasuk: new Date(),
  tanggalMasuk: '18-05-2021',
  tanggalKeluar: '19-07-2021',
}, {
  nis: '2222',
  // tanggalLahir: new Date(),
  tanggalLahir : '11-06-1998',
  tempatLahir: 'Jakarta',
  namaSiswa: 'Intan',
  alamat: 'Jalan panjang Raya',
  jenisKelamin: 'P',
  agama: 'Katolik',
  namaOrangtua: 'nugroho',
  kelas: 'X B/01',
  // tanggalMasuk: new Date(),
  tanggalMasuk: '17-02-2021',
  tanggalKeluar: '21-06-2021',
}, {
  nis: '333',
  // tanggalLahir: new Date(),
  tanggalLahir : '01-06-1997',
  tanggalLahir : '02-06-1995',
  tempatLahir: 'Jakarta',
  namaSiswa: 'Diri',
  alamat: 'Jalan Kelapa Dua',
  jenisKelamin: 'P',
  agama: 'Islam',
  namaOrangtua: 'nugroho',
  kelas: 'X B/01',
  // tanggalMasuk: new Date(),
  tanggalMasuk: '22-04-2021',
  tanggalKeluar: '28-07-2021',
}];

export const KLAPPER_RESULT_MOCK = [{
  nis: '1111',
  namaSiswa: 'Intan',
  lp:'Perempuan',
  tanggalMasuk: new Date(),
  // tanggalMasuk: '18-05-2021',
  16: 'XB',
  17: '',
  18: '',
  19: '',
  20: '',
  keterangan: '',
  tanggalKeluar: new Date(),

}, {
  nis: '2222',
  namaSiswa: 'Sri',
  lp:'Perempuan',
  tanggalMasuk: new Date(),
  // tanggalMasuk: '17-02-2021',
  16: 'XB',
  17: '',
  18: '',
  19: '',
  20: '',
  tanggalKeluar: new Date(),
  keterangan: '',
}, {
  nis: '333',
  namaSiswa: 'Diri',
  lp:'Perempuan',
  tanggalMasuk: new Date(),
  // tanggalMasuk: 'tes',
  16: '',
  17: 'XA',
  18: '',
  19: '',
  20: '',
  tanggalKeluar: new Date(),
  keterangan: '',
}];

export const UPLOAD_STUDENT_FORM_FIELDS = [{
  type: 'file',
  name: 'file',
  label: 'File',
  placeholder: 'Pilih file',
  inputType: 'file',
  full: true,
}];

export const STUDENT_BOOK_MOCK = [{
  title: 'KETERANGAN TENTANG DATA DIRI SISWA',
  data: [{
    label: 'Nama Siswa',
    value: 'Agustinus Andi Hismanto',
    details: [{ label: 'Nama Lengkap', value: 'Agustinus Andi Hismanto' }, { label: 'Nama Panggilan', value: 'Andi' }],
  }, {
    label: 'Jenis Kelamin',
    value: 'Laki-laki',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: 'Lampung, 27-05- 1981',
  }, {
    label: 'Agama',
    value: 'Katolik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Anak Ke Berapa',
    value: '1',
  }, {
    label: 'Jumlah Saudara Kandung',
    value: '4',
  }, {
    label: 'Jumlah Saudara Tiri',
    value: '-',
  }, {
    label: 'Jumlah Saudara Angkat',
    value: '-',
  }, {
    label: 'Anak',
    value: 'Lengkap Orang Tuanya',
  }, {
    label: 'Bahasa Sehari Hari',
    value: 'Indonesia',
  }],
}, {
  title: 'KETERANGAN TEMPAT TINGGAL',
  data: [{
    label: 'Alamat',
    value: 'Serpong garden blok a',
  }, {
    label: 'Nomor Telepon',
    value: '08123123123',
  }, {
    label: 'Tinggal Dengan\n(Orang tua / saudara / asrama /kos)',
    value: 'Orang Tua',
  }, {
    label: 'Jarak Tempat\n(tinggal - sekolah)',
    value: '15.00 km',
  }],
}, {
  title: 'KETERANGAN KESEHATAN',
  data: [{
    label: 'Golongan Darah',
    value: 'O',
  }, {
    label: 'Penyakir Yang Pernah Diderita (TBC / Cacar / Malaria / dsb)',
    value: '-',
  }, {
    label: 'Kelainan Jasmani',
    value: '-',
  }, {
    label: 'Tinggi Dan Berat Badan',
    value: '170cm 80kg',
  }],
}, {
  title: 'KETERANGAN PENDIDIKAN',
  data: [{
    label: 'Pendidikan Sebelumnya',
    value: '',
    details: [{
      label: 'Lulusan Dari',
      value: 'Santa Ursula BSD',
    }, {
      label: 'Tanggal dan Nomor STTB',
      value: '1123123',
    }, {
      label: 'Lama Belajar',
      value: '3 Tahun',
    }],
  }, {
    label: 'Pindahan',
    value: '',
    details: [{
      label: 'Dari Sekolah',
      value: '-',
    }, {
      label: 'Alasan',
      value: '-',
    }],
  }, {
    label: 'Diterima Di Sekolah Ini',
    value: '',
    details: [{
      label: 'Di Kelas',
      value: '1',
    }, {
      label: 'Program',
      value: '-',
    }, {
      label: 'Tanggal',
      value: '21-7-2010',
    }],
  }],
}, {
  title: 'KETERANGAN TENTANG AYAH KANDUNG',
  data: [{
    label: 'Nama',
    value: '-',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: '-',
  }, {
    label: 'Agama',
    value: 'Katokik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Pendidikan',
    value: '-',
  }, {
    label: 'Pekerjaan',
    value: '-',
  }, {
    label: 'Penghasilan / Bulan',
    value: '-',
  }, {
    label: 'Alamat Rumah Dan Telepon',
    value: 'Serpong Garden Blok e',
  }, {
    label: 'Masih Hidup / Meninggal',
    value: 'Masih Hidup',
  }],
}, {
  title: 'KETERANGAN TENTANG IBU KANDUNG',
  data: [{
    label: 'Nama',
    value: '-',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: '-',
  }, {
    label: 'Agama',
    value: 'Katokik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Pendidikan',
    value: '-',
  }, {
    label: 'Pekerjaan',
    value: '-',
  }, {
    label: 'Penghasilan / Bulan',
    value: '-',
  }, {
    label: 'Alamat Rumah Dan Telepon',
    value: 'Serpong Garden Blok e',
  }, {
    label: 'Masih Hidup / Meninggal',
    value: 'Masih Hidup',
  }],
}, {
  title: 'KETERANGAN TENTANG WALI',
  data: [{
    label: 'Nama',
    value: '-',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: '-',
  }, {
    label: 'Agama',
    value: 'Katokik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Pendidikan',
    value: '-',
  }, {
    label: 'Pekerjaan',
    value: '-',
  }, {
    label: 'Penghasilan / Bulan',
    value: '-',
  }, {
    label: 'Alamat Rumah Dan Telepon',
    value: 'Serpong Garden Blok e',
  }, {
    label: 'Masih Hidup / Meninggal',
    value: 'Masih Hidup',
  }],
}, {
  title: 'KEGEMARAN SISWA',
  data: [{
    label: 'Kesenian',
    value: '-',
  }, {
    label: 'Olahraga',
    value: '-',
  }, {
    label: 'Kemasyarakatan',
    value: '-',
  }, {
    label: 'Lain-lain',
    value: '-',
  }],
}, {
  title: 'KETERANGAN PERKEMBANGAN SISWA',
  data: [{
    label: 'Menerima Beasiswa',
    value: '-',
  }, {
    label: 'Meninggalkan Sekolah',
    value: '',
    details: [{
      label: 'Tanggal Meninggalkan Sekolah',
      value: '-',
    }, {
      label: 'Alasa',
      value: '-',
    }],
  }, {
    label: 'Akhir Pendidikan',
    value: '',
    details: [{
      label: 'Tempat Belajar',
      value: '-',
    }, {
      label: 'STTB Nomor',
      value: '-',
    }],
  }],
}, {
  title: 'KETERANGAN SETELAH SELESAI PENDIDIKAN',
  data: [{
    label: 'Melanjutkan Di',
    value: '-',
  }, {
    label: 'Bekerja',
    value: '',
    details: [{
      label: 'Tanggal Mulai Bekerja',
      value: '-',
    }, {
      label: 'Nama Perusahaan / Lembaga',
      value: '-',
    }, {
      label: 'Penghasilan',
      value: '-',
    }],
  }],
}];

 const SEARCH_SISWA_PAGE1 = [{
  type: 'text',
  name: 'nis',
  label: 'NIS / Nama Siswa',
  placeholder: '',


},{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',

  data: [
    {label: '2019-2020', value: 'tahun'},
    {label: '2020-2021', value: 'tahun'},
  ]

},{
  type: 'select',
  name: 'tingkatkelas',
  label: 'Tingkat Kelas',
  placeholder: 'Pilih',
  data: [
    {label: 'Kelas 1A', value: 'kelas'},
  ]


},{

  type: 'select',
  name: 'unit',
  label: 'Unit',
  placeholder: 'Pilih',
  data: [
    {label: 'Kelas 1A', value: 'kelas'},
  ]
},{
  type: 'select',
  name: 'bentuk',
  label: 'Bentuk',
  placeholder: 'Pilih',
  data: [
    {label: 'Kelas 1A', value: 'kelas'},

  ]


}];


const SEARCH_SISWA_TABLE2 = {
  label: [

    'NISN',
    'No VA',
    'Nama Lengkap',
    'L/P',
    'Tempat Lahir',
    'Tanggal Lahir',
    'Agama',
    'Nama Ayah/Ibu',
    'Alamat',
    'Kelas',
    'Pekerjaan Ayah',
    'Pekerjaan Ibu',
    'Sekolah Asal',
    'Tanggal Masuk',
  ],
  value: [
    {},
    {type:'string', attribute:'nisn'},
    {type:'string', attribute:'no_va'},
    {type:'string', attribute:'namalengkap'},
    {type:'string', attribute: 'lp'},
    {type:'string', attribute:'tempatlahir'},
    {type:'date', attribute:'tgllahir'},
    {type:'string', attribute:'agama'},
    {type:'string', attribute: 'namaortu'},
    {type:'string', attribute:'alamat'},
    {type:'string', attribute:'kelas'},
    {type:'string', attribute: 'kerjaayah'},
    {type:'string', attribute:'kerjaibu'},
    {type:'string', attribute: 'sekolahasal'},
    {type:'string', attribute:'tglmasuk'},

  
  ],
 
 };

export const SEARCH_SISWA = {
  1: SEARCH_SISWA_PAGE1,
  2: SEARCH_SISWA_TABLE2,
};

const ABSENSI_HARIAN_PAGE1 = [{
  type: 'select',
  name: 'kelas',
  label: 'Kelas',

  data: [
    {label: '7A', value: 'kelas7a'},
    {label: '1A', value: 'kelas1a'},
  ]

},{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
 
  data: [
    {label: '2019-2020', value: 'tahun'},
  ]


},{
  type: 'text',
  name: 'nis',
  label: 'NIS / Nama Siswa',
  placeholder: '',


},{
type: 'date',
  name: 'tgl',
  label: 'Tanggal',
},{
  type: 'select',
  name: 'kelas',
  label: 'Unit',

  data: [
    {label: '7A', value: 'kelas7a'},
    {label: '1A', value: 'kelas1a'},
  ]

}];


const ABSENSI_HARIAN_PAGE2 =[{
  type: 'text',
  name:'tahunajaran',
  label: 'Tahun Ajaran 2019-2020 Kelas',
},{
  type: 'text',
  name:'tahunajaran',
  label: 'Tanggal',
}];

export const ABSENSI_HARIAN_TABLE = {
  label: [

    'No',
    'NISN',
    'No VA',
    'Nama Siswa',
    'Status Keterangan',
    'Keterangan',
    
  ],
  value: [
    {},
    {type:'string', attribute: 'nisn'},
    {type:'string', attribute: 'no_va'},
    {type:'string', attribute:'namasiswa'},
    {type:'string', attribute:'status'},
    {type:'string', attribute:'keterangan'},
    

  
  ],
 
 };

export const ABSENSI_HARIAN = {
  1: ABSENSI_HARIAN_PAGE1,
  2: ABSENSI_HARIAN_PAGE2,
 
 
};





export const ABSENSI_REKAP_PAGE2 =[{
  type: 'text',
  name:'tahunajaran',
  label: 'Periode Bulan ',
},{
  type: 'text',
  name:'kelas',
  label: 'Kelas',
}];

export const ABSENSI_REKAP_TABLE = {
  label: [

    'No',
    'NISN',
    'No VA',
    'Nama Siswa',
    'Ijin',
    'Sakit',
    'Alpha',
    'Terlambat',
    'Hadir',
    
  ],
  value: [
    {},
    {type:'string', attribute: 'nisn'},
    {type:'string', attribute: 'no_va'},
    {type:'string', attribute:'namasiswa'},
    {type:'string', attribute:'ijin'},
    {type:'string', attribute:'sakit'},
    {type:'string', attribute: 'Alpha'},
    {type:'string', attribute:'Terlambat'},
    {type:'string', attribute:'Hadir'},
    

  
  ],
 
 };


 const BIDANG_STUDY_PAGE1 =[{

  type: 'select',
  name: 'kelas',
  label: 'Kelas',

  data: [
    {label: '7A', value: 'kelas7a'},
    {label: '1A', value: 'kelas1a'},
  ]

},{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
 
  data: [
    {label: '2019-2020', value: 'tahun'},
  ]


},{
  type: 'select',
  name: 'tanggal',
  label: 'Tanggal',
 
  data: [
    {label: '2019-11-25 (Senin)', value: 'tanggal'},
  ]
},{
  type: 'select',
  name: 'periode',
  label: 'Periode',
 
  data: [
    {label: 'Ganjil', value: 'ganjil'},
  ]

},{
  type: 'select',
  name: 'matpel',
  label: 'Mata Pelajaran',
 
  data: [
    {label: 'Bahasa Indonesia', value: 'bindo'},
  ],
full: true,


}]

const BIDANG_STUDY_PAGE2 =[{
  type: 'textarea',
  name:'tahunajaran',
  label: '\u00A0',
}]


export const BIDANG_STUDY_TABLE = {
  label: [

    'No',
    'NISN',
    'No VA',
    'Nama Siswa',
    'Status',
    'Pengawasan',
    'Keterangan',
    
    
  ],
  value: [
    {},
    {type:'string', attribute: 'nisn'},
    {type:'string', attribute: 'no_va'},
    {type:'string', attribute:'namasiswa'},
    {type:'string', attribute:'status'},
    {type:'string', attribute:'pengawasan'},
    {type:'string', attribute: 'keterangan'},
   
    

  
  ],
 
 };
export const BIDANG_STUDY = {
  1: BIDANG_STUDY_PAGE1,
  2: BIDANG_STUDY_PAGE2,
};


const LAPORAN_ABSEN_PAGE1 =[{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
 
  data: [
    {label: '2019-2020', value: 'tahun'},
  ],
  full: true,


},{
  type: 'dualInput',
  leftType:'date',
  leftName: 'tempat',
  leftLabel: 'Tanggal',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahir',
  rightLabel: '\u00A0',
}]

const LAPORAN_ABSEN_PAGE2 =[{
  type: 'select',
  name: 'kelas',
  label: '\u00A0',

  data: [
    {label: '7A', value: 'kelas7a'},
    {label: '1A', value: 'kelas1a'},
  ]
}]



export const LAPORAN_ABSEN = {
  1: LAPORAN_ABSEN_PAGE1,
  2: LAPORAN_ABSEN_PAGE2,
};

 export const LAPORAN_8355_FORM_1=[{
  type: 'select',
  name:'tahunajaran',
  label: 'Tahun Ajaran ',
  data: [
    {label: '2019-2020', value: 'tahun'},
  ],
},{
  type: 'select',
  name:'tingkatkelas',
  label: 'Tingkat Kelas',
  data: [
    {label: 'Kelas 7', value: 'tahun'},
  ],
},{
  type: 'select',
  name:'model8355',
  label:'Model 8355',
  data: [
    {label: 'Model Standart', value: 'model'},
  ],

},{
  type: 'select',
  name: 'statussiswa',
  label:'Status Siswa',
  data: [
    {label: 'Semua Siswa', value: 'all',}
  ],

},{
  type: 'dualInput',
  leftName: 'kepalasekolah',
  leftLabel: 'Kepala Sekolah / NIP',
  leftPlaceholder: '',
  rightName: 'nip',
  rightLabel: '\u00A0',
  rightPlaceholder: '',

},{
  type: 'dualInput',
  leftName: 'namapengawas',
  leftLabel: 'Nama Pengawas / NIP',
  leftPlaceholder: '',
  rightName: 'nip',
  rightLabel: '\u00A0',
  rightPlaceholder: '',

},{

  type: 'select',
  name: 'tandatangan',
  label: 'Tanda Tangan',
  data: [
    {label: 'Halaman Terakhir', value: 'halterakhir'},
  ],

},{

  type: 'date',
  name: 'tglcetak',
  label: 'Tanggal Cetak',

},{
  type: 'textarea',
  name: 'header',
  label: 'Header',

},{
  type: 'textarea',
  name: 'footer',
  label: 'Footer',

}];

export const LAPORAN_DISPLINER =[{
  type: 'select',
  name: 'cetak',
  label: 'Cetak',
  placeholder: 'Pilih',
  data:[
    {label: 'Kelas 7', value:'kelas7'}
  ],

},{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
  data:[
    {label: '2019 - 2020', value:'2019 - 2020'}
  ],
},{
  type: 'select',
  name: 'bentuk',
  label: 'Bentuk',
  data:[
    {label: 'Rekap', value:'rekap'}
  ],

},{

  type: 'select',
  name: 'tingkat/kelas',
  label: 'Tingkat/Kelas',
  data:[
    {label: 'Semua Kelas', value:'all'}
  ],
},{

  type: 'date',
  name: 'daritanggal',
  label: 'Dari Tanggal',
  
},{
  type: 'date',
  name: 'sampaitanggal',
  label: 'Sampai Tanggal',

}]

export const LAPORAN_KONSELING =[{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
  data:[
    {label: '2019 - 2020', value:'2019 - 2020'}
  ],
  full: true,
},{
  type: 'date',
  name: 'from',
  label: 'Dari Tanggal',
  full: true,

},{

  type: 'date',
  name: 'to',
  label: 'Sampai Tanggal',
  full: true,
},{

  type: 'select',
  name: 'tingkatkelas',
  label: 'Tingkat Kelas',
  data:[
    {label: 'Semua Kelas', value:'all'}
  ],
  full: true,
}]

export const LAPORAN_ABSENSI_KELAS =[{
  type: 'select',
  name: 'bentuk',
  label: 'Bentuk',
  data:[
    {label: 'Rekap', value:'rekap'}
  ],

},{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
  data:[
    {label: '2019 - 2020', value:'2019 - 2020'}
  ],
},{
  type: 'select',
  name: 'rinci',
  label: 'Rinci Per Bulan',
  data:[
    {label: 'Ya', value:'ya'}
  ],
},{
  type: 'select',
  name: 'tingkat/kelas',
  label: 'Tingkat/Kelas',
  data:[
    {label: 'Semua Kelas', value:'all'}
  ],
},{

  type: 'date',
  name: 'daritanggal',
  label: 'Dari Tanggal',
  
},{
  type: 'date',
  name: 'sampaitanggal',
  label: 'Sampai Tanggal',

}]

export const LAPORAN_PERSENSI =[{
  type: 'select',
  name: 'bentuk',
  label: 'Bentuk',
  data:[
    {label: 'Rekap', value:'rekap'}
  ],

},{
  type: 'select',
  name: 'tahunajaran',
  label: 'Tahun Ajaran',
  data:[
    {label: '2019 - 2020', value:'2019 - 2020'}
  ],
},{
  type: 'select',
  name: 'break',
  label: 'Break Tiap Tingkat',
  data:[
    {label: 'Ya', value:'ya'}
  ],
},{
  type: 'select',
  name: 'pembulatan',
  label: 'Pembulatan Hasil Persentase',
  data:[
    {label: 'Semua Kelas', value:'all'}
  ],
},{

  type: 'date',
  name: 'daritanggal',
  label: 'Dari Tanggal',
  
},{
  type: 'date',
  name: 'sampaitanggal',
  label: 'Sampai Tanggal',

},{
  type: 'text',
  name:'jmlhari',
  label: 'Jumlah Hari Efektif',

}]

export const VALIDATION_FORM_PENDAFTARAN  = [
  //DATA DIRI
  {
    type : 'text',
    name : 'nissiswa',
  },
  {
    type : 'text',
    name : 'first_name',
  },
  {
    type : 'text',
    name : 'last_name',
  },
  {
    type : 'text',
    name : 'nama_panggilan',
  },
  {
    type : 'select',
    name : 'genders',
  },
  {
    type : 'text',
    name : 'tinggi_badan',
  },
  {
    type : 'text',
    name : 'berat_badan',
  },
  {
    type : 'text',
    name : 'berat_badan',
  },
  {
    type : 'text',
    name : 'tempat',
  },
  {
    type : 'date',
    name : 'tgl_lahir',
  },
  {
    type : 'select',
    name : 'blood_types',
  },
  {
    type : 'text',
    name : 'hobi',
  },
  {
    type : 'text',
    name : 'penyakit',
  },
  {
    type : 'select',
    name : 'religions',
  },
  {
    type : 'select',
    name : 'citizenships',
  },
  {
    type : 'number',
    name : 'anak_ke',
  },
  {
    type : 'number',
    name : 'jml_saudara',
  },
  {
    type : 'text',
    name : 'bahasa',
  },
  {
    type : 'text',
    name : 'status_anak',
  },
  {
    type : 'text',
    name : 'email',
  },
  {
    type : 'text',
    name : 'no_telp',
  },
  {
    type : 'text',
    name : 'status_kepemilikan',
  },
  {
    type : 'number',
    name : 'jarak',
  },
  {
    type : 'select',
    name : 'levels',
  },
  {
    type : 'number',
    name : 'tahunajaran',
  },
  {
    type : 'select',
    name : 'classes',
  },
  {
    type : 'text',
    name : 'alamat',
  },
  {
    type : 'select',
    name : 'provinces_id',
  },
  {
    type : 'select',
    name : 'cities_id',
  },
  {
    type : 'select',
    name : 'districts_id',
  },
  {
    type : 'select',
    name : 'sub_districts',
  },
  //ASAL SEKOLAH
  {
    type : 'text',
    name : 'namasekolah',
  },
  {
    type : 'text',
    name : 'alamatsekolah',
  },
  {
    type : 'select',
    name : 'provinces_id2',
  },
  {
    type : 'select',
    name : 'cities_id2',
  },
  {
    type : 'select',
    name : 'districts_id2',
  },
  {
    type : 'select',
    name : 'sub_districts2',
  },
  {
    type : 'number',
    name : 'notelp_sekolah',
  },
  {
    type : 'text',
    name : 'alasan',
  },
  {
    type : 'select',
    name : 'levels1',
  },
  {
    type : 'text',
    name : 'namasurat',
  },
  {
    type : 'date',
    name : 'tanggal',
  },
  {
    type : 'text',
    name : 'nisn',
  },
  {
    type : 'text',
    name : 'nomor_ijazah',
  },
  {
    type : 'date',
    name : 'tanggal_ijazah',
  },
  {
    type : 'number',
    name : 'nilai_ijazah',
  },
  {
    type : 'number',
    name : 'jumlah_pelajaran_ijazah',
  },
  {
    type : 'text',
    name : 'nama_orang_tua',
  },
  {
    type : 'text',
    name : 'nomor',
  },
  {
    type : 'date',
    name : 'tanggal_shun',
  },
  {
    type : 'number',
    name : 'nilai',
  },
  {
    type : 'number',
    name : 'jumlah_pelajaran',
  },
  {
    type : 'text',
    name : 'no_peserta',
  },
  //ORTU ayah
  {
    type : 'text',
    name : 'nama_lengkap_ayah',
  },
  {
    type : 'text',
    name : 'nik1',
  },
  {
    type : 'select',
    name : 'religions1',
  },
  {
    type : 'select',
    name : 'citizenships1',
  },
  {
    type : 'select',
    name : 'educations1',
  },
  {
    type : 'text',
    name : 'tempat_tgl_lahir_ayah',
  },
  {
    type : 'date',
    name : 'tgl_lahir_ayah',
  },
  {
    type : 'number',
    name : 'notelpayah',
  },
  {
    type : 'select',
    name : 'occupations1',
  },
  {
    type : 'text',
    name : 'jabatan1',
  },
  {
    type : 'text',
    name : 'penghasilan1',
  },
  {
    type : 'text',
    name : 'alamat1',
  },
  {
    type : 'select',
    name : 'provinces_id3',
  },
  {
    type : 'select',
    name : 'cities_id3',
  },
  {
    type : 'select',
    name : 'districts_id3',
  },
  {
    type : 'select',
    name : 'sub_districts3',
  },
  {
    type : 'text',
    name : 'statusayah',
  },
  //ORTU ibu
  {
    type : 'text',
    name : 'nama_lengkap_ibu',
  },
  {
    type : 'text',
    name : 'nik2',
  },
  {
    type : 'select',
    name : 'religions2',
  },
  {
    type : 'select',
    name : 'citizenships2',
  },
  {
    type : 'select',
    name : 'educations2',
  },
  {
    type : 'text',
    name : 'tempat_tgl_lahir_ibu',
  },
  {
    type : 'date',
    name : 'tgl_lahir_ibu',
  },
  {
    type : 'number',
    name : 'notelpibu',
  },
  {
    type : 'select',
    name : 'occupations2',
  },
  {
    type : 'text',
    name : 'jabatan2',
  },
  {
    type : 'text',
    name : 'penghasilan2',
  },
  {
    type : 'text',
    name : 'alamat2',
  },
  {
    type : 'select',
    name : 'provinces_id4',
  },
  {
    type : 'select',
    name : 'cities_id4',
  },
  {
    type : 'select',
    name : 'districts_id4',
  },
  {
    type : 'select',
    name : 'sub_districts4',
  },
  {
    type : 'text',
    name : 'statusibu',
  },
  //Wali
  // {
  //   type : 'text',
  //   name : 'nama_lengkap_wali',
  // },
  // {
  //   type : 'text',
  //   name : 'nik3',
  // },
  // {
  //   type : 'select',
  //   name : 'religions3',
  // },
  // {
  //   type : 'select',
  //   name : 'citizenships3',
  // },
  // {
  //   type : 'select',
  //   name : 'educations3',
  // },
  // {
  //   type : 'text',
  //   name : 'tempat_tgl_lahir_wali',
  // },
  // {
  //   type : 'date',
  //   name : 'tgl_lahir_wali',
  // },
  // {
  //   type : 'number',
  //   name : 'notelpwali',
  // },
  // {
  //   type : 'select',
  //   name : 'occupations3',
  // },
  // {
  //   type : 'text',
  //   name : 'jabatan3',
  // },
  // {
  //   type : 'text',
  //   name : 'penghasilan3',
  // },
  // {
  //   type : 'text',
  //   name : 'alamat3',
  // },
  // {
  //   type : 'select',
  //   name : 'provinces_id5',
  // },
  // {
  //   type : 'select',
  //   name : 'cities_id5',
  // },
  // {
  //   type : 'select',
  //   name : 'districts_id5',
  // },
  // {
  //   type : 'select',
  //   name : 'sub_districts5',
  // },
  // {
  //   type : 'text',
  //   name : 'statuswali',
  // },
]

export const STUDENT_TABLE_EXCEL_UNIT_FIELDS = {
  label: [
    'No',
    'NISN',
    'No VA',
    'Nama Lengkap Siswa',
    'L/P',
    'Tempat & Tanggal Lahir',
    'Kelas',
    'Alamat',


  ],
  value: [
    {},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'students_name' },
    { type: 'string', attribute: 'genders' },
    { type: 'string', attribute: 'pob_dob' },
    { type: 'string', attribute: 'classrooms'},
    { type: 'string', attribute: 'address'},
  ],
};

export const STUDENT_TABLE_PPDB = {
  label: [
    'No',
    // 'NISN',
    'No VA',
    'Nama Lengkap Siswa',
    'L/P',
    'Tempat & Tanggal Lahir',
    'Total Kesanggupan UPP',
    'Total UPP yang Sudah Bayar'
  ],
  value: [
    {},
    // { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'fullname' },
    { type: 'string', attribute: 'genders' },
    { type: 'string', attribute: 'dob' },
  ],
};


