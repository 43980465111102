import React, { PureComponent } from "react";
import PropTypes, { string } from "prop-types";
import { noop,isEmpty } from "lodash";
import Button from "../../../components/base/Button/Button.component";
import {Input, Select,Textarea2} from '../../../../src/components/base';
import TembusanForm from '../components/DataTembusanSKForm.component';
import {validateSKForm} from '../../../utils/validation.util';
import { errorAlert } from '../../../../src/utils/alert.util';
import { format } from "date-fns";

export default class ManageInformationLetter extends PureComponent {
  constructor(props) {
    super(props);
    const date = new Date();
    const formatdate = format(date, "yyyy-MM-dd");
    this._onFormChange = this._onFormChange.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      created_by: '',
      nip : undefined,
      datades : undefined,
      datagol : undefined,
      datajabatan : undefined,
      create_by : undefined,
      form: {
        value: {
          id_sk_descriptions:'',
          keterangan_sk:'',
          tanggal_pembuatan_sk:formatdate,
          nama_ketua_yayasan : '',
          pangkat_golongan:'',
          pangkat :'',
          tanggal_berlaku_sk:formatdate,
          lama_bekerja_tahun:'',
          lama_bekerja_bulan:'',
          gaji_pokok:'',
          jabatan:'',
          nama_tabel_gaji:'',
          keterangan:'',
          tembusan:[]
        },
        error: {
          id_sk_descriptions:'',
          keterangan_sk:'',
          tanggal_pembuatan_sk:'',
          nama_ketua_yayasan : '',
          pangkat_golongan:'',
          pangkat :'',
          tanggal_berlaku_sk:'',
          lama_bekerja_tahun:'',
          lama_bekerja_bulan:'',
          gaji_pokok:'',
          jabatan:'',
          nama_tabel_gaji:'',
          keterangan:'',
          tembusan:[]
        }
      },
      errortembusan : '',
      no_sk :'',
    };

    this._onClickAddTembusan = this._onClickAddTembusan.bind(this);
    this._onDeleteListTembusan = this._onDeleteListTembusan.bind(this);
    this._onClickToSKList = this._onClickToSKList.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { isEdit = false, data } = location.state;
    this.onGetData();
    this.setState({created_by: this.props.user.id});
    if (isEdit) {
      this._setForm(location.state.data.id);
    }
  }

  async onGetData(){
    const{handelgetDataDeskripsi,handelgetDataGolongan,
      handelgetDataPegawai,handelgetDataJabatan
    } = this.props;
    const datadeskripsi = await handelgetDataDeskripsi();
    const nip = this.props.location.state.data.nip;
    const unit_kerja = {unitkerja : this.props.location.state.data.unit_kerja};
    const datagolongan = await handelgetDataGolongan(unit_kerja);
    const datajabatan = await handelgetDataJabatan(unit_kerja);

    this.setState({datades: datadeskripsi,datagol : datagolongan,
      datajabatan : datajabatan, nip : nip
    })
  }

async _setForm(id){
      const {handleGetDataSK} = this.props;
      const nip = this.props.location.state.data.nip;
      const unit_kerja = this.props.location.state.data.unit_kerja;
      const data_sk = await handleGetDataSK({
          id:id,
          nip : nip,
          unitkerja : unit_kerja
      });
      const data = data_sk.result.new_data;
      // console.log(data)
      let datatembusan = data.tembusan.map((item, index) =>{
        return(
            {counter: item.counter, note: item.note}     
      )});
      let golongan = data.pangkat_golongan+","+data.gaji_pokok;

    const dataset = {...this.state.form}
    dataset.value.id_sk_descriptions = data.id_sk_descriptions;
    dataset.value.tanggal_pembuatan_sk = data.tanggal_pembuatan_sk;
    dataset.value.keterangan_sk = data.keterangan_sk;
    dataset.value.nama_ketua_yayasan = data.nama_ketua_yayasan;
    dataset.value.keterangan = data.keterangan;
    dataset.value.pangkat = golongan;
    dataset.value.pangkat_golongan = data.pangkat_golongan;
    dataset.value.gaji_pokok = data.gaji_pokok;
    dataset.value.jabatan = data.jabatan;
    dataset.value.nama_tabel_gaji = data.nama_tabel_gaji;
    dataset.value.tanggal_berlaku_sk = data.tanggal_berlaku_sk;
    dataset.value.lama_bekerja_tahun = data.lama_bekerja_tahun;
    dataset.value.lama_bekerja_bulan = data.lama_bekerja_bulan;
    dataset.value.tembusan = datatembusan;
    this.setState({form : dataset, no_sk : data.no_sk});
}

_onFormChange(event) {
  const { name, value, dataset, checked, type, label} = event.target;
  const { inputType = 'text', inputArray = false, 
            arrayPosition = 0, fieldName,
          } = dataset;
    
    
    if( name === 'pangkat' ){
      console.log(typeof(value))
      const data = {...this.state.form}
      let temp = value.split(",")
      data.value.gaji_pokok = temp[1]
      data.value.pangkat_golongan = temp[0]
      data.error.gaji_pokok = ""
      this.setState({form:data})
    }
    
    if(fieldName == 'tembusan'){ 
      this.setState({errortembusan:''})
    }

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if (inputType === 'number') {
        // formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }

        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        
        if(fieldName === 'tembusan' && name === 'note') {
          newList[arrayPosition]['counter'] = parseInt(arrayPosition) + 1;
        }

        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }

        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }


  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {}
      }
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleManageInformationLetter, history, location } = this.props;
    const { data, isEdit = false } = location.state;
    const created_by = this.state.created_by;
    const {error,flag} = validateSKForm(form);
    if(flag){
      // console.log("valid")
      if (isEdit) {
        let id = location.state.data.id;
        handleManageInformationLetter(
          { nip: data.nip, id: id, no_sk :this.state.no_sk , ...form.value },
          history.goBack,
          isEdit
        ); 
      }else{
        handleManageInformationLetter(
          { nip: data.nip, ...form.value },
          history.goBack,
          isEdit
        );
      }
    }else{
      console.log(error)
      // console.log("invalid")
      if(error.error.lama_bekerja_bulan == "Bulan kerja salah"){
        errorAlert({title: 'Lama bulan kerja yang diisikan salah',});
      }else{
        errorAlert({title: 'Data yang diisikan tidak lengkap',});
      }
      this.setState({form : error});
      if(isEmpty(this.state.form.value.tembusan) || this.state.form.value.tembusan[0].note === '' ){
        this.state.errortembusan = 'Silahkan isi tembusan';
      }
    }
  }

  //buat tembusan
  _onClickAddTembusan(data) {
    const { form } = this.state;  
    const {tembusan} = form.value;
    
    //kalau bentuk array ngga boleh diubah langsung begini, bisa efek ke array yang lain, harus di clone dulu
    tembusan.push({
      counter: '',
      note: '',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tembusan,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onDeleteListTembusan(idx) {
    this.setState((prevState) => {
      const { tembusan } = prevState.form.value;
      const list = prevState.form.value.tembusan;

      list.splice(idx, 1);
      return {
        ...prevState,
        value: {
          ...prevState.value,
          tembusan: list,
        }
      };
    });
  }

  _onClickToSKList(){
    const { history,location } = this.props;
    let val = location.state.data;
    history.push(`/dashboard/personalia/surat-keputusan/${val.nip}`,{ data: val });
  }

  render() {
    const { form } = this.state;
    const { value,error } = form;
    return (
      <div className="manage-information-letter">
        <div>
          <h1 className="manage-information-letter__title">Surat Keputusan</h1>
        </div>
        <div>
          <Select
            name="id_sk_descriptions"
            label="Deskripsi SK"
            value = {value.id_sk_descriptions}
            data = {this.state.datades}
            onChange={this._onFormChange}
            placeholder="Pilih Deskripsi SK"
          />
          <div className="manage-information-letter__error">{this.state.form.error.id_sk_descriptions}</div>
          <Textarea2
            name="keterangan_sk"
            label="Isi Deskripsi SK"
            value={value.keterangan_sk}
            onChange={this._onFormChange}
            style={{height:100}}
          />
          <div className="manage-information-letter__error">{this.state.form.error.keterangan_sk}</div>
          <Input 
            type="date"
            name="tanggal_pembuatan_sk"
            value={value.tanggal_pembuatan_sk}
            label="Tanggal Surat Ditetapkan"
            onChange={this._onFormChange}
            style={{width:100}}
          />
          <div className="manage-information-letter__error">{this.state.form.error.tanggal_berlaku_sk}</div>
          <Input
            name="nama_ketua_yayasan"
            value={value.nama_ketua_yayasan}
            label="Nama Ketua Yayasan"
            onChange={this._onFormChange}
          />
          <div className="manage-information-letter__error">{this.state.form.error.nama_ketua_yayasan}</div>
          <Textarea2
            name="keterangan"
            value={value.keterangan}
            label="Keterangan Lain-lain"
            onChange={this._onFormChange}
            style={{height:100}}
          />
          <div className="manage-information-letter__error">{this.state.form.error.keterangan}</div>
        </div>
        <h2>DATA BARU</h2><br/>
        <div>
          <Select
            name="pangkat"
            value={value.pangkat}
            label="Pangkat/gol.ruang gaji"
            placeholder="Pilih Pangkat /  Gol"
            data={this.state.datagol}
            onChange={this._onFormChange}
          />
          <div className="manage-information-letter__error">{this.state.form.error.pangkat}</div>
          <Select 
            name="jabatan"
            value={value.jabatan}
            label="Jabatan"
            placeholder="Pilih Jabatan"
            data={this.state.datajabatan}
            onChange={this._onFormChange}
          />
          <div className="manage-information-letter__error">{this.state.form.error.jabatan}</div>
          
          <div className="manage-information-letter__tab">
            <div style={{flex:3}}>
                <Input 
                  name="gaji_pokok"
                  value={value.gaji_pokok}
                  label="Gaji Pokok"
                  onChange={this._onFormChange}
                  readOnly
                />
                <div className="manage-information-letter__error">{this.state.form.error.gaji_pokok}</div>
            </div>
            <div className="manage-information-letter__divider" ></div>
            <div style={{flex:3}}>
                <Input 
                  name="nama_tabel_gaji"
                  value={value.nama_tabel_gaji}
                  label="Nama Tabel Gaji"
                  onChange={this._onFormChange}
                />
                <div className="manage-information-letter__error">{this.state.form.error.nama_tabel_gaji}</div>
            </div>
          </div>
          
          <div className="manage-information-letter__tab">
            <div style={{flex:2}}>
              <Input 
                type="date"
                name="tanggal_berlaku_sk"
                value={value.tanggal_berlaku_sk}
                label="Tanggal Berlaku SK"
                onChange={this._onFormChange}
              />
              <div className="manage-information-letter__error">{this.state.form.error.tanggal_berlaku_sk}</div>  
            </div>
            <div className="manage-information-letter__divider" ></div>
            <div style={{flex:2}}>
              <Input 
                type ="number"
                name="lama_bekerja_tahun"
                min = "0"
                value={value.lama_bekerja_tahun}
                label="Tahun"
                onChange={this._onFormChange}
              />
              <div className="manage-information-letter__error">{this.state.form.error.lama_bekerja_tahun}</div>
            </div>
            <div className="manage-information-letter__divider" ></div>
            <div style={{flex:2}}>
              <Input 
                type ="number"
                name="lama_bekerja_bulan"
                min ="0"
                value={value.lama_bekerja_bulan}
                label="Bulan"
                onChange={this._onFormChange}
              />
              <div className="manage-information-letter__error">{this.state.form.error.lama_bekerja_bulan}</div>
            </div>
          </div>
        </div>
        
        <div className="manage-information-letter__error">{this.state.errortembusan} </div>
          <TembusanForm
            onAddList={this._onClickAddTembusan}
            onChange={this._onFormChange}
            onDeleteList = {this._onDeleteListTembusan}
            list = {value.tembusan}
          />
        
        <form onSubmit={this._onSubmit}>
          <div>
            <Button type="submit" title="Simpan" />
          </div>
        </form>
        <br/>
        <Button 
          title="Kembali"
          onClick = {()=>this._onClickToSKList()}
        />
        <br/>
      </div>
    );
  }
}
ManageInformationLetter.propTypes = {
  handleManageInformationLetter: PropTypes.func,
  handelgetDataDeskripsi: PropTypes.func,
  handelgetDataGolongan: PropTypes.func,
  handelgetDataJabatan : PropTypes.func,
  handleGetDataSK : PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
ManageInformationLetter.defaultProps = {
  handelgetDataDeskripsi:noop,
  handleManageInformationLetter: noop,
  handelgetDataGolongan : noop,
  handelgetDataJabatan : noop,
  handleGetDataSK : noop,
};
