import { connect } from 'react-redux';
import LaporanSekolahEdit from '../../../views/Akademi/DataKelas/LaporanSekolahEdit.view';
import { listPrmEducators, deletePrmEducators } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListPrmEducators: (payload, goback) => dispatch(listPrmEducators(payload, goback)),
    handleDeletePrmEducators: (payload, goback) => dispatch(deletePrmEducators(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanSekolahEdit);
