import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function EditKodeMatpelForm(props) {


  const { onFormChange, form, classes } = props;
  return (
    <div className="kode-matpel__custom-form">
      <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
          <Input
            name="kode_pel"
            label="Kode Pelajaran"
            onChange={onFormChange}
            value={form.value.kode_pel}
            error={form.error.kode_pel}
          />
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
          <Input
            name="nama_pel"
            label="Nama Pelajaran"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Praktek/Keterampilan/Kl 4'}
            value={form.value.nama_pel}
            error={form.error.nama_pel}
          />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
          <Input
            type="text"
            name="nama_singkat"
            label="Nama Singkat"
            onChange={onFormChange}
            value={form.value.nama_singkat || ''}
            error={form.error.nama_singkat || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
          <div className="kode-matpel__custom-form-row__field">
            <Select
              name="classes"
              label="Kelas"
              onChange={onFormChange}
              data={classes.list}
              placeholder={'Pilihan'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
          </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
          <Input
            type="text"
            name="urutan"
            label="Urutan di Raport"
            onChange={onFormChange}
            value={form.value.urutan || ''}
            error={form.error.urutan || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
           
           name="tahunajaran"
           label="Jurusan"
           onChange={onFormChange}
           value={form.value.Jurusan || ''}
           error={form.error.Jurusan || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="kode-matpel__custom-form-column__field">
            
            <Input
             
              type='text'
              name="kkm"
              label="KKM"
              onChange={onFormChange}
              value={form.value.kkm || ''}
              error={form.error.kkm || ''}
            />
            
         
           
          </div>
        </div>

        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
           
           name="mapel"
           label="Mapel Program/Jurusan"
           onChange={onFormChange}
           value={form.value.mapel || ''}
           error={form.error.mapel || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="kode-matpel__custom-form-column__field">
            
        <Select
           
           name="ekskul"
           label="Merupakan Ekskul"
           onChange={onFormChange}
           value={form.value.ekskul || ''}
           error={form.error.ekskul || ''}
           placeholder={'Pilihan'}
         />
            
         
           
          </div>
        </div>

        <div className="kode-matpel__custom-form-row-check">
        <div className="kode-matpel__custom-font">
          <div className="kode-matpel__custom-font__field">
            
          Hari Ekskul
          </div>
          <div className="kode-matpel__custom-checkbox__field">
          <div className="kode-matpel__custom-form-row-check">
            <p>Kognitif</p> &nbsp;
          <div className="button-ekskul__button-wrapper">
             
               <Input
                 type="checkbox"
                 name="kognitif"
                 onChange={onFormChange}
                 value={form.value.kognitif || ''}
                 error={form.error.kognitif || ''}
                //  checked='true'
                  // checked='false'
                 
              /> 
                </div>
          </div>
            
          
          <div className="kode-matpel__custom-checkbox__field">
           <div className="kode-matpel__custom-form-row-check">
            <p>Praktek</p> &nbsp;
            
          <div className="button-ekskul__button-wrapper">
             
               <Input
                 type="checkbox"
                 name="praktek"
                 onChange={onFormChange}
                 value={form.value.praktek || ''}
                 error={form.error.praktek || ''}
                  //checked='false'
                  // checked='false'
                 
              /> 
                </div>
                </div>
          </div>
          <div className="kode-matpel__custom-checkbox__field">
           <div className="kode-matpel__custom-form-row-check">
            <p>Afektif</p> &nbsp;
            
          <div className="button-ekskul__button-wrapper">
             
               <Input
                 type="checkbox"
                 name="afektif"
                 onChange={onFormChange}
                 value={form.value.afektif || ''}
                 error={form.error.afektif || ''}
                  //checked='false'
                  // checked='false'
                 
              /> 
                </div>
                </div>
          </div>
         
            <div className="kode-matpel__custom-form-data-row">
            <p>Sprit/Sos</p> &nbsp;
            <Select
           
           name="sprit"
           onChange={onFormChange}
           value={form.value.sprit || ''}
           error={form.error.sprit || ''}
           placeholder={'Pilihan'}
         />
          </div>
          </div>
           </div>
           <div className="kode-matpel__custom-form-column2__field">
          <Select
            name="output"
            label="Output Raport Ekskul"
            onChange={onFormChange}
            value={form.value.output || ''}
            error={form.error.output || ''}
          />
          </div>
          
         </div>

         <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
           
           name="nilai_raport"
           label="Nilai Raport"
           onChange={onFormChange}
           value={form.value.nilai_raport || ''}
           error={form.error.nilai_raport || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="kode-matpel__custom-form-column__field">
            
        <Input
           type="text"
           name="group"
           label="Grup Pelajaran"
           onChange={onFormChange}
           value={form.value.group || ''}
           error={form.error.group || ''}
           placeholder={'Pilihan'}
         />
          </div>
        </div>

        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
           
           name="metode"
           label="Metode Perhitugan Grouping"
           onChange={onFormChange}
           value={form.value.metode || ''}
           error={form.error.metode || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="kode-matpel__custom-form-column__field">
            
        <Input
           type="text"
           name="nama_output"
           label="Nama Output Raport"
           onChange={onFormChange}
           value={form.value.nama_output || ''}
           error={form.error.nama_output || ''}
           placeholder={'Pilihan'}
         />
          </div>
        </div>

        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Input
           type="text"
           name="group"
           label="Group Output Raport"
           onChange={onFormChange}
           value={form.value.group || ''}
           error={form.error.group || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="kode-matpel__custom-form-column__field">
            
        <Input
           type="text"
           name="bobot"
           label="Bobot"
           onChange={onFormChange}
           value={form.value.bobot || ''}
           error={form.error.bobot || ''}
           placeholder={'Pilihan'}
         />
          </div>
        </div>

        </div>
        
 
   
  );
}
EditKodeMatpelForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
EditKodeMatpelForm.defaultProps = {
  onFormChange: noop,
  classes: [],
};
