import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanPembayaranPPDB extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);
    this._onClickDetails = this._onClickDetails.bind(this);
    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;

    this.state = {
      page: 1,
      content: initialContent,
      list: [],
      filters: {
        year: "",
      },
      list: [],
      isPrint: true,
      form: {
        value: {
          currents_year: "",
          // years_now,
          levels_id,
          listreport: [],
          listreport2: [],
          user_id: user.id
        },
        error: {
          listreport: [],
          listreport2: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { levels_id } = school_unit;
    const { content, filters, form } = this.state;
    const { value } = form;
    let savedFilters = JSON.parse(window.localStorage.getItem('invoicePPDBFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('invoicePPDBForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    this._initializeData(savedFilters);
    if (savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id,
            currents_year: savedForm.value.currents_year,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id
        }
      }));
      if (user_group.name === 'pusat' || user_group.name === 'perwakilan') {
        this.getYearsOfEducationsOptions({ units_id: savedForm.value.units_id.value })

      }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { school_unit, user_group, organizations_id } = user;
    if (user_group.name == 'perwakilan') {
      await this._getSchoolUnitsOptions({ perwakilan_id: school_unit.perwakilan_id })
    }
    else {
      await this._getSchoolUnitsOptions();
    }

    if ((user_group.name == 'administration' || user_group.name == 'pusat' || user_group.name == 'perwakilan')) {
      //if ( organizations_id !== 3){
      await this.getPrmPaymentsNonGeneral();
      //}
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id: savedFilters.classrooms_id,
          jenis: savedFilters.jenis,
          payments_id: savedFilters.payments_id,
          period: savedFilters.period,
          year: savedFilters.year,
        }
      }), () => {
        // commonActions.setLoading({ show: true });
        this._onSearchContent()
      });
    }
    // if (organizations_id == 3){
    await this.getYearsOfEducationsOptions({ units_id: school_unit.id });

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        jenis: savedFilters.jenis,
      }
    }), () => {
      // commonActions.setLoading({ show: true });
      this._onSearchContent()
    });
    // }

  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id);

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '',
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran selanjutnya
        if (res[idx + 1]) {
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              year: res[idx + 1].value.toString().substring(2),
            },
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                currents_year: res[idx + 1].value
              }
            }
          }))
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }


  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('invoicePPDBFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('invoicePPDBForm', JSON.stringify(this.state.form));

        if (name === 'units_id') {
          const param = this.state.param.units;
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                units_id: value,
              }
            },
            filters: {
              ...prevState.filters,
              jenis: '',
            }
          }));
          this.getYearsOfEducationsOptions({ units_id: value.value })

        }

        this._onSearchContent();
      }
    );
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onClickEdit(val) {
    const { history, user } = this.props;
    const { organizations_id } = user;
    if (organizations_id == 4 || organizations_id == 5) {
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", { data: val, isEdit: true });
    }
    else {
      history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { jenis, period, payments_id, units_id } = filters;
    const { currents_year, years_now } = form.value

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleReportPPDBSpp, handleReportPPDBUpp, handleReportPPDBOfflineSpp, handleReportPPDBSppGeneral, handleReportPPDBUppGeneral,
            handleReportPPDBOfflineUpp, handleReportPPDBSppF07, handleReportPPDBUppF07, handleListStudentsClassDivGeneral, handleReportPPDBSppRicci, handleReportPPDBUppRicci } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id, user_group } = user;
          let result = {};
          let result2 = {};

          const subdomain_pst = units_id ? units_id.attributes.subdomain : '';
          const ppdb_online_pst = units_id ? units_id.attributes.ppdb_online : '';
          const ppdb_name_pst = units_id ? units_id.attributes.ppdb_name : '';
          const unit_code_pst = units_id ? units_id.attributes.unit_code : '';
          const units_id_pst = units_id ? units_id.value : '';

          const ppdb_online = user_group.name == 'pusat' || user_group.name == 'perwakilan' ? ppdb_online_pst : school_unit.ppdb_online;
          const subdomain = user_group.name == 'pusat' || user_group.name == 'perwakilan' ? subdomain_pst : school_unit.subdomain;
          const unit_code = user_group.name == 'pusat' || user_group.name == 'perwakilan' ? unit_code_pst : school_unit.unit_code;
          const ppdb_name = user_group.name == 'pusat' || user_group.name == 'perwakilan' ? ppdb_name_pst : school_unit.ppdb_name;

          if (ppdb_online === 1 && unit_code !== 'F07' && subdomain == null && organizations_id == 3) {
            if (jenis === "spp") {
              result = await handleReportPPDBSpp({
                ...params,
                currents_year: currents_year,
                type: ppdb_name,
                // units_id: school_unit.id,
                // currents_year: years_now,
                filters,
              });
            }
            else if (jenis === "upp") {
              result2 = await handleReportPPDBUpp({
                ...params,
                organizations_id: organizations_id,
                type: ppdb_name,
                units_id: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? units_id_pst : school_unit.id,
                filters,
              });
            }
          } else if (ppdb_online === 1 && unit_code == 'F07' && subdomain == null) {
            if (jenis === "spp") {
              result = await handleReportPPDBSppF07({
                ...params,
                currents_year: currents_year,
                type: ppdb_name,
                // units_id: school_unit.id,
                // currents_year: years_now,
                filters,
              });
            }
            else if (jenis === "upp") {
              result2 = await handleReportPPDBUppF07({
                ...params,
                organizations_id: organizations_id,
                type: ppdb_name,
                units_id: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? units_id_pst : school_unit.id,
                filters,
              });
            }
          }
          else if (ppdb_online === 1 && subdomain !== null) {
            if (jenis === "spp") {
              result = await handleReportPPDBSppGeneral({
                ...params,
                currents_year: currents_year,
                subdomain: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? subdomain_pst : school_unit.subdomain,
                filters,
              });
            }
            else {
              result2 = await handleReportPPDBUppGeneral({
                ...params,
                currents_year: currents_year,
                subdomain: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? subdomain_pst : school_unit.subdomain,
                filters,
              })
            }
          }
          else if (ppdb_online === 0) {
            if (jenis === "spp") {
              result = await handleReportPPDBOfflineSpp({
                ...params,
                currents_year: currents_year,
                units_id: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? units_id_pst : school_unit.id,
                // currents_year: years_now,
                filters,
              });
            }
            else if (jenis === "upp") {
              result2 = await handleReportPPDBOfflineUpp({
                ...params,
                organizations_id: organizations_id,
                units_id: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? units_id_pst : school_unit.id,
                filters,
              });
            }
          }
          else if (ppdb_online === 1 && school_unit.subdomain == null && unit_code !== 'F07' && organizations_id == 4 || organizations_id == 5) {
            if (jenis === "spp") {
              result = await handleReportPPDBSppRicci({
                ...params,
                currents_year: currents_year,
                type: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? ppdb_name_pst : school_unit.ppdb_name,
                filters,
              });
            }
            else {
              result2 = await handleReportPPDBUppRicci({
                ...params,
                organizations_id: organizations_id,
                units_id: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? units_id_pst : school_unit.id,
                payments_id: payments_id,
                type: user_group.name == 'pusat' || user_group.name == 'perwakilan' ? ppdb_name_pst : school_unit.ppdb_name,
                filters,
              })
            }
          }

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: result,
                listreport2: result2,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: [],
                listreport2: [],
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  _onClickDetails(val) {
    const { history } = this.props;
    const { form, filters, param, page, isPrint, data_idx } = this.state;
    const { listreport, listreport2, years_now, currents_year } = form.value;
    history.push("/dashboard/tagihan-siswa/laporan-pembayaran-ppdb-excel", {
      data1: listreport,
      data2: listreport2,
    });
  }

  render() {
    const { form, filters, param, page, isPrint, data_idx } = this.state;
    const { listreport, listreport2, years_now, currents_year } = form.value;
    const { jenis, payments_id, units_id } = filters;
    const { user } = this.props
    const { school_unit, organization, organizations_id, user_group } = user;
    const { logo_right } = organization;
    const { prm_payments } = param;
    let prm_payments_main = prm_payments;

    const componentRef = React.createRef();

    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      {
        -webkit-print-color-adjust: exact;
      }
    }`;


    const prm_jenis = [
      { label: organizations_id == 3 ? 'SPP' : 'RUTIN', value: 'spp' },
      { label: organizations_id == 3 ? 'DPP/UPP' : 'NON RUTIN', value: 'upp' },
    ];
    const bulan = [
      { label: 'Juli' },
      { label: 'Agustus' },
      { label: 'September' },
      { label: 'Oktober' },
      { label: 'November' },
      { label: 'Desember' },
      { label: 'Januari' },
      { label: 'Februari' },
      { label: 'Maret' },
      { label: 'April' },
      { label: 'Mei' },
      { label: 'Juni' },
    ];

    var lunas = 0
    var ttllunas = 0
    var subtotal = 0
    var total = 0
    var all_total = 0
    var totalAwal = 0

    var title = ""
    if (jenis == "spp") {
      title = organizations_id == 3 ? 'SPP' : 'RUTIN'
    }
    if (jenis == "upp") {
      title = organizations_id == 3 ? 'DPP/UPP' : 'NON RUTIN'
    }

    const units_label = units_id ? units_id.label : '';
    const units_value = units_id ? units_id.value : '';
    const ppdb_online_pst = units_id ? units_id.attributes.ppdb_online : '';
    const ppdb_online = user_group.name == 'pusat' || user_group.name == 'perwakilan' ? ppdb_online_pst : school_unit.ppdb_online;
    var payments_name;

    map(param.prm_payments, (data, idx) => {
      if (data.value == payments_id) {
        payments_name = data.label
      }
    })


    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Laporan Pembayaran PPDB</h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
          {(user_group.name === 'pusat' || user_group.name == 'perwakilan') ?
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="units_id"
                  list={param.units}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Unit"
                  value={form.value.units_id}
                  rightIcon="icon-search"
                  label="Pilih Unit"
                />
              </div>
            </div>
            : ''}
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="jenis"
                label="Jenis Pembayaran"
                onChange={this._onChangeFilter}
                data={prm_jenis}
                placeholder="Pilihan"
                value={jenis}
                disabled={(user_group.name !== 'pusat' || units_value) ? false : true}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              {(jenis === "upp") && (
                <Select
                  name="payments_id"
                  label="Nama Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_payments_main}
                  placeholder="Pilihan"
                  value={payments_id}
                />
              )}
            </div>
          </div>
        </div>

        <br></br>
        <div className="beginning-balance__button-wrapper">
          <ReactToPrint
            pageStyle={printTest}
            onBeforeGetContent={() => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.setState(
                    { isPrint: false },
                    resolve
                  );
                }, 500);
              });
            }}
            onAfterPrint={() => this.setState({ isPrint: true })}
            trigger={() => (
              // <div className="manage-registration__footer">
              <Button title="Cetak" />
              // </div>
            )}
            content={() => componentRef.current}
          />
          &nbsp;
          {jenis === 'upp' && organizations_id !== 3 ?
            <div style={{ marginLeft: '0rem' }} className="student-list__button">
              <Button
                title="Detail Laporan"
                onClick={this._onClickDetails}
              />
            </div>
            : ''}
        </div>
        {/* {isPrint? */}
        <form style={{ pageBreakInside: 'avoid', padding: '20px' }} ref={componentRef}>
          <div>
            <table className="table-report-header">
              <tr>
                <th rowSpan="2">
                  <img style={{ width: "70px" }} src={logo_right} />
                </th>
                <th style={{ paddingRight: '10rem' }}>
                  <h2>{user_group.name === 'pusat' || user_group.name == 'perwakilan' ? units_label : school_unit.name}</h2>
                </th>
              </tr>
              <tr>
                <th style={{ paddingRight: '10rem' }}>
                  <h3>LAPORAN PEMBAYARAN PPDB</h3>
                </th>
              </tr>
            </table>

            {(() => {
              if (!isEmpty(listreport) && jenis == "spp") {
                return (
                  <>
                    <table className="laporan-ki4__table-test" style={{ width: "unset" }}>
                      <tr>
                        <td>Jenis Pembayaran</td>
                        <td>&emsp; : {title}</td>
                      </tr>
                      <tr>
                        <td>Tahun Ajaran</td>
                        <td>&emsp; : {currents_year ? currents_year + "/" + (Number(currents_year) + 1) : "-"}</td>
                      </tr>
                    </table>
                    <br></br>
                    <table className="tablespp">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th rowSpan={2}>No VA</th>
                          <th rowSpan={2}>Nama Siswa</th>
                          <th colSpan={12}>Rincian Pembayaran</th>
                          <th rowSpan={2}>Total Pembayaran</th>
                        </tr>
                        <tr>
                          {map(bulan, (bln, idx) => (
                            <th>{bln.label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {map(listreport, (data, idx) => (
                          <tr key={`budget_row_${idx}`}>
                            {(() => {
                              data.id = data.no_va
                              data.entrance_year = currents_year
                              subtotal = 0
                            })()}
                            <td key={`table_index_${idx}`} style={{ textAlign: "center" }}>
                              {idx + 1}
                            </td>
                            <td>{data.no_va}</td>
                            <td style={{ textTransform: "capitalize" }}>{capitalize(data.name)}</td>
                            {(() => {
                              if (isEmpty(data.tr_invoices)) {
                                return (
                                  <td colSpan={12}>Invoice belum dibuat.</td>
                                )
                              } else {
                                return (
                                  map(data.tr_invoices, (data_detail, idx_detail) => {
                                    if (data_detail.payments_date !== null) {
                                      return (
                                        <td className="reportspp" style={{ color: "green", textAlign: "center" }}>LUNAS</td>
                                      )
                                    } else {
                                      subtotal = subtotal + Number(data_detail.nominal)
                                      return (
                                        <td className="reportspp">
                                          {(user_group.name !== "perwakilan" && organizations_id == 3) || (user_group.name !== 'administration' && organizations_id !== 3) ?
                                            <><Link
                                              to={{
                                                pathname: "/dashboard/tagihan-siswa/create-invoice/rutin",
                                                state: {
                                                  getdata: data_detail,
                                                  data: data,
                                                  isEdit: true,
                                                },
                                              }}>
                                              {commaFormatted(data_detail.nominal)}
                                            </Link>
                                            </>
                                            :
                                            commaFormatted(data_detail.nominal)
                                          }
                                        </td>
                                      )
                                    }
                                  })
                                )
                              }
                            })()}
                            <td className="nominal" style={{ width: "90px", minWidth: "90px", maxWidth: "90px" }}>{commaFormatted(subtotal)}</td>
                            {(() => {
                              total = total + subtotal
                            })()}
                          </tr>
                        ))}
                        <tr>
                          <th colSpan={15}>Total</th>
                          <th className="nominal" style={{ width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right" }}>{commaFormatted(total)}</th>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )
              }
              else if (!isEmpty(listreport2) && jenis == "upp") {
                return (
                  <div>
                    <table className="laporan-ki4__table-test" style={{ width: "unset" }}>
                      <tr>
                        <td>Jenis Pembayaran</td>
                        <td>&emsp; : {payments_name}</td>
                      </tr>
                      <tr>
                        <td>Tahun Masuk</td>
                        <td>&emsp; : {currents_year ? currents_year : "-"}</td>
                      </tr>
                    </table>
                    <br></br>
                    <table className="tablespp">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>No VA</th>
                          <th>Nama Siswa</th>
                          {/* <th>Total Sudah Dibayar</th>
                          <th>Total Sisa Bayar</th> */}
                          <th>Total Kesanggupan</th>
                          <th>Total Pembayaran</th>
                          <th>Total Tunggakan</th>
                          {(user_group.name !== 'perwakilan' && organizations_id == 3) || (user_group.name !== 'administration' && organizations_id !== 3) ?
                            isPrint ? <th>Opsi</th> : null
                            : ''}
                        </tr>
                      </thead>
                      <tbody>
                        {map(listreport2, (data, idx) => (
                          <tr key={`budget_row_${idx}`}>
                            {(() => {
                              lunas = 0
                              subtotal = 0
                              all_total = 0
                            })()}
                            <td key={`table_index_${idx}`} style={{ textAlign: "center" }}>
                              {idx + 1}
                            </td>
                            <td>{data.no_va}</td>
                            <td style={{ textTransform: "capitalize" }}>{capitalize(data.name)}</td>
                            {(() => {
                              if (!isEmpty(data.tr_payment_report) && !isEmpty(data.tr_payment_report[0].paymentdetails)) {
                                data.tr_payment_report[0].academics_year = currents_year
                                map(data.tr_payment_report[0].paymentdetails, (data_detail, idx_detail) => {
                                  if (data_detail.payments_date !== null) {
                                    lunas = lunas + Number(data_detail.nominal)
                                  } else {
                                    subtotal = subtotal + Number(data_detail.nominal)
                                  }
                                  all_total = lunas + subtotal
                                })
                                return (
                                  <>
                                    <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(all_total)}</td>
                                    <td className="nominal" style={{ width: "90px", minWidth: "90px", maxWidth: "90px" }}>{commaFormatted(lunas)}</td>
                                    <td className="nominal" style={{ width: "90px", minWidth: "90px", maxWidth: "90px" }}>{commaFormatted(subtotal)}</td>
                                    {(user_group.name !== 'perwakilan' && organizations_id == 3) || (user_group.name !== 'administration' && organizations_id !== 3) ?
                                      isPrint ?
                                        <td>
                                          <Button
                                            onClick={() => this._onClickEdit(data)}
                                            disabled={subtotal == 0 ? true : false}
                                            title="Edit"
                                          />
                                        </td>
                                        : null
                                      : ''}
                                  </>
                                )
                              } else {
                                return (
                                  <td colSpan={3}>Invoice belum dibuat.</td>
                                )
                              }
                            })()}
                            {(() => {
                              ttllunas = ttllunas + lunas
                              total = total + subtotal
                              totalAwal = totalAwal + all_total
                            })()}
                          </tr>
                        ))}
                          <tr>
                            <th colSpan={3}>Total</th>
                            <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(totalAwal)}</th>
                            <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(ttllunas)}</th>
                            <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                )
              }
              else if (jenis == undefined || jenis == "") {
                return (
                  <h2 style={{ textAlign: "center" }}>Jenis Pembayaran belum dipilih.</h2>
                )
              }
              else {
                return (
                  <h2 style={{ textAlign: "center" }}>Data {currents_year} belum ada.</h2>
                )
              }
            })()}
          </div>
        </form>

      </div>
    );
  }
}
LaporanPembayaranPPDB.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanPembayaranPPDB.defaultProps = {
  handleGetStudent: noop,
};
