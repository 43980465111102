import { connect } from 'react-redux';
import LaporanSekolahStatus from '../../../views/Akademi/DataKelas/LaporanSekolahStatus.view';
import { listPrmTypeEducators, savePrmTypeEducators, editPrmTypeEducators, deletePrmTypeEducators } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListPrmTypeEducators: (payload, goback) => dispatch(listPrmTypeEducators(payload, goback)),
    handleSavePrmTypeEducators: (payload, goback) => dispatch(savePrmTypeEducators(payload, goback)),
    handleEditPrmTypeEducators: (payload, goback) => dispatch(editPrmTypeEducators(payload, goback)), 
    handleDeletePrmTypeEducators: (payload, goback) => dispatch(deletePrmTypeEducators(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanSekolahStatus);
