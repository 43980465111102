import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { FileInput, Input, Button, Select } from '../../../../components/base/index';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';

export default class tabellistsoal extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    console.log(this.props)
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name,
    } = this.props;
    console.log(`render props : ` + JSON.stringify(list))
    const Matpel = [
        { label: 'Bahasa Indonesia', value: '1' },
        { label: 'Matematika', value: '2' },
        { label: 'Pendidikan Kewarganegaraan', value: '3' },
        { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: '4' },
      ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Tanggal</th>
            <th>Judul</th>
            <th>Mata Pelajaran</th>
            <th>Organisasi</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                    <Input
                        type="date"
                        inputArray
                        arrayPosition={idx}
                        fieldName='tabellistsoal'
                        name='date'
                        value={data.date}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Input
                        type="text"
                        name="judul"
                        inputArray
                        arrayPosition={idx}
                        fieldName='tabellistsoal'
                        value={data.judul}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Input
                        type="text"
                        arrayPosition={idx}
                        fieldName='tabellistsoal'
                        name='mata_pelajaran'
                        value={data.mata_pelajaran}
                        onChange={this._onFormChange}
                    />
                </td>
                <td>
                    <Select
                        name="organization_id"
                        label="Organisasi"
                        onChange={this._onFormChange} 
                        value={data.organization_id}
                    />
                </td>
                <td>
                    <Input
                        type="text"
                        arrayPosition={idx}
                        fieldName='tabellistsoal'
                        name='status'
                        value={data.status}
                    />
                </td>
                <td>
                    <div className="table__actions">
                        <Button
                            type="button"
                            title="Edit"
                        />
                        <Button
                            type="button"
                            title="Hapus"
                            onClick={this._onDeleteList}
                        />
                    </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}

tabellistsoal.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,

};
tabellistsoal.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  plafond_name: [],
};
