import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';
import { Link } from "react-router-dom";
import * as commonActions from '../../../../states/actions/common.action';
import { listAnnouncementStudent } from "../../../../states/thunks/academics.thunk";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class RekapPenyesuaian extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickStudent = this._onClickStudent.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._onClickSubmit = this._onClickSubmit.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);
    this._getParamPerwakilan = this._getParamPerwakilan.bind(this);
    this._onClickSet = this._onClickSet.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      isTable:true,
      content: initialContent,
      list: [],
      filters:{
      },
      list: [],
      isPrint: true,
      form: {
        value: {
          levels_id,
          listsiswa: [],
          units_id:'',
          levels_units:'',
          user_id:user.id
        },
        error: {
          listsiswa: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem('adjustmentFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('adjustmentForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    if (isEmpty(content.list)) {
    }
    this._initializeData(savedFilters);
    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            payments_id: savedForm.value.payments_id,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id,
        }
      }));
     
      if(user_group.name === "pusat"  || user_group.name === "perwakilan"){
         this._getPeriodsOptions({units_id: savedForm.value.units_id.value});
         this._getParamOptionsAcademics("classrooms", {
          payments_id: savedForm.value.payments_id,
          organizations_id,
          units_id: savedForm.value.units_id.value
        });
        this._getParamPerwakilan();

        }
        else {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: savedForm.value.levels_id,
            organizations_id,
            // units_id: savedForm.value.units_id.value
          });
        }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user
    const paramTypesLevels = ["levels"];
    paramTypesLevels.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
   
    if(user_group.name !== 'pusat'){
      const paramTypes = ["classrooms", "levels"];

      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
      this._getPeriodsOptions({units_id: school_unit.id});
    }

    await this._getSchoolUnitsOptions();
    await this._getParamPerwakilan();

    if ( organizations_id == 3){
      if(user_group.name == 'perwakilan'){
        await this._getSchoolUnitsOptions({perwakilan_id: school_unit.perwakilan_id})
        await this.getPrmPaymentsNon();
      }
      await this.getPrmPaymentsNon();

    }
    else {
    await this.getPrmPaymentsNonGeneral();

    }

    if (user_group.name == 'pusat' || user_group.name == 'perwakilan'){
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          // payments_id : savedFilters.payments_id,
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
   
  }

  async _getParamPerwakilan(filters = {}) {
    const { getParamPerwakilan, user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    const res = await getParamPerwakilan(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        perwakilan_prm: res,
      },
    }));
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      // window.localStorage.setItem('invoiceValue', JSON.stringify(this.state.form));
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    },
    () => {
      if (name === "levels_id") {
        this._getParamOptionsAcademics("classrooms", {
          levels_id: value,
          organizations_id: organizations_id
        });
      }
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { form } = this.state;
    const { organizations_id, school_unit } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('adjustmentFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('adjustmentForm', JSON.stringify(this.state.form));
       
        if (name === 'perwakilan_id'){
          this.setState((prevState) => ({
            ...prevState,
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                perwakilan_id: value,
                units_id: "",
                listsiswa: []
              },
            },
            filters: {
              ...prevState.filters,
              units_id: "",
              payments_id:"",
            }
          }));
          this._getSchoolUnitsOptions({perwakilan_id: value.value})
          // if(value.value == 'all'){
          // this._onSearchContent();
          // }
        }
        if (name === 'units_id') {
          const param = this.state.param.units;
          const levels_units = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.levels_id:"";
          
          console.log(levels_units)
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                levels_id: levels_units,
                units_id : value,
              }
            },
          }));
          this._getParamOptionsAcademics("classrooms", {
            levels_id: levels_units, organizations_id: organizations_id, units_id: value.value
          });
        }
        if (name == 'payments_id'){
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
          }));
        }
        if (name == 'jenis'){
          this.setState(prevState => ({
            ...prevState,
            isTable:true,
          }));
        }

          // this._onSearchContent();

        // }
      }
    );
  }

  _onClickStudent(data, idx) {
    this._onSearchStudent(data);
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
      data_idx: idx,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  _onClickSubmit() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp");
  }

  _onClickEdit(val) {
    const { history, user } = this.props;
    const { organizations_id}  = user;
    // console.log("ini val" +val)
    if(organizations_id == 4 ||  organizations_id == 5){
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", {data: val, isEdit: true });
    }
    else {
      history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form, savedUnits } = this.state;
    const { value } = form;
    const { subject_id, classrooms_id, period } = value;
    const { units_id, payments_id, perwakilan_id, jenis } = filters;

    console.log(savedUnits)
   
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListAdjustmentRecapUnits,
            handleListAdjustmentRecapAllUnits,
            handleListAdjustmentRecapPerwakilan,
            handleListAdjustmentRecapUnitsRutin,
            handleListAdjustmentRecapAllUnitsRutin,
            handleListAdjustmentRecapPerwakilanRutin,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id } = school_unit;
          const units =
            user_group.name == "pusat" || user_group.name == "perwakilan" ? units_id.value : school_unit.id;
            const perwakilanValue = perwakilan_id?perwakilan_id.value:'';  
            
            const perwakilan = user_group.name == "perwakilan" ?school_unit.perwakilan_id : perwakilanValue;
          
            console.log("perwakilan " +perwakilan)
            console.log("units " + units)
            console.log(payments_id)
            let result = {};

            if(jenis === 'spp'){
               if(!units_id){
              result = await handleListAdjustmentRecapUnitsRutin({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                payments_id: payments_id,
                filters,
              });
            } else if(units_id.value !== "all") {
              result = await handleListAdjustmentRecapUnitsRutin({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                payments_id: payments_id,
                filters,
              });
            } else if(perwakilan === "all" && units_id.value === "all") {
              if (!(savedUnits && savedUnits.perwakilan === "all" && savedUnits.units_id == "all" && savedUnits.jenis == "spp")){
                // delete filters.username;
                this.setState((prevState) => ({
                  ...prevState,
                  savedUnits: {
                    units_id : units,
                    perwakilan : perwakilan,
                    jenis: jenis
                  },
                }));
              result = await handleListAdjustmentRecapAllUnitsRutin({
                ...params,
                organizations_id: organizations_id,
                payments_id: payments_id,
                filters,
              });
            }
            } else if( perwakilan !== "all" && units_id.value === "all") {
              if (!(savedUnits && savedUnits.perwakilan !== "all" && savedUnits.units_id == "all" && savedUnits.jenis == "spp")){
                
                this.setState((prevState) => ({
                  ...prevState,
                  savedUnits: {
                    units_id : units,
                    perwakilan : perwakilan,
                    jenis: jenis,
                  },
                }));
              result = await handleListAdjustmentRecapPerwakilanRutin({
                ...params,
                organizations_id: organizations_id,
                payments_id: payments_id,
                perwakilan_id: perwakilan,
                filters,
              });
            }
          }
        }
            if(jenis === 'upp'){
               if(!units_id){
              result = await handleListAdjustmentRecapUnits({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                payments_id: payments_id,
                filters,
              });
            } else if(units_id.value !== "all") {
              result = await handleListAdjustmentRecapUnits({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                payments_id: payments_id,
                filters,
              });
            } else if(perwakilan === "all" && units_id.value === "all" && payments_id) {
              if (!(savedUnits && savedUnits.perwakilan === "all" && savedUnits.units_id == "all" && savedUnits.payments_id == payments_id && savedUnits.jenis == "upp")){
                // delete filters.username;
                this.setState((prevState) => ({
                  ...prevState,
                  savedUnits: {
                    units_id : units,
                    perwakilan : perwakilan,
                    payments_id: payments_id,
                    jenis: jenis
                  },
                }));
              result = await handleListAdjustmentRecapAllUnits({
                ...params,
                organizations_id: organizations_id,
                payments_id: payments_id,
                filters,
              });
            }
            } else if( perwakilan !== "all" && units_id.value === "all" && payments_id) {
              if (!(savedUnits && savedUnits.perwakilan !== "all" && savedUnits.units_id == "all" && savedUnits.payments_id == payments_id && savedUnits.jenis == "upp")){
                
                this.setState((prevState) => ({
                  ...prevState,
                  savedUnits: {
                    units_id : units,
                    perwakilan : perwakilan,
                    payments_id : payments_id,
                    jenis: jenis
                  },
                }));
              result = await handleListAdjustmentRecapPerwakilan({
                ...params,
                organizations_id: organizations_id,
                payments_id: payments_id,
                perwakilan_id: perwakilan,
                filters,
              });
            }
          }
        }
           

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }


  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    const { form } = this.state;
    const { units_id } = form.value;
    
    if (user_group.name !== 'pusat'){
      if (type === "classrooms" || type === "classrooms") {
        filters.organizations_id = organizations_id;
        {units_id?filters.units_id = units_id : filters.units_id = school_unit.id}
        
        // filters.units_id = 121;
      } else {
        delete filters.organizations_id;
      }
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }
  async _getSchoolUnitsOptions(filters={}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id); 
    
    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onClickSet(){
    this.setState(
      (prevState) => ({
        ...prevState,
        isTable: false,
        filters: {
          ...prevState.filters,
        },
      }),
      () => {
        this._onSearchContent()
      }
    );
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
      isTable
    } = this.state;
    const { listsiswa } = form.value;
    const { classrooms_id = "", status_id = "", period = "", units_id, payments_id, jenis } = filters;
    // console.log(total_siswa);
    const { classes = {}, levels = {}, user } = this.props;
    const { school_unit, user_group, organizations_id } = user;
    const { prm_payments } = param;

    const All_unit = [{value: 'all', label: "Semua Unit", attributes: {}}]
    const All = [{value: 'all', label: "Semua perwakilan", attributes: {}}]

    const componentRef = React.createRef();
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    const units_label = units_id?units_id.label:'';
    const units_attributes = units_id?units_id.attributes:'';
    const units_kode = units_attributes?units_attributes.unit_code:'';

    const prm_period = param.periods ? param.periods : '';
    const prm_units = param.units ? param.units : '';
    const prm_perwakilan= param.perwakilan_prm ? param.perwakilan_prm : '';
    const perwakilan_all = [...All, ...prm_perwakilan]
    const period_label = prm_period?prm_period.find((i) => i.value == period)?prm_period.find((i) => i.value == period).label:'':'';
    const units_all = [...All_unit, ...prm_units]
    const years = []
    const thisyear = new Date().getFullYear();
    for(var i=2021; i<=thisyear; i++) {
      var nextyear=Number(i)+1
      years.push({
        label: i+"-"+nextyear,
        value: i,
      });
    }
    const prm_jenis = [
      { label: organizations_id ==3?'SPP':'RUTIN', value: 'spp'},
      { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'upp'},
    ];
    console.log(isTable)
    console.log(form.value.units_id.value)


    let ttl_kesanggupan = 0;
    let ttl_tagihan = 0;
    let ttl_penyesuaian = 0;
    return (
      <div className="manage-registration">
        <h1>Rekap Penyesuaian</h1>
        <div className="absensi-rekap__custom-form">
        {(user_group.name === "pusat" || user_group.name === "perwakilan")? (
              <div>
              <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                {user_group.name == "pusat"?
                 
                  <SearchSelect
                    noMargin
                    async={false}
                    name="perwakilan_id"
                    list={perwakilan_all}
                    inputArray
                    onClick={this._onChangeFilter}
                    placeholder="Pilih Perwakilan"
                    value={form.value.perwakilan_id}
                    rightIcon="icon-search"
                    label="Pilih Perwakilan"
                  />
                  :''}
                </div>
              </div>
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="units_id"
                  list={units_all}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Unit"
                  value={form.value.units_id}
                  rightIcon="icon-search"
                  label="Pilih Unit"
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                  name="jenis"
                  label="Jenis Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_jenis}
                  placeholder="Pilihan"
                  value={jenis}
                />
              </div>
            </div>
            {(jenis === "upp") && (
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <Select
                  name="payments_id"
                  label="Nama Pembayaran"
                  onChange={this._onChangeFilter}
                  data={param.prm_payments}
                  placeholder={"Pilihan"}
                  value={payments_id}
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
             
              </div>
            </div>
            )}
            </div>
        ) : (
          <>
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  name="jenis"
                  label="Jenis Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_jenis}
                  placeholder="Pilihan"
                  value={jenis}
                />
              </div>
            <div className="absensi-rekap__custom-form-column__field">
            {(jenis === "upp") && (
              <Select
                name="payments_id"
                label="Nama Pembayaran"
                onChange={this._onChangeFilter}
                data={param.prm_payments}
                placeholder={"Pilihan"}
                value={payments_id}
              />
            )}
            </div>
          </div>
        </>
        )}
          
        </div>
        <br></br>
        <Button
          className="button_save"
          title="Proses"
          onClick={this._onClickSet}
          />
        <br></br>
        <div className="beginning-balance__button-wrapper">
          <ReactToPrint
          pageStyle={printTest}
          onBeforeGetContent={()=> {
            return new Promise((resolve) => {
              setTimeout(() => {
                this.setState(
                  { isPrint: false },
                  resolve
                );
              }, 500);
            });
          }}
          onAfterPrint={()=> this.setState({isPrint: true})}
            trigger={() => (
                <Button title="Cetak" />
            )}
            content={() => componentRef.current}
          />
            &nbsp;
          </div>
        <form style={{ pageBreakInside: 'avoid', paddingTop: '20px' }} ref={componentRef}>
          <div className="manage-registration">
            {form.value.units_id.value !== 'all'?

            <table className="laporan-ki4__table-test" style={{width:"unset"}}>
              <tr>
                <td>Kode Unit :  {user_group.name === 'pusat' ? units_kode : school_unit.unit_code} </td>
              </tr>
              <tr>
                <td>Nama Unit :  {user_group.name === 'pusat' ? units_label : school_unit.name} </td>
              </tr>
            </table>
            :
            ''}
            {(jenis === 'spp') && (
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                {form.value.units_id.value == 'all'?

                  <tr className="grey">
                    <th>No</th>
                    <th>Kode Unit</th>
                    <th>Nama Unit</th>
                    <th>Nominal</th>
                   </tr>
                :
                  <tr className="grey">
                    <th>No</th>
                    <th>No Va</th>
                    <th>Nama</th>
                    <th>Kelas</th>
                    <th>Nominal</th>
                    <th>Keterangan</th>
                </tr>}
                </thead>
                <tbody>
                {(form.value.units_id.value === 'all' && !isTable) && (

                  map(listsiswa, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {console.log("Masuk unit ")}

                      <td style={{textAlign:"center"}}>{idx + 1}</td>
                      <td style={{textAlign:"center"}}>{list.kode_unit}</td>
                      <td>{list.nama_unit}</td>
                      <td>{commaFormatted(list.tagihan)}</td>
                      {(() => {
                     ttl_tagihan = ttl_tagihan + Number(list.tagihan)
                    })()}
                   </tr>
                    )
                    ))}
                    
                   {(form.value.units_id.value !== 'all' && !isTable) && (
                     map(listsiswa, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {console.log("tidak Masuk unit ")}

                      <td style={{textAlign:"center"}}>{idx + 1}</td>
                      <td>{list.no_va}</td>
                      <td style={{textTransform: "capitalize"}}>{capitalize(list.nama)}</td>
                      <td>{list.class_name == null? "-" : list.class_name}</td>
                      <td>{commaFormatted(list.tagihan)}</td>
                      <td>{list.description}</td>
                      {(() => {
                      ttl_tagihan = ttl_tagihan + Number(list.tagihan)


                    })()}
                    </tr>
                   )
                   ))}

                    <tr>
                      <td colSpan={form.value.units_id.value == 'all'?3:4}>Total</td>
                      <td colSpan={2}>{ttl_tagihan?commaFormatted(ttl_tagihan):0}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            )}
            {(jenis === 'upp') && (
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                {form.value.units_id.value == 'all'?

                  <tr className="grey">
                    <th>No</th>
                    <th>Kode Unit</th>
                    <th>Nama Unit</th>
                    <th>Total Kesanggupan</th>
                    <th>Total Penyesuaian</th>
                    <th>Total Tagihan</th>
                   </tr>
                :
                  <tr className="grey">
                    <th>No</th>
                    <th>No Va</th>
                    <th>Nama</th>
                    <th>Kelas</th>
                    {/* <th>Kode Unit</th>
                    <th>Nama Unit</th> */}
                    <th>Total Kesanggupan</th>
                    <th>Total Penyesuaian</th>
                    <th>Total Tagihan</th>

                </tr>}
                </thead>
                <tbody>
                {(form.value.units_id.value === 'all' && !isTable) && (

                  map(listsiswa, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {console.log("Masuk unit ")}

                      <td style={{textAlign:"center"}}>{idx + 1}</td>
                      <td style={{textAlign:"center"}}>{list.kode_unit}</td>
                      <td>{list.nama_unit}</td>
                      <td>{commaFormatted(list.total_kesanggupan)}</td>
                      <td>{commaFormatted(list.total_penyesuaian)}</td>
                      <td>{commaFormatted(list.total_tagihan)}</td>
                      {(() => {
                     ttl_kesanggupan = ttl_kesanggupan + Number(list.total_kesanggupan)
                     ttl_penyesuaian = ttl_penyesuaian + Number(list.total_penyesuaian)
                     ttl_tagihan = ttl_tagihan + Number(list.total_tagihan)
                    })()}
                   </tr>
                    )
                    ))}
                    
                   {(form.value.units_id.value !== 'all' && !isTable) && (
                     map(listsiswa, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {console.log("tidak Masuk unit ")}

                      <td style={{textAlign:"center"}}>{idx + 1}</td>
                      <td>{list.no_va}</td>
                      <td style={{textTransform: "capitalize"}}>{capitalize(list.nama)}</td>
                      <td>{list.class_name == null? "-" : list.class_name}</td>
                      {/* <td style={{textAlign:"center"}}>{list.kode_unit}</td>
                      <td>{list.nama_unit}</td> */}
                      <td>{commaFormatted(list.kesanggupan)}</td>
                      <td>{commaFormatted(list.penyesuaian)}</td>
                      <td>{commaFormatted(list.tagihan)}</td>
                      {(() => {
                      ttl_kesanggupan = ttl_kesanggupan + Number(list.kesanggupan)
                      ttl_penyesuaian = ttl_penyesuaian + Number(list.penyesuaian)
                      ttl_tagihan = ttl_tagihan + Number(list.tagihan)


                    })()}
                    </tr>
                   )
                   ))}

                    <tr>
                      <td colSpan={form.value.units_id.value == 'all'?3:4}>Total</td>
                      <td>{ttl_kesanggupan?commaFormatted(ttl_kesanggupan):0}</td>
                      <td>{ttl_penyesuaian?commaFormatted(ttl_penyesuaian):0}</td>
                      <td>{ttl_tagihan?commaFormatted(ttl_tagihan):0}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            )}

          </div>
      </form>

        
      </div>
    );
  }
}
RekapPenyesuaian.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
RekapPenyesuaian.defaultProps = {
  handleGetStudent: noop,
};
