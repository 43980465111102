import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import { validateRequiredFields } from "../../utils/validation.util";
import { normalizeAmount, formatData } from "../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
  InputDate
} from "../../components/base/index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export default class TambahDataGuru extends Component {
  constructor(props) {
    super(props);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);

  
    const { user } = this.props;
    const { school_unit, organizations_id } = user;
    this.state = {
      page: 1,
      filters: {},
      form: {
        value: {
          organizations_id,
          units_id: school_unit.id
        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    const {
      handleGetCitizenships,
      handleGetReligions,
      handleGetEducations,
      handleGetOccupations,
      handleGetClasses,
      handleGetGenders,
      handleGetBloodTypes,
      handleGetLevels,
      location,
      user,
    } = this.props;
    const { state = {} } = location;
    const { school_unit, organizations_id } = user;
    const { levels_id, units_id } = school_unit;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    const paramTypes = [
      "educations",
      "occupations",
      "citizenships",
      "religions",
      "genders",
      "blood_types",
      "classes",
    ];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
  }

  async _setForm(id) {
    const { handleGetListTeacher, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { form } = this.state;
    const { value } = form;
    try {
      const payload = await handleGetListTeacher({ id });


      this.setState({
        form: {
          value: {
            id: payload.id,
            employee_id: payload.employee_id,
            full_name: payload.full_name,
            nip: payload.nip,
            nuptk: payload.nuptk,
            genders_id: payload.genders_id,
            phone: payload.phone,
            pob: payload.pob,
            dob: payload.dob,
            certificate: payload.certificate,
            
          },
          error: {
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async onEdit() {
    const { history, handleEditListTeacher } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    await handleEditListTeacher(value);
    history.push("/dashboard/pengaturan/data-guru");
    // }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }
  
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    if (name === "user_groups_id") {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          username: "",
        },
      }));
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, param } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem("param")) || "";
    const prm_usergroups = savedFilters.userGroups;
    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";

    if (filters.user_groups_id === "") {
      delete filters.user_groups_id;
    }
    if (
      filters.username === "" ||
      (!isEmpty(filters.username) && filters.username.value === "")
    ) {
      delete filters.username;
    }

    this.setState(async () => {
      try {
        const { handleGetListTeacher, user } = this.props;
        const { school_unit, organizations_id } = user;
        const result = await handleGetListTeacher({
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
        });
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listunits: result,
            },
          },
        }));
      } catch (err) {
        // if (this.isMount) {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listunits: [],
            },
          },
        }));
      }
      // }
    });
  }


  _setIsBlocked(isBlocked) {
    const { form, filters, param } = this.state;
    const { value } = form;
    const { listunits, classes_id } = value;
    const newList = JSON.parse(JSON.stringify(listunits));
    newList.forEach((item) => {
      item.is_blocked = isBlocked;
    });

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          listunits: newList,
        },
      },
    }));
  }


  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id });
        // }
        if (name === "is_blocked" && !inputArray) {
          this._setIsBlocked(value);
        }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === 'classes_id') {
          const param = this.state.param.classes_prm;
          this.setState(prevState => ({
                ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                // graduate: param.find(i => i.value == value).attributes.graduate, 
              }
            },
          }));
        
        }
      }
    );
  }
  _renderButtons(data) {
    const { user } = this.props;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onReset(data)} title="Reset" />
        </div>
      </td>
    );
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/tambah-data-guru', { isEdit: true} );
  }

  async _onSubmit() {
    const { history, handleSaveListTeacher } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, [
      // "nip",
      "nuptk",
      "full_name",
      "genders_id",
      "pob",
      "dob",
    ]);

    console.log(form.value.nip!==undefined)
    if( form.value.nip!==undefined ){
      if (error.nip == undefined && form.value.nip.length != 18) {
        error.nip = "NIK harus 18 digit";
      }
    }
    
    if (error.nuptk == undefined && form.value.nuptk.length != 16) {
      error.nuptk = "NIK harus 16 digit";
    }
    // if (error.guardian_nik == undefined && form.value.guardian_nik.length != 16) {
    //   error.guardian_nik = "NIK harus 16 digit";
    // }

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSaveListTeacher(value);
      history.push("/dashboard/pengaturan/data-guru");
    }
  }

  render() {
    const { form, filters, param } = this.state;
    const { listunits } = form.value;
    const prm_usergroups = param.userGroups;
    const prm_block = [
      { value: 0, label: "Open All" },
      { value: 1, label: "Block All" },
    ];

    const prm_is_block = [
      { value: 0, label: "Open" },
      { value: 1, label: "Block" },
    ];

    const userStudents = prm_usergroups
      ? prm_usergroups.find((e) => e.label == "student")
      : "";
  
    return (
      <div className="manage-registration">
        <form>
        <h1>Data Guru</h1>
       <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
                type="text"
                name="nip"
                label="NIP"
                onChange={this._onFormChange}
                value={form.value.nip}
                error={form.error.nip || ""}
                onInput={(e) => e.target.value = e.target.value.slice(0, 18)}
                isRequired
              />
          </div>
          <div className="manage-registration__custom-form-row__field">
          <Input
              type="text"
              name="nuptk"
              label="NUPTK *"
              onChange={this._onFormChange}
              value={form.value.nuptk}
              onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
              error={form.error.nuptk || ""}
            />
           
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Input
              type="text"
              name="full_name"
              label="Nama Guru *"
              onChange={this._onFormChange}
              value={form.value.full_name}
              error={form.error.full_name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
                name="genders_id"
                label="Jenis kelamin *"
                onChange={this._onFormChange}
                value={form.value.genders_id}
                error={form.error.genders_id || ""}
                placeholder={"Pilihan"}
                data={param.genders}
                isRequired
              />
           
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                  type="number"
                  name="phone"
                  label="No Telepon"
                  onChange={this._onFormChange}
                  value={form.value.phone}
                  error={form.error.phone || ""}
                />
            
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Input
              type="text"
              name="pob"
              label="Tempat Lahir *"
              onChange={this._onFormChange}
              value={form.value.pob}
              error={form.error.pob || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <InputDate
                type="date"
                name="dob"
                label="Tanggal Lahir *"
                onChange={this._onFormChange}
                value={form.value.dob}
                error={form.error.dob || ""}
                isRequired
              />
           
          </div>
        </div>
        <div className="student-list__button">
        {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this._onSubmit} />
          )}
        </div>
        </form>
      </div>
    );
  }
}
TambahDataGuru.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahDataGuru.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
