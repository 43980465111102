import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input, Select, Textarea, InputDate } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";

export default class CatatPrestasiForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);

    this.state = {
      form: {
        values: {
          classrooms: "",
          no_absen: "",
          tanggal: "",
          nis: "",
        },
        error: {
          classrooms: "",
        },
      },
    };
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => {
      console.log(this.state);
    });

    onChange(e);
  }

  render() {
    const { onChange, form, classrooms, onFormChange, param } = this.props;
    const { value } = form;
    const Matpel = [
      { label: "Bahasa Indonesia", value: "1" },
      { label: "Matematika", value: "2" },
      { label: "Pendidikan Kewarganegaraan", value: "3" },
      { label: "Pendidikan Jasmani Olahraga Kesehatan", value: "4" },
    ];
    const Period = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const order_of_achievement = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ];
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="period"
              label="Tahun Ajaran"
              placeholder="Pilih Tahun Ajaran"
              onChange={onFormChange}
              data={param.periods}
              value={form.value.period}
              error={form.error.period || ""}
              disabled
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="text"
              name="levels_id"
              label="Unit"
              disabled
              placeholder="Pilih Unit"
              data={param.levels}
              onChange={onFormChange}
              value={form.value.levels_id}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="classes_id"
              label="Tingkat Kelas *"
              onChange={onFormChange}
              data={param.classes}
              placeholder={'Pilihan'}
              value={form.value.classes_id}
              error={form.error.classes_id || ''}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="text"
              name="classrooms_id"
              label="Kelas *"
              placeholder="Pilih Kelas"
              data={param.classrooms}
              onChange={onFormChange}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ""}
              disabled={form.value.classes_id?false:true}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="students_id"
              label="Nama Siswa *"
              onChange={onFormChange}
              data={param.student}
              placeholder={"Pilihan"}
              value={form.value.students_id}
              error={form.error.students_id || ""}
              disabled={form.value.classrooms_id?false:true}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <InputDate
              type="date"
              name="date"
              label="Tanggal Prestasi *"
              onChange={onFormChange}
              value={form.value.date}
              error={form.error.date || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="type_achievement"
              label="Jenis Prestasi *"
              placeholder="Pilihan"
              data={param.prm_achievement}
              onChange={onFormChange}
              value={form.value.type_achievement}
              error={form.error.type_achievement || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="subject_id"
              label="Mata Pelajaran *"
              onChange={onFormChange}
              data={param.subject}
              placeholder="Pilih Matapelajaran"
              value={form.value.subject_id}
              error={form.error.subject_id || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              type="number"
              name="score"
              label="Skor *"
              onChange={onFormChange}
              value={form.value.score}
              error={form.error.score || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="text"
              name="order_of_achievement"
              label="Prestasi Ke *"
              placeholder="Pilihan"
              onChange={onFormChange}
              data={order_of_achievement}
              value={form.value.order_of_achievement}
              error={form.error.order_of_achievement || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Textarea
              type="text"
              name="description"
              label="Keterangan"
              // style={{ backgroundColor: "yellow" }}
              onChange={onFormChange}
              value={form.value.description}
              error={form.error.description || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field"></div>
        </div>
      </div>
    );
  }
}
CatatPrestasiForm.propTypes = {
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classrooms: PropTypes.array,
};
CatatPrestasiForm.defaultProps = {
  onChange: noop,
  onFormChange: noop,
  classrooms: [],
};
