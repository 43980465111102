import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, InputStaff, Button, Select } from '../../../components/base';

export default class DataCutiLama extends Component {
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteListCutiLama = this._onDeleteListCutiLama.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }
    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteListCutiLama(idx) {
        const { onDeleteListCutiLama, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteListCutiLama(idx, fieldName);
      }  

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }
     
    render() {
        const {
          list, error, onChange,
          fieldName, cuti_panjang_name, cuti_panjang,
        } = this.props;

        return (
            <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Alasan Cuti</th>
                  <th>Tanggal</th>
                  <th>Sampai Tanggal</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_cuti_panjang_form__${idx}`}>
                <td>{idx + 1}</td>
                <td>{data.alasan_cuti}</td>
                <td>{data.tanggal}</td>
                <td>{data.sampai_tanggal}</td>
                <td>{data.keterangan}</td>
                {/* <td>
                  <Button onClick={() => this._onDeleteListCutiLama(idx)} icon="icon-trash-o" />
                </td> */}
              </tr>
            ))
        }
        {/* <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr> */}
        </tbody>
        </table>
        );
    }
}

DataCutiLama.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteListCutiLama: PropTypes.func,
    onChange: PropTypes.func,
    cuti_panjang_name: PropTypes.array,
};

DataCutiLama.defaultProps = {
    onAddList: noop,
    onDeleteListCutiLama: noop,
    onChange: noop,
    cuti_panjang_name:[],
};