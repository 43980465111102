import DaftarGuru from '../../../views/Kurikulum/MataPelajaran/DaftarGuru.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getSubjectOptions, getClassesOptions } from '../../../states/thunks/options.thunk';
import { listTeacher, deleteListTeacher, listTeacherExcel } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      result: state.result,
      user: state.user || {},
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        handleDeleteListTeacher: (payload, goBack) => dispatch(deleteListTeacher(payload, goBack)),
        handleListTeacher: (payload, goBack) => dispatch(listTeacher(payload, goBack)),
        handleListTeacherExcel: (payload, goBack) => dispatch(listTeacherExcel(payload, goBack)),
        getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
        getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
      };
}
export default connect(mapStateToProps, mapDispatchToProps) (DaftarGuru);