import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_HARIAN } from "../../../constants/student.constant";
import { ABSENSI_HARIAN_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { Button, Input, Select } from "../../../components/base/index";
import { ANALISA_DISPLINER_FORM_FIELDS } from "../../../constants/Student/displiner.constant";
import KodeMatpelTable from "./components/KodeMatpelTable.component";
import JadwalMatpelTable1 from "./components/JadwalMatpelTable1.component";
import ReactToPrint from "react-to-print";
import { JADWAL_MATPEL_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JadwalMatpel extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickCetak = this._onClickCetak.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getDayOptions = this._getDayOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          classrooms: [],
          levels: [],
          classrooms_id: "",
          listjadwal: [],
        },
        error: {
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetclassrooms,
      handleGetLevels,
      user
    } = this.props;
    const { organizations_id, school_unit } = user;
    const paramTypes = ["classrooms", "levels"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPeriodsOptions({units_id: school_unit.id});
    this._getDayOptions();

    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });

  }

  async _getDayOptions() {
    const { getDayOptions } = this.props;
    const res = await getDayOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        day: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              periods_id: data.value,
            }
          },
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }


  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push(
      "/dashboard/pengaturan/tambah-jadwal-matpel",
      { isEdit: true }
    );
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/pengaturan/tambah-jadwal-matpel",
      { data: val, isEdit: true }
    );
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  _onClickCetak() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/mata-pelajaran/cetak/jadwal-mata-pelajaran"
    );
  }
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
      }
    );
  }



  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListScheduleSubject, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;

          const result = await handleListScheduleSubject({
            ...params,
            organizations_id: organizations_id,
            units_id: school_unit.id,
            filters,
          });
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listjadwal: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listjadwal: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  async onClickDelete(id) {
    const { handleDeleteScheduleSubject } = this.props;
    const res = await handleDeleteScheduleSubject(id);
    if (res) {
      this._onSearchContent();
    }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          <Button onClick={() => this.onClickDelete(data)} title="Hapus" />
        </div>
      </td>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    // const { form} = this.state;
    const { form } = this.state;
    const { handleAddBudget, history, user } = this.props;
    const { workingUnit, prm_school_units_id } = user;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    handleAddBudget(form.value, history.goBack, unit_id);
    // handleAddBudget(form.value, plafon, history.goBack, unit_id);
    //handleAddBudget(form.value, history.goBack, unit_id);
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }

  render() {
    const { form, content, param, filters } = this.state;
    const { value } = form;
    const { listjadwal } = form.value;

    const { classrooms_id = "", periods_id = "", day = "", semester = "" } = filters;
    let semesterPrm = [
      { value: "ganjil", label: "Ganjil" },
      { value: "genap", label: "Genap" },
    ];
    const { handleGetKelas, handleDeleteKelas, coa, classrooms } = this.props;
    const componentRef = React.createRef();
    const printTest = `
          @media print {
            @page {
              page-break-inside: avoid;
            }
            * {
              -webkit-print-color-adjust: exact;
            }
          }`;
    return (
      <div className="add-budget">
        <div className="nilai-kognitif__custom-form-row">
          <Select
            name="periods_id"
            label="Tahun Ajaran *"
            onChange={this._onChangeFilter}
            data={param.periods}
            placeholder={"Pilihan"}
            value={periods_id}
            isRequired
            // disabled
          />
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Select
            name="semester"
            label="Semester *"
            onChange={this._onChangeFilter}
            data={semesterPrm}
            placeholder={"Pilihan"}
            value={semester}
            isRequired
          />
        </div>
        <div className="nilai-kognitif__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Select
              name="classrooms_id"
              label="Kelas"
              onChange={this._onChangeFilter}
              placeholder="Pilih Kelas"
              data={param.classrooms}
              value={classrooms_id}

            />
          </div>
          <div className="data-pelanggaran__custom-form-row__field">
            <Select
              name="day"
              label="Hari"
              onChange={this._onChangeFilter}
              placeholder="Pilihan"
              data={param.day}
              value={day}
            />
          </div>
        </div>
           <div className="beginning-balance__button-wrapper">
           {/* <Button title="Cetak Data" onClick={this._onClickCetak} /> */}
          
          <ReactToPrint
            pageStyle={printTest}
            onBeforeGetContent={()=> {
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.setState(
                    { isPrint: false },
                    resolve
                  );
                }, 500);
              });
            }}
            onAfterPrint={()=> this.setState({isPrint: true})}
            trigger={() => (
              // <div className="manage-registration__footer">
                <Button title="Cetak/Simpan PDF" />
              // </div>
            )}
            content={() => componentRef.current}
          />
            <ReactHTMLTableToExcel
              id="export-to-xls-button"
              className="button "
              table="printable"
              filename={`Jadwal Mata Pelajaran`}
              buttonText="Unduh ke Excel"
            />
          </div>
          <div className="fund-request__content">
            <table className="table" ref={componentRef} id="printable"> 
              <thead>
                <tr className="grey">
                 <th>No</th>
                 <th>Kelas</th>
                 <th>Hari</th>
                 <th>Mata Pelajaran</th>
                 <th>Pengajar</th>
                 <th>Jam Mulai</th>
                 <th>Jam Selesai</th>
                </tr>
              </thead>
              <tbody>
                {map(listjadwal, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    <td>{idx+1}</td>
                    <td>{list.classrooms_name}</td>
                    <td>{list.day}</td>
                    {/* {this._renderButtons(list)} */}
                    <td>{list.subject_name}</td>
                    <td>{list.teacher_profile_name}</td>
                    <td>{list.start_hour}</td>
                    <td>{list.end_hour}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      </div>
    );
  }
}
JadwalMatpel.propTypes = {
  handleListScheduleSubject: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
JadwalMatpel.defaultProps = {
  handleListScheduleSubject: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
