import { connect } from 'react-redux';
import LaporanLulusTidakMelanjutkan from '../../../views/Akademi/LaporanIndividual/LaporanLulusTidakMelanjutkan.view';
import { getParamOptionsAcademics, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listReportLulusTidakLanjut, getHeadmaster } from '../../../states/thunks/academics.thunk';
function mapStateToProps(state){
    return{
        user: state.user,
        result: state.result,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleListReportLulus: (payload) => dispatch(listReportLulusTidakLanjut(payload)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
        getHeadmaster: (payload) => dispatch(getHeadmaster(payload)),
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanLulusTidakMelanjutkan);