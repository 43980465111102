import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input, Select, Textarea } from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";

export default function LembarHarianForm2(props) {
  const { onFormChange, form } = props;
  const choseone = [
    { label: "BB - Belum Berkembang", value: "belum_berkembang" },
    { label: "MB - Mulai Berkembang", value: "mulai_berkembang" },
    { label: "BSH - Berkembang Sesuai Harapan", value: "berkembang_sesuai_harapan" },
    { label: "BSB - Berkembang Sangat Baik", value: "berkembang_sangat_baik" },
  ];
  // const pengisian = [
  //   { label: 'Sikap', value: 'sikap'},
  //   { label: 'Kognitif', value: 'kognitif'},
  // ]
  return (
    <div className="lembar-harian2__custom-form">
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p>A : NORMA AGAMA & MORAL (NAM)</p>
          </div>
          <Select
            name="indicator_value"
            onChange={onFormChange}
            value={form.value.indicator_value || ""}
            error={form.error.indicator_value || ""}
            placeholder={"-Pilih-"}
            data={choseone}
          />
          <Textarea
            type="text"
            name="observation_result"
            value={form.value.observation_result}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
          />
        </div>
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p>B : SOSIAL EMOSIONAL</p>
          </div>
          <Select
            name="indicator_value_social"
            onChange={onFormChange}
            value={form.value.indicator_value_social || ""}
            error={form.error.indicator_value_social || ""}
            placeholder={"-Pilih-"}
            data={choseone}
          />
          <Textarea
            type="text"
            name="observation_result_social"
            value={form.value.observation_result_social}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
          />
        </div>
      </div>
      
      <br/>
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p>C : BAHASA</p>
          </div>
          <Select
            name="indicator_value_language"
            onChange={onFormChange}
            value={form.value.indicator_value_language || ""}
            error={form.error.indicator_value_language || ""}
            placeholder={"-Pilih-"}
            data={choseone}
          />
          <Textarea
            type="text"
            name="observation_result_language"
            value={form.value.observation_result_language}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
          />
        </div>
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p>D : FISIK MOTORIK (FISMOT)</p>
          </div>
          <Select
            name="indicator_value_fismot"
            onChange={onFormChange}
            value={form.value.indicator_value_fismot || ""}
            error={form.error.indicator_value_fismot || ""}
            placeholder={"-Pilih-"}
            data={choseone}
          />
          <Textarea
            type="text"
            name="observation_result_fismot"
            value={form.value.observation_result_fismot}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
          />
        </div>
      </div>
      
      <br/>
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p>E : KOGNITIF</p>
          </div>
          <Select
            name="indicator_value_kognitif"
            onChange={onFormChange}
            value={form.value.indicator_value_kognitif || ""}
            error={form.error.indicator_value_kognitif || ""}
            placeholder={"-Pilih-"}
            data={choseone}
          />
          <Textarea
            type="text"
            name="observation_result_kognitif"
            value={form.value.observation_result_kognitif}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
          />
        </div>
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p>F : SENI</p>
          </div>
          <Select
            name="indicator_value_art"
            onChange={onFormChange}
            value={form.value.indicator_value_art || ""}
            error={form.error.indicator_value_art || ""}
            placeholder={"-Pilih-"}
            data={choseone}
          />
          <Textarea
            type="text"
            name="observation_result_art"
            value={form.value.observation_result_art}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
          />
        </div>
      </div>

      
      {/* <div className="lembar-harian2__title">
        <h1>Catatan Guru</h1>
      </div>
      <Textarea
        type="text"
        name="teacher_notes"
        value={form.value.teacher_notes}
        onChange={onFormChange}
      /> */}
    </div>
  );
}
LembarHarianForm2.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LembarHarianForm2.defaultProps = {
  onFormChange: noop,
};
