import { connect } from 'react-redux';
import LaporanAbsensi from '../../../views/Student/absensi/LaporanAbsensi.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {getParamOptions, getSubjectOptions, getParamOptionsAcademics, getPeriodsOptions, getClassesOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import {listReportStudyAttendance, listReportStudentAttendance } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
      user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleListReportStudy: (payload, goBack) => dispatch(listReportStudyAttendance(payload, goBack)),
    handleListReportStudent: (payload, goBack) => dispatch(listReportStudentAttendance(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanAbsensi);


