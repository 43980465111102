import { connect } from 'react-redux';
import AR from '../../../views/Finance/Report/AR.report.view';
import { getBalanceReport } from '../../../states/thunks/report.thunk';
import { getPerwakilanOptions, getUnitOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    perwakilan: state.options.perwakilan,
    user: state.user,
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPerwakilan: payload => dispatch(getPerwakilanOptions(payload)),
    handleGetUnit: payload => dispatch(getUnitOptions(payload)),
    handleGetBalanceReport: payload => dispatch(getBalanceReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AR);
