import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';
import { Link } from "react-router-dom";
import * as commonActions from '../../../../states/actions/common.action';
import { listAnnouncementStudent } from "../../../../states/thunks/academics.thunk";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class RekapPembayaran extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickStudent = this._onClickStudent.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._onClickSubmit = this._onClickSubmit.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this._getParamPerwakilan = this._getParamPerwakilan.bind(this);
    this._onClickSet = this._onClickSet.bind(this);
    this.getPrmPaymentsNickGeneral = this.getPrmPaymentsNickGeneral.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      isTable:true,
      content: initialContent,
      list: [],
      filters:{
      },
      list: [],
      isPrint: true,
      form: {
        value: {
          levels_id,
          listsiswa: [],
          units_id:'',
          levels_units:'',
          user_id:user.id
        },
        error: {
          listsiswa: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem('recapFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('recapForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    console.log(savedFilters)
    console.log(savedForm)
    if (isEmpty(content.list)) {
    }
    this._initializeData(savedFilters);
    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id,
        }
      }));
     
      if(user_group.name === 'pusat'){
         this._getPeriodsOptions({units_id: savedForm.value.units_id.value});
         this._getParamOptionsAcademics("classrooms", {
          levels_id: savedForm.value.levels_id,
          organizations_id,
          units_id: savedForm.value.units_id.value
        });
        }
        else {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: savedForm.value.levels_id,
            organizations_id,
            // units_id: savedForm.value.units_id.value
          });
        }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user
    const paramTypesLevels = ["levels"];
    paramTypesLevels.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
   
    if(user_group.name !== 'pusat'){
      const paramTypes = ["classrooms", "levels"];

      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
      this._getPeriodsOptions({units_id: school_unit.id});
    }

    await this._getSchoolUnitsOptions();
    await this.getPrmPaymentsNickGeneral();

    // else {
    // await this.getPrmPaymentsNonGeneral();
    // }

    if (user_group.name == 'pusat'){
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id : savedFilters.classrooms_id,
          jenis : savedFilters.jenis,
          payments_id : savedFilters.payments_id, 
          period : savedFilters.period,
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
    else {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id : savedFilters.classrooms_id,
          jenis : savedFilters.jenis,
          payments_id : savedFilters.payments_id, 
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
   
  }


  async _getParamPerwakilan(filters = {}) {
    const { getParamPerwakilan, user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;
    const res = await getParamPerwakilan(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        perwakilan_prm: res,
      },
    }));
  }

  // async getPrmPaymentsNonGeneral() {
  //   const { getPrmPaymentsNonGeneral } = this.props;
  //   const res = await getPrmPaymentsNonGeneral();
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       prm_payments: res,
  //     },
  //   }));
  // }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      // window.localStorage.setItem('invoiceValue', JSON.stringify(this.state.form));
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    },
    () => {
      if (name === "levels_id") {
        this._getParamOptionsAcademics("classrooms", {
          levels_id: value,
          organizations_id: organizations_id
        });
      }
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { form } = this.state;
    const { organizations_id, school_unit } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('recapFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('recapForm', JSON.stringify(this.state.form));
       
        if (name === 'perwakilan_id'){
          this.setState((prevState) => ({
            ...prevState,
            isTable:true,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                perwakilan_id: value,
                units_id: "",
                listsiswa: []
              },
            },
            filters: {
              ...prevState.filters,
              units_id: "",
              payments_id:"",
            }
          }));
          this._getSchoolUnitsOptions({perwakilan_id: value.value})
          // if(value.value == 'all'){
          // this._onSearchContent();
          // }
        }
        if (name === 'units_id') {
            const param = this.state.param.units;
            console.log(value.value)
            const levels_units = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.levels_id:"";
            
            console.log(levels_units)
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  classrooms_id: "",
                  levels_id: levels_units,
                  units_id : value,
                }
              },
              filters : {
                ...prevState.filters,
                jenis: "",
              }
            }));
            this._getParamOptionsAcademics("classrooms", {
              levels_id: levels_units, organizations_id: organizations_id, units_id: value.value
            });
            this._getPeriodsOptions({units_id: value.value})
          }

          this._onSearchContent();

        // }
      }
    );
  }

  _onClickStudent(data, idx) {
    this._onSearchStudent(data);
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
      data_idx: idx,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  _onClickSubmit() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp");
  }

  _onClickEdit(val) {
    const { history, user } = this.props;
    const { organizations_id}  = user;
    // console.log("ini val" +val)
    if(organizations_id == 4 ||  organizations_id == 5){
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", {data: val, isEdit: true });
    }
    else {
      history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form, savedUnits, param } = this.state;
    const { value } = form;
    const { subject_id, currents_year } = value;
    const { units_id, payments_id, perwakilan_id, classrooms_id, period, jenis} = filters;
    const { prm_payments } = param;
    let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListRecapPayments,
            handleListRecapPaymentsUKG,
            handleListRecapPaymentsDPP,
            handleListRecapPaymentsTGK,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id } = school_unit;
          const units =
            user_group.name == "pusat" || user_group.name == "perwakilan" ? units_id.value : school_unit.id;
            const perwakilanValue = perwakilan_id?perwakilan_id.value:'';  
            
            const perwakilan = user_group.name == "perwakilan" ?school_unit.perwakilan_id : perwakilanValue;
          
            let result = {};
            var year = new Date().getFullYear();
            var month = new Date().getMonth() + 1;
            if (month < 7) {
              year -= 1;
            }
            if(jenis=='USK'){
              classrooms_id == 'all_class'?
              result = await handleListRecapPayments({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              }):  
              result = await handleListRecapPayments({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                classrooms_id:classrooms_id,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              ;
            } else if(jenis == 'DPP'){
              classrooms_id == 'all_class'?
              result = await handleListRecapPaymentsDPP({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              :
              result = await handleListRecapPaymentsDPP({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                classrooms_id:classrooms_id,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              ;
            } else if(jenis == 'UKG'){
              classrooms_id == 'all_class'?
              result = await handleListRecapPaymentsUKG({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              :
              result = await handleListRecapPaymentsUKG({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                classrooms_id:classrooms_id,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              ;
            } else if(jenis == 'TGK'){
              classrooms_id == 'all_class'?
              result = await handleListRecapPaymentsTGK({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              :
              result = await handleListRecapPaymentsTGK({
                ...params,
                organizations_id: organizations_id,
                units_id: units,
                classrooms_id:classrooms_id,
                currents_year : currents_year ? currents_year : year,
                period: period,
                filters,
              })
              ;
            }
              
         
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }


  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    const { form } = this.state;
    const { units_id } = form.value;
    
    if (user_group.name !== 'pusat'){
      if (type === "classrooms" || type === "classrooms") {
        filters.organizations_id = organizations_id;
        {units_id?filters.units_id = units_id : filters.units_id = school_unit.id}
        
        // filters.units_id = 121;
      } else {
        delete filters.organizations_id;
      }
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }
  async _getSchoolUnitsOptions(filters={}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id); 
    
    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async getPrmPaymentsNickGeneral() {
    const { getPrmPaymentsNickGeneral } = this.props;
    const res = await getPrmPaymentsNickGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onClickSet(){
    this.setState(
      (prevState) => ({
        ...prevState,
        isTable: false,
        filters: {
          ...prevState.filters,
        },
      }),
      () => {
        this._onSearchContent()
      }
    );
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
      isTable
    } = this.state;
    const { listsiswa, currents_year } = form.value;
    const { classrooms_id = "", status_id = "", period = "", units_id, payments_id, jenis } = filters;
    // console.log(total_siswa);
    const { classes = {}, levels = {}, user } = this.props;
    const { school_unit, user_group, organizations_id } = user;
    const {total_monthly = [], total_payments = 0, total_outstanding = 0, total_yhd = 0, total_diff = 0,
           total_dpp = 0, total_ukg = 0, total_admin_fee = 0 } = listsiswa;
    const TGK = [{value: 'TGK', label: "Tunggakan"}]
    const All_unit = [{value: 'all', label: "Semua Unit", attributes: {}}]
    const All = [{value: 'all', label: "Semua perwakilan", attributes: {}}]
    const All_class = [{value: 'all_class', label: "Semua Kelas", attributes: {}}]
    const componentRef = React.createRef();
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;


      const bulan = [
        { label: 'Jul'},
        { label: 'Agu'},
        { label: 'Sep'},
        { label: 'Okt'},
        { label: 'Nov'},
        { label: 'Des'},
        { label: 'Jan'},
        { label: 'Feb'},
        { label: 'Mar'},
        { label: 'Apr'},
        { label: 'Mei'},
        { label: 'Jun'},
      ];

    const units_label = units_id?units_id.label:'';
    const units_attributes = units_id?units_id.attributes:'';
    const units_kode = units_attributes?units_attributes.unit_code:'';

    const prm_period = param.periods ? param.periods : '';
    const prm_units = param.units ? param.units : '';
    const prm_perwakilan= param.perwakilan_prm ? param.perwakilan_prm : '';
    
    const prm_payments = param.prm_payments?param.prm_payments:'';
    const perwakilan_all = [...All, ...prm_perwakilan]
    const period_label = prm_period?prm_period.find((i) => i.value == period)?prm_period.find((i) => i.value == period).label:'':'';
    const units_all = [...All_unit, ...prm_units]
    const prm_classrooms = param.classrooms?param.classrooms:'';
    const classrooms_all = [...All_class, ...prm_classrooms]
    const prm_payments_add = [...prm_payments, ...TGK]
    const years = []
    const thisyear = new Date().getFullYear();
    for(var i=2021; i<=thisyear; i++) {
      var nextyear=Number(i)+1
      years.push({
        label: i+"-"+nextyear,
        value: i,
      });
    }
    const prm_jenis = [
      { label: organizations_id ==3?'SPP':'RUTIN', value: 'spp'},
      { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'upp'},
    ];

    if(!isEmpty(param.periods) && period) {
        let period_label = param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""
        if (period_label && period_label != "") {
          let period_idx = period_label.indexOf("-");
          form.value.currents_year = period_label.substring(0, period_idx)
        }
      }
    let ttl_kesanggupan = 0;
    let ttl_tagihan = 0;
    let ttl_penyesuaian = 0;

    console.log(classrooms_all)
    let label_jenis = prm_payments_add.find((i) => i.value == jenis)?prm_payments_add.find((i) => i.value == jenis).label:''
   console.log(label_jenis)
    return (
      <div className="manage-registration">
        <h1>Rekap Pembayaran</h1>
        <hr></hr>
        <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="units_id"
                  list={param.units}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Unit"
                  value={form.value.units_id}
                  rightIcon="icon-search"
                  label="Pilih Unit"
                />
              </div>
            </div>
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder="Pilihan"
                  value={period}
                />
              </div>
            <div className="absensi-rekap__custom-form-column__field">
           
              <Select
                name="classrooms_id"
                label="Kelas"
                onChange={this._onChangeFilter}
                data={classrooms_all}
                placeholder={"Pilihan"}
                value={classrooms_id}
              />
            </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <Select
                  name="jenis"
                  label="Jenis Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_payments_add}
                  placeholder="Pilihan"
                  value={jenis}

              />
              </div>
            <div className="absensi-rekap__custom-form-column__field">
           
            </div>
          </div>
        </div>
        <br></br>
        {/* <Button
          className="button_save"
          title="Proses"
          onClick={this._onClickSet}
          /> */}
        <br></br>
        <div className="beginning-balance__button-wrapper">
            <ReactHTMLTableToExcel
                id="export-to-xls-button"
                className="button "
                table="printable"
                filename={`Rekap Pembayaran Tahun Ajaran ${currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}}`}
                sheet={currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}
                buttonText="Unduh ke Excel"
              />

            &nbsp;
          </div>
          <form style={{ pageBreakInside: 'avoid', paddingTop: '20px' }} ref={componentRef}>
          <div>
            <table className="table-report-header">
              <tr>
                <th rowSpan="2">
                  {/* <img style={{width:"70px"}} src={logo_right} /> */}
                </th>
               
              </tr>
            
            </table>
                  <table className="tablespp" id="printable">
                    <thead>
                    <th rowSpan={2}>No</th>
                    <th rowSpan={2}>No VA</th>
                    <th rowSpan={2}>Nama</th>
                    {classrooms_id == 'all_class'?<th rowSpan={2}>Kelas</th> : ''}
                    <th rowSpan={2}>Yang Harus Dibayar {label_jenis}</th>
                    <th colSpan={12}>Bulan {label_jenis} <br></br>Tapel {currents_year?currents_year+"/"+(Number(currents_year)+1):''}</th>
                    <th rowSpan={2}>Total Penerimaan {label_jenis}</th>
                    <th rowSpan={2}>Sisa Piutang {label_jenis}</th>
                      <tr>
                        {map(bulan, (bln, idx) => (
                          <th>{bln.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                        {map(listsiswa.data, (data, idx) => (
                            <tr key={`budget_row_${idx}`}>
                                <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                                    {idx + 1}
                                </td>
                                <td>{data.no_va}</td>
                                <td style={{textTransform:"capitalize"}}>{capitalize(data.name)}</td>
                                {classrooms_id == 'all_class'?<td>{data.classrooms_name}</td> : ''}
                                <td>{commaFormatted(data.yhd)}</td>
                                <td>{data.invoices[7] ? commaFormatted(data.invoices[7]) : 0}</td>
                                <td>{data.invoices[8] ? commaFormatted(data.invoices[8]) : 0}</td>
                                <td>{data.invoices[9] ? commaFormatted(data.invoices[9]) : 0}</td>
                                <td>{data.invoices[10] ? commaFormatted(data.invoices[10]) : 0}</td>
                                <td>{data.invoices[11] ? commaFormatted(data.invoices[11]) : 0}</td>
                                <td>{data.invoices[12] ? commaFormatted(data.invoices[12]) : 0}</td>
                                <td>{data.invoices[1] ? commaFormatted(data.invoices[1]) : 0}</td>
                                <td>{data.invoices[2] ? commaFormatted(data.invoices[2]) : 0}</td>
                                <td>{data.invoices[3] ? commaFormatted(data.invoices[3]) : 0}</td>
                                <td>{data.invoices[4] ? commaFormatted(data.invoices[4]) : 0}</td>
                                <td>{data.invoices[5] ? commaFormatted(data.invoices[5]) : 0}</td>
                                <td>{data.invoices[6] ? commaFormatted(data.invoices[6]) : 0}</td>
                                <td>{commaFormatted(data.total_payments)}</td>
                                <td>{commaFormatted(data.diff)}</td>
                            </tr>
                        ))}
                        <tr>
                          <td colSpan={classrooms_id == 'all_class'?4:3}>Total</td>
                          <td>{commaFormatted(total_yhd)}</td>
                          <td>{commaFormatted(total_monthly[6])}</td>
                          <td>{commaFormatted(total_monthly[7])}</td>
                          <td>{commaFormatted(total_monthly[8])}</td>
                          <td>{commaFormatted(total_monthly[9])}</td>
                          <td>{commaFormatted(total_monthly[10])}</td>
                          <td>{commaFormatted(total_monthly[11])}</td>
                          <td>{commaFormatted(total_monthly[0])}</td>
                          <td>{commaFormatted(total_monthly[1])}</td>
                          <td>{commaFormatted(total_monthly[2])}</td>
                          <td>{commaFormatted(total_monthly[3])}</td>
                          <td>{commaFormatted(total_monthly[4])}</td>
                          <td>{commaFormatted(total_monthly[5])}</td>
                          <td>{commaFormatted(total_payments)}</td>
                          <td>{commaFormatted(total_diff)}</td>
                        </tr>
                    </tbody>
                  </table>
          </div>
        </form>


        
      </div>
    );
  }
}
RekapPembayaran.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
RekapPembayaran.defaultProps = {
  handleGetStudent: noop,
};
