import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../components/base/index';
import { commaFormatted, formatData, normalizeAmount } from '../../../../utils/transformer.util';

export default class MatpelGuruTable extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    
    const {
      error, onChange, param, list,form,  onFormChange,
      fieldName, plafond_name,
      classrooms
    } = this.props;
    
    const Matpel = [
        { label: 'Bahasa Indonesia', value: '1' },
        { label: 'Matematika', value: '2' },
        { label: 'Pendidikan Kewarganegaraan', value: '3' },
        { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: '4' },
        
      ];

      const Pengajar = [
        { label: 'Renang', value: 'renang'},
        { label: 'Badminton', value: 'badminton'},
        { label: 'Basket', value: 'Basket'},
      ]

    return (
    <div>
    <p>Mata Pelajaran</p>
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            {/* <th>Unit</th>
            <th>Tingkat Kelas</th> */}
            <th>Kelas</th>
            <th>Mata Pelajaran</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td style={{textAlign:"center"}}>{idx + 1}</td>
                {/* <td>
                  <Select
                    value={data.levels_id}
                    placeholder="Pilih Unit"
                    data={param.levels}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='matpel'
                    // disabled
                    name="levels_id"
                    onChange={onFormChange}
                  />
                </td>
                <td>
                  <Select
                    value={data.classes_id}
                    placeholder="Pilih Tingkat Kelas"
                    data={param.classes}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='matpel'
                    name='classes_id'
                    onChange={onFormChange}
                  />
                </td> */}
                <td className="nominal">
                <Select
                    value={data.classrooms_id}
                    placeholder="Kelas"
                    data={param.classrooms}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='matpel'
                    name='classrooms_id'
                    onChange={onFormChange}
                  />   
                </td>
               
                <td>
                  <Select
                    value={data.subject_id}
                    placeholder="Pilih Mata Pelajaran"
                    data={param.subject}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='matpel'
                    name='subject_id'
                    onChange={onFormChange}
                  />

                </td>
               
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={7}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }
}

MatpelGuruTable.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  classrooms:PropTypes.array,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,

};
MatpelGuruTable.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onFormChange: noop,
  onChange: noop,
  plafond_name: [],
  classrooms : [],
};
