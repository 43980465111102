import { connect } from 'react-redux';
import RincianPembayaran from '../../../../views/Finance/Spp/RincianPembayaran/RincianPembayaran.view';
import { getStudentSPPOptions, getParamOptionsAcademics, getYearsOfEducationsOptions, getMsTempsSiswaOptions, getPrmStudentGeneral, getSchoolUnitsOptions } from '../../../../states/thunks/options.thunk';
import { historyPaymentsRutin, historyPaymentsNonRutin, listPaymentGateway, saveTemporary, detailPayments, historyPaymentsRutinVA, historyPaymentsNonRutinVA} from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    handleHistoryPaymentsRutin: (payload, goback) => dispatch(historyPaymentsRutin(payload, goback)),
    handleHistoryPaymentsNonRutin: (payload, goback) => dispatch(historyPaymentsNonRutin(payload, goback)),
    handleListPaymentGateway: (payload, goback) => dispatch(listPaymentGateway(payload, goback)),
    handleSaveTemporary: (payload, goback) => dispatch(saveTemporary(payload, goback)),
    handleDetailPayments: (payload, goback) => dispatch(detailPayments(payload, goback)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    handleHistoryPaymentsRutinGeneral: (payload, goback) => dispatch(historyPaymentsRutinVA(payload, goback)),
    handleHistoryPaymentsNonRutinGeneral: (payload, goback) => dispatch(historyPaymentsNonRutinVA(payload, goback)),
    getPrmStudentGeneralOptions: (payload) => dispatch(getPrmStudentGeneral(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
 
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RincianPembayaran);



