import BuatKodeMatpel from '../../../views/Kurikulum/MataPelajaran/BuatKodeMatpel.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getMajorOptions, getSubjectTypeOptions,
         getParamOptionsAcademics, getClassesOptions, getSubjectGroupOptions,
         getClassroomOptions, getPeriodsOptions, getPrmLevels } from '../../../states/thunks/options.thunk';
import { saveSubjectDetail, getKodeMatpel, EditKodeMatpel } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      result: state.result,
      classes: state.options.classes,
      user: state.user,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getSubjectTypeOptions: (payload) => dispatch(getSubjectTypeOptions(payload)),
        getSubjectGroupOptions: (payload) => dispatch(getSubjectGroupOptions(payload)),
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getClassroomsOptions: (payload) => dispatch(getClassroomOptions(payload)),
        getMajorOptions: (payload) => dispatch(getMajorOptions(payload)),
        handleSaveSubjectDetail: (payload, goback) => dispatch(saveSubjectDetail(payload, goback)),
        handleGetSubjectDetail: payload => dispatch(getKodeMatpel(payload)),
        handleEditSubjectDetail: (payload, goback) => dispatch(EditKodeMatpel(payload, goback)), 
        getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps) (BuatKodeMatpel);