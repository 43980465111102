import { connect } from 'react-redux';
import LaporanPembayaranTotalGeneral from '../../../../views/Finance/Spp/LaporanSPP/LaporanPembayaranTotalGeneral.view';
import { getStudentSPPOptions, getParamOptionsAcademics, getSchoolUnitsOptions, getPaymentsTypeOptions, getYearsOfEducationsOptions} from '../../../../states/thunks/options.thunk';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, historyDetailPaymentsH2H, 
         reportTotalPayments, reportTotalPaymentsGeneral, reportPaymentsGeneral, reportPaymentsGeneralPPDB, reportTotalPaymentsGeneralPPDB, reportTotalAdminGeneral, reportTotalAdminGeneralPPDB  } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    // getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleHistoryDetailPayments: (payload, goback) => dispatch(historyDetailPaymentsRutin(payload, goback)),
    handleHistoryDetailPaymentsOffline: (payload, goback) => dispatch(historyDetailPaymentsOffline(payload, goback)),
    handleHistoryDetailPaymentsH2H: (payload, goback) => dispatch(historyDetailPaymentsH2H(payload, goback)),
    handleReportTotalPayments: (payload, goback) => dispatch(reportTotalPayments(payload, goback)),
    handleReportTotalPaymentsGeneral: (payload, goback) => dispatch(reportTotalPaymentsGeneral(payload, goback)),
    handleReportTotalPaymentsGeneralPPDB: (payload, goback) => dispatch(reportTotalPaymentsGeneralPPDB(payload, goback)),
    handleReportTotalAdminGeneral: (payload, goback) => dispatch(reportTotalAdminGeneral(payload, goback)),
    handleReportTotalAdminGeneralPPDB: (payload, goback) => dispatch(reportTotalAdminGeneralPPDB(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPaymentsTypeOptions: (payload, goback) => dispatch(getPaymentsTypeOptions(payload, goback)),
    handleReportPaymentsGeneral: (payload, goback) => dispatch(reportPaymentsGeneral(payload, goback)),
    handleReportPaymentsGeneralPPDB: (payload, goback) => dispatch(reportPaymentsGeneralPPDB(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPembayaranTotalGeneral);
