import React, { PureComponent,Component } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Button from "../../../components/base/Button/Button.component";
import ReactToPrint from "react-to-print";
import ComponentToPrint from '../components/PrintST.component';
import { FORMAL_BPJS_KESEHATAN_FORM } from "../../../constants/personnel.constant";


export default class PreviewSuratKeputusan extends Component {
    constructor(props){
        super(props);
        this.onClickToList = this.onClickToList.bind(this);
        this.handler = this.handler.bind(this);

        this.state = {
            disabled : false,
        }
    }

    componentDidMount(){
        // console.log(this.props)
    }

    handler() {
        this.setState({
            disabled: true
        })
    }

    onClickToList(){
        const { history,location } = this.props;
        let val = location.state.data;
        history.push(`/dashboard/personalia/surat-tugas/${val.nip}`,{ data: val });
    }

    _onClickSubmit(id){
        const { history, location, handleSubmitST } = this.props;
        const data = location.state.data;
        // console.log(this.props)
        handleSubmitST(
            { nip: data.nip, id: location.state.id, data: data },
            history.goBack,
            true
            
        ); 
        history.push(`/dashboard/personalia/surat-tugas/${data.nip}`,{ data: data });
    }

    _onClickEdit(id) {
        const { history, location } = this.props;
        const data = location.state.data;
        //console.log(this.props)
        history.push(`/dashboard/personalia/tambah-surat-tugas/${data.nip}`,
        { data: { id:this.props.location.state.id,nip: data.nip, unit_kerja : data.unit_kerja.id_unit_kerja}, isEdit: true });
    }

    render(){
        console.log(this.props)
        const {handleGetDataST, location} = this.props;
        return(
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box">
                                <div className="divContainter">
                                    <div className="preview_sk__content">
                                        <ComponentToPrint ref={el => (this.componentRef = el)} 
                                            datapegawai = {location.state.data}
                                            handler ={this.handler}
                                            id = {location.state.id}
                                            kota = {location.state.kota}
                                            handleGetDataST = {handleGetDataST}
                                        />
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div  className="preview_sk__button">
                    <div className="table__actions">
                    <ReactToPrint
                        trigger={() => (
                            <Button 
                                style={{ marginBottom: 5 }}
                                title = "Cetak" 
                                block
                            />
                        )}
                        content={() => this.componentRef}
                    /> 

                    <Button 
                        style={{ marginBottom: 20 }} 
                        title = "Kembali"
                        onClick = {()=>this.onClickToList()}
                    />
                    </div>
                    {/* <ReactToPdf  targetRef={() => this.componentRef} filename="SuratSK.pdf">
                        {({toPdf}) => (
                            <Button onClick={toPdf} 
                                title = "Simpan PDF" 
                            />
                        )}
                    </ReactToPdf> */}
                    <br/>
                    {!this.state.disabled?
                    <div className="table__actions">
                    <Button 
                        style={{ marginBottom: 20 }} 
                        title = "Edit"
                        onClick = {()=>this._onClickEdit()}
                    />
                    <Button 
                        style={{ marginBottom: 20 }} 
                        title = "Submit"
                        onClick = {()=>this._onClickSubmit()}
                    />
                    </div>
                    :null}
                    
                </div> 
            </div>
        );
    }
}
PreviewSuratKeputusan.propTypes = {
    handelgetDataPegawai: PropTypes.func,
    handleGetDataST : PropTypes.func,
    handleSubmitST : PropTypes.func,
};
PreviewSuratKeputusan.defaultProps = {
    handelgetDataPegawai: noop,
    handleGetDataST : noop,
    handleSubmitST : noop,
};