import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, isEqual, noop } from "lodash";
import { Input, Select, Textarea } from "../../../components/base/index";

export default class PPDBAlamatForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.state = {};
    this.updated = false;
  }

  _onFormChange(ev) {
    const { onChange } = this.props;
    onChange(ev);
  }

  render() {
    const { form } = this.props;

    if (form.value.province == "") {
      form.value.sub_province = "";
    }
    if (form.value.sub_province == "") {
      form.value.district = "";
    }
    if (form.value.district == "") {
      form.value.sub_district = "";
    }

    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="address"
            label="Alamat Tempat Tinggal *"
            onChange={this._onFormChange}
            value={form.value.address}
            error={form.error.address || ""}
            isRequired
          />
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="rt"
              label="RT"
              onChange={this._onFormChange}
              value={form.value.rt}
              error={form.error.rt || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="number"
                name="rw"
                label="RW"
                onChange={this._onFormChange}
                value={form.value.rw}
                error={form.error.rw || ""}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="province"
              label="Propinsi"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.province}
              error={form.error.province}
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {form.value.province && (
              <Input
                name="sub_province"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.sub_province}
                error={form.error.sub_province || ""}
              />
            )}
          </div>
          <div className="manage-registration__custom-form-row__field">
            {form.value.sub_province && (
              <Input
                name="district"
                label="Kecamatan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.district}
                error={form.error.district || ""}
              />
            )}
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {form.value.district && (
              <Input
                name="sub_district"
                label="Kelurahan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.sub_district}
                error={form.error.sub_district || ""}
              />
            )}
          </div>
          <div className="manage-registration__custom-form-row__field">
          </div>
        </div>
      </div>
    );
  }
}
PPDBAlamatForm.propTypes = {
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
};
PPDBAlamatForm.defaultProps = {
  onChange: noop,
};
